<template>
  <div class="py-2" style="overflow: hidden">
    <v-alert v-if="error != undefined" type="error" outlined text>
      {{ error }}
    </v-alert>

    <div class="scroll-container" row>
      <div class="third" scroll>
        <v-treeview
          v-bind:items="expression"
          color="primary"
          open-on-click
          dense
          hoverable
        >
          <template v-slot:label="{ item }">
            <div
              v-if="item.children == undefined"
              v-on:click.stop="editRule(item)"
              v-bind:class="{
                'font-weight-bold': true,
                error: fault != undefined && fault.id === item.id,
                primary:
                  edit != undefined &&
                  edit.id === item.id &&
                  fault == undefined,
                'white--text':
                  (edit != undefined && edit.id === item.id) ||
                  (fault != undefined && fault.id === item.id),
                'pa-2':
                  (edit != undefined && edit.id === item.id) ||
                  (fault != undefined && fault.id === item.id),
                rounded:
                  (edit != undefined && edit.id === item.id) ||
                  (fault != undefined && fault.id === item.id),
              }"
            >
              {{ parse(item.obj) }}
            </div>
            <div v-else>
              <span
                v-bind:class="
                  (fault != undefined && fault.id === item.id) || item.faulty
                    ? 'red--text'
                    : ''
                "
              >
                {{ item.name }}
              </span>

              <v-btn
                v-on:click.stop="addComparisonDialog(item)"
                v-bind:title="$t('addRuleBoolean')"
                class="primary ms-2"
                icon
                x-small
                dark
              >
                <v-icon color="white">mdi-plus</v-icon>
              </v-btn>

              <v-btn
                v-on:click.stop="confirmDeleteItem(item.id)"
                v-bind:title="
                  $t('btnDelete') + ' ' + $t('rulesGroup').toLowerCase()
                "
                class="error ms-2"
                icon
                x-small
                dark
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-treeview>

        <div>
          <v-btn
            v-on:click="addComparisonDialog()"
            v-bind:text="expression.length > 0"
            class="mt-6 ps-2"
            color="primary"
          >
            <v-icon>mdi-plus</v-icon>
            <span class="ms-1">{{ $t("addRuleBoolean") }}</span>
          </v-btn>
        </div>
      </div>
      <div class="two-third pa-2" scroll>
        <template v-if="edit !== undefined">
          <v-card>
            <v-card-title>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                "
              >
                {{
                  typeof edit.obj === "object"
                    ? $t("ruleComparison")
                    : $t("comparator")
                }}

                <v-btn
                  v-on:click.stop="confirmDeleteItem(edit.id)"
                  v-bind:title="$t('btnDelete')"
                  class="error"
                  icon
                  dark
                  small
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-card-title>

            <v-card-text>
              <template v-if="typeof edit.obj === 'object'">
                <v-combobox
                  v-model="edit.obj.topic"
                  v-bind:items="filteredAvailableTopics"
                  v-bind:search-input.sync="searchTopic"
                  v-bind:label="$t('ruleTopic')"
                  v-bind:outlined="outlinedPref"
                  clearable
                >
                  <template
                    v-if="!searchTopic || searchTopic.length === 0"
                    v-slot:prepend-item
                  >
                    <v-list-item class="mb-0 pt-0" dense>
                      <v-list-item-content>
                        <v-select
                          v-model="searchTopic"
                          v-bind:items="availableTopicsGroups"
                          v-bind:label="$t('topicGroup')"
                          clearable
                          hide-details
                        />
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="mt-0 pt-0" dense>
                      <v-list-item-content class="caption">
                        {{ filteredAvailableTopics.length }}
                        {{ $t("elements") }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider />
                  </template>
                  <template v-if="!!searchTopic" v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            $t('comboBoxNoData').replace('%s%', searchTopic)
                          "
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>

                <div class="mb-5" row>
                  <v-select
                    v-model="edit.obj.comparison"
                    v-bind:items="comparators"
                    v-bind:label="$t('comparator')"
                    v-bind:outlined="outlinedPref"
                    style="max-width: 12ch"
                  >
                    <template v-slot:item="{ item }">
                      <div
                        class="text-h5 d-flex justify-center"
                        style="width: 100%"
                      >
                        {{ $t(item) }}
                      </div>
                    </template>

                    <template v-slot:selection="{ item }">
                      <div
                        class="text-h5 d-flex justify-center"
                        style="width: 100%"
                      >
                        {{ $t(item) }}
                      </div>
                    </template>
                  </v-select>

                  <v-text-field
                    v-model="edit.obj.value"
                    v-bind:label="$t('targetValue')"
                    v-bind:outlined="outlinedPref"
                    clearable
                  />
                </div>

                <h4
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  "
                >
                  {{ $t("hysteresis") }}
                  <v-btn
                    v-if="hysteresisAdded"
                    v-on:click.stop="deleteHysteresis()"
                    v-bind:title="
                      $t('btnDelete') + ' ' + $t('hysteresis').toLowerCase()
                    "
                    class="error"
                    icon
                    dark
                    x-small
                  >
                    <v-icon x-small>mdi-delete</v-icon>
                  </v-btn>
                </h4>

                <div
                  class="mt-2 mb-5"
                  style="align-items: center; gap: 1rem"
                  row
                >
                  <v-btn
                    v-if="!hysteresisAdded"
                    v-on:click="
                      edit.props.push({ key: 'hysteresisMax', value: 0 });
                      edit.props.push({ key: 'hysteresisMin', value: 0 });
                    "
                    block
                    small
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <template v-else>
                    <v-text-field
                      v-model.number="hysteresis"
                      v-bind:label="
                        $t('value') + ' ' + $t('hysteresis').toLowerCase()
                      "
                      v-bind:disabled="!hysteresisSymmetrical"
                      type="number"
                      min="0"
                      style="max-width: 18ch"
                      clearable
                    />

                    <div style="width: 100%" col>
                      <div
                        v-for="prop in edit.props"
                        v-bind:key="`hysteresis-${prop.key}`"
                        style="width: 100%"
                      >
                        <v-text-field
                          v-if="prop.key === 'hysteresisMax'"
                          v-model.number="prop.value"
                          v-bind:color="prop.faulty ? 'error' : ''"
                          type="number"
                          min="0"
                          label="hysteresisMax"
                          prepend-icon="mdi-arrow-top-right"
                          prepend-inner-icon="mdi-plus"
                          clearable
                        />

                        <v-text-field
                          v-if="prop.key === 'hysteresisMin'"
                          v-model.number="prop.value"
                          v-bind:color="prop.faulty ? 'error' : ''"
                          type="number"
                          min="0"
                          label="hysteresisMin"
                          prepend-icon="mdi-arrow-bottom-right"
                          prepend-inner-icon="mdi-minus"
                          clearable
                        />
                      </div>
                    </div>
                  </template>
                </div>

                <h4>{{ $t("customProperties") }}</h4>

                <div class="mt-2" col>
                  <div
                    v-for="(prop, i) in filteredProps"
                    v-bind:key="`custom-prop-${prop.key}-${i}`"
                    class="mb-4 align-baseline"
                    row
                  >
                    <v-combobox
                      v-model="prop.key"
                      v-bind:items="availableCustomKeys"
                      v-bind:search-input.sync="prop.searchKey"
                      v-bind:label="$t('key')"
                      v-bind:outlined="outlinedPref"
                      class="half"
                      hide-details
                      clearable
                    >
                      <template v-if="!!prop.searchKey" v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="
                                $t('comboBoxNoData').replace(
                                  '%s%',
                                  prop.searchKey
                                )
                              "
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>

                    <v-text-field
                      v-model="prop.value"
                      v-bind:label="$t('value')"
                      v-bind:outlined="outlinedPref"
                      class="half"
                      hide-details
                      clearable
                    />

                    <v-btn
                      v-on:click="edit.props.splice(getPropIndex(prop.key), 1)"
                      v-bind:title="
                        $t('btnDelete') + ' ' + $t('key').toLowerCase()
                      "
                      color="error"
                      icon
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>

                  <v-btn
                    v-on:click="
                      edit.props.push({ key: undefined, value: undefined })
                    "
                    block
                    small
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </template>
              <template v-else>
                <v-select
                  v-model="edit.obj"
                  v-bind:items="booleans"
                  v-bind:label="$t('operator')"
                  v-bind:outlined="outlinedPref"
                />
              </template>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn v-on:click="reset" color="error" text>
                {{ $t("btnClose") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-else>
          <v-card>
            <v-card-text>
              {{ $t("noRuleSelected") }}
            </v-card-text>
          </v-card>
        </template>
      </div>
    </div>

    <v-dialog
      v-model="booleansDialog"
      v-if="booleansDialog"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("ruleBoolean") }}</v-card-title>

        <v-card-text>
          <div col>
            <v-select
              v-if="
                (addTo.children == undefined && addTo.length % 2 === 1) ||
                (addTo.children != undefined && addTo.children.length % 2 === 1)
              "
              v-model="selectedBoolean"
              v-bind:items="booleans"
              v-bind:label="$t('comparator')"
              v-bind:outlined="outlinedPref"
            />

            <v-select
              v-model="selectedExpression"
              v-bind:items="expressions"
              v-bind:label="$t('ruleBoolean')"
              v-bind:outlined="outlinedPref"
            >
              <template v-slot:selection="{ item }">{{ $t(item) }}</template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" class="my-0">
                  <v-list-item-content class="my-0">
                    {{ $t(item) }}
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="booleansDialog = false" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn v-on:click="addComparison" color="primary" text>
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      v-if="confirmDelete"
      v-bind:callback="() => deleteItem(expression)"
      v-bind:content="$t('confirmExpDelete')"
      v-bind:title="$t('dialogExpDeleteConfirm')"
      v-on:cancel="resetDelete"
    />
  </div>
</template>

<style scoped>
.scroll-container {
  overflow: hidden;
  height: 45ch;
}

.third {
  width: 40%;
}

.two-third {
  width: 60%;
}

.rounded {
  border-radius: 4px;
}

div[col] {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

div[row] {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
}

div[scroll] {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
</style>

<script>
import ExpressionInfo from "../models/ExpressionInfo";
import ConfirmationDialog from "./ConfirmationDialog";

const EMPTY_GUARD = {
  topic: null,
  comparison: null,
  value: null,
};
const POSITIVE_NUMERIC_PROPS = ["hysteresisMax", "hysteresisMin"];
const ALL_CUSTOMS = [];

export default {
  name: "Comparison",

  components: { ConfirmationDialog },

  props: {
    value: { type: Array, required: true },
    topics: { type: Array, required: false, default: () => [] },
  },

  data: () => ({
    confirmDelete: false,
    expression: [],
    booleans: ExpressionInfo.BOOLEANS,
    booleansDialog: false,
    selectedBoolean: ExpressionInfo.BOOLEANS[0],
    comparators: ExpressionInfo.COMPARISONS,
    selectedExpression: "ruleComparison",
    expressions: ["ruleComparison", "rulesGroup"],
    tmpId: 0,
    addTo: undefined,
    edit: undefined,
    validRule: false,
    error: undefined,
    fault: undefined,
    searchTopic: undefined,
    toDelete: -1,
  }),

  computed: {
    filteredAvailableTopics() {
      return this.topics.filter((t) =>
        t.toLowerCase().includes((this.searchTopic || "").toLowerCase())
      );
    },
    availableTopicsGroups() {
      return this.filteredAvailableTopics.map((t) => t.split("/")[0]);
    },
    availableCustomKeys() {
      return this.edit !== undefined
        ? ALL_CUSTOMS.filter(
            (k) => !(this.edit.props || []).map((p) => p.key).includes(k)
          )
        : ALL_CUSTOMS;
    },
    filteredProps() {
      return (this.edit?.props || []).filter(
        (p) => !POSITIVE_NUMERIC_PROPS.includes(p.key)
      );
    },
    hysteresisAdded() {
      const max = this.getPropIndex("hysteresisMax");
      const min = this.getPropIndex("hysteresisMin");

      return max >= 0 && min >= 0;
    },
    hysteresisSymmetrical() {
      const max = this.edit?.props?.[this.getPropIndex("hysteresisMax")];
      const min = this.edit?.props?.[this.getPropIndex("hysteresisMin")];

      return !max?.value || !min?.value || max?.value == min?.value;
    },
    hysteresis: {
      get() {
        const max = this.edit?.props?.[this.getPropIndex("hysteresisMax")];
        const min = this.edit?.props?.[this.getPropIndex("hysteresisMin")];

        return max?.value == min?.value ? max.value : null;
      },
      set(newValue) {
        const max = this.getPropIndex("hysteresisMax");
        const min = this.getPropIndex("hysteresisMin");

        if (max !== -1) {
          this.edit.props[max].value = newValue;
        }

        if (min !== -1) {
          this.edit.props[min].value = newValue;
        }
      },
    },
  },

  watch: {
    validRule(newValue) {
      this.$emit("valid", newValue);
    },
    expression: {
      deep: true,
      handler(newValue) {
        this.error = undefined;
        this.fault = undefined;
        this.validRule = this.ruleIsValid(newValue);

        if (this.validRule) {
          this.saveAll();
        }
      },
    },
  },

  beforeMount() {
    this.expression = this.buildTreeView(
      JSON.parse(JSON.stringify(this.value || []))
    );
  },

  methods: {
    ruleIsValid(rule) {
      if (Array.isArray(rule)) {
        if (rule.length > 0) {
          for (let i = 0; i < rule.length; i++) {
            let isInRightPlace = true;

            if (i % 2 === 0) {
              isInRightPlace =
                typeof (rule[i].obj || rule[i].children) === "object";
            } else {
              isInRightPlace =
                rule[i].obj != undefined && typeof rule[i].obj === "string";
            }

            if (!isInRightPlace) {
              this.setFault(
                this.$t("ruleInvalidPiece"),
                rule[i],
                rule[i].children == undefined ? rule[i] : undefined
              );

              return false;
            }
          }

          const last = rule[rule.length - 1];

          if (typeof last.obj === "string") {
            this.setFault(this.$t("ruleInvalidPiece"), last, last);

            return false;
          }
        } else {
          return false;
        }

        return rule
          .map((r) => this.ruleIsValid(r))
          .reduce((acc, e) => acc && e, true);
      }

      if (rule.children != undefined) {
        const validChildren = this.ruleIsValid(rule.children);
        rule.faulty = !validChildren;

        if (!validChildren) {
          this.setFault(this.$t("ruleEmptyGroup"), rule, undefined);

          return false;
        }
      }

      if (typeof rule.obj === "object") {
        const isFilled =
          !!rule.obj.topic &&
          !!rule.obj.comparison &&
          rule.obj.value != undefined &&
          rule.obj.value !== "";

        if (!isFilled) {
          this.setFault(this.$t("ruleNotFilled"), rule, rule);

          return false;
        }

        for (let i = 0; i < rule.props.length; i++) {
          if (POSITIVE_NUMERIC_PROPS.includes(rule.props[i].key)) {
            if (
              rule.props[i].value == undefined ||
              rule.props[i].value === ""
            ) {
              // eslint-disable-next-line prettier/prettier
              this.setFault(this.$t("rulePropNotFilled").replace("%p%", rule.props[i].key), rule, rule);
              rule.props[i].faulty = true;

              return false;
            } else if (rule.props[i].value < 0) {
              // eslint-disable-next-line prettier/prettier
              this.setFault(this.$t("rulePropNegative").replace("%p%", rule.props[i].key), rule, rule);
              rule.props[i].faulty = true;

              return false;
            } else {
              rule.props[i].faulty = false;
            }
          }
        }
      }

      return true;
    },
    emptyGuard() {
      return { ...EMPTY_GUARD };
    },
    addComparisonDialog(item = undefined) {
      this.addTo = item || this.expression;
      this.booleansDialog = true;
    },
    addComparison() {
      const target = Array.isArray(this.addTo)
        ? this.addTo
        : this.addTo.children;

      this.booleansDialog = false;

      if (target.length % 2 === 1) {
        target.push({
          id: this.getId(),
          name: this.selectedBoolean,
          obj: this.selectedBoolean,
        });
      }

      if (this.selectedExpression === this.expressions[0]) {
        const newExp = {
          id: this.getId(),
          name: "TODO: " + this.$t("ruleBoolean"),
          obj: { ...EMPTY_GUARD },
          props: [],
        };

        target.push(newExp);
        this.edit = newExp;
      } else {
        target.push({
          id: this.getId(),
          name: this.$t("rulesGroup"),
          children: [],
        });
      }

      this.selectedBoolean = ExpressionInfo.BOOLEANS[0];
      this.selectedExpression = this.expressions[0];
      this.addTo = undefined;
    },
    editRule(item) {
      this.edit = item;
    },
    saveAll() {
      if (this.edit) {
        this.edit.name =
          typeof this.edit.obj === "object"
            ? `${this.edit.obj.topic} ${this.edit.obj.comparison} ${this.edit.obj.value}`
            : this.edit.obj;
      }

      this.$emit("input", this.buildExpArray(this.expression));
    },
    confirmDeleteItem(id) {
      this.toDelete = id;
      this.confirmDelete = true;
    },
    deleteItem(list) {
      if (list == undefined) return;
      if (!Array.isArray(list)) return this.deleteItem(list.children);

      const index = list.findIndex((e) => e.id === this.toDelete);

      if (index !== -1) {
        list.splice(index, 1);
        return this.reset();
      }

      for (let i = 0; i < list.length; i++) {
        this.deleteItem(list[i]);
      }
    },
    buildTreeView(expArray) {
      return expArray.map((e) => {
        return !Array.isArray(e)
          ? typeof e === "object"
            ? {
                id: this.getId(),
                obj: e,
                props: this.getCustomProps(e),
              }
            : { id: this.getId(), obj: e }
          : {
              id: this.getId(),
              name: this.$t("rulesGroup"),
              children: this.buildTreeView(e),
            };
      });
    },
    buildExpArray(treeView) {
      const typizeValue = (value) => {
        if (!isNaN(value)) return Number(value);
        if (typeof value === "boolean") return value;

        if (new RegExp("^(n|y|no|yes|false|true|off|on)$").test(value)) {
          return new RegExp("(^y|yes|true|on)$").test(value);
        }

        return value;
      };

      return treeView.map((t) => {
        const comparison =
          t.children != undefined
            ? this.buildExpArray(t.children)
            : JSON.parse(JSON.stringify(t.obj));

        // Parse values
        if (!Array.isArray(comparison) && typeof comparison === "object") {
          comparison.value = typizeValue(comparison.value);
        }

        if (t.props !== undefined) {
          // Delete old properties
          this.getCustomProps(comparison).forEach((p) => {
            delete comparison[p.key];
          });

          // Add new properties
          t.props.forEach((p) => {
            comparison[p.key] = typizeValue(p.value);
          });
        }

        return comparison;
      });
    },
    deleteHysteresis() {
      const max = this.getPropIndex("hysteresisMax");
      this.edit.props.splice(max, 1);

      const min = this.getPropIndex("hysteresisMin");
      this.edit.props.splice(min, 1);
    },
    getId() {
      return ++this.tmpId;
    },
    setFault(message, faulty, edit) {
      if (this.error == undefined && this.fault == undefined) {
        this.error = message;
        this.fault = faulty;
        this.edit = edit;
      }
    },
    getCustomProps(comparison) {
      return Object.keys(comparison)
        .filter((k) => !Object.keys(EMPTY_GUARD).includes(k))
        .map((k) => ({ key: k, value: comparison[k] }));
    },
    getPropIndex(key) {
      return (this.edit?.props || []).findIndex((p) => p.key === key);
    },
    reset() {
      this.edit = undefined;
      this.addTo = undefined;
      this.resetDelete();
    },
    resetDelete() {
      this.confirmDelete = false;
      this.toDelete = -1;
    },
    parse(operation) {
      return typeof operation === "string"
        ? operation // eslint-disable-next-line prettier/prettier
        : `${operation.topic} ${this.$t(operation.comparison)} ${operation.value}`;
    },
  },
};
</script>
