import Vue from "vue";

const configVuexModule = {
  state: {
    config: {
      VUE_APP_APP_NAME: null,
      VUE_APP_APP_LOGO: null,
      VUE_APP_APP_COLOR: null,
      VUE_APP_ALL_LOCALES: null,
      VUE_APP_HUB_MSGS_MessageReceive: null,
      VUE_APP_HUB_PATH: null,
      VUE_APP_I18N_FALLBACK_LOCALE: null,
      VUE_APP_I18N_LOCALE: null,
      VUE_APP_LAST_SEEN_TOPIC: null,
      VUE_APP_LOCALES: null,
      VUE_APP_OFFLINE_TIMEOUT_MINUTES: null,
      VUE_APP_OUTLINED_COMPONENTS: null,
      VUE_APP_RETRY_DELAY_MILLIS: null,
      VUE_APP_EVENTS_TOPIC: null,
      VUE_APP_MEASURES_TOPIC: null,
      VUE_APP_APP_HIDDEN_MENUS: null,
      VUE_APP_APP_CUSTOM_MENUS: null,
      VUE_APP_APP_BROWSER_NAME: null,
      VUE_APP_APP_VENDOR: null,
      VUE_APP_APP_VENDOR_ICON: null,
      VUE_APP_MAP_TILES_SERVICE: null,
      VUE_APP_DETAILS_INTERPOLATE: null,
      VUE_APP_ANALYSIS_INTERPOLATE: null,
    },
  },

  mutations: {
    addConfig: (state, { key, value }) => {
      Vue.set(state.config, key, value);
    },
  },

  getters: {
    config: (state) => state.config,
    hubs: (state) =>
      Object.keys(state.config)
        .map((k) => {
          const found = k.match(/^VUE_APP_HUB_MSGS_([a-zA-Z0-9]+)$/);

          return found != undefined
            ? {
                message: found[1],
                mapped: state.config[k],
              }
            : undefined;
        })
        .filter((h) => h !== undefined),
    string: (state) => (key) => localStorage[key] || state.config[key],
    number: (state) => (key) =>
      Number(localStorage[key] || state.config[key] || 0),
    bool: (state) => (key) =>
      localStorage[key] != undefined
        ? localStorage[key] == "true"
        : state.config[key],
    object: (state) => (key) =>
      localStorage[key] != undefined
        ? JSON.parse(localStorage[key])
        : JSON.parse(state.config[key]),
    array: (state) => (key) =>
      localStorage[key] != undefined
        ? localStorage[key].split(",")
        : (state.config[key] || "").split(","),
  },
};

export default configVuexModule;
