<template>
  <v-list-item link>
    <v-list-item-avatar
      v-bind:title="$t(command.enabled ? 'commandEnabled' : 'commandDisabled')"
    >
      <v-avatar v-bind:color="command.enabled ? 'success' : 'error'">
        <v-icon dark>
          {{ command.enabled ? "mdi-check-circle-outline" : "mdi-cancel" }}
        </v-icon>
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <div class="d-flex align-center" style="gap: 1em">
        <div class="d-flex flex-column" style="width: 27%">
          <span class="font-weight-bold">
            {{ command.name }}
          </span>
          <span class="caption primary--text text--lighten-2">
            {{ command.description }}
          </span>
        </div>

        <v-divider style="width: 1%" vertical />

        <div class="d-flex flex-column flex-grow-1 caption" style="width: 72%">
          <div>
            <span class="primary--text text--lighten-2">
              {{ $t("users") }}:
            </span>
            <span class="font-weight-bold">
              {{ command.userIds.length > 0 ? users.join(", ") : $t("all") }}
            </span>
          </div>
          <div>
            <span class="primary--text text--lighten-2">
              {{ $t("assets") }}:
            </span>
            <span class="font-weight-bold">
              {{ command.assetIds.length > 0 ? assets.join(", ") : $t("all") }}
            </span>
          </div>
          <div>
            <span class="primary--text text--lighten-2">
              {{ $t("customerEntityCodes") }}:
            </span>
            <span class="font-weight-bold">
              {{
                command.assetEntityCodes.length > 0
                  ? entityCodesStrings.join(", ")
                  : $t("all")
              }}
            </span>
          </div>
          <div v-if="displayOwner">
            <span class="primary--text text--lighten-2">
              {{ $t("commandOwner") }}:
            </span>
            <span class="font-weight-bold">
              {{ (company || {}).name || $t("all") }}
            </span>
          </div>
        </div>

        <v-divider style="width: 1%" vertical />

        <div class="flex-grow-0">
          {{ $t("commandSendArgs") }}: {{ argumentsLength }}
        </div>
      </div>
    </v-list-item-content>

    <v-list-item-action>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" rounded icon>
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="updateCommand" link>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("btnEdit") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-on:click="toggleCommand" link>
            <v-list-item-content>
              <v-list-item-title>
                {{ command.enabled ? $t("btnDisable") : $t("btnEnable") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-on:click="confirmDelete = true" link>
            <v-list-item-content>
              <v-list-item-title class="error--text">
                {{ $t("btnDelete") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>

    <ConfirmationDialog
      v-if="confirmDelete"
      v-bind:callback="deleteCommand"
      v-bind:title="$t('deleteCommandTitle')"
      v-bind:content="$t('deleteCommandContent').replace('%c%', command.name)"
      v-on:cancel="confirmDelete = false"
    />
  </v-list-item>
</template>

<style scoped>
.small-line {
  line-height: 1em;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Messages from "../utils/messages";
import ConfirmationDialog from "./ConfirmationDialog.vue";

export default {
  name: "CommandItem",

  components: { ConfirmationDialog },

  props: {
    command: { type: Object, required: true },
    index: { type: Number, required: true },
    displayOwner: { type: Boolean, required: true },
    entityCodes: { type: Array, required: false, default: () => [] },
  },

  data: () => ({
    confirmDelete: false,
  }),

  computed: {
    assets() {
      return this.command.assetIds
        .map((dbId) => {
          const asset = this.$store.getters.assetById(dbId);
          const name = asset?.plate || asset?.serial;
          let model = "";

          if (!name) return undefined;

          if (asset?.brand) model += asset?.brand;
          if (asset?.model) model += ` ${asset?.model}`;

          return `${name}${model ? " (" + model + ")" : ""}`;
        })
        .filter((name) => !!name);
    },
    argumentsLength() {
      return JSON.parse(this.command?.arguments?.args || "[]").length;
    },
    users() {
      return this.command.userIds.map((dbId) => {
        const user = this.$store.getters.userById(dbId);
        return user?.nameSurname;
      });
    },
    company() {
      return this.command.companyId
        ? this.$store.getters.customerById(this.command.companyId)
        : null;
    },
    entityCodesStrings() {
      let ecs = this.entityCodes.filter((ec) =>
        this.command.assetEntityCodes.includes(ec.id)
      );
      const unknowns = this.command.assetEntityCodes.filter(
        (ec) => ecs.find((k) => k.id === ec) == undefined
      );

      ecs = ecs.map((ec) => {
        let model = "";

        if (ec.brand) model += ec.brand;
        if (ec.model) model += ` ${ec.model}`;
        if (ec.version) model += ` (${ec.version})`;

        return model || ec.id;
      });

      unknowns.forEach((ec) => (ecs[ecs.length] = ec));

      return ecs;
    },
  },

  methods: {
    deleteCommand() {
      ApiRequests.deleteCommand(
        this.command.id,
        () => {
          this.$bus.$emit(
            Messages.SUCCESS_MSG,
            this.$t("commandDeleteSuccess")
          );
          this.fetchCommands();
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("commandDeleteFailure"),
          });
        }
      );
    },
    toggleCommand() {
      ApiRequests.updateCommand(
        this.command.id,
        { enabled: !this.command.enabled },
        () => {
          this.$bus.$emit(
            Messages.SUCCESS_MSG,
            this.$t("commandUpdateSuccess")
          );
          this.fetchCommands();
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("commandUpdateFailure"),
          });
        }
      );
    },
    updateCommand() {
      this.$emit("update", this.index);
    },
  },
};
</script>
