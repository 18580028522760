import moment from "moment";
import BaseMsg from "./BaseMsg";
import AssetInfo from "./AssetInfo";

class Event extends BaseMsg {
  static types = {
    SINGLE: "single",
    UP: "up",
    DOWN: "down",
  };

  static severity = {
    TRACE: "trace",
    LOW: "low",
    MEDIUM: "medium",
    HIGH: "high",
  };

  static durationConverter = (duration) => {
    if (duration == undefined) return "--";

    const timeValue = [];
    const conversionTable = [
      { factor: 1 / 1000 / 86400, name: "dayUnit", back: 1000 * 86400 },
      { factor: 1 / 1000 / 3600, name: "hourUnit", back: 1000 * 3600 },
      { factor: 1 / 1000 / 60, name: "minuteUnit", back: 1000 * 60 },
      { factor: 1 / 1000, name: "secondUnit", back: 1000 },
    ];

    let reminder = duration;

    conversionTable.forEach((conversion) => {
      const converted = reminder * conversion.factor;
      const value = Math.trunc(converted);

      reminder = reminder - value * conversion.back;

      timeValue.push({ value, unit: conversion.name });
    });

    const firstNonZero = timeValue.findIndex((tv) => tv.value > 0);

    return firstNonZero > 0
      ? timeValue
          .splice(firstNonZero)
          .map(
            (tv) =>
              `${tv.value < 10 ? "0" : ""}${tv.value}${BaseMsg.VUE.$t(tv.unit)}`
          )
          .join(" ")
      : `00${BaseMsg.VUE.$t("secondUnit")}`;
  };

  _content = undefined;
  _contentType = undefined;
  _ts = undefined;
  _code = undefined;
  _group = undefined;
  _attributes = {};
  _severity = undefined;
  _serial = undefined;
  _graphics = {
    color: undefined,
    icon: undefined,
  };

  constructor(event = undefined) {
    super(event);

    if (event != undefined) {
      // Fix path
      const splittedPath = this._origin.path.split("/");
      this._origin.path = splittedPath[0] + "/" + splittedPath[1];

      this._content = event.Content.toLowerCase();
      this._contentType = event.ContentType.toLowerCase();
      this._ts = Number(event.Properties.ts);
      this._code = event.Properties.code;
      this._group = event.Properties.group;
      this._serial = event.Properties.serial || event.Properties.serialNumber;
      this._attributes = event.Properties;

      const downTs = event.Properties.eventDownTimestamp;

      this._downTs =
        downTs != undefined ? moment.utc(downTs).toDate().getTime() : undefined;

      if (event.Properties.attributes != undefined) {
        try {
          this._attributes = {
            ...this._attributes,
            ...JSON.parse(event.Properties.attributes),
          };
        } catch {
          this._attributes.message = event.Properties.attributes;
        }
      }

      this._parseContent();
    }
  }

  // Getters

  get content() {
    return BaseMsg.VUE.$t(this._content);
  }
  get severity() {
    return this._content;
  }
  get contentType() {
    return BaseMsg.VUE.$t(this._contentType);
  }
  get stateType() {
    return this._contentType;
  }
  get ts() {
    return this._ts;
  }
  get downTs() {
    return this._downTs;
  }
  get code() {
    return this._code;
  }
  get group() {
    return this._group;
  }
  get serial() {
    return this._serial;
  }
  get attributes() {
    return this._attributes;
  }
  get graphics() {
    return this._graphics;
  }
  get durationMillis() {
    return this._downTs != undefined ? this._downTs - this._ts : undefined;
  }
  get severityNum() {
    switch (this._content) {
      case Event.severity.HIGH:
        return 0;
      case Event.severity.MEDIUM:
        return 1;
      case Event.severity.LOW:
        return 2;
      default:
        return 3;
    }
  }
  get statusNum() {
    switch (this._contentType) {
      case Event.types.UP:
        return 0;
      case Event.types.DOWN:
        return 1;
      default:
        return 2;
    }
  }

  // Public methods

  /**
   * Formats the message in a displayable string.
   * @public
   * @returns {String} - Displayable string.
   */
  formattedMessage() {
    const translation = BaseMsg.VUE.$t(this._code);

    return translation != this._code
      ? translation
      : this._attributes.message ?? "--";
  }

  /**
   * Converts the timestamp into a human readable date.
   * @public
   * @param {Boolean} delta - Calculate the time past until now.
   * @returns {String} - Formatted string date.
   */
  readableTs(delta = false) {
    const ts = moment.utc(this._ts).local();
    const today = moment();

    if (!delta) {
      const init = ts.format("HH:mm:ss");
      let finish = "";

      if (this._downTs != undefined) {
        const dts = moment.utc(this._downTs).local();
        finish = " → " + dts.format("HH:mm:ss");
      }

      return init + finish;
    }

    // Calculate the time delta

    const deltaMoments = [
      { tsDelta: today.diff(ts, "days"), des: "daysAgo" },
      { tsDelta: today.diff(ts, "hours"), des: "hoursAgo" },
      { tsDelta: today.diff(ts, "minutes"), des: "minutesAgo" },
      { tsDelta: today.diff(ts, "seconds"), des: "secondsAgo" },
    ];
    const deltaMoment = deltaMoments.find((d) => d.tsDelta > 0);

    return deltaMoment != undefined
      ? `${deltaMoment.tsDelta} ${BaseMsg.VUE.$t(deltaMoment.des)}`
      : BaseMsg.VUE.$t("now");
  }

  /**
   * Calculates the event duration.
   * @public
   * @returns {String} - Formatted event duration.
   */
  duration() {
    return Event.durationConverter(this.durationMillis);
  }

  /**
   * Useful for filtering events.
   * @public
   * @param {String} searchTerm - The word to search in this measure.
   * @returns {Boolean} - Returns `true` if the searched word is contained in this event, `false` otherwise.
   */
  matchSearchTerm(searchTerm) {
    const s = (searchTerm || "").toLowerCase();

    const normalSearch =
      this._name.toLowerCase().includes(s) ||
      this.content.toLowerCase().includes(s) ||
      this.contentType.toLowerCase().includes(s) ||
      this.code.toLowerCase().includes(s) ||
      this.group.toLowerCase().includes(s) ||
      (this.serial || "").toLowerCase().includes(s) ||
      this.readableTs().toLowerCase().includes(s) ||
      this.formattedMessage().toLowerCase().includes(s) ||
      Object.keys(this._attributes)
        .map((k) =>
          (typeof this._attributes[k] === "string"
            ? this._attributes[k] || ""
            : ""
          )
            .toLowerCase()
            .includes(s)
        )
        .reduce((acc, m) => acc || m, false);
    const severityFilter = s.includes(this.content.toLowerCase());

    return normalSearch || severityFilter;
  }

  /**
   * Determines if this event is newer than another.
   * @public
   * @param {Event} comparableEvent - Event to compare this instance against.
   * @returns {Boolean} - Returns `true` if this instance is more recent, `false` otherwise.
   */
  isNewerThan(comparableEvent) {
    return comparableEvent != undefined ? this._ts >= comparableEvent.ts : true;
  }

  /**
   * Returns the list of Influx tags of this alarm.
   * @public
   * @returns {String[]} - Influx tags.
   */
  getTags() {
    return Object.keys(this._attributes)
      .filter((key) => key.includes(AssetInfo.INFLUX_TAG))
      .map((key) => key.replace(AssetInfo.INFLUX_TAG, ""));
  }

  /**
   * Get the value of a tag by its name.
   * @param {String} tag - The tag name.
   * @returns [String] - The tag value.
   */
  getTagValue(tag) {
    return this._attributes[AssetInfo.INFLUX_TAG + tag];
  }

  // Private methods

  /**
   * Builds the graphics for the event.
   * @private
   */
  _parseContent() {
    switch (this._content) {
      case Event.severity.TRACE:
        this._graphics.color = "primary";
        this._graphics.icon = "mdi-information-outline";
        break;
      case Event.severity.LOW:
        this._graphics.color = "success";
        this._graphics.icon = "mdi-alert-circle-outline";
        break;
      case Event.severity.MEDIUM:
        this._graphics.color = "warning";
        this._graphics.icon = "mdi-alert-outline";
        break;
      case Event.severity.HIGH:
        this._graphics.color = "error";
        this._graphics.icon = "mdi-alert-decagram-outline";
        break;
      default:
        this._graphics.color = "";
        this._graphics.icon = "mdi-help-circle-outline";
        break;
    }
  }
}

export default Event;
