<template>
  <div class="pa-4 fill-height grid-container">
    <h1 class="title">
      <div>
        {{ asset.plate || asset.boxMacAddress || asset.serial }}
        <span class="mx-1"> - </span>
        <span class="primary--text text--lighten-2">
          {{ asset.brand }}
          {{ asset.model }}
        </span>
      </div>

      <div class="d-flex flex-row align-center py-0">
        <span class="caption me-1">{{ $t("lastDataTs") }}:</span>
        <span class="caption font-weight-bold me-6">
          {{
            lastMessage != undefined ? getReadableTimestamp(lastMessage) : "--"
          }}
        </span>
        <Dot
          v-bind:color="
            isOffline[asset.boxMacAddress] == undefined
              ? 'grey'
              : isOffline[asset.boxMacAddress]
              ? 'error'
              : 'success'
          "
          v-bind:text="
            $t(
              isOffline[asset.boxMacAddress] == undefined
                ? 'unknown'
                : isOffline[asset.boxMacAddress]
                ? 'offline'
                : 'online'
            )
          "
          justify-end
        />
      </div>
    </h1>

    <div class="body">
      <DynamicDashboard
        v-bind:asset-id="assetId"
        v-bind:asset="asset"
        v-on:refresh-component="fetchMeasures"
        class="body-content"
        ref="dashboard"
      />
    </div>
  </div>
</template>

<style scoped>
.grid-container {
  display: grid;
  gap: 0.1rem;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
}

.title {
  display: flex;
  justify-content: space-between;
}

.body {
  position: relative;
}

.body-content {
  position: absolute;
  inset: 0;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import Messages from "../utils/messages";
import AssetInfo from "../models/AssetInfo";
import Dot from "../components/Dot";
import AssetDataRequestBody from "../models/AssetDataRequestBody";
import DynamicDashboard from "../components/DynamicDashboard";

export default {
  name: "AssetDynamicDetails",

  components: { Dot, DynamicDashboard },

  data: () => ({
    assetId: undefined,
    asset: new AssetInfo(),
  }),

  async beforeMount() {
    try {
      await this.fetchAssetData();
    } catch (err) {
      if (process.env.NODE_ENV === "development") console.error(err);
      this.$router.replace({ path: "/dashboard" });
      this.$bus.$emit(Messages.ERROR_MSG, {
        error: err?.response?.data?.error || err,
        description: this.$t("assetFetchFailure").replace(
          "%serial%",
          this.assetId
        ),
      });
      return;
    }

    this.$store.commit("setCurrentPage", Pages.DYNAMIC_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
    this.updateFiltersRules(this.clientId);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuDynamicDetails"),
      to: `/asset-dynamic-details/${this.assetDbId}`,
      root: false,
    });
  },

  computed: {
    assetDbId() {
      return this.$route.params.assetId;
    },
    lastMessage() {
      return this.lastSeenBySerial(this.assetId);
    },
    topics() {
      return Object.keys(this.asset.customComponent.values || {});
    },
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue, false);
    },
  },

  methods: {
    async fetchAssetData() {
      this.asset = new AssetInfo(
        (await ApiRequests.getAsset(this.assetDbId, undefined, undefined)).data
      );

      this.assetId = this.asset.serial;
      this.asset.companyName = this.customersList.find(
        (c) => c.id === this.asset.companyId
      )?.name;
      this.asset.groupName = this.groupsList.find(
        (f) => f.id === this.asset.groupId
      )?.name;
    },
    fetchMeasures() {
      const measuresUsed = this.topics.map((t) => `measures/${t}`);
      const requestBody = new AssetDataRequestBody();

      requestBody.ids = [this.assetDbId];
      requestBody.paths = measuresUsed;
      requestBody.includeMetadata = true;

      ApiRequests.getSavedMeasures(
        requestBody,
        undefined,
        (res) => {
          const requestedData = res.data[this.assetDbId];

          Object.keys(requestedData).forEach((k) => {
            this.$store.dispatch("saveMessage", requestedData[k]);
          });

          this.$refs.dashboard.computeBody();
        },
        (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
      );
    },
    updateFiltersRules(id, getMeasures = true) {
      if (id !== undefined) {
        const measuresUsed = this.topics.map((t) => `measures/${t}`);

        ApiRequests.createOrUpdateFilter(
          id,
          [...this.signalRBaseRegexes, ...measuresUsed],
          () => (getMeasures ? this.fetchMeasures() : undefined),
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
  },
};
</script>
