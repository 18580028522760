<template>
  <div class="template-container">
    <div class="body-content" v-html="bodyContent"></div>

    <div class="footer">
      <div class="d-flex flex-row align-center py-0">
        <span class="caption me-1">{{ $t("lastUpdate") }}:</span>
        <span class="caption font-weight-bold me-2">
          {{ getReadableTimestamp(timestamp) }}
        </span>

        <v-btn v-on:click="computeBody" icon small>
          <v-icon v-bind:class="{ spinning: loading }" small>mdi-sync</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.template-container {
  max-height: 100%;
  width: 100%;
}

.body-content {
  position: absolute;
  inset: 0;
}

.footer {
  position: absolute;
  bottom: 0;
}
</style>

<script>
import moment from "moment";

export default {
  name: "DynamicDashboard",

  props: {
    assetId: { type: String },
    asset: { type: Object },
  },

  data: () => ({
    timestamp: undefined,
    loading: true,
    refresher: undefined,
    bodyContent: "",
  }),

  computed: {
    measures() {
      const ms = this.$store.getters.measuresById(this.assetId);
      return ms === undefined ? {} : ms;
    },
    topics() {
      return Object.keys(this.asset.customComponent.values || {});
    },
  },

  beforeMount() {
    this.refresher = setInterval(
      this.computeBody,
      this.asset.customComponent.interval
    );
  },

  mounted() {
    this.computeBody();
  },

  beforeDestroy() {
    if (this.refresher !== undefined) {
      clearInterval(this.computeBody);
      this.refresher = undefined;
    }
  },

  methods: {
    computeBody() {
      if (!this.asset.customComponent.enabled) return "";

      this.$emit("refresh-component");

      this.loading = true;
      let html = this.asset.customComponent.template;

      this.topics.forEach((t) => {
        const object = this.asset.customComponent.values[t];
        const notArray = object.type != "array";

        if (notArray) {
          // Replace texts
          html = html.replace(
            RegExp(`%t${t}t%`, "g"),
            this.measures[t]?.value?.formattedContent()
          );

          // Replace values
          html = html.replace(
            RegExp(`%v${t}v%`, "g"),
            this.measures[t]?.value?.content
          );

          // Replace properties
          Object.keys(object).forEach((k) => {
            html = html.replace(RegExp(`%o${t}.${k}o%`, "g"), object[k]);
          });
        } else {
          for (let i = 0; i < object.length; i++) {
            // Replace texts
            html = html.replace(
              RegExp(`%t${t}_${i}t%`, "g"),
              this.measures[t]?.value?.content?.[i]?.formattedContent()
            );

            // Replace values
            html = html.replace(
              RegExp(`%v${t}_${i}v%`, "g"),
              this.measures[t]?.value?.content?.[i]?.content
            );

            // Replace properties
            Object.keys(object).forEach((k) => {
              html = html.replace(RegExp(`%o${t}_${i}.${k}o%`, "g"), object[k]);
            });
          }
        }
      });

      [...html.matchAll(RegExp("%e.*?e%", "g"))].forEach((exp) => {
        const cleaned = exp[0].replace("%e", "").replace("e%", "");
        html = html.replace(exp[0], eval(cleaned));
      });

      this.timestamp = moment().toDate().getTime();
      this.bodyContent = html;
      this.loading = false;
    },
  },
};
</script>
