class AssetEventsStatsRequestBody {
  serials = undefined;
  activeOnly = false; // Required
  severities = []; // Required
  starting = undefined; // Required
  ending = undefined;
  groupBySeverity = false;
  groupInterval = undefined; // Required (day|month|year)
  groupTimeSlots = [];
}

export default AssetEventsStatsRequestBody;
