<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-bind:headers="tableHeaders"
        v-bind:items="tableData"
        v-bind:items-per-page="-1"
        group-by="asset"
        height="100%"
        hide-default-footer
      >
        <template v-slot:group.header="{ group, items, isOpen, toggle }">
          <td v-if="group" class="indigo lighten-5" colspan="7">
            <v-btn v-on:click="toggle" icon>
              <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
            <span>{{ group }}</span>
            <span class="text-lowercase">
              ({{ items.length }} {{ $t("mainParameters") }})
            </span>
          </td>
        </template>

        <!-- Cursors values -->

        <template v-slot:item.A="{ item }">
          <div
            v-if="item.A != undefined"
            class="d-flex flex-column align-center justify-center"
          >
            <span>
              {{
                item.name === "Timestamp"
                  ? getParsedTimestamp(item.A)
                  : item.A.val.toFixed(item.A.format || defaultDecimals) +
                    " " +
                    item.A.unit
              }}
            </span>
            <span
              v-if="item.name !== 'Timestamp'"
              class="caption grey--text ms-2"
            >
              ({{ getParsedTimestamp(item.A.ts) }})
            </span>
          </div>
        </template>

        <template v-slot:item.B="{ item }">
          <div
            v-if="item.B != undefined"
            class="d-flex flex-column align-center justify-center"
          >
            <span>
              {{
                item.name === "Timestamp"
                  ? getParsedTimestamp(item.B)
                  : item.B.val.toFixed(item.B.format || defaultDecimals) +
                    " " +
                    item.B.unit
              }}
            </span>
            <span
              v-if="item.name !== 'Timestamp'"
              class="caption grey--text ms-2"
            >
              ({{ getParsedTimestamp(item.B.ts) }})
            </span>
          </div>
        </template>

        <template v-slot:item.diff="{ item }">
          <div v-if="item.diff != undefined">
            {{
              item.name === "Timestamp"
                ? item.diff
                : item.diff.toFixed(item.B.format || defaultDecimals) +
                  " " +
                  item.B.unit
            }}
          </div>
        </template>

        <!-- Maximum and minimum values -->

        <template v-slot:item.max="{ item }">
          <div
            v-if="item.max != undefined"
            class="d-flex flex-column align-center justify-center"
          >
            <span>
              {{
                item.name === "Timestamp"
                  ? ""
                  : item.max.val.toFixed(item.B.format || defaultDecimals) +
                    " " +
                    item.B.unit
              }}
            </span>
            <span
              v-if="item.name !== 'Timestamp'"
              class="caption grey--text ms-2"
            >
              ({{ getParsedTimestamp(item.max.ts) }})
            </span>
          </div>
        </template>

        <template v-slot:item.min="{ item }">
          <div
            v-if="item.min != undefined"
            class="d-flex flex-column align-center justify-center"
          >
            <span>
              {{
                item.name === "Timestamp"
                  ? ""
                  : item.min.val.toFixed(item.A.format || defaultDecimals) +
                    " " +
                    item.A.unit
              }}
            </span>
            <span
              v-if="item.name !== 'Timestamp'"
              class="caption grey--text ms-2"
            >
              ({{ getParsedTimestamp(item.min.ts) }})
            </span>
          </div>
        </template>

        <template v-slot:item.maxDiff="{ item }">
          <div v-if="item.maxDiff != undefined">
            {{
              item.name === "Timestamp"
                ? ""
                : item.maxDiff.toFixed(item.B.format || defaultDecimals) +
                  " " +
                  item.B.unit
            }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="d-flex justify-center">
      <v-alert type="warning" icon="mdi-alert-outline" dense text>
        {{ $t("cursorsComparisonWarning") }}
      </v-alert>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "CursorsComparison",

  props: {
    cursorsValues: { type: Object, required: true },
    maxminValues: { type: Object, required: true },
    defaultDecimals: { type: Number, required: false, default: 3 },
  },

  data: () => ({
    tableData: [],
    tableHeaders: [],
  }),

  beforeMount() {
    const cursors = Object.keys(this.cursorsValues);

    this.tableHeaders.push({
      text: this.$t("detailsTitleName"),
      align: "start",
      sortable: true,
      value: "name",
    });

    cursors.forEach((cursor) => {
      const cursorName = `${this.$t("cursor")} ${cursor}`;

      this.tableHeaders.push({
        text: cursorName,
        align: "center",
        sortable: false,
        value: cursor,
      });

      Object.keys(this.cursorsValues[cursor] || {}).forEach((v) => {
        const nameParts = v.split(": ");
        const value = this.cursorsValues[cursor][v];
        const tableObjId = this.tableData.findIndex(
          (d) => d.name === (nameParts.length > 1 ? nameParts[1] : v)
        );

        const baseMinMax = this.maxminValues[v];
        const minValue =
          !baseMinMax || baseMinMax.min == undefined
            ? undefined
            : {
                val: this.maxminValues[v].min,
                ts: this.maxminValues[v].minTs,
              };
        const maxValue =
          !baseMinMax || baseMinMax.max == undefined
            ? undefined
            : {
                val: this.maxminValues[v].max,
                ts: this.maxminValues[v].maxTs,
              };
        const maxDiff =
          maxValue == undefined || minValue == undefined
            ? undefined
            : maxValue.val - minValue.val;

        if (tableObjId >= 0) {
          this.tableData[tableObjId][cursor] = value;
          this.tableData[tableObjId].min = minValue;
          this.tableData[tableObjId].max = maxValue;
          this.tableData[tableObjId].maxDiff = maxDiff;
        } else {
          this.tableData.push({
            name: nameParts.length > 1 ? nameParts[1] : v,
            asset: nameParts.length > 1 ? nameParts[0] : undefined,
            [cursor]: value,
            min: minValue,
            max: maxValue,
            maxDiff,
          });
        }
      });
    });

    this.tableHeaders.push(
      ...[
        {
          text: `${this.$t("cursor")} B - ${this.$t("cursor")} A`,
          align: "center",
          sortable: false,
          value: "diff",
        },
        {
          text: this.$t("max"),
          align: "center",
          sortable: false,
          value: "max",
        },
        {
          text: this.$t("min"),
          align: "center",
          sortable: false,
          value: "min",
        },
        {
          text: `${this.$t("max")} - ${this.$t("min")}`,
          align: "center",
          sortable: false,
          value: "maxDiff",
        },
      ]
    );

    for (let i = 0; i < this.tableData.length; i++) {
      if (
        this.tableData[i].A != undefined &&
        this.tableData[i].B != undefined
      ) {
        if (this.tableData[i].name === "Timestamp") {
          const b = moment(this.tableData[i].B);
          const a = moment(this.tableData[i].A);
          const deltaMoments = [
            { tsDelta: b.diff(a, "days"), des: "daysAgo" },
            { tsDelta: b.diff(a, "hours"), des: "hoursAgo" },
            { tsDelta: b.diff(a, "minutes"), des: "minutesAgo" },
            { tsDelta: b.diff(a, "seconds"), des: "secondsAgo" },
          ];
          const deltaMoment = deltaMoments.find((d) => d.tsDelta > 0);

          this.tableData[i].diff =
            deltaMoment != undefined
              ? `${deltaMoment.tsDelta} ${this.$t(deltaMoment.des)}`
              : "--";
        } else {
          // eslint-disable-next-line prettier/prettier
          this.tableData[i].diff = this.tableData[i].B.val - this.tableData[i].A.val;
        }
      }
    }
  },

  methods: {
    getParsedTimestamp(timestamp) {
      if (!timestamp) return undefined;

      return moment.utc(timestamp).local().format("DD MMM HH:mm:ss");
    },
  },
};
</script>
