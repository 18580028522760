<template>
  <div class="pa-4 fill-height grid">
    <h1>
      <v-row align="center">
        <v-col>
          {{ $t("menuRules") }}
        </v-col>
        <v-col align="end">
          <v-btn v-on:click="newRuleDialog = true" color="primary">
            {{ $t("btnNewRule") }}
          </v-btn>
        </v-col>
      </v-row>
    </h1>

    <div class="table">
      <EntitiesTable
        v-bind:headers="headers"
        v-bind:sorting-fields="sortingFields"
        v-bind:items="allRules"
        v-bind:defaultSort="sortingFields[0].value"
        v-bind:loading="loading"
        v-bind:actionsColumn="true"
        v-bind:actions="{ edit: true, enableDisable: true, delete: true }"
        v-bind:bulkColumn="true"
        v-on:table-click="handleClick"
        v-on:table-edit="handleClick"
        v-on:table-delete="deleteRule(find($event))"
        v-on:table-disable="handleEnable(find($event), false)"
        v-on:table-enable="handleEnable(find($event), true)"
        v-on:table-bulk-delete="manageRuleBulk($event, 0)"
        v-on:table-bulk-enable="manageRuleBulk($event, 1)"
        v-on:table-bulk-disable="manageRuleBulk($event, 2)"
      />
    </div>

    <v-dialog
      v-model="newRuleDialog"
      v-if="newRuleDialog"
      width="min(60%, 92vw)"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          {{ edit ? $t("btnEditRule") : $t("btnNewRule") }}
        </v-card-title>

        <v-card-text>
          <v-form class="input-container">
            <div class="mt-1" row>
              <v-text-field
                v-model="newRule.name"
                v-bind:outlined="outlinedPref"
                v-bind:label="$t('ruleName')"
                v-bind:rules="[
                  (v) => !!v || $t('required'),
                  (v) =>
                    allRules.findIndex((r) => r.name === v) === -1 ||
                    edit ||
                    $t('duplicateRule'),
                ]"
                clearable
                required
              />

              <v-switch
                v-model="newRule.enabled"
                v-bind:label="
                  newRule.enabled ? $t('userEnabled') : $t('userDisabled')
                "
                class="ms-6"
                inset
              />
            </div>

            <div class="d-flex flex-column">
              <v-textarea
                v-model="newRule.description"
                v-bind:label="$t('ruleDescription')"
                v-bind:rules="[(v) => !!v || $t('required')]"
                class="mb-1"
                style="max-width: 100%"
                rows="2"
                hide-details
                no-resize
                outlined
                required
              />

              <div style="gap: 2ch; align-items: center" row>
                <v-combobox
                  v-model="addresseesEmails"
                  v-bind:label="$t('alarmsEmails')"
                  v-bind:items="emailSuggestions || []"
                  v-bind:return-object="false"
                  v-bind:disabled="!newRule.channels.includes('email')"
                  v-bind:outlined="outlinedPref"
                  style="flex-grow: 1"
                  append-icon=""
                  hide-details
                  multiple
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      v-on:click:close="
                        newRule.addressees = newRule.addressees.filter(
                          (i) => i != `${item}:email`
                        )
                      "
                      close
                      small
                    >
                      {{ item }}
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item
                      v-on="on"
                      v-bind="attrs"
                      v-on:click="
                        newRule.addressees.includes(`${item}:email`)
                          ? (newRule.addressees = newRule.addressees.filter(
                              (i) => i != `${item}:email`
                            ))
                          : newRule.addressees.push(item)
                      "
                      #default="{ active }"
                    >
                      <v-list-item-action>
                        <v-checkbox
                          v-bind:input-value="active"
                          v-bind:ripple="false"
                        />
                      </v-list-item-action>
                      <v-list-item-content>
                        {{ item }}
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
                <v-combobox
                  v-model="addresseesPhones"
                  v-bind:label="$t('alarmsPhones')"
                  v-bind:items="[
                    ...(smsSuggestions || []),
                    ...(telegramSuggestions || []),
                  ]"
                  v-bind:return-object="false"
                  v-bind:disabled="
                    !newRule.channels.includes('sms') &&
                    !newRule.channels.includes('telegram')
                  "
                  v-bind:outlined="outlinedPref"
                  style="flex-grow: 1"
                  append-icon=""
                  hide-details
                  multiple
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      v-on:click:close="
                        newRule.addressees = newRule.addressees.filter(
                          (i) => i != `${item}:phone`
                        )
                      "
                      close
                      small
                    >
                      {{ item }}
                    </v-chip>
                  </template>
                </v-combobox>

                <v-select
                  v-model="newRule.channels"
                  v-bind:items="channels"
                  v-bind:label="$t('notificationChannel')"
                  v-bind:outlined="outlinedPref"
                  style="flex-grow: 0; max-width: 25%"
                  hide-details
                  multiple
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item
                      v-on="on"
                      v-bind="attrs"
                      v-bind:disabled="!pluginsStatus(item)"
                      #default="{ active }"
                      dense
                    >
                      <v-list-item-action class="d-flex align-center">
                        <v-checkbox
                          v-bind:input-value="active"
                          v-bind:ripple="false"
                          v-bind:disabled="!pluginsStatus(item)"
                        />
                      </v-list-item-action>
                      <v-list-item-content>{{ $t(item) }}</v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <div class="d-flex">
                      <span>{{ $t(item) }}</span>
                      <span
                        v-if="index < newRule.channels.length - 1"
                        class="me-1"
                      >
                        ;
                      </span>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>

            <v-divider class="my-4" />

            <div class="mt-0 vertical-center" row>
              <v-combobox
                v-model="newRule.group"
                v-bind:items="eventGroups"
                v-bind:search-input.sync="newRule.searchGroup"
                v-bind:label="$t('ruleGroup')"
                v-bind:rules="[(v) => !!v || $t('required')]"
                v-bind:outlined="outlinedPref"
                required
              >
                <template v-if="!!newRule.searchGroup" v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="
                          $t('comboBoxNoData').replace(
                            '%s%',
                            newRule.searchGroup
                          )
                        "
                      />
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
              <span>/</span>
              <v-text-field
                v-model="newRule.code"
                v-bind:label="$t('ruleCode')"
                v-bind:outlined="outlinedPref"
                readonly
                required
              />

              <v-select
                v-model="newRule.type"
                v-bind:outlined="outlinedPref"
                v-bind:items="types"
                v-bind:label="$t('ruleType')"
                v-bind:rules="[(v) => !!v || $t('required')]"
                class="small-input"
                required
              >
                <template v-slot:item="{ item }">
                  <div>{{ $t(item) }}</div>
                </template>

                <template v-slot:selection="{ item }">
                  <div>{{ $t(item) }}</div>
                </template>
              </v-select>

              <v-select
                v-model="newRule.severity"
                v-bind:outlined="outlinedPref"
                v-bind:items="severities"
                v-bind:label="$t('ruleSeverity')"
                v-bind:rules="[(v) => !!v || $t('required')]"
                class="small-input"
                required
              >
                <template v-slot:item="{ item }">
                  <div row>
                    <v-icon v-bind:color="getSeverityColor(item)">
                      mdi-{{ getSeverityIcon(item) }}
                    </v-icon>
                    <div col>
                      <span>{{ $t(item) }}</span>
                      <span
                        v-if="item === constSevs.TRACE"
                        class="caption primary--text text--lighten-2"
                      >
                        {{ $t("noShowSeverity") }}
                      </span>
                    </div>
                  </div>
                </template>

                <template v-slot:selection="{ item }">
                  <div>{{ $t(item) }}</div>
                </template>
              </v-select>
            </div>

            <div class="vertical-center" row>
              <v-select
                v-model="newRule.assetsEntityCodes"
                v-bind:items="filteredEntityCodes"
                v-bind:label="$t('customerEntityCodes')"
                v-bind:outlined="outlinedPref"
                item-value="id"
                style="width: 45%"
                clearable
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-select
                        v-model="ecFilter"
                        v-bind:items="allTypes"
                        v-bind:label="$t('assetType')"
                        clearable
                      />
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item, index }">
                  <ItemSelection
                    v-bind:index="index"
                    v-bind:item="item"
                    v-bind:array-length="newRule.assetsEntityCodes.length"
                    v-bind:shown-items="1"
                    mode="entityCode"
                  />
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item
                    v-on="on"
                    v-bind="attrs"
                    #default="{ active }"
                    class="my-0"
                  >
                    <AssetItem
                      v-bind:active="active"
                      v-bind:item="item"
                      mode="entityCode"
                    />
                  </v-list-item>
                </template>
              </v-select>

              <span class="mx-3">|</span>

              <v-select
                v-model="newRule.assetsIds"
                v-bind:items="filteredAssetsIds"
                v-bind:label="$t('customerAssets')"
                v-bind:outlined="outlinedPref"
                item-value="id"
                style="width: 60%"
                clearable
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-select
                        v-model="aIdFilter"
                        v-bind:items="allTypes"
                        v-bind:label="$t('assetType')"
                        clearable
                      />
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item, index }">
                  <ItemSelection
                    v-bind:index="index"
                    v-bind:item="item"
                    v-bind:array-length="newRule.assetsIds.length"
                    v-bind:shown-items="1"
                    mode="asset"
                  />
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item
                    v-on="on"
                    v-bind="attrs"
                    #default="{ active }"
                    class="my-0"
                  >
                    <AssetItem
                      v-bind:active="active"
                      v-bind:item="item"
                      mode="asset"
                    />
                  </v-list-item>
                </template>
              </v-select>

              <v-btn v-on:click="selectDeselectAll" color="primary" text>
                {{
                  newRule.assetsIds.length != pairedAssetsList.length
                    ? $t("btnSelectAll")
                    : $t("btnDeselectAll")
                }}
              </v-btn>
            </div>

            <h4>{{ $t("ruleBoolean") }}</h4>

            <Comparison
              v-model="newRule.structure"
              v-bind:topics="filteredAvailableTopics"
              v-on:valid="ruleIsValid = $event"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="edit"
            v-on:click="deleteRule(find(newRule))"
            color="error"
            text
          >
            {{ $t("btnDelete") }}
          </v-btn>
          <v-spacer />
          <v-btn v-on:click="resetRule(edit)" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validRule"
            v-on:click="postRule()"
            color="primary"
            text
          >
            {{ edit ? $t("btnSave") : $t("btnCreate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      v-if="confirmDelete"
      v-bind:callback="confirmCallback || deleteCallback"
      v-bind:content="$t('confirmRuleDelete')"
      v-bind:title="$t('dialogRuleDeleteConfirm')"
      v-on:cancel="confirmDelete = false"
    />

    <v-dialog v-model="loading" width="unset" persistent>
      <v-card>
        <v-progress-circular class="ma-4" color="primary" indeterminate />
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.input-container {
  display: flex;
  flex-direction: column;
}

.large-input {
  min-width: 35ch;
  max-width: 35ch;
}

.small-input {
  max-width: 16ch;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-rows: min-content 1fr;
  max-height: 100%;
  overflow: hidden;
}

.table {
  position: relative;
  overflow: hidden;
}

div[row] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5em;
}

div[col] {
  display: flex;
  flex-direction: column;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Messages from "../utils/messages";
import Pages from "../utils/pages";
import Event from "../models/Event";
import ExpressionInfo from "../models/ExpressionInfo";
import EntitiesTable from "../components/EntitiesTable";
import ConfirmationDialog from "../components/ConfirmationDialog";
import Comparison from "../components/Comparison";
import EntityCode from "../models/EntityCode";
import ItemSelection from "../components/ItemSelection";
import AssetItem from "../components/AssetItem";

export default {
  name: "Rules",

  components: {
    Comparison,
    ConfirmationDialog,
    EntitiesTable,
    ItemSelection,
    AssetItem,
  },

  data: () => ({
    loading: false,
    newRuleDialog: false,
    confirmDelete: false,
    confirmCallback: undefined,
    ruleIsValid: false,
    edit: false,
    newRule: new ExpressionInfo(),
    constSevs: Event.severity,
    allRules: [],
    entityCodes: [],
    ecFilter: undefined,
    aIdFilter: undefined,
    operators: ExpressionInfo.BOOLEANS,
    comparators: ExpressionInfo.COMPARISONS,
    types: ExpressionInfo.TYPES,
    channels: ExpressionInfo.CHANNELS,
    severities: [
      Event.severity.TRACE,
      Event.severity.LOW,
      Event.severity.MEDIUM,
      Event.severity.HIGH,
    ],
  }),

  async beforeMount() {
    this.$store.commit("setCurrentPage", Pages.RULES_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);

    await this.getEntityCodes();
    this.updateFiltersRules(this.clientId);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuRules"),
      to: "/rules",
      root: true,
    });

    setTimeout(() => this.loadRules(), 250);
  },

  computed: {
    validRule() {
      return (
        !!this.newRule.name &&
        !!this.newRule.description &&
        !!this.newRule.group &&
        !!this.newRule.code &&
        !!this.newRule.severity &&
        !!this.newRule.type &&
        this.newRule.enabled != undefined &&
        this.newRule.structure.length > 0 &&
        (this.newRule.assetsIds.length > 0 ||
          this.newRule.assetsEntityCodes.length > 0) &&
        this.ruleIsValid
      );
    },
    filteredAvailableTopics() {
      const allTopics = this.$store.getters.availableTopics;
      const singleEcs = this.newRule.assetsIds
        .map((id) => {
          return this.pairedAssetsList.find((a) => a.id === id)?.entityCode;
        })
        .filter((ec) => ec != undefined);
      const selectedEcs = [...this.newRule.assetsEntityCodes, ...singleEcs];

      return Object.keys(allTopics).filter((t) => {
        return (
          allTopics[t].length === 0 ||
          selectedEcs.length === 0 ||
          allTopics[t].reduce((a, c) => a || selectedEcs.includes(c), false)
        );
      });
    },
    eventTopics() {
      return Object.keys(this.eventMessages).map((t) => t.split("/"));
    },
    eventGroups() {
      return this.eventTopics.map((t) => t[0]);
    },
    allTypes() {
      return this.entityCodes.map((ec) => ec.type).sort();
    },
    filteredEntityCodes() {
      return this.entityCodes.filter((ec) =>
        this.ecFilter ? ec.type === this.ecFilter : true
      );
    },
    pairedAssetsList() {
      return this.assetsList.filter((v) => !!v.boxMacAddress);
    },
    filteredAssetsIds() {
      return this.pairedAssetsList.filter((a) =>
        this.aIdFilter ? a.type === this.aIdFilter : true
      );
    },
    sortingFields() {
      return [
        { text: this.$t("ruleName"), value: "name" },
        { text: this.$t("ruleType"), value: "type" },
        { text: this.$t("ruleSeverity"), value: "severityNum" },
        { text: this.$t("ruleEnabled"), value: "enabled" },
        { text: this.$t("userModified"), value: "modified" },
      ];
    },
    headers() {
      return [
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("ruleEnabled"), align: "start", sortable: false, value: "enabled", width: "8%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("ruleName"), align: "start", sortable: false, value: "name", width: "16%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("ruleBoolean"), align: "start", sortable: false, value: "structure" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("ruleType"), align: "end", sortable: false, value: "type", width: "8%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("ruleSeverity"), align: "start", sortable: false, value: "severityNum", width: "8%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("userModified"), align: "end", sortable: false, value: "modified", width: "10%" },
        // eslint-disable-next-line prettier/prettier
        { text: "", align: "end", sortable: false, value: "actions", width: "5%" },
      ];
    },
    addresseesEmails: {
      get() {
        return this.newRule.addressees
          .filter((a) => a.includes("email"))
          .map((a) => a.split(":")[0]);
      },
      set(newValue) {
        const addresseesSet = new Set(this.newRule.addressees);
        newValue.forEach((v) => addresseesSet.add(`${v}:email`));
        this.newRule.addressees = Array.from(addresseesSet);
      },
    },
    addresseesPhones: {
      get() {
        return this.newRule.addressees
          .filter((a) => a.includes("phone"))
          .map((a) => a.split(":")[0]);
      },
      set(newValue) {
        const addresseesSet = new Set(this.newRule.addressees);
        newValue.forEach((v) => addresseesSet.add(`${v}:phone`));
        this.newRule.addressees = Array.from(addresseesSet);
      },
    },
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
  },

  methods: {
    async getEntityCodes() {
      try {
        this.entityCodes = (
          await ApiRequests.getAssetsEntityCodes(undefined, undefined)
        ).data.map((ec) => new EntityCode(ec));
      } catch {
        /* Unhandled exception */
      }
    },
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    loadRules() {
      this.loading = true;

      ApiRequests.getExpressions(
        undefined,
        (res) => {
          this.allRules = res.data.map((exp) => new ExpressionInfo(exp));
          this.loading = false;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.loading = false;
        }
      );
    },
    openEditDialog(index) {
      this.newRule = this.clone(this.allRules[index]);
      this.edit = true;
      this.newRuleDialog = true;
    },
    resetRule() {
      this.newRuleDialog = false;
      this.confirmDelete = false;
      this.newRule = new ExpressionInfo();
      this.edit = false;
      this.ecFilter = undefined;
      this.aIdFilter = undefined;
    },
    deleteRule(index) {
      this.newRule = this.clone(this.allRules[index]);
      this.confirmDelete = true;
    },
    deleteCallback() {
      ApiRequests.deleteExpression(
        this.newRule.id,
        () => {
          this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("ruleDeleteSuccess"));
          this.loadRules();
          this.resetRule();
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.resetRule();
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("ruleDeleteFailure"),
          });
        }
      );
    },
    postRule() {
      if (!this.edit) {
        ApiRequests.createExpression(
          this.newRule.getCreateUpdateExpressionBody(),
          () => {
            this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("ruleCreateSuccess"));
            this.loadRules();
            this.resetRule();
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("ruleCreateFailure"),
            });
          }
        );
      } else {
        ApiRequests.updateExpression(
          this.newRule.id,
          this.newRule.getCreateUpdateExpressionBody(),
          () => {
            this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("ruleUpdateSuccess"));
            this.loadRules();
            this.resetRule();
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("ruleUpdateFailure"),
            });
          }
        );
      }
    },
    handleClick(item) {
      this.openEditDialog(this.find(item));
    },
    handleEnable(index, status) {
      this.newRule = this.clone(this.allRules[index]);
      this.edit = true;
      this.newRule.enabled = status;

      this.postRule();
    },
    manageRuleBulk(selected, mode) {
      const enableDisable = (enabled, rule) => {
        return ApiRequests.updateExpression(
          rule.id,
          { enabled },
          undefined,
          undefined
        );
      };
      const callback = (res, text1, text2, text3) => {
        const total = selected.length;
        const sent = res.reduce(
          (acc, r) => acc + (r.status === "fulfilled" ? 1 : 0),
          0
        );

        if (total > sent) {
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: text3.replace(/%s%/g, sent).replace(/%t%/g, total),
            description: text2,
          });
        } else {
          this.$bus.$emit(Messages.SUCCESS_MSG, text1);
        }

        this.$bus.$emit(Messages.RESET_TABLE_SELECTED);
        this.loadRules();
        this.loading = false;
      };

      switch (mode) {
        case 1:
          this.loading = true;
          Promise.allSettled(
            selected.map((r) => enableDisable(true, r))
          ).then((res) =>
            callback(
              res,
              this.$t("ruleUpdateSuccess"),
              this.$t("ruleUpdateFailure"),
              "Error: %s% of %t% customers updated"
            )
          );
          break;
        case 2:
          this.loading = true;
          Promise.allSettled(
            selected.map((r) => enableDisable(false, r))
          ).then((res) =>
            callback(
              res,
              this.$t("ruleUpdateSuccess"),
              this.$t("ruleUpdateFailure"),
              "Error: %s% of %t% rules updated"
            )
          );
          break;
        default:
          this.confirmCallback = () => {
            this.loading = true;
            Promise.allSettled(
              selected.map((r) => {
                return ApiRequests.deleteExpression(r.id, undefined, undefined);
              })
            ).then((res) => {
              this.confirmCallback = undefined;
              callback(
                res,
                this.$t("ruleDeleteSuccess"),
                this.$t("ruleDeleteFailure"),
                "Error: %s% of %t% rules deleted"
              );
            });
          };
          this.confirmDelete = true;
          break;
      }
    },
    clone(item) {
      return item.clone();
    },
    find(item) {
      return this.allRules.findIndex((r) => r.id === item.id);
    },
    selectDeselectAll() {
      if (this.newRule.assetsIds.length < this.pairedAssetsList.length) {
        this.newRule.assetsIds = this.pairedAssetsList.map((a) => a.id);
      } else {
        this.newRule.assetsIds = [];
      }
    },
    getSeverityIcon(sev) {
      switch (sev) {
        case Event.severity.LOW:
          return "alert-circle-outline";
        case Event.severity.MEDIUM:
          return "alert-outline";
        case Event.severity.HIGH:
          return "alert-decagram-outline";
        default:
          return "information-outline";
      }
    },
    getSeverityColor(sev) {
      switch (sev) {
        case Event.severity.LOW:
          return "success";
        case Event.severity.MEDIUM:
          return "warning";
        case Event.severity.HIGH:
          return "error";
        default:
          return "primary";
      }
    },
  },
};
</script>
