class Connection {
  /* Constants */
  static CONNECTED = "connection_connected";
  static DISCONNECTED = "connection_disconnected";
  static RECONNECTING = "connection_reconnecting";

  static STATES = {
    connection_connected: {
      icon: "mdi-wifi",
      color: "success",
      text: "connected",
      animated: false,
    },
    connection_disconnected: {
      icon: "mdi-wifi-off",
      color: "error",
      text: "disconnected",
      animated: false,
    },
    connection_reconnecting: {
      icon: "mdi-sync",
      color: "warning",
      text: "connecting",
      animated: true,
    },
  };
}

export default Connection;
