<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card elevation="6">
          <div
            v-bind:style="'background-color: ' + appColor"
            class="d-flex flex-row align-center dark"
          >
            <v-card-title>
              <v-avatar class="white">
                <v-img v-bind:src="appLogo" />
              </v-avatar>
            </v-card-title>
            <div class="flex-grow-1">
              <v-card-title class="white--text">
                {{ appName }}
              </v-card-title>
              <v-card-subtitle v-if="vendorName" class="white--text caption">
                {{ vendorName }}
              </v-card-subtitle>
            </div>
          </div>
          <v-card-text>
            <v-form>
              <p>
                <v-text-field
                  v-model="credentials.email"
                  v-bind:label="$t('fieldEmail')"
                  v-bind:rules="[(v) => !!v || $t('required')]"
                  type="text"
                  autofocus
                  hide-details
                  v-bind:outlined="outlinedPref"
                  required
                />
              </p>
              <p>
                <v-text-field
                  v-model="credentials.password"
                  v-bind:append-icon="
                    showPwd ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  v-bind:label="$t('fieldPassword')"
                  v-bind:rules="[(v) => !!v || $t('required')]"
                  v-bind:type="showPwd ? 'text' : 'password'"
                  v-on:click:append="showPwd = !showPwd"
                  v-on:keyup.enter="login"
                  hide-details
                  v-bind:outlined="outlinedPref"
                  required
                />
              </p>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-select
              v-if="languages.length > 1"
              v-model="selectedLanguage"
              v-bind:items="languages"
              v-bind:outlined="outlinedPref"
              v-bind:class="{
                'small-selector': !outlinedPref,
                'bigger-selector': outlinedPref,
                'ms-2': true,
              }"
            />
            <v-spacer />
            <v-btn
              v-bind:disabled="!validCredentials"
              v-on:click="login"
              color="primary"
              text
            >
              {{ $t("btnLogin") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.small-selector {
  max-width: 6ch;
}

.bigger-selector {
  max-width: 10ch;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import Messages from "../utils/messages";

export default {
  name: "Login",

  data: () => ({
    showPwd: false,
    credentials: {
      email: undefined,
      password: undefined,
    },
  }),

  computed: {
    validCredentials() {
      return !!this.credentials.email && !!this.credentials.password;
    },
    languages() {
      return this.$store.getters.array("VUE_APP_LOCALES");
    },
  },

  beforeMount() {
    this.$store.commit("setLogged", false);
    this.$store.commit("setCurrentUser", undefined);
    this.$store.commit("setCurrentToken", {});
    this.$store.commit("setCurrentCompany", undefined);
    this.$store.commit("setGroups", []);
    this.$store.commit("setUsers", []);
    this.$store.commit("setCustomers", []);
    this.$store.commit("enumerateAssets", []);
    this.$store.commit("setCurrentPage", Pages.LOGIN_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuLogin"),
      to: "/login",
      root: true,
    });
  },

  methods: {
    login() {
      this.error = undefined;

      ApiRequests.userLogin(
        this.credentials,
        () => {
          // Get logged user
          ApiRequests.userCurrent(
            (res) => {
              this.$store.commit("setCurrentUser", res.data);
              this.$store.commit("setLogged", true);

              if (res.data.companyId != undefined) {
                ApiRequests.getCurrentCustomer(
                  (res) => {
                    this.$store.commit("setCurrentCompany", res.data);
                    this.$router.replace({ path: "/dashboard" });
                  },
                  (err) =>
                    process.env.NODE_ENV === "development"
                      ? console.error(err)
                      : undefined
                );
              } else {
                this.$router.replace({ path: "/dashboard" });
              }
            },
            (err) => {
              if (process.env.NODE_ENV === "development") console.error(err);
              this.$bus.$emit(Messages.ERROR_MSG, {
                error: err?.response?.data?.error || err,
                description: this.$t("loginFailure"),
              });
            }
          );

          // Get session token
          ApiRequests.tokenCurrent(
            (res) => {
              const token = res.data || {};

              this.$store.commit("setCurrentToken", token);
            },
            (err) =>
              process.env.NODE_ENV === "development"
                ? console.error(err)
                : undefined
          );
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          const errStatus = err.response?.status;
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description:
              errStatus === 404 || errStatus === 400
                ? this.$t("invalidCredentials")
                : this.$t("loginFailure"),
          });
        }
      );
    },
  },
};
</script>
