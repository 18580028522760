<template>
  <div class="fill-height pb-2">
    <iframe
      v-bind:src="iFrameUrl"
      style="width: 100%; height: 100%; border: none"
    />
  </div>
</template>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";

export default {
  name: "CustomPage",

  beforeMount() {
    this.updateFiltersRules(this.clientId);
    this.$store.commit("setCurrentPage", Pages.TEMPLATE_PAGE);
    this.$store.commit("setCustomCurrentPage", Number(this.$route.query.page));
  },

  mounted() {
    this.$store.commit("addPage", {
      text: "CustomPage",
      to: "/custom-page",
      root: true,
    });
  },

  computed: {
    iFrameUrl() {
      let queryUrl = this.$route.query.url || "";

      // If the url is like:
      // rprotocol=string;rport=number;rpath=string
      //
      // the real url must be builded from the current host

      if (queryUrl.includes("rport")) {
        const splitted = decodeURI(queryUrl).split(";");
        const urlObj = {};

        splitted.forEach((piece) => {
          const entry = piece.split("=");
          urlObj[entry[0]] = entry[1];
        });

        queryUrl = urlObj.rprotocol || "";

        if (queryUrl) queryUrl += "://";

        queryUrl += window.location.hostname;
        queryUrl += ":" + urlObj.rport;
        queryUrl += urlObj.rpath || "";
      }

      try {
        const url = new URL(queryUrl);

        url.searchParams.set("_sn", this.currentToken.name);
        url.searchParams.set("_sv", this.currentToken.value);

        return url.toString();
      } catch {
        // Internal url
        return this.$route.query.url;
      }
    },
    pageNumber() {
      return Number(this.$route.query.page);
    },
    currentToken() {
      return this.$store.getters.currentToken;
    },
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
    pageNumber(newPage) {
      this.$store.commit("setCustomCurrentPage", newPage);
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
  },
};
</script>
