<template>
  <v-app>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-alert border="left" elevation="6" color="error" outlined text>
            <v-card-title color="red"> Configuration error </v-card-title>
            <v-card-text color="red">
              An error occurred when fetching the app configuration. Please,
              verify your internet connection and contact your system
              administrator if the problem persists.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn v-on:click="$router.go()" color="error" small text>
                Try reloading the page
              </v-btn>
            </v-card-actions>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "ConfigError",
};
</script>
