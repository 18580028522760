import BaseMsg from "./BaseMsg";

class Metadata extends BaseMsg {
  static types = {
    ATTRIBUTE: "attribute",
    PROPERTIES: "properties",
    NODES: "nodes",
  };

  _content = undefined;
  _contentType = undefined;
  _label = undefined;
  _path = undefined;

  constructor(meta = undefined) {
    super(meta);

    if (meta != undefined) {
      this._content = meta.Content;
      this._contentType = meta.ContentType.toLowerCase();
      this._label = meta.Properties.label;
      this._path = meta.Properties.path;

      this._parseContent();
    }
  }

  // Getters

  get content() {
    return this._content;
  }
  get contentType() {
    return this._contentType;
  }
  get label() {
    return this._label;
  }
  get path() {
    return this._path;
  }

  // Public methods

  /**
   * Determines if the metadata is a measure attribute or not.
   * @public
   * @returns {Boolean} - The metadata is a measure attribute or not.
   */
  isAttribute() {
    return this._contentType === Metadata.types.ATTRIBUTE;
  }

  /**
   * Determines if the metadata is a list of measure topics.
   * @public
   * @returns {Boolean} - The metadata is a list of measure topics.
   */
  isTopicsList() {
    return (
      this._contentType === Metadata.types.PROPERTIES ||
      this._contentType === Metadata.types.NODES
    );
  }

  // Private methods

  /**
   * Converts the metadata payload onto it's type.
   * @private
   */
  _parseContent() {
    if (this.isTopicsList()) {
      this._content = this._content.split(",");
    }
  }
}

export default Metadata;
