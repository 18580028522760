<template>
  <div class="chart-container">
    <LineChart
      id="line-chart"
      v-bind:chartData="chartDataSettings"
      v-bind:plugins="plugins.value"
      v-bind:options="options.value"
      v-bind:key="'multiline-chart-' + update"
    />

    <div v-if="realtime" class="realtime-header">
      <v-btn
        v-bind:disabled="!selectionFromClick"
        v-on:click="selectionFromClick = false"
        color="primary"
        text
        x-small
      >
        {{
          !selectionFromClick
            ? $t("measuresRealtime")
            : $t("activateMeasuresRealtime")
        }}
      </v-btn>
    </div>

    <div v-if="loading" class="loader">
      <v-progress-circular color="primary" indeterminate />
    </div>
  </div>
</template>

<style scoped>
.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(245, 245, 245, 0.6);
}

.realtime-header {
  display: inline;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

#line-chart {
  max-height: 100%;
  min-height: 100%;
  max-width: 100%;
}
</style>

<script>
import { Interaction } from "chart.js";
import { CrosshairPlugin, Interpolate } from "chartjs-plugin-crosshair";
import { LineChart } from "vue-chart-3";
import { ref } from "@vue/composition-api";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment";

export default {
  name: "Chart",

  components: { LineChart },

  props: {
    chartData: { type: Object, required: false, default: () => {} },
    topicPrefix: { type: String, required: false, default: "" },
    asset: { type: String, required: false, default: undefined },
    colors: { type: Object, required: false },
    filter: { type: Array, required: false, default: undefined },
    minTimestamp: { type: Number, required: false, default: undefined },
    maxTimestamp: { type: Number, required: false, default: undefined },
    realtime: { type: Boolean, required: false, default: false },
    startAtZero: { type: Boolean, required: false, default: true },
    dataGaps: { type: Boolean, required: false, default: false },
  },

  data: () => ({
    update: false,
    loading: false,
    datasets: ref([]),
    options: ref({}),
    plugins: ref([]),
    selectionFromClick: false,
    topics: 0,
    minTsZm: undefined,
    maxTsZm: undefined,
  }),

  computed: {
    chartDataSettings() {
      return { datasets: this.datasets.value };
    },
  },

  watch: {
    startAtZero(newValue) {
      Object.keys(this.options.value.scales).forEach((axis) => {
        if (axis !== "x") {
          const minWorker = new Worker("./workers/min-value.js");

          minWorker.onerror = () => {
            minWorker.terminate();
          };

          minWorker.onmessage = (e) => {
            // eslint-disable-next-line prettier/prettier
            this.options.value.scales[axis].beginAtZero = newValue && e.data >= 0;
            this.$nextTick(() => {
              this.refresh();
              minWorker.terminate();
            });
          };

          minWorker.postMessage({
            datasets: this.datasets.value,
            axis,
          });
        }
      });
    },
    minTimestamp(newValue) {
      if (newValue == undefined) return;

      this.options.value.scales.x.min = newValue;
      this.minTsZm = newValue;
      this.refresh();
    },
    maxTimestamp(newValue) {
      if (newValue == undefined) return;

      this.options.value.scales.x.max = newValue;
      this.maxTsZm = newValue;
      this.refresh();
    },
    filter: {
      deep: true,
      handler(newFilter) {
        this.datasets.value.forEach((dataset) => {
          dataset.hidden =
            newFilter !== undefined && !newFilter.includes(dataset.label[0]);
        });

        let firstGrid = undefined;
        const visibleAxes = this.datasets.value
          .filter((dataset) => !dataset.hidden)
          .map((dataset) => dataset.yAxisID);

        Object.keys(this.options.value.scales).forEach((axis) => {
          if (axis !== "x") {
            const isVisible = visibleAxes.includes(axis);

            if (firstGrid === undefined && isVisible) firstGrid = axis;

            this.options.value.scales[axis].display = isVisible;
            // eslint-disable-next-line prettier/prettier
            this.options.value.scales[axis].grid.drawOnChartArea = firstGrid === axis;
          }
        });

        this.refresh();
      },
    },
    chartData: {
      deep: true,
      handler(newData) {
        if (!this.realtime) this.loading = true;

        this.emptyRefs();

        // eslint-disable-next-line prettier/prettier
        const offlineMs = (this.$store.getters.number("VUE_APP_OFFLINE_TIMEOUT_MINUTES") || 15) * 60000;
        const dataKeys = Object.keys(newData);
        this.topics = dataKeys.length;
        const createAxes = () => {
          // Find not hidden dataset
          let firstNotHiddenDS = 0;

          while (
            firstNotHiddenDS < this.datasets.value.length &&
            this.datasets.value[firstNotHiddenDS].hidden
          ) {
            firstNotHiddenDS++;
          }

          // Create Y axes
          this.datasets.value.forEach((dataset, index) => {
            // eslint-disable-next-line prettier/prettier
            const minInDataset = dataset.data.reduce((m, v) => v.y < m ? v.y : m, 0);

            if (this.options.value.scales[dataset.yAxisID] == undefined) {
              this.options.value.scales[dataset.yAxisID] = {
                type: "linear",
                beginAtZero: this.startAtZero && minInDataset >= 0,
                position:
                  Object.keys(this.options.value.scales).length < 6
                    ? "left"
                    : "right",
                display: this.filter.includes(dataset.label[0]),
                ticks: {
                  color: dataset.backgroundColor,
                  callback: (label) =>
                    `${this.formatValue(label)}${dataset.yAxisID}`,
                },
                grid: {
                  drawOnChartArea: index === firstNotHiddenDS,
                  color: dataset.backgroundColor,
                },
              };
            } else {
              // eslint-disable-next-line prettier/prettier
              this.options.value.scales[dataset.yAxisID].beginAtZero = minInDataset >= 0;
            }
          });
        };

        if (dataKeys.length === 0) this.loaded();

        let finishedCount = 0;

        dataKeys.forEach((t) => {
          const topic = t.replace(this.topicPrefix, "");
          const values = newData[t];

          const datasetWorker = new Worker("./workers/datasetter.js");

          datasetWorker.onerror = () => {
            datasetWorker.terminate();
          };

          datasetWorker.onmessage = (e) => {
            this.datasets.value.push(e.data);
            createAxes();
            this.emitLastValues();
            this.loaded(++finishedCount);
            datasetWorker.terminate();
          };

          datasetWorker.postMessage({
            template: {
              label: [topic, this.$t(topic)],
              hidden: !this.filter.includes(topic),
              data: [],
              borderColor: this.colors[t],
              backgroundColor: this.colors[t],
              spanGaps: false,
              yAxisID: this.getUnit(topic.split("_")[0]) || " ",
            },
            placeGaps: this.dataGaps,
            offlineMs,
            values,
          });
        });
      },
    },
  },

  beforeMount() {
    Interaction.modes.interpolate = Interpolate;

    this.plugins.value.push(CrosshairPlugin);
    this.plugins.value.push(zoomPlugin);

    this.loadChartSettings();
  },

  methods: {
    loaded(number = 0) {
      if (this.topics === number) {
        this.refresh();
        this.loading = false;
        this.$emit("data-loaded");
      }
    },
    addToDataset(ts, value, topic) {
      const index = this.datasets.value.findIndex((d) => d.label[0] === topic);

      if (index < 0) return;

      this.datasets.value[index].data.push({ x: ts, y: value });
      this.emitLastValues();
      this.refresh();
    },
    emitLastValues() {
      if (
        this.datasets.value.length === 0 ||
        (this.realtime && this.selectionFromClick)
      ) {
        return;
      }

      const selectedData = {};

      this.datasets.value.forEach((dataset) => {
        const lastValue = dataset.data[dataset.data.length - 1];

        selectedData[dataset.label[0]] = {
          ts: lastValue.x,
          data: lastValue.y,
        };
      });

      this.$emit("x-axis-selected", selectedData);
    },
    loadChartSettings() {
      this.options.value = {
        responsive: true,
        stacked: false,
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          axis: "x",
          intersect: false,
        },
        animation: false,
        parsing: false,
        onClick: this.selectedValues,
        elements: {
          point: {
            radius: 1.5,
            borderWidth: 0,
            hoverRadius: 5,
          },
          line: {
            borderWidth: 3,
            borderJoinStyle: "round",
            cubicInterpolationMode: "monotone",
          },
        },
        plugins: {
          legend: false,
          decimation: {
            enabled: true,
            algorithm: "lttb",
            samples: 500,
            threshold: 1000,
          },
          tooltip: {
            mode: "nearest",
            axis: "x",
            intersect: false,
            callbacks: {
              title: (tooltipItem) =>
                moment
                  .utc(tooltipItem[0].parsed.x)
                  .local()
                  .format("DD MMM YYYY HH:mm:ss"),
              label: (tooltipItem) => {
                const unit = tooltipItem.dataset.yAxisID;
                const value = this.formatValue(
                  tooltipItem.dataset.data[tooltipItem.dataIndex].y
                );

                return ` ${tooltipItem.dataset.label[1]}: ${value}${unit}`;
              },
            },
          },
          crosshair: {
            snap: { enabled: true },
            zoom: { enabled: false },
            line: {
              color: "#1B1B1B",
              width: 1,
              dashPattern: [6, 3],
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
            },
            zoom: {
              wheel: { enabled: true },
              mode: "x",
            },
          },
        },
        scales: {
          x: {
            type: "time",
            ticks: {
              font: { size: 10 },
              maxRotation: 45,
              autoSkip: true,
            },
            time: {
              displayFormats: {
                millisecond: "HH:mm:ss.SSS",
                second: "HH:mm:ss",
                minute: "HH:mm:ss",
                hour: "DD MMM HH:mm:ss",
                day: "DD MMM HH:mm:ss",
                week: "DD MMM HH:mm:ss",
                month: "DD MMM YYYY",
                quarter: "DD MMM YYYY",
                year: "DD MMM YYYY",
              },
            },
          },
        },
      };
    },
    emptyRefs() {
      this.datasets.value = [];
      this.options.value.scales = {
        x: {
          type: "time",
          ticks: {
            font: { size: 10 },
            maxRotation: 45,
            autoSkip: true,
          },
          time: {
            displayFormats: {
              millisecond: "HH:mm:ss.SSS",
              second: "HH:mm:ss",
              minute: "HH:mm:ss",
              hour: "DD MMM HH:mm:ss",
              day: "DD MMM HH:mm:ss",
              week: "DD MMM HH:mm:ss",
              month: "DD MMM YYYY",
              quarter: "DD MMM YYYY",
              year: "DD MMM YYYY",
            },
          },
        },
      };

      this.refresh();
    },
    getValue(topic) {
      return this.getMeasureValue(
        this.$store.getters.measuresById(this.asset ?? "")?.[topic]
      );
    },
    getUnit(topic) {
      return this.getValue(topic)?.getUnit() ?? "";
    },
    formatValue(value) {
      if (Number.isNaN(value)) return value;

      const number = Number(value);
      const truncated = Math.trunc(number);

      return number.toFixed(number - truncated !== 0 ? 2 : 0);
    },
    refresh() {
      this.options.value.scales.x.min = this.minTsZm;
      this.options.value.scales.x.max = this.maxTsZm;
      this.update = !this.update;
    },
    selectedValues(evt, activeElements) {
      this.selectionFromClick = true;

      const allDatasets = evt.chart.data.datasets;
      const allActiveDatasets = activeElements.map((ae) => ae.datasetIndex);
      const selectedData = {};
      let selectedTs = undefined;

      // Emit selected data
      activeElements.forEach((element) => {
        const dataset = allDatasets[element.datasetIndex];
        const selectedElement = dataset.data[element.index];

        selectedTs = selectedElement?.x;

        selectedData[dataset.label[0]] = {
          ts: selectedTs,
          data: selectedElement?.y,
        };
      });

      // Emit null for not selected data
      allDatasets.forEach((dataset, index) => {
        if (!allActiveDatasets.includes(index)) {
          selectedData[dataset.label[0]] = {
            ts: selectedTs,
            data: null,
          };
        }
      });

      this.$emit("x-axis-selected", selectedData);
    },
  },
};
</script>
