import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import Messages from "./messages";
import store from "../store/index";
import ApiRequests from "../utils/requests";

export default {
  async install(Vue) {
    // Use new Vue instance as an event bus
    const signalr = new Vue();
    // Every component will use this.$signalr to access the event bus
    Vue.prototype.$signalr = signalr;
    // Reconnection delay
    const delay = store.getters.number("VUE_APP_RETRY_DELAY_MILLIS") || 5000;

    const connection = new HubConnectionBuilder()
      .withUrl(
        `${ApiRequests.BACKEND_URL}${store.getters.string("VUE_APP_HUB_PATH")}`,
        {
          skipNegotiation: false,
          transport: HttpTransportType.ServerSentEvents,
        }
      )
      .withAutomaticReconnect([delay, delay, delay, delay])
      .configureLogging(
        process.env.NODE_ENV === "development"
          ? LogLevel.Information
          : LogLevel.None
      )
      .build();

    store.getters.hubs.forEach((msg) => {
      // Forward server side SignalR events through $signalr, where components will listen to them
      connection.on(msg.message, (topic, message) => {
        signalr.$emit(msg.mapped, { topic, message });
      });
    });

    connection.onreconnecting((error) => {
      if (process.env.NODE_ENV === "development") {
        console.assert(connection.state === HubConnectionState.Reconnecting);
        console.warn(`Connection lost due to error "${error}", reconnecting`);
      }

      signalr.$emit(Messages.SIGNALR_RECONNECTING_MSG);
    });

    connection.onreconnected((connectionId) => {
      if (process.env.NODE_ENV === "development") {
        console.assert(connection.state === HubConnectionState.Connected);
      }

      signalr.$emit(Messages.SIGNALR_CONNECTED_MSG, connectionId);
    });

    connection.onclose((error) => {
      if (process.env.NODE_ENV === "development") {
        console.assert(connection.state === HubConnectionState.Disconnected);
        console.error(
          `Connection closed due to error "${error}". Try refreshing this page to restart the connection`
        );
      }

      signalr.$emit(Messages.SIGNALR_DISCONNECTED_MSG);
    });

    async function start() {
      try {
        await connection.start();

        if (process.env.NODE_ENV === "development") {
          console.assert(connection.state === HubConnectionState.Connected);
        }

        signalr.$emit(Messages.SIGNALR_CONNECTED_MSG, connection.connectionId);
      } catch (err) {
        if (process.env.NODE_ENV === "development") {
          console.assert(connection.state === HubConnectionState.Disconnected);
          console.error(err);
        }

        signalr.$emit(Messages.SIGNALR_DISCONNECTED_MSG);
        setTimeout(() => start(), delay);
      }
    }

    start();
  },
};
