<template>
  <div class="pa-4 fill-height grid">
    <h1>{{ $t("menuBoxes") }}</h1>

    <div class="table">
      <EntitiesTable
        v-bind:headers="headers"
        v-bind:sorting-fields="sortingFields"
        v-bind:items="unfilteredAssetsList"
        v-bind:defaultSort="sortingFields[0].value"
        v-bind:loading="loading"
        v-bind:actionsColumn="false"
        v-bind:actions="{}"
        v-bind:bulkColumn="false"
        v-on:table-click="handleClick"
        v-on:table-bulk-customer="/* Do nothing */"
        v-on:table-bulk-group="/* Do nothing */"
        v-on:table-bulk-delete="/* Do nothing */"
        v-on:table-edit="/* Do nothing */"
        v-on:table-delete="/* Do nothing */"
        v-on:table-bulk-enable="/* Do nothing */"
        v-on:table-bulk-disable="/* Do nothing */"
        v-on:table-disable="/* Do nothing */"
        v-on:table-enable="/* Do nothing */"
      />
    </div>
  </div>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-rows: min-content 1fr;
  max-height: 100%;
  overflow: hidden;
}

.table {
  position: relative;
  overflow: hidden;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import EntitiesTable from "../components/EntitiesTable";

export default {
  name: "Boxes",

  components: { EntitiesTable },

  data: () => ({
    loading: false,
  }),

  computed: {
    sortingFields() {
      const fields = [
        { text: this.$t("assetMac"), value: "boxMacAddress" },
        { text: this.$t("assetBrand"), value: "brand" },
        { text: this.$t("assetModel"), value: "model" },
        { text: this.$t("assetGroup"), value: "groupName" },
        { text: this.$t("assetType"), value: "type" },
      ];

      if (this.currentUser.isSuperAdmin) {
        fields.push({
          text: this.$t("assetOwner"),
          value: "companyName",
        });
      }

      return fields;
    },
    headers() {
      const fields = [
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetStatus"), align: "start", sortable: false, value: "connection", width: "10%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetMac"), align: "start", sortable: false, value: "boxMacAddress" },
        // eslint-disable-next-line prettier/prettier
        { text: `${this.$t("assetBrand")} - ${this.$t("assetModel")}`, align: "end", sortable: false, value: "brand" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetType"), align: "end", sortable: false, value: "type", width: "12%" }
      ];

      if (this.currentUser.isSuperAdmin) {
        fields.push({
          text: `${this.$t("assetOwner")} - ${this.$t("assetGroup")}`,
          align: "end",
          sortable: false,
          value: "companyName",
          width: "12%",
        });
      } else {
        fields.push({
          text: this.$t("assetGroup"),
          align: "end",
          sortable: false,
          value: "groupName",
          width: "12%",
        });
      }

      return fields;
    },
  },

  beforeMount() {
    this.updateFiltersRules(this.clientId);

    this.$store.commit("setCurrentPage", Pages.BOXES_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuBoxes"),
      to: "/boxes",
      root: true,
    });
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    handleClick(value) {
      this.$router.push({ path: `/asset-details/${value.id}` });
    },
  },
};
</script>
