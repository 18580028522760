<template>
  <div class="chart-container">
    <canvas id="heat-chart-canvas" ref="heatChartCanvas" />
    <div v-if="loading" class="loader">
      <v-progress-circular color="primary" indeterminate />
    </div>
  </div>
</template>

<style scoped>
.chart-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

#heat-chart-canvas {
  max-width: 100%;
  max-height: 100%;
  background-color: white;
}
</style>

<script>
import { Chart } from "chart.js";

export default {
  name: "HeatMap",

  props: {
    data: { type: Array, required: true },
    timeSlots: { type: Array, required: true },
    color: { type: String, required: true },
    loading: { type: Boolean, required: false, default: false },
  },

  watch: {
    data: {
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) return;

        const max = newValue.reduce((m, c) => (c.v > m ? c.v : m), 0);

        this.makeChart(newValue, max);
      },
    },
  },

  methods: {
    makeChart(chartData = [], max = 1) {
      if (this.chart !== undefined) {
        this.chart.destroy();
        this.chart = undefined;
      }

      this.chart = new Chart(this.$refs.heatChartCanvas, {
        type: "matrix",
        data: {
          datasets: [
            {
              label: "Basic matrix",
              data: chartData,
              borderWidth: 1,
              borderRadius: 0,
              hoverBackgroundColor: "#F6D55C",
              backgroundColor: (ctx) => {
                const value = ctx.dataset.data[ctx.dataIndex].v;
                let alpha = Math.round((value / max) * 255).toString(16);

                if (alpha.length === 1) alpha = `0${alpha}`;

                return value > 0 ? `${this.color}${alpha}` : `${this.color}00`;
              },
              width: ({ chart }) =>
                (chart.chartArea || {}).width / chart.scales.x.ticks.length,
              height: ({ chart }) =>
                // eslint-disable-next-line prettier/prettier
                (chart.chartArea || {}).height / chart.scales.y.ticks.length,
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: "category",
              position: "top",
              offset: true,
              ticks: { font: { size: 10 } },
              grid: { display: false },
              afterTickToLabelConversion: (ctx) =>
                ctx.ticks.forEach((_, i) => {
                  if (i % 2 === 1) ctx.ticks[i].label = "";
                }),
            },
            y: {
              type: "category",
              labels: this.timeSlots,
              offset: true,
              reverse: false,
              position: "left",
              ticks: { display: true },
              grid: { display: false },
            },
          },
          plugins: {
            legend: false,
            zoom: false,
            crosshair: false,
            tooltip: {
              displayColors: false,
              callbacks: {
                label: (ctx) => {
                  const dataItem = ctx.dataset.data[ctx.dataIndex];

                  return `${dataItem.y}: ${dataItem.v} ${this.$t("events")}`;
                },
              },
            },
          },
        },
      });
    },
  },
};
</script>
