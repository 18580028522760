<template>
  <div class="pa-4 fill-height grid">
    <h1>
      <v-row align="center">
        <v-col>
          {{ $t("menuUsers") }}
        </v-col>
        <v-col align="end">
          <template v-if="!currentUser.isOperator">
            <v-btn v-on:click="newUserDialog = true" color="primary">
              {{ $t("btnNewUser") }}
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </h1>

    <div class="table">
      <EntitiesTable
        v-bind:headers="headers"
        v-bind:sorting-fields="sortingFields"
        v-bind:items="usersList"
        v-bind:defaultSort="sortingFields[0].value"
        v-bind:loading="loading"
        v-bind:actionsColumn="false"
        v-bind:actions="{ edit: false, enableDisable: true, delete: true }"
        v-bind:bulkColumn="true"
        v-on:table-bulk-enable="manageUserBulk($event, 0)"
        v-on:table-bulk-disable="manageUserBulk($event, 1)"
        v-on:table-bulk-delete="manageUserBulk($event, 2)"
        v-on:table-click="manageUser($event, 0)"
        v-on:table-edit="/* Do nothing */"
        v-on:table-disable="/* Do nothing */"
        v-on:table-enable="/* Do nothing */"
        v-on:table-delete="/* Do nothing */"
      />
    </div>

    <v-dialog
      v-model="newUserDialog"
      v-if="newUserDialog"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("btnNewUser") }}</v-card-title>
        <v-card-subtitle v-if="!password" class="pb-0">
          <v-alert class="mt-2 mb-0" color="indigo" text outlined>
            {{ $t("autoPassword") }}
          </v-alert>
        </v-card-subtitle>
        <v-card-text>
          <v-form class="mt-2 d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="newUser.name"
              v-bind:label="$t('userName')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
            <v-text-field
              v-model="newUser.surname"
              v-bind:label="$t('userSurname')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
          </v-form>
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="newUser.email"
              v-bind:label="$t('userEmail')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
            <v-select
              v-model="newUser.role"
              v-bind:items="roles"
              v-bind:label="$t('userRole')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              class="mx-2 flex-grow-0"
              v-bind:outlined="outlinedPref"
            />
          </v-form>
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="password"
              v-bind:append-icon="
                showPwd ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              v-bind:label="$t('userPassword')"
              v-bind:rules="[
                (v) => !v || pwdRegExp.test(v) || $t('regExpPassword'),
              ]"
              v-bind:type="showPwd ? 'text' : 'password'"
              v-on:click:append="showPwd = !showPwd"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
          </v-form>
          <v-form
            v-if="newUser.role > roleCodes.ADMIN"
            class="d-flex flex-grow-1 flex-row flex-wrap"
          >
            <v-select
              v-model="newUser.groupIds"
              v-bind:items="groupsList"
              v-bind:label="$t('userGroups')"
              v-bind:outlined="outlinedPref"
              item-text="name"
              item-value="id"
              class="ma-2"
              hide-details
              multiple
            />
          </v-form>
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-select
              v-model="newUser.assetIds"
              v-bind:items="assetsListTextFiltered"
              v-bind:label="$t('customerAssets')"
              v-bind:outlined="outlinedPref"
              item-value="id"
              class="ma-2"
              hide-details
              clearable
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field
                      v-model="aIdFilter"
                      v-bind:label="$t('searchPlaceholder')"
                      prepend-inner-icon="mdi-magnify"
                      clearable
                    />
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item, index }">
                <ItemSelection
                  v-bind:index="index"
                  v-bind:item="item"
                  v-bind:array-length="newUser.assetIds.length"
                  v-bind:shown-items="1"
                  mode="asset"
                />
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  #default="{ active }"
                  class="my-0"
                >
                  <AssetItem
                    v-bind:active="active"
                    v-bind:item="item"
                    mode="asset"
                  />
                </v-list-item>
              </template>
            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-switch
            v-model="newUser.enabled"
            v-bind:label="
              newUser.enabled ? $t('userEnabled') : $t('userDisabled')
            "
            class="ms-4"
            inset
          />
          <v-spacer />
          <v-btn v-on:click="resetUser(false)" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validUser(false)"
            v-on:click="postUser(false)"
            color="primary"
            text
          >
            {{ $t("btnCreate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editUserDialog"
      v-if="editUserDialog"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("userUpdate") }}</v-card-title>
        <v-card-text>
          <v-form class="mt-2 d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="newUser.name"
              v-bind:label="$t('userName')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
            <v-text-field
              v-model="newUser.surname"
              v-bind:label="$t('userSurname')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
          </v-form>
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="newUser.email"
              v-bind:label="$t('userEmail')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
          </v-form>
          <v-form
            v-if="!currentUser.isSuperAdmin || newUser.companyId == undefined"
            class="d-flex flex-grow-1 flex-row flex-wrap"
          >
            <v-select
              v-model="newUser.groupIds"
              v-bind:items="groupsList"
              v-bind:label="$t('userGroups')"
              v-bind:outlined="outlinedPref"
              item-text="name"
              item-value="id"
              class="ma-2"
              hide-details
              multiple
            />
          </v-form>
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-select
              v-model="newUser.assetIds"
              v-bind:items="assetsListTextFiltered"
              v-bind:label="$t('customerAssets')"
              v-bind:outlined="outlinedPref"
              item-value="id"
              class="ma-2"
              hide-details
              clearable
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-text-field
                      v-model="aIdFilter"
                      v-bind:label="$t('searchPlaceholder')"
                      prepend-inner-icon="mdi-magnify"
                      clearable
                    />
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item, index }">
                <ItemSelection
                  v-bind:index="index"
                  v-bind:item="item"
                  v-bind:array-length="newUser.assetIds.length"
                  v-bind:shown-items="1"
                  mode="asset"
                />
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  #default="{ active }"
                  class="my-0"
                >
                  <AssetItem
                    v-bind:active="active"
                    v-bind:item="item"
                    mode="asset"
                  />
                </v-list-item>
              </template>
            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-switch
            v-model="newUser.enabled"
            v-bind:label="
              newUser.enabled ? $t('userEnabled') : $t('userDisabled')
            "
            class="ms-4"
            inset
          />
          <v-spacer />
          <v-btn v-on:click="manageUser(newUser, 1)" color="error" text>
            {{ $t("btnDelete") }}
          </v-btn>
          <v-btn v-on:click="resetUser(true)" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validUser(true)"
            v-on:click="postUser(true)"
            color="primary"
            text
          >
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      v-if="confirmDelete"
      v-bind:callback="confirmCallback"
      v-bind:content="$t('confirmUserDelete')"
      v-bind:title="$t('dialogUserDeleteConfirm')"
      v-on:cancel="confirmDelete = false"
    />

    <v-dialog v-model="updating" width="unset" persistent>
      <v-card>
        <v-progress-circular class="ma-4" color="primary" indeterminate />
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-rows: min-content 1fr;
  max-height: 100%;
  overflow: hidden;
}

.table {
  position: relative;
  overflow: hidden;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import Messages from "../utils/messages";
import UserInfo from "../models/UserInfo";
import ConfirmationDialog from "../components/ConfirmationDialog";
import EntitiesTable from "../components/EntitiesTable";
import ItemSelection from "../components/ItemSelection";
import AssetItem from "../components/AssetItem";

export default {
  name: "Users",

  components: {
    ConfirmationDialog,
    EntitiesTable,
    ItemSelection,
    AssetItem,
  },

  data: () => ({
    showPwd: false,
    newUserDialog: false,
    newUser: new UserInfo(),
    editUserDialog: false,
    confirmDelete: false,
    confirmCallback: () => {},
    password: undefined,
    loading: false,
    updating: false,
    roles: [],
    roleCodes: UserInfo.roles,
    aIdFilter: undefined,
  }),

  computed: {
    validUser() {
      return (editing) => {
        const hasGroups =
          this.newUser.role !== UserInfo.roles.MANAGER ||
          (this.newUser.groupIds != undefined &&
            this.newUser.groupIds.length > 0);

        if (editing) {
          return (
            !!this.newUser.name &&
            !!this.newUser.surname &&
            !!this.newUser.email &&
            hasGroups
          );
        }

        return (
          !!this.newUser.name &&
          !!this.newUser.surname &&
          !!this.newUser.email &&
          !!this.newUser.role &&
          (!this.password || this.pwdRegExp.test(this.password)) &&
          hasGroups
        );
      };
    },
    assetsListTextFiltered() {
      return this.assetsList.filter((asset) =>
        this.aIdFilter ? asset.matchSearchTerm(this.aIdFilter) : true
      );
    },
    sortingFields() {
      const fields = [
        { text: this.$t("userRole"), value: "role" },
        { text: this.$t("userName"), value: "name" },
        { text: this.$t("userSurname"), value: "surname" },
        { text: this.$t("userModified"), value: "modified" },
        { text: this.$t("userStatus"), value: "enabled" },
      ];

      if (this.currentUser.isSuperAdmin) {
        fields.splice(3, 0, {
          text: this.$t("companyName"),
          value: "companyName",
        });
      }

      return fields;
    },
    headers() {
      const fields = [
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("userStatus"), align: "start", sortable: false, value: "enabled", width: "10%" },
        // eslint-disable-next-line prettier/prettier
        { text: `${this.$t("userName")} - ${this.$t("userEmail")}`, align: "start", sortable: false, value: "name", width: "30%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("userRole"), align: "start", sortable: false, value: "role" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("userAssets"), align: "end", sortable: false, value: "assetIds", width: "7%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("userModified"), align: "end", sortable: false, value: "modified", width: "10%" },
      ];

      if (this.currentUser.isSuperAdmin) {
        fields.splice(3, 0, {
          text: `${this.$t("companyName")} - ${this.$t("groups")}`,
          align: "end",
          sortable: false,
          value: "companyName",
        });
      } else {
        fields.splice(3, 0, {
          text: this.$t("groups"),
          align: "end",
          sortable: false,
          value: "groupNames",
        });
      }

      return fields;
    },
  },

  async beforeMount() {
    this.updateFiltersRules(this.clientId);
    this.$store.commit("setCurrentPage", Pages.USERS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);

    this.roles = UserInfo.roleNames
      .map((rn, i) => ({
        text: this.$t(rn),
        value: i,
      }))
      .filter((r) => r.value > 0);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuUsers"),
      to: "/users",
      root: true,
    });
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    postUser(editing = false) {
      if (!editing) {
        const userBodyWithPassword = this.newUser.getUserCreateBody();

        userBodyWithPassword.password = this.password;

        ApiRequests.createUser(
          userBodyWithPassword,
          () => {
            this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("userCreateSuccess"));
            this.resetUser(false);
            this.fetchAllData();
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("userCreateFailure"),
            });
          }
        );
      } else {
        ApiRequests.updateUserAdmin(
          this.newUser.id,
          this.newUser.getUserUpdateBody(),
          () => {
            this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("userUpdateSuccess"));
            this.resetUser(true);
            this.fetchAllData();
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("userUpdateFailure"),
            });
          }
        );
      }
    },
    resetUser(editing = false) {
      if (!editing) {
        this.newUserDialog = false;
        this.newUser = new UserInfo();
        this.password = undefined;
      } else {
        this.editUserDialog = false;
        this.newUser = new UserInfo();
      }

      this.aIdFilter = undefined;
    },
    manageUserBulk(selected, mode) {
      const enableDisable = (enabled, user) => {
        return ApiRequests.updateUserAdmin(
          user.id,
          { enabled },
          undefined,
          undefined
        );
      };
      const callback = (res, text1, text2, text3) => {
        const total = selected.length;
        const sent = res.reduce(
          (acc, r) => acc + (r.status === "fulfilled" ? 1 : 0),
          0
        );

        if (total > sent) {
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: text3.replace(/%s%/g, sent).replace(/%t%/g, total),
            description: text2,
          });
        } else {
          this.$bus.$emit(Messages.SUCCESS_MSG, text1);
        }

        this.$bus.$emit(Messages.RESET_TABLE_SELECTED);
        this.fetchAllData();
        this.updating = false;
      };

      switch (mode) {
        case 0:
          this.updating = true;
          Promise.allSettled(
            selected.map((u) => enableDisable(true, u))
          ).then((res) =>
            callback(
              res,
              this.$t("usersUpdateSuccess"),
              this.$t("usersUpdateFailure"),
              "Error: %s% of %t% users updated"
            )
          );
          break;
        case 1:
          this.updating = true;
          Promise.allSettled(
            selected.map((u) => enableDisable(false, u))
          ).then((res) =>
            callback(
              res,
              this.$t("usersUpdateSuccess"),
              this.$t("usersUpdateFailure"),
              "Error: %s% of %t% users updated"
            )
          );
          break;
        default:
          this.confirmDelete = true;
          this.confirmCallback = () => {
            this.updating = true;
            Promise.allSettled(
              selected.map((u) => {
                return ApiRequests.deleteUser(u.id, undefined, undefined);
              })
            ).then((res) => {
              this.confirmCallback = () => {};
              callback(
                res,
                this.$t("usersDeleteSuccess"),
                this.$t("usersDeleteFailure"),
                "Error: %s% of %t% users deleted"
              );
            });
          };
          break;
      }
    },
    manageUser(user, mode) {
      if (
        user.id !== this.currentUser.id &&
        user.role >= this.currentUser.role
      ) {
        switch (mode) {
          case 0:
            Object.assign(this.newUser, user);
            this.editUserDialog = true;
            break;
          default:
            this.confirmDelete = true;
            this.confirmCallback = () =>
              ApiRequests.deleteUser(
                user.id,
                () => {
                  this.confirmCallback = () => {};
                  this.$bus.$emit(
                    Messages.SUCCESS_MSG,
                    this.$t("userDeleteSuccess")
                  );
                  this.resetUser(true);
                  this.fetchAllData();
                },
                (err) => {
                  if (process.env.NODE_ENV === "development")
                    console.error(err);
                  this.confirmCallback = () => {};
                  this.$bus.$emit(Messages.ERROR_MSG, {
                    error: err?.response?.data?.error || err,
                    description: this.$t("userDeleteFailure"),
                  });
                }
              );
            break;
        }
      }
    },
  },
};
</script>
