<template>
  <div>
    <BarChart id="bar-chart" v-bind="chartSettings" />
  </div>
</template>

<style scoped>
#bar-chart {
  max-height: 100%;
  max-width: 100%;
}
</style>

<script>
import { Chart, Tooltip } from "chart.js";
import { BarChart } from "vue-chart-3";
import ChartJsPlugins from "../plugins/chart-plugins";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ref } from "@vue/composition-api";

Tooltip.positioners.middle = (items) => {
  if (items.length !== 1) {
    // For more than 1 item, just show at the nearest
    return Chart.Tooltip?.positioners?.average(items) || { x: 0, y: 0 };
  }

  const el = items[0].element;
  let xPos = 0;
  let yPos = 0;

  if (el != undefined && el.hasValue()) {
    const { base, horizontal, x, y } = el.getProps();

    if (horizontal) {
      xPos = (base + x) / 2;
      yPos = y;
    } else {
      xPos = x;
      yPos = (base + y) / 2;
    }
  }

  return { x: xPos, y: yPos };
};

export default {
  name: "Bars",

  components: { BarChart },

  props: {
    data: { type: Array, required: true },
    colors: { type: Object, required: true },
  },

  data: () => ({
    chartSettings: {},
    chartData: {
      labels: ref([]),
      datasets: ref([]),
    },
  }),

  watch: {
    data: {
      deep: true,
      handler(newData) {
        if (newData.length === 0) return;

        this.emptyRefs();

        const datasetsMap = {};
        let datasetsCount = 0;

        newData.forEach((element) => {
          Object.keys(element).forEach((key) => {
            if (key !== "month") {
              if (datasetsMap[key] == undefined) {
                datasetsMap[key] = datasetsCount++;
              }

              if (this.chartData.datasets.value.length <= [datasetsMap[key]]) {
                this.chartData.datasets.value.push({
                  label: this.$t(key),
                  data: [element[key]],
                  backgroundColor: [this.colors[this.$t(key)]],
                  borderRadius: 4,
                });
              } else {
                // eslint-disable-next-line prettier/prettier
                this.chartData.datasets.value[datasetsMap[key]].data.push(element[key]);
              }
            } else {
              this.chartData.labels.value.push(element[key]);
            }
          });
        });
      },
    },
  },

  beforeMount() {
    this.chartSettings = {
      chartData: {
        labels: this.chartData.labels.value,
        datasets: this.chartData.datasets.value,
      },
      plugins: [ChartDataLabels, ChartJsPlugins.TOTALIZER],
      options: {
        responsive: true,
        scales: {
          x: { stacked: true },
          y: {
            beginAtZero: true,
            stacked: true,
            ticks: { stepSize: 1 },
          },
        },
        plugins: {
          zoom: false,
          crosshair: false,
          legend: { position: "bottom" },
          tooltip: {
            position: "middle",
            callbacks: {
              label: (tooltipItem) =>
                // eslint-disable-next-line prettier/prettier
                ` ${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} ${this.$t("events")}`,
              footer: (tooltipItem) =>
                // eslint-disable-next-line prettier/prettier
                `${this.$t("total")}: ${tooltipItem[0].chart.$totalizer.totals[tooltipItem[0].dataIndex]} ${this.$t("events")}`,
            },
          },
          datalabels: {
            color: "#000000",
            font: { weight: "bold" },
            clip: true,
            clamp: true,
            anchor: "end",
            align: "end",
            display: (ctx) => ctx.datasetIndex === ctx.chart.$totalizer.utmost,
            formatter: (_value, ctx) =>
              ctx.chart.$totalizer.totals[ctx.dataIndex],
          },
        },
      },
    };
  },

  methods: {
    emptyRefs() {
      this.chartData.labels.value.length = 0;
      this.chartData.datasets.value.length = 0;
    },
  },
};
</script>
