import EnrichedBaseEntity from "./EnrichedBaseEntity";

/**
 * @typedef {Object} AssetUpdateBody
 * @property {String} plate - Asset's plate.
 * @property {String} companyId - Company of the asset.
 * @property {String} groupId - Group of the asset.
 * @property {String} boxMacAddress - The address of the box installed in the asset.
 */

/**
 * @typedef {Object} AssetCreateBody
 * @property {String} serial - Asset's Serial number code.
 * @property {String} plate - Asset's plate.
 * @property {String} groupId - Group of the asset.
 * @property {String} boxMacAddress - The address of the box installed in the asset.
 */

class AssetInfo extends EnrichedBaseEntity {
  static INFLUX_TAG = "Influx#";
  static forbiddenAttributes = [
    "assetBrand",
    "assetModel",
    "assetImage",
    "assetIcon",
    "latitude",
    "longitude",
    "isForecast",
    "mailingList_Trace",
    "mailingList_Low",
    "mailingList_Medium",
    "mailingList_High",
  ];
  static defaultAttributes = [
    "assetType",
    "assetSubType",
    "assetBrand",
    "assetModel",
    "assetVersion",
  ];
  static mailing = [
    "mailingList_Trace",
    "mailingList_Low",
    "mailingList_Medium",
    "mailingList_High",
  ];

  _serial = undefined;
  _plate = undefined;
  _companyId = "";
  _groupId = "";
  _boxMacAddress = undefined;
  _properties = undefined;
  _custom = {};
  _userInterface = {
    header: [],
    maintenance: {
      enabled: false,
      next: undefined,
      topics: [],
    },
    measures: {
      highlights: [],
      highlightsNames: [],
      realtimes: [],
    },
  };
  _customComponent = {
    enabled: false,
    interval: 1000,
    values: {},
    template: "",
  };

  companyName = undefined;
  groupName = undefined;

  original = undefined;

  constructor(assetJson = undefined) {
    super(assetJson);

    if (assetJson != undefined) {
      this._serial = assetJson.serial;
      this._plate = assetJson.plate;
      this._companyId = assetJson.companyId || "";
      this._groupId = assetJson.groupId || "";
      this._boxMacAddress = assetJson.boxMacAddress;
      this._properties = assetJson.properties || {};
      this._custom = assetJson.custom || {};

      this.original = JSON.stringify(assetJson);

      this._parseUI(this._properties);
      this._parseComponent(this._properties);
      this._populateCustom();
    }
  }

  // Getters

  get serial() {
    return this._serial;
  }
  get plate() {
    return this._plate;
  }
  get companyId() {
    return this._companyId.length > 0 ? this._companyId : undefined;
  }
  get groupId() {
    return this._groupId.length > 0 ? this._groupId : undefined;
  }
  get boxMacAddress() {
    return this._boxMacAddress;
  }
  get custom() {
    return this._custom;
  }
  // Properties getters
  get type() {
    return this._custom.assetType;
  }
  get subType() {
    return this._custom.assetSubType;
  }
  get brand() {
    return this._custom.assetBrand;
  }
  get model() {
    return this._custom.assetModel;
  }
  get version() {
    return this._custom.assetVersion;
  }
  get modelVersion() {
    return `${this.model} - ${this.version}`;
  }
  get image() {
    return this._custom.assetImage;
  }
  get icon() {
    return this._custom.assetIcon;
  }
  get entityCode() {
    return this._properties?.ID;
  }
  get userInterface() {
    return this._userInterface;
  }
  get customComponent() {
    return this._customComponent;
  }
  get measuresSummary() {
    return this._userInterface.measures?.highlights?.slice(0, 3) || [];
  }
  get latitude() {
    return this._custom.latitude;
  }
  get longitude() {
    return this._custom.longitude;
  }
  get isForecast() {
    return this._custom.isForecast == "true";
  }

  // Setters

  set serial(serial) {
    this._serial = serial;
  }
  set plate(plate) {
    this._plate = plate;
  }
  set companyId(companyId) {
    this._companyId = companyId || "";
  }
  set groupId(groupId) {
    this._groupId = groupId || "";
  }
  set boxMacAddress(boxMacAddress) {
    this._boxMacAddress = boxMacAddress;
  }
  set latitude(latitude) {
    this._custom.latitude = latitude;
  }
  set longitude(longitude) {
    this._custom.longitude = longitude;
  }

  // Public methods

  /**
   * Returns an object usable to update the asset via the API.
   * @public
   * @returns {AssetUpdateBody} - Patch object.
   */
  getAssetUpdateBody() {
    return {
      plate: this._plate || "",
      companyId: this._companyId || "",
      groupId: this._groupId || "",
      boxMacAddress: this._boxMacAddress || "",
      custom: this._custom || {},
    };
  }

  /**
   * Returns an object usable to create a new asset via the API.
   * @public
   * @returns {AssetCreateBody} - Create object.
   */
  getAssetCreateBody() {
    return {
      serial: this._serial,
      plate: this._plate,
      groupId: this._groupId,
      boxMacAddress: this._boxMacAddress,
    };
  }

  /**
   * Useful for filtering assets.
   * @public
   * @param {String} searchTerm - The word to search in this asset.
   * @returns {Boolean} - Returns `true` if the searched word is contained in this asset, `false` otherwise.
   */
  matchSearchTerm(searchTerm) {
    const s = (searchTerm || "").toLowerCase();

    return (
      (this._serial || "").toLowerCase().includes(s) ||
      (this.brand || "").toLowerCase().includes(s) ||
      (this.model || "").toLowerCase().includes(s) ||
      this.modelVersion.toLowerCase().includes(s) ||
      (this._plate || "").toLowerCase().includes(s) ||
      (this._boxMacAddress || "").toLowerCase().includes(s) ||
      this.readableCreated().toLowerCase().includes(s) ||
      this.readableModified().toLowerCase().includes(s) ||
      (this.companyName || "").toLowerCase().includes(s) ||
      (this.groupName || "").toLowerCase().includes(s) ||
      Object.keys(this._custom).reduce((result, key) => {
        return !AssetInfo.forbiddenAttributes.includes(key)
          ? result || (this._custom[key] || "").toLowerCase().includes(s)
          : result;
      }, false)
    );
  }

  /**
   * Get highlighted measure short name.
   * @param {String} highlight - Topic of a measure.
   * @returns {String} - Returns the short name of the measure.
   */
  highlightName(highlight) {
    const nameIndex =
      this._userInterface.measures.highlights?.findIndex(
        (h) => h == highlight
      ) ?? -1;

    if (nameIndex < 0) return "";

    const parts = highlight.split("/");

    return (
      this._userInterface.measures.highlightsNames?.[nameIndex] ||
      parts[parts.length - 1]
    );
  }

  // Utilities

  allCustoms(forbidden = []) {
    return Object.keys(this._custom)
      .filter((k) => this._custom[k] != undefined)
      .filter((k) => !forbidden.includes(k));
  }

  listableCustoms() {
    return Object.keys(this._custom)
      .filter((k) => this._custom[k] != undefined)
      .filter((k) => !AssetInfo.forbiddenAttributes.includes(k));
  }

  influxTags(removePrefix = false) {
    return this.listableCustoms()
      .filter((t) => t.includes(AssetInfo.INFLUX_TAG))
      .map((t) => (removePrefix ? t.replace(AssetInfo.INFLUX_TAG, "") : t));
  }

  toJson() {
    return {
      id: this._id,
      created: this._created,
      createdBy: this._createdBy,
      modified: this._modified,
      modifiedBy: this._modifiedBy,
      plate: this._plate,
      serial: this._serial,
      brand: this.brand,
      model: this.model,
      version: this.version,
      entityCode: this._properties?.ID,
      properties: this._properties || {},
      custom: this._custom || {},
    };
  }

  toJsonString() {
    return JSON.stringify(this.toJson());
  }

  static fromJsonString(str) {
    const parsed = JSON.parse(str);

    return new AssetInfo({
      id: parsed.id,
      created: parsed.created,
      createdBy: parsed.createdBy,
      modified: parsed.modified,
      modifiedBy: parsed.modifiedBy,
      plate: parsed.plate,
      serial: parsed.serial,
      custom: parsed.custom,
      properties: {
        ...parsed.properties,
        ID: parsed.entityCode,
      },
    });
  }

  // Private properties

  _parseComponent(properties) {
    this._customComponent.enabled =
      properties.dashboardConfig_component_template != undefined;

    this._customComponent.interval = Number(
      properties.dashboardConfig_component_intervalmillis || 1000
    );

    this._customComponent.values = JSON.parse(
      properties.dashboardConfig_component_values || "{}"
    );

    this._customComponent.template =
      properties.dashboardConfig_component_template;
  }

  _parseUI(properties) {
    const booleanRegExp = new RegExp("^1|y|yes|true|on$");

    if (properties && Object.keys(properties).length > 0) {
      // Get the header

      const hTopics = properties.dashboardConfig_header_topics
        ?.split(",")
        ?.map((t) => t.trim());
      const hIcons = properties.dashboardConfig_header_icons
        ?.split(",")
        ?.map((t) => t.trim());

      hTopics?.forEach((t, i) => {
        this._userInterface.header.push({
          topic: t,
          icon: hIcons ? hIcons[i] : undefined,
        });
      });

      // Get the maintenance

      this._userInterface.maintenance.enabled = booleanRegExp.test(
        properties.dashboardConfig_maintenance_enabled
      );

      // eslint-disable-next-line prettier/prettier
      this._userInterface.maintenance.next = properties.dashboardConfig_maintenance_nextTopic;

      const mTopics = properties.dashboardConfig_maintenance_topics
        ?.split(",")
        ?.map((t) => t.trim());
      const mTargets = properties.dashboardConfig_maintenance_targets
        ?.split(",")
        ?.map((n) => Number(n.trim()));
      const mDescending = properties.dashboardConfig_maintenance_descending
        ?.split(",")
        ?.map((b) => booleanRegExp.test(b.trim()));

      mTopics?.forEach((t, i) => {
        this._userInterface.maintenance.topics.push({
          topic: t,
          descending: mDescending ? mDescending[i] : false,
          target: mTargets ? mTargets[i] : undefined,
        });
      });

      // Get highlights

      this._userInterface.measures.highlights = properties.dashboardConfig_measures_highlights
        ?.split(",")
        ?.map((t) => t.trim());
      this._userInterface.measures.highlightsNames = properties.dashboardConfig_measures_highlights_names
        ?.split(",")
        ?.map((n) => n.trim());
      this._userInterface.measures.realtimes = properties.dashboardConfig_measures_realtimes
        ?.split(",")
        ?.map((t) => t.trim());
    }
  }

  _populateCustom() {
    // eslint-disable-next-line prettier/prettier
    this._custom.assetImage = this._custom.assetImage || this._properties?.image;
    this._custom.assetIcon = this._custom.assetIcon || this._properties?.icon;
    this._custom.assetType = this._custom.assetType || this._properties?.type;
    // eslint-disable-next-line prettier/prettier
    this._custom.assetSubType = this._custom.assetSubType || this._properties?.subType;
    // eslint-disable-next-line prettier/prettier
    this._custom.assetBrand = this._custom.assetBrand || this._properties?.brand;
    // eslint-disable-next-line prettier/prettier
    this._custom.assetModel = this._custom.assetModel || this._properties?.model;
    // eslint-disable-next-line prettier/prettier
    this._custom.assetVersion = this._custom.assetVersion || this._properties?.version;

    Object.keys(this._properties ?? {})
      .filter((prop) => prop.includes(AssetInfo.INFLUX_TAG))
      .forEach((prop) => {
        this._custom[prop] = this._custom[prop] || this._properties?.[prop];
      });
  }
}

export default AssetInfo;
