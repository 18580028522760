import EnrichedBaseEntity from "./EnrichedBaseEntity";

/**
 * @typedef {Object} UserUpdateBody
 * @property {String} groupIds - Group IDs.
 * @property {String} name - User's first name.
 * @property {String} surname - User's last name.
 * @property {String} email - User's email.
 * @property {Boolean} enabled - True if the user is enabled.
 */

/**
 * @typedef {Object} UserCreateBody
 * @property {String} name - User's first name.
 * @property {String} surname - User's last name.
 * @property {String} role - User's role.
 * @property {String} email - User's email.
 * @property {String[]} groupIds - Group IDs.
 * @property {Boolean} enabled - True if the user is enabled.
 * @property {String} password - User's new password.
 */

class UserInfo extends EnrichedBaseEntity {
  _companyId = undefined;
  _groupIds = [];
  _assetIds = [];
  _name = undefined;
  _surname = undefined;
  _role = undefined;
  _email = undefined;
  _enabled = true;

  companyName = undefined;
  groupNames = [];

  static roles = {
    SUPER_ADMIN: 0,
    ADMIN: 1,
    MANAGER: 2,
    OPERATOR: 3,
  };
  static rolesMap = ["ADMIN", "ADMIN", "ADMIN", "OPERATOR"];
  static roleNames = ["superAdmin", "admin", "groupManager", "operator"];

  constructor(userJson = undefined) {
    super(userJson);

    if (userJson != undefined) {
      this._companyId = userJson.companyId;
      this._groupIds = userJson.groupIds;
      this._assetIds = userJson.assetIds;
      this._name = userJson.name;
      this._surname = userJson.surname;
      this._email = userJson.email;
      this._enabled = userJson.enabled;
      this._role = this._parseRole(
        userJson.role,
        this._companyId,
        this._groupIds
      );
    }
  }

  // Getters

  get companyId() {
    return this._companyId;
  }
  get groupIds() {
    return this._groupIds || [];
  }
  get assetIds() {
    return this._assetIds || [];
  }
  get name() {
    return this._name;
  }
  get surname() {
    return this._surname;
  }
  get role() {
    return this._role;
  }
  get email() {
    return this._email;
  }
  get enabled() {
    return this._enabled;
  }
  get isSuperAdmin() {
    return this._role === UserInfo.roles.SUPER_ADMIN;
  }
  get isAdminNotManager() {
    return (
      this._role === UserInfo.roles.ADMIN ||
      this._role === UserInfo.roles.SUPER_ADMIN
    );
  }
  get isAdmin() {
    return (
      this._role === UserInfo.roles.ADMIN ||
      this._role === UserInfo.roles.MANAGER
    );
  }
  get isOperator() {
    return this._role === UserInfo.roles.OPERATOR;
  }
  get roleName() {
    return UserInfo.roleNames[this._role];
  }
  get nameSurname() {
    return this.fullName();
  }
  get surnameName() {
    return this.fullName(true);
  }

  // Setters

  set groupIds(groupIds) {
    this._groupIds = groupIds;
  }
  set assetIds(assetIds) {
    this._assetIds = assetIds;
  }
  set name(name) {
    this._name = name;
  }
  set surname(surname) {
    this._surname = surname;
  }
  set email(email) {
    this._email = email;
  }
  set role(role) {
    this._role = role;
  }
  set enabled(enabled) {
    this._enabled = enabled;
  }

  // Public methods

  /**
   * Returns an object usable to update the user via the API.
   * @public
   * @returns {UserUpdateBody} - Patch object.
   */
  getUserUpdateBody() {
    return {
      groupIds: [...this._groupIds],
      assetIds: Array.from(new Set(this._assetIds)),
      name: this._name,
      surname: this._surname,
      email: this._email,
      enabled: this._enabled,
    };
  }

  /**
   * Returns an object usable to create a new user via the API.
   * @public
   * @returns {UserCreateBody} - Creation object.
   */
  getUserCreateBody() {
    return {
      name: this._name,
      surname: this._surname,
      role: UserInfo.rolesMap[this._role],
      email: this._email,
      groupIds: [...this._groupIds],
      assetIds: Array.from(new Set(this._assetIds)),
      enabled: this._enabled,
      password: undefined,
    };
  }

  /**
   * Format the name of this user.
   * @public
   * @param {Boolean} surnameFirst - True for haserialg the last name at the first place.
   * @returns {String} - Formatted name.
   */
  fullName(surnameFirst = false) {
    if (surnameFirst) {
      return `${this._surname} ${this.name}`;
    }

    return `${this.name} ${this._surname}`;
  }

  /**
   * Useful for filtering users.
   * @public
   * @param {String} searchTerm - The word to search in this user.
   * @returns {Boolean} - Returns `true` if the searched word is contained in this user, `false` otherwise.
   */
  matchSearchTerm(searchTerm) {
    const s = (searchTerm || "").toLowerCase();

    return (
      (this._name || "").toLowerCase().includes(s) ||
      (this._surname.toString() || "").toLowerCase().includes(s) ||
      (this._email || "").toLowerCase().includes(s) ||
      this.fullName().toLowerCase().includes(s) ||
      this.fullName(true).toLowerCase().includes(s) ||
      this.readableCreated().toLowerCase().includes(s) ||
      this.readableModified().toLowerCase().includes(s) ||
      UserInfo.rolesMap[this._role].toLowerCase().includes(s) ||
      (this.companyName || "").toLowerCase().includes(s)
    );
  }

  // Private methods

  /**
   * Parse the role given by the API.
   * @private
   */
  _parseRole(unparsed = undefined, company = undefined, groupIds = undefined) {
    if (unparsed == undefined) {
      return -1;
    }

    switch (unparsed) {
      case "ADMIN":
        if (groupIds != undefined && groupIds.length > 0) {
          return UserInfo.roles.MANAGER;
        }

        if (company == undefined) {
          return UserInfo.roles.SUPER_ADMIN;
        }

        return UserInfo.roles.ADMIN;
      case "OPERATOR":
        return UserInfo.roles.OPERATOR;
      default:
        return -1;
    }
  }
}

export default UserInfo;
