<template>
  <div class="pa-4 fill-height alarms-grid">
    <h1 class="d-flex justify-space-between align-center">
      <div class="d-flex flex-column align-start">
        {{ $t("menuAlarms") }}
        <span
          v-if="searchSerial != undefined"
          class="caption d-flex align-center"
        >
          <v-btn
            v-on:click="searchSerial = undefined"
            class="me-2"
            color="error"
            icon
            x-small
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          {{ $t("assetSerial") }}:
          <span class="font-weight-bold ms-2">{{ searchSerial }}</span>
        </span>
      </div>
      <div class="body-1 d-flex align-center" style="gap: 0.5em">
        <v-checkbox
          v-model="showClosed"
          v-bind:label="$t('showClosedEvents')"
          v-bind:disabled="loading"
          class="py-0 my-0 me-3"
          color="primary"
          hide-details
        />

        <v-checkbox
          v-model="showTrace"
          v-bind:label="$t('showTraceMessages')"
          v-bind:disabled="loading"
          class="py-0 my-0"
          color="primary"
          hide-details
        />

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-bind:disabled="loading"
              v-on="on"
              class="mx-8"
              color="primary"
            >
              {{ $t("severityFilters") }}
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item
              v-for="severity in Object.keys(severities)"
              v-bind:key="severity"
              dense
            >
              <v-list-item-content>
                <v-checkbox
                  v-model="severityFilters"
                  v-bind:disabled="severity === 'TRACE' && !showTrace"
                  v-bind:label="$t(severities[severity])"
                  v-bind:value="$t(severities[severity])"
                  v-bind:ripple="false"
                  v-on:click.stop
                  class="py-0 my-1"
                  hide-details
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          v-on:click="moveRange(-1)"
          v-bind:disabled="loading"
          color="primary"
          icon
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div
          v-on:click="openDatePicker"
          v-bind:class="{
            clickable: !loading,
            'primary--text': !loading,
            'grey--text': loading,
          }"
        >
          {{ startingDate.format($t("tzLocale")) }}
        </div>
        <v-btn
          v-on:click="moveRange(1)"
          v-bind:disabled="isToday || loading"
          color="primary"
          icon
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </h1>
    <div class="scrollable-table">
      <EntitiesTable
        v-bind:headers="headers"
        v-bind:sorting-fields="sortingFields"
        v-bind:items="events"
        v-bind:syncedItems.sync="exportEvents"
        v-bind:defaultSort="sortingFields[0].value"
        v-bind:loading="loading"
        v-bind:actionsColumn="false"
        v-bind:actions="{
          edit: false,
          enableDisable: false,
          delete: false,
        }"
        v-bind:bulkColumn="false"
        v-bind:refreshTrigger="mainListKey"
        v-bind:tableItems.sync="tableItems"
        v-on:table-click="handleClick"
        v-on:table-search="tableFilter = $event"
        v-on:table-bulk-customer="/* Do nothing */"
        v-on:table-bulk-group="/* Do nothing */"
        v-on:table-bulk-delete="/* Do nothing */"
        v-on:table-edit="/* Do nothing */"
        v-on:table-delete="/* Do nothing */"
        v-on:table-bulk-enable="/* Do nothing */"
        v-on:table-bulk-disable="/* Do nothing */"
        v-on:table-disable="/* Do nothing */"
        v-on:table-enable="/* Do nothing */"
        ref="alarmsTable"
      >
        <template v-slot:misc-actions>
          <v-menu v-if="events.length > 0 && tableItems > 0" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-bind:disabled="loading"
                v-on="on"
                color="primary"
                small
                text
              >
                {{ $t("downloadReport") }}
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-on:click="downloadPdf">
                <v-list-item-content>
                  <v-list-item-title>{{ $t("downloadPdf") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <JsonCSV
                v-bind:name="fileName()"
                v-bind:data="parsedExportEvents"
                delimiter=";"
              >
                <v-list-item link>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("downloadCsvReport") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </JsonCSV>
            </v-list>
          </v-menu>
        </template>
      </EntitiesTable>
    </div>

    <v-dialog v-model="datePicker" width="unset" persistent>
      <v-card v-if="datePicker">
        <v-card-text class="px-0">
          <v-date-picker
            v-model="selectedDate"
            v-bind:locale="$store.getters.string('VUE_APP_I18N_LOCALE')"
            v-bind:max="today"
            color="primary"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="datePicker = false" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn v-on:click="chooseDate" color="primary" text>
            {{ $t("btnOk") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="alarmDetails" width="unset" persistent scrollable>
      <v-card v-if="alarmDetails" style="min-width: 65ch">
        <v-card-title>
          <v-icon
            v-bind:color="clickedAlarm.graphics.color"
            v-bind:title="$t(clickedAlarm.severity)"
            class="me-2"
          >
            {{ clickedAlarm.graphics.icon }}
          </v-icon>
          {{ clickedAlarm.group }}/{{ clickedAlarm.code }}
          <v-spacer />
          <v-btn v-on:click="closeEvent" color="error" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          <span class="mx-1">
            <v-icon
              v-if="clickedAlarm.stateType === eventTypes.SINGLE"
              v-bind:title="clickedAlarm.contentType"
              color="primary"
              small
            >
              mdi-numeric-1-circle-outline
            </v-icon>
            <v-icon
              v-if="clickedAlarm.stateType === eventTypes.UP"
              v-bind:title="clickedAlarm.contentType"
              color="error"
              small
            >
              mdi-arrow-up-circle-outline
            </v-icon>
            <v-icon
              v-if="clickedAlarm.stateType === eventTypes.DOWN"
              v-bind:title="clickedAlarm.contentType"
              color="success"
              small
            >
              mdi-arrow-down-circle-outline
            </v-icon>
          </span>
          {{ $t("eventTimeStamp") }}: {{ clickedAlarm.readableTs() }}
        </v-card-subtitle>

        <v-card-text style="">
          <div class="caption">
            <span class="font-weight-bold">{{ $t("eventMessage") }}:</span>
            {{ clickedAlarm.formattedMessage() }}
          </div>

          <v-list>
            <v-list-item link>
              <v-list-item-content>
                <div class="d-flex justify-space-between align-center">
                  <span class="body-2">
                    {{ $t("eventDuration") }}
                  </span>
                  <span class="body-1 font-weight-bold">
                    {{ clickedAlarm.duration() }}
                  </span>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="tag in getEventTags(clickedAlarm)"
              v-bind:key="clickedAlarm.id + '-' + tag"
              link
            >
              <v-list-item-content>
                <div class="d-flex justify-space-between align-center">
                  <span class="body-2">
                    {{ tag }}
                  </span>
                  <span class="body-1 font-weight-bold">
                    {{
                      getEventProperty(
                        tag,
                        clickedAlarm.attributes,
                        "--",
                        true,
                        clickedAlarm.isFromSignalR
                      )
                    }}
                  </span>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-card elevation="0" outlined>
            <v-card-text>
              <div class="font-weight-bold">{{ $t("menuDetails") }}</div>
              <v-list>
                <v-list-item dense link>
                  <v-list-item-content class="grey--text text--darken-2">
                    <div class="d-flex justify-space-between align-center">
                      <span class="caption">
                        {{ $t("assetSerial") }}
                      </span>
                      <span class="body-2 font-weight-bold">
                        {{
                          getEventProperty(
                            "serial",
                            clickedAlarm.attributes,
                            undefined,
                            false,
                            clickedAlarm.isFromSignalR
                          ) ||
                          getEventProperty(
                            "serialNumber",
                            clickedAlarm.attributes,
                            "--",
                            false,
                            clickedAlarm.isFromSignalR
                          )
                        }}
                      </span>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense link>
                  <v-list-item-content class="grey--text text--darken-2">
                    <div class="d-flex justify-space-between align-center">
                      <span class="caption">
                        {{ $t("assetPlate") }}
                      </span>
                      <span class="body-2 font-weight-bold">
                        {{
                          getEventProperty(
                            "plate",
                            clickedAlarm.attributes,
                            "--",
                            false,
                            clickedAlarm.isFromSignalR
                          )
                        }}
                      </span>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense link>
                  <v-list-item-content class="grey--text text--darken-2">
                    <div class="d-flex justify-space-between align-center">
                      <span class="caption">
                        {{ $t("assetMac") }}
                      </span>
                      <span class="body-2 font-weight-bold">
                        {{
                          getEventProperty(
                            "boxMacAddress",
                            clickedAlarm.attributes,
                            "--",
                            false,
                            clickedAlarm.isFromSignalR
                          )
                        }}
                      </span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <div v-if="!clickedAlarmSnap" class="d-flex justify-center">
                <v-progress-circular color="primary" indeterminate />
              </div>
              <v-expansion-panels v-else flat>
                <v-expansion-panel>
                  <v-expansion-panel-header class="mx-0 px-0">
                    <div class="grey--text text--darken-2 font-weight-bold">
                      {{ $t("measuresSnapshot") }}
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item
                        v-for="mSnap in clickedAlarmSnap"
                        v-bind:key="clickedAlarm.id + '-' + mSnap.name"
                        dense
                        link
                      >
                        <v-list-item-content class="grey--text text--darken-2">
                          <div
                            class="d-flex justify-space-between align-center"
                          >
                            <span class="caption">
                              {{ $t(mSnap.name) }}
                            </span>
                            <span class="body-2 font-weight-bold">
                              {{ mSnap.value }}
                            </span>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="openEventAsset" color="primary" text>
            {{ $t("goToAsset") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.alarms-grid {
  display: grid;
  grid-template-rows: min-content 1fr;
  max-height: 100%;
  overflow: hidden;
}

.clickable {
  cursor: pointer;
}

.scrollable-table {
  position: relative;
  overflow: hidden;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import EntitiesTable from "../components/EntitiesTable.vue";
import AssetEventsRequestBody from "../models/AssetEventsRequestBody";
import Event from "../models/Event";
import Messages from "../utils/messages";
import moment from "moment";
import AssetInfo from "../models/AssetInfo";
import JsonCSV from "vue-json-csv";

export default {
  name: "Alarms",

  components: { EntitiesTable, JsonCSV },

  data: () => ({
    loading: false,
    mainListKey: false,
    mainListRefresher: undefined,
    today: moment().startOf("day").format("YYYY-MM-DD"),
    startingDate: moment().startOf("day"),
    endingDate: moment().endOf("day"),
    isToday: true,
    datePicker: false,
    selectedDate: undefined,
    showTrace: false,
    showClosed: false,
    severities: Event.severity,
    eventTypes: Event.types,
    severityFilters: [],
    searchSerial: undefined,
    tableFilter: undefined,
    tableItems: 0,
    clickedAlarm: undefined,
    clickedAlarmSnap: undefined,
    alarmDetails: false,
    exportEvents: [],
  }),

  computed: {
    alarmsCount() {
      return this.$store.getters.alarmsCount;
    },
    events() {
      const evt = this.$store.getters.allEvents ?? [];
      const evtOut = [];

      Object.keys(evt).forEach((k1) => {
        Object.keys(evt[k1]).forEach((k2) => {
          evt[k1][k2].forEach((e) => evtOut.push(e));
        });
      });

      return evtOut
        .filter((e) => this.showClosed || e.stateType === Event.types.UP)
        .filter((e) => e.matchSearchTerm(this.searchSerial))
        .filter((e) =>
          e.matchSearchTerm((this.severityFilters || []).join(", "))
        );
    },
    parsedExportEvents() {
      const availableTags = new Set();
      const data = this.exportEvents.map((item) => {
        item.getTags().forEach((tagName) => availableTags.add(tagName));

        return {
          [this.$t("alarmsTitleSeverity")]: this.$t(item.severity),
          [this.$t("eventStatus")]: item.contentType,
          [this.$t("eventMessage")]: item.attributes.message || "--",
          [this.$t("eventCode")]: item.code,
          [this.$t("eventAsset")]:
            item.attributes.plate || item.attributes.serial,
          [this.$t("eventTimeStamp")]: item.readableTs(false),
          [this.$t("eventDuration")]: item.duration(),
          Timestamp: item.ts,
          event: item,
        };
      });

      for (let i = 0; i < data.length; i++) {
        availableTags.forEach((tagName) => {
          data[i][tagName] = data[i].event.getTagValue(tagName);
        });

        delete data[i].event;
      }

      return data;
    },
    sortingFields() {
      return [
        { text: this.$t("eventTimeStamp"), value: "ts" },
        { text: this.$t("eventStatus"), value: "statusNum" },
        { text: this.$t("alarmsTitleSeverity"), value: "severityNum" },
        { text: this.$t("eventAsset"), value: "originId" },
      ];
    },
    headers() {
      return [
        // eslint-disable-next-line prettier/prettier
        { text: "", align: "center", sortable: false, value: "graphics", width: "4%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("eventStatus"), align: "start", sortable: false, value: "contentType", width: "9%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("eventTags"), align: "center", sortable: false, value: "attributes", width: "11%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("eventMessage"), align: "start", sortable: false, value: "message" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("eventCode"), align: "end", sortable: false, value: "code", width: "10%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("eventAsset"), align: "end", sortable: false, value: "originId", width: "13%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("eventTimeStamp"), align: "end", sortable: false, value: "readableTs", width: "12%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("eventDuration"), align: "end", sortable: false, value: "eventDuration", width: "9%" },
        // eslint-disable-next-line prettier/prettier
        { text: "", align: "end", sortable: false, value: "ts", width: "5%" },
      ];
    },
  },

  beforeMount() {
    this.updateFiltersRules(this.clientId);

    this.$store.commit("setCurrentPage", Pages.ALARMS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
    this.$store.commit("resetAlarmsCount");
    this.$store.commit("offAlarmsCount");
    this.$store.commit("clearEventsList");

    this.mainListRefresher = setInterval(this.reRenderer, 15000);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuAlarms"),
      to: "/alarms",
      root: true,
    });

    setTimeout(() => {
      this.fetchEventsHistory();
      this.$nextTick(() => {
        this.searchSerial = this.$route.query.serial;
        this.severityFilters = [this.$t(this.$route.query.severity)];
      });
    }, 250);
  },

  beforeDestroy() {
    this.$store.commit("onAlarmsCount");
    this.$store.commit("clearEventsList");

    if (this.mainListRefresher !== undefined) {
      clearInterval(this.reRenderer);
      this.mainListRefresher = undefined;
    }
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
    showTrace(newValue) {
      if (!newValue) {
        this.severityFilters = this.severityFilters.filter(
          (f) => f !== this.$t(Event.severity.TRACE)
        );
      }

      this.$store.commit("clearEventsList");
      this.$nextTick(this.fetchEventsHistory);
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    fetchEventsHistory() {
      this.loading = true;

      const eventsRequest = new AssetEventsRequestBody();

      eventsRequest.severities = [
        ...(this.showTrace ? [Event.severity.TRACE] : []),
        Event.severity.LOW,
        Event.severity.MEDIUM,
        Event.severity.HIGH,
      ];
      eventsRequest.starting = this.startingDate.format();
      eventsRequest.ending = this.endingDate.format();

      ApiRequests.getSavedEvents(
        eventsRequest,
        (res) => {
          res.data.forEach((e) => {
            const normalized = {};

            Object.keys(e).forEach((k) => {
              normalized[k.charAt(0).toUpperCase() + k.substring(1)] = e[k];
            });

            normalized.saveTrace = this.showTrace;

            this.$store.dispatch("saveMessage", normalized);
          });

          this.loading = false;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.loading = false;
        }
      );
    },
    moveRange(amount) {
      this.$store.commit("clearEventsList");
      this.startingDate.add(amount, "day");
      this.endingDate.add(amount, "day");

      this.isToday = this.startingDate.isSameOrAfter(moment(), "day");

      this.fetchEventsHistory();
    },
    openDatePicker() {
      if (this.loading) return;

      this.selectedDate = this.startingDate.format("YYYY-MM-DD");
      this.datePicker = true;
    },
    chooseDate() {
      this.$store.commit("clearEventsList");

      // eslint-disable-next-line prettier/prettier
      this.startingDate = moment(this.selectedDate, "YYYY-MM-DD").startOf("day");
      this.endingDate = moment(this.selectedDate, "YYYY-MM-DD").endOf("day");
      this.isToday = this.startingDate.isSameOrAfter(moment(), "day");
      this.datePicker = false;

      this.fetchEventsHistory();
    },
    downloadPdf() {
      this.loading = true;

      const reportData = {
        selectedDate: this.startingDate.format(this.$t("tzLocale")),
        dateFormat: this.$t("tzLocale"),
        selectedSeverities: (this.severityFilters || []).filter((f) => !!f),
        events: this.exportEvents.map((e) => {
          const tmpAttrs = JSON.parse(JSON.stringify(e.attributes));

          tmpAttrs.type = this.$t(tmpAttrs.type);
          tmpAttrs.severity = this.$t(tmpAttrs.severity);
          tmpAttrs.duration = e.duration();

          return tmpAttrs;
        }),
        totalDuration: Event.durationConverter(
          this.exportEvents.reduce(
            (totDuration, e) =>
              totDuration + (e.durationMillis ? e.durationMillis : 0),
            0
          )
        ),
      };

      if (this.searchSerial != undefined) {
        reportData.assetDbId = this.exportEvents[0].attributes.assetId;
      }

      if (this.tableFilter != undefined) {
        reportData.searchTerm = this.tableFilter;
      }

      // Download PDF report from backend
      ApiRequests.getEventsReport(
        reportData,
        (res) => {
          const downloader = document.createElement("a");
          const url = window.URL || window.webkitURL;

          downloader.href = url.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          downloader.download = this.fileName("pdf");

          downloader.click();

          this.loading = false;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);

          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("pdfCreateFailure"),
          });

          this.loading = false;
        }
      );
    },
    fileName(ext = "csv") {
      const selectedDate = this.startingDate.format("YYYY_MM_DD");

      return `${this.$t("eventsReport")}-${selectedDate}.${ext}`;
    },
    handleClick(clickedAlarm) {
      this.clickedAlarm = clickedAlarm;
      this.getDataSnapshot(
        this.clickedAlarm?.attributes?.assetId,
        this.clickedAlarm.ts
      );
      this.alarmDetails = true;
    },
    closeEvent() {
      this.alarmDetails = false;
      this.clickedAlarm = undefined;
      this.clickedAlarmSnap = undefined;
    },
    openEventAsset() {
      if (this.clickedAlarm?.attributes?.assetId !== undefined) {
        this.$router.push({
          path: `/asset-details/${this.clickedAlarm.attributes.assetId}`,
        });
      }
    },
    getEventTags(e) {
      let tags = e.getTags() ?? [];

      if (tags.length === 0 && e.isFromSignalR) {
        tags = this.$store.getters
          .assetById(e.attributes.assetId)
          .influxTags(true);
      }

      return tags;
    },
    getEventProperty(
      prop,
      attributes,
      defaultVal,
      fromInflux = false,
      isRealtime = false
    ) {
      if (isRealtime && !fromInflux) {
        const linkedAsset = this.$store.getters.assetById(attributes.assetId);

        return linkedAsset[prop] || defaultVal;
      }

      return (
        attributes[(fromInflux ? AssetInfo.INFLUX_TAG : "") + prop] ||
        defaultVal
      );
    },
    getDataSnapshot(assetId, timestamp) {
      const date = moment.utc(timestamp).format("YYYY-MM-DD[T]HH:mm:ss[Z]");
      this.clickedAlarmSnap = undefined;

      ApiRequests.getMeasuresSnapshot(
        assetId,
        date,
        (res) => {
          const toBeSorted = Object.entries(res.data).map((entry) => ({
            name: entry[0],
            value: entry[1],
          }));

          toBeSorted.sort((m1, m2) =>
            m1.name > m2.name ? 1 : m2.name > m1.name ? -1 : 0
          );

          this.clickedAlarmSnap = toBeSorted;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.clickedAlarmSnap = [];
        }
      );
    },
    reRenderer() {
      this.mainListKey = !this.mainListKey;
    },
  },
};
</script>
