import BaseEntity from "./BaseEntity";

/**
 * @typedef {Object} CustomerCreateBody
 * @property {String} name - Customer's name.
 * @property {String} adminName - Customer's administrator's first name.
 * @property {String} adminSurname - Customer's administrator's last name.
 * @property {String} adminEmail - Customer's administrator's email.
 * @property {String} adminPassword - Customer's administrator's password.
 * @property {Boolean} enabled - True if customer is enabled.
 * @property {String} code - Customer's code.
 */

/**
 * @typedef {Object} CompanyUpdateBody
 * @property {String} name - Customer's name.
 * @property {String} code - Customer's code.
 * @property {Boolean} enabled - True if customer is enabled.
 */

class CustomerInfo extends BaseEntity {
  _name = undefined;
  _enabled = true;
  _code = undefined;
  _assets = 0;

  adminName = undefined;
  adminSurname = undefined;
  adminEmail = undefined;
  adminPassword = undefined;

  constructor(customerInfo = undefined) {
    super(customerInfo);

    if (customerInfo != undefined) {
      this._id = customerInfo.id;
      this._name = customerInfo.name;
      this._enabled = customerInfo.enabled;
      this._code = customerInfo.code;
    }
  }

  // Getters

  get name() {
    return this._name;
  }
  get enabled() {
    return this._enabled;
  }
  get code() {
    return this._code;
  }
  get assets() {
    return this._assets;
  }

  // Setters

  set name(name) {
    this._name = name;
  }
  set enabled(enabled) {
    this._enabled = enabled;
  }
  set code(code) {
    this._code = code;
  }
  set assets(assets) {
    this._assets = assets;
  }

  // Public methods

  /**
   * Returns an object usable to create a customer via the API.
   * @public
   * @returns {CustomerCreateBody} - Create object.
   */
  getCustomerCreateBody() {
    return {
      name: this._name,
      adminName: this.adminName,
      adminSurname: this.adminSurname,
      adminEmail: this.adminEmail,
      adminPassword: this.adminPassword,
      enabled: this._enabled,
      code: this._code,
    };
  }

  /**
   * Returns an object usable to update the customer via the API.
   * @public
   * @returns {CompanyUpdateBody} - Update object.
   */
  getCompanyUpdateBody() {
    return {
      name: this._name,
      code: this._code || "",
      enabled: this._enabled,
    };
  }

  /**
   * Useful for filtering customers.
   * @public
   * @param {String} searchTerm - The word to search in this customer.
   * @returns {Boolean} - Returns `true` if the searched word is contained in this customer, `false` otherwise.
   */
  matchSearchTerm(searchTerm) {
    const s = (searchTerm || "").toLowerCase();

    return (
      (this._name || "").toLowerCase().includes(s) ||
      (this._code || "").toLowerCase().includes(s)
    );
  }
}

export default CustomerInfo;
