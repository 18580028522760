<template>
  <v-combobox
    v-model="computedValue"
    v-bind:label="label"
    v-bind:rules="rules"
    v-bind:outlined="outlined"
    v-bind:hint="hint"
    clearable
    multiple
    chips
    deletable-chips
  />
</template>

<script>
export default {
  name: "OCombobox",

  props: {
    value: { type: Array, required: true, default: () => [] },
    label: { type: String, required: true },
    rules: { type: Array, required: true },
    outlined: { type: Boolean, required: true },
    hint: { type: String, required: false },
  },

  data: () => ({
    selectedValue: undefined,
  }),

  computed: {
    computedValue: {
      get() {
        return (this.selectedValue || []).map((v, i) => {
          return { text: v, value: i };
        });
      },
      set(newValue) {
        this.selectedValue = newValue.map((v) => v.text ?? v);
        this.$emit("input", this.selectedValue);
      },
    },
  },

  beforeMount() {
    this.selectedValue = this.value;
  },
};
</script>
