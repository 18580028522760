<template>
  <v-card>
    <v-card-title>Geocoding</v-card-title>
    <v-card-text class="d-flex flex-column align-center justify-center">
      <div class="d-flex align-center" style="gap: 1em">
        <v-text-field
          v-model="searchString"
          v-bind:label="$t('locationSearch')"
          v-bind:rules="[(v) => !!v || $t('required')]"
          v-bind:outlined="outlinedPref"
          style="width: 40ch"
          clearable
        />

        <v-btn
          v-bind:disabled="!validSearch"
          v-on:click="startGeocoding"
          color="primary"
          icon
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>

      <v-alert
        v-if="searchError"
        border="left"
        color="error"
        width="45ch"
        outlined
        text
      >
        {{ $t("geocodingError") }}:
        <span class="font-weight-bold">{{ searchString }}</span>
      </v-alert>

      <v-select
        v-model="selectedCoords"
        v-bind:label="$t('geocodingResults')"
        v-bind:items="coordinatesList"
        v-bind:hint="coordsHint"
        v-bind:outlined="outlinedPref"
        style="width: 100%; max-width: 45ch"
        persistent-hint
      />

      <div class="geo-map mt-4">
        <vl-map
          v-bind:controls="{ attribution: true, rotate: false, zoom: false }"
          v-bind:load-tiles-while-animating="true"
          v-bind:load-tiles-while-interacting="true"
          v-on:created="mapCreated"
          data-projection="EPSG:4326"
          name="eye-geocoding-map"
          ref="geocodingMap"
        >
          <vl-view
            v-bind:zoom="zoom"
            v-bind:center="center"
            v-bind:rotation="0"
            name="eye-geocoding-map-view"
          />

          <vl-layer-tile name="eye-geocoding-map-tiles">
            <vl-source-osm
              v-if="mapTilesService == undefined"
              name="eye-geocoding-map-osm"
            />
            <vl-source-xyz
              v-else
              v-bind:url="mapTilesService"
              name="eye-geocoding-map-xyz"
            />
          </vl-layer-tile>

          <vl-overlay
            v-if="validCoordinates"
            v-bind:position="center"
            class="no-event"
            name="eye-geocoding-map-overlay"
          >
            <div class="centering no-event">
              <v-icon class="no-event" color="primary" large>
                mdi-map-marker
              </v-icon>
            </div>
          </vl-overlay>
        </vl-map>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn v-on:click="abortGeocoding" color="error" text>
        {{ $t("btnCancel") }}
      </v-btn>
      <v-btn
        v-bind:disabled="!validCoordinates"
        v-on:click="confirmGeocoding"
        color="primary"
        text
      >
        {{ $t("btnConfirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.geo-map {
  width: 100%;
  height: 15em;
  border: 1px solid #42424242;
  border-radius: 4px;
  overflow: hidden;
}

.centering {
  transform: translate(-50%, -100%);
}

.no-event {
  pointer-events: none;
}
</style>

<script>
import "vuelayers/lib/style.css";
import axios from "axios";

export default {
  name: "Geocoding",

  props: {
    init: { type: Object, required: false, default: () => {} },
  },

  data: () => ({
    searchString: undefined,
    coordinatesList: [],
    selectedCoords: undefined,
    lat: undefined,
    lon: undefined,
    searchError: false,
  }),

  computed: {
    mapTilesService() {
      return (
        this.$store.getters.string("VUE_APP_MAP_TILES_SERVICE")?.trim() ||
        undefined
      );
    },
    validCoordinates() {
      return this.lat != undefined && this.lon != undefined;
    },
    validSearch() {
      return this.searchString != undefined && this.searchString.trim() != "";
    },
    coordsHint() {
      const latitude = `${this.$t("groupLat")} ${this.lat ?? "--"}`;
      const longitude = `${this.$t("groupLon")} ${this.lon ?? "--"}`;

      return `${latitude}  |  ${longitude}`;
    },
    center() {
      return this.validCoordinates
        ? [Number(this.lon), Number(this.lat)]
        : [12.4829321, 41.8933203];
    },
    zoom() {
      return this.validCoordinates ? 17 : 4;
    },
  },

  watch: {
    searchString() {
      this.searchError = false;
    },
    selectedCoords: {
      deep: true,
      handler(newCoords) {
        if (newCoords != undefined) {
          const coordsValue = JSON.parse(newCoords);

          this.lat = coordsValue.lat;
          this.lon = coordsValue.lon;
        }
      },
    },
  },

  beforeMount() {
    this.lat = this.init.lat;
    this.lon = this.init.lon;
  },

  methods: {
    mapCreated() {
      this.$refs.geocodingMap.$map.getControls().clear();
      this.$refs.geocodingMap.$map.on("click", (evt) => {
        const coordinate = evt.coordinate;

        /* Convert Mercator (EPSG:900913) to Lat/Lon (EPSG:4326) */

        const factor = 111319.490778;

        this.lon = (coordinate[0] / factor).toFixed(7);
        // eslint-disable-next-line prettier/prettier
        this.lat = (Math.atan(Math.exp((coordinate[1] / factor) * (Math.PI / 180))) * (360 / Math.PI) - 90).toFixed(7);
      });
    },
    abortGeocoding() {
      this.$emit("close");
    },
    confirmGeocoding() {
      this.$emit("geocoded", { lat: this.lat, lon: this.lon });
    },
    startGeocoding() {
      const urlSafeString = encodeURI(this.searchString);
      const searchUrl = `https://nominatim.openstreetmap.org/search?q=${urlSafeString}&format=json`;

      this.searchError = false;

      axios
        .get(searchUrl)
        .then((res) => {
          this.coordinatesList = res.data.map((place) => {
            return {
              text: place.display_name,
              value: JSON.stringify({
                lat: Number(place.lat).toFixed(7),
                lon: Number(place.lon).toFixed(7),
              }),
            };
          });
          this.selectedCoords = this.coordinatesList[0].value;
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.error(err);

          this.lat = undefined;
          this.lon = undefined;
          this.selectedCoords = undefined;
          this.coordinatesList = [];
          this.searchError = true;
        });
    },
  },
};
</script>
