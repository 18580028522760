import dataVuexModule from "./data";
import sessionVuexModule from "./session";
import configVuexModule from "./config";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    config: configVuexModule,
    data: dataVuexModule,
    session: sessionVuexModule,
  },
});
