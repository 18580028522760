var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 fill-height grid"},[_c('h1',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("menuGroups"))+" ")]),_c('v-col',{attrs:{"align":"end"}},[(!_vm.currentUser.isOperator)?[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.newGroupDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("btnNewGroup"))+" ")])]:_vm._e()],2)],1)],1),_c('div',{staticClass:"table"},[_c('EntitiesTable',{attrs:{"headers":_vm.headers,"sorting-fields":_vm.sortingFields,"items":_vm.groupsList,"defaultSort":_vm.sortingFields[0].value,"loading":_vm.loading,"actionsColumn":true,"actions":{
        edit: true,
        enableDisable: false,
        delete: _vm.currentUser.role < _vm.roleCodes.MANAGER,
      },"bulkColumn":_vm.currentUser.role < _vm.roleCodes.MANAGER},on:{"table-bulk-delete":_vm.manageGroupBulk,"table-click":_vm.handleClick,"table-edit":function($event){return _vm.manageGroup($event, 0)},"table-delete":function($event){return _vm.manageGroup($event, 1)},"table-disable":function($event){/* Do nothing */},"table-enable":function($event){/* Do nothing */},"table-bulk-enable":function($event){/* Do nothing */},"table-bulk-disable":function($event){/* Do nothing */}}})],1),(_vm.newGroupDialog)?_c('v-dialog',{attrs:{"width":"unset","persistent":"","scrollable":""},model:{value:(_vm.newGroupDialog),callback:function ($$v) {_vm.newGroupDialog=$$v},expression:"newGroupDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("btnNewGroup")))]),_c('v-card-text',{staticClass:"d-flex flex-column"},[_c('v-text-field',{attrs:{"label":_vm.$t('groupName'),"rules":[function (v) { return !!v || _vm.$t('required'); }],"outlined":_vm.outlinedPref,"clearable":""},model:{value:(_vm.group.name),callback:function ($$v) {_vm.$set(_vm.group, "name", $$v)},expression:"group.name"}}),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"1em"}},[_c('v-text-field',{attrs:{"label":_vm.$t('groupProvince'),"outlined":_vm.outlinedPref,"clearable":"","hide-details":""},model:{value:(_vm.group.province),callback:function ($$v) {_vm.$set(_vm.group, "province", $$v)},expression:"group.province"}}),_c('v-text-field',{attrs:{"label":_vm.$t('groupMunicipality'),"outlined":_vm.outlinedPref,"clearable":"","hide-details":""},model:{value:(_vm.group.municipality),callback:function ($$v) {_vm.$set(_vm.group, "municipality", $$v)},expression:"group.municipality"}})],1),_c('v-select',{attrs:{"items":_vm.groupTypes,"label":_vm.$t('groupType'),"outlined":_vm.outlinedPref,"clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$t(item)))])]}},{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$t(item)))])]}}],null,false,3246045404),model:{value:(_vm.group.type),callback:function ($$v) {_vm.$set(_vm.group, "type", $$v)},expression:"group.type"}}),_c('v-divider',{staticClass:"mt-4 mb-1"}),_c('div',{staticClass:"caption font-weight-bold d-flex justify-space-between align-center"},[_vm._v(" "+_vm._s(_vm.$t("groupCoords"))+" "),_c('v-btn',{attrs:{"color":"primary","text":"","x-small":""},on:{"click":function($event){_vm.geocodingDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("locationSearch"))+" ")])],1),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"1em"}},[_c('v-text-field',{attrs:{"label":_vm.$t('groupLat'),"outlined":_vm.outlinedPref,"clearable":"","hide-details":""},model:{value:(_vm.group.lat),callback:function ($$v) {_vm.$set(_vm.group, "lat", $$v)},expression:"group.lat"}}),_c('v-text-field',{attrs:{"label":_vm.$t('groupLon'),"outlined":_vm.outlinedPref,"clearable":"","hide-details":""},model:{value:(_vm.group.lon),callback:function ($$v) {_vm.$set(_vm.group, "lon", $$v)},expression:"group.lon"}})],1),_c('v-divider',{staticClass:"mt-4 mb-1"}),_c('div',{staticClass:"caption font-weight-bold d-flex justify-space-between align-center"},[_vm._v(" "+_vm._s(_vm.$t("groupCustoms"))+" "),_c('v-btn',{attrs:{"color":"primary","text":"","x-small":""},on:{"click":function($event){_vm.newKeyDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("createCustomKey"))+" ")])],1),_c('div',{key:'customs-new-list-' + _vm.refreshList,staticClass:"d-flex flex-column"},_vm._l((Object.keys(_vm.group.customs)),function(key){return _c('v-text-field',{key:_vm.group.name + '-new-' + key,attrs:{"label":key,"outlined":_vm.outlinedPref,"clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"title":_vm.$t('btnDelete'),"color":"error","icon":"","small":""},on:{"click":function($event){delete _vm.group.customs[key];
                  _vm.refreshList = !_vm.refreshList;}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]},proxy:true}],null,true),model:{value:(_vm.group.customs[key]),callback:function ($$v) {_vm.$set(_vm.group.customs, key, $$v)},expression:"group.customs[key]"}})}),1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.resetGroup}},[_vm._v(" "+_vm._s(_vm.$t("btnCancel"))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.validGroup,"color":"primary","text":""},on:{"click":function($event){return _vm.postGroup(false)}}},[_vm._v(" "+_vm._s(_vm.$t("btnCreate"))+" ")])],1)],1)],1):_vm._e(),(_vm.editGroupDialog)?_c('v-dialog',{attrs:{"width":"unset","persistent":"","scrollable":""},model:{value:(_vm.editGroupDialog),callback:function ($$v) {_vm.editGroupDialog=$$v},expression:"editGroupDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("groupUpdate")))]),_c('v-card-text',{staticClass:"d-flex flex-column"},[_c('v-text-field',{attrs:{"label":_vm.$t('groupName'),"rules":[function (v) { return !!v || _vm.$t('required'); }],"outlined":_vm.outlinedPref,"clearable":""},model:{value:(_vm.group.name),callback:function ($$v) {_vm.$set(_vm.group, "name", $$v)},expression:"group.name"}}),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"1em"}},[_c('v-text-field',{attrs:{"label":_vm.$t('groupProvince'),"outlined":_vm.outlinedPref,"clearable":"","hide-details":""},model:{value:(_vm.group.province),callback:function ($$v) {_vm.$set(_vm.group, "province", $$v)},expression:"group.province"}}),_c('v-text-field',{attrs:{"label":_vm.$t('groupMunicipality'),"outlined":_vm.outlinedPref,"clearable":"","hide-details":""},model:{value:(_vm.group.municipality),callback:function ($$v) {_vm.$set(_vm.group, "municipality", $$v)},expression:"group.municipality"}})],1),_c('v-select',{attrs:{"items":_vm.groupTypes,"label":_vm.$t('groupType'),"outlined":_vm.outlinedPref,"clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$t(item)))])]}},{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$t(item)))])]}}],null,false,3246045404),model:{value:(_vm.group.type),callback:function ($$v) {_vm.$set(_vm.group, "type", $$v)},expression:"group.type"}}),_c('v-divider',{staticClass:"mt-4 mb-1"}),_c('div',{staticClass:"caption font-weight-bold d-flex justify-space-between align-center"},[_vm._v(" "+_vm._s(_vm.$t("groupCoords"))+" "),_c('v-btn',{attrs:{"color":"primary","text":"","x-small":""},on:{"click":function($event){_vm.geocodingDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("locationSearch"))+" ")])],1),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"1em"}},[_c('v-text-field',{attrs:{"label":_vm.$t('groupLat'),"outlined":_vm.outlinedPref,"clearable":"","hide-details":""},model:{value:(_vm.group.lat),callback:function ($$v) {_vm.$set(_vm.group, "lat", $$v)},expression:"group.lat"}}),_c('v-text-field',{attrs:{"label":_vm.$t('groupLon'),"outlined":_vm.outlinedPref,"clearable":"","hide-details":""},model:{value:(_vm.group.lon),callback:function ($$v) {_vm.$set(_vm.group, "lon", $$v)},expression:"group.lon"}})],1),_c('v-divider',{staticClass:"mt-4 mb-1"}),_c('div',{staticClass:"caption font-weight-bold d-flex justify-space-between align-center"},[_vm._v(" "+_vm._s(_vm.$t("groupCustoms"))+" "),_c('v-btn',{attrs:{"color":"primary","text":"","x-small":""},on:{"click":function($event){_vm.newKeyDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("createCustomKey"))+" ")])],1),_c('div',{key:'customs-edit-list-' + _vm.refreshList,staticClass:"d-flex flex-column"},_vm._l((Object.keys(_vm.group.customs)),function(key){return _c('v-text-field',{key:_vm.group.name + '-edit-' + key,attrs:{"label":key,"outlined":_vm.outlinedPref,"clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"title":_vm.$t('btnDelete'),"color":"error","icon":"","small":""},on:{"click":function($event){delete _vm.group.customs[key];
                  _vm.refreshList = !_vm.refreshList;}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]},proxy:true}],null,true),model:{value:(_vm.group.customs[key]),callback:function ($$v) {_vm.$set(_vm.group.customs, key, $$v)},expression:"group.customs[key]"}})}),1)],1),_c('v-card-actions',[(_vm.currentUser.role < _vm.roleCodes.MANAGER)?_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.manageGroup(_vm.group, 1)}}},[_vm._v(" "+_vm._s(_vm.$t("btnDelete"))+" ")]):_vm._e(),_c('v-spacer',{staticClass:"mx-6"}),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.resetGroup}},[_vm._v(" "+_vm._s(_vm.$t("btnCancel"))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.validGroup,"color":"primary","text":""},on:{"click":function($event){return _vm.postGroup(true)}}},[_vm._v(" "+_vm._s(_vm.$t("btnSave"))+" ")])],1)],1)],1):_vm._e(),(_vm.newKeyDialog)?_c('v-dialog',{attrs:{"width":"unset","persistent":""},model:{value:(_vm.newKeyDialog),callback:function ($$v) {_vm.newKeyDialog=$$v},expression:"newKeyDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("createCustomKey")))]),_c('v-card-text',{staticClass:"d-flex",staticStyle:{"gap":"2em"}},[_c('v-text-field',{staticStyle:{"width":"45ch"},attrs:{"label":_vm.$t('newCustomKey'),"rules":[function (v) { return !!v || _vm.$t('required'); }],"outlined":_vm.outlinedPref,"clearable":""},model:{value:(_vm.newKey),callback:function ($$v) {_vm.newKey=$$v},expression:"newKey"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.newKeyDialog = false;
            _vm.newKey = undefined;
            _vm.refreshList = !_vm.refreshList;}}},[_vm._v(" "+_vm._s(_vm.$t("btnCancel"))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.newKey == undefined,"color":"primary","text":""},on:{"click":function($event){_vm.group.customs[_vm.newKey] = '';
            _vm.newKeyDialog = false;
            _vm.newKey = undefined;
            _vm.refreshList = !_vm.refreshList;}}},[_vm._v(" "+_vm._s(_vm.$t("btnCreate"))+" ")])],1)],1)],1):_vm._e(),(_vm.confirmDelete)?_c('ConfirmationDialog',{attrs:{"callback":_vm.confirmCallback,"content":_vm.$t('confirmGroupDelete'),"title":_vm.$t('dialogGroupDeleteConfirm')},on:{"cancel":function($event){_vm.confirmDelete = false}}}):_vm._e(),(_vm.geocodingDialog)?_c('v-dialog',{attrs:{"width":"unset","persistent":"","scrollable":""},model:{value:(_vm.geocodingDialog),callback:function ($$v) {_vm.geocodingDialog=$$v},expression:"geocodingDialog"}},[_c('Geocoding',{attrs:{"init":{ lat: _vm.group.lat, lon: _vm.group.lon }},on:{"close":function($event){_vm.geocodingDialog = false},"geocoded":_vm.useGeocodingCoords}})],1):_vm._e(),_c('v-dialog',{attrs:{"width":"unset","persistent":""},model:{value:(_vm.updating),callback:function ($$v) {_vm.updating=$$v},expression:"updating"}},[_c('v-card',[_c('v-progress-circular',{staticClass:"ma-4",attrs:{"color":"primary","indeterminate":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }