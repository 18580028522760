import moment from "moment";
import BaseEntity from "./BaseEntity";

class EnrichedBaseEntity extends BaseEntity {
  _created = moment();
  _createdBy = undefined;
  _modified = moment();
  _modifiedBy = undefined;

  constructor(enrichedJson = undefined) {
    super(enrichedJson);

    if (enrichedJson != undefined) {
      this._created = moment(enrichedJson.created);
      this._createdBy = enrichedJson.createdBy;
      this._modified = moment(enrichedJson.modified);
      this._modifiedBy = enrichedJson.modifiedBy;
    }
  }

  // Getters

  get created() {
    return this._created;
  }
  get createdBy() {
    return this._createdBy;
  }
  get modified() {
    return this._modified;
  }
  get modifiedBy() {
    return this._modifiedBy;
  }

  // Public methods

  /**
   * Converts the last modified date into a human readable date.
   * @public
   * @returns {String} - Formatted string date.
   */
  readableModified() {
    const today = moment();

    return this._modified.format(
      this._modified.isBefore(today, "day")
        ? EnrichedBaseEntity.VUE.$t("tzLocale")
        : "HH:mm"
    );
  }

  /**
   * Converts the created date into a human readable date.
   * @public
   * @returns {String} - Formatted string date.
   */
  readableCreated() {
    const today = moment();

    return this._created.format(
      this._created.isBefore(today, "day")
        ? EnrichedBaseEntity.VUE.$t("tzLocale")
        : "HH:mm"
    );
  }
}

export default EnrichedBaseEntity;
