import Vue from "vue";
import i18n from "../plugins/i18n";

class BaseEntity {
  static VUE = new Vue({ i18n });

  _id = undefined;

  constructor(baseJson = undefined) {
    if (baseJson != undefined) {
      this._id = baseJson.id;
    }
  }

  // Getters

  get id() {
    return this._id;
  }

  // Abstract methods

  matchSearchTerm(searchTerm) {
    throw new Error(
      `You have to implement the method "matchSearchTerm" to digest the input ${searchTerm}`
    );
  }
}

export default BaseEntity;
