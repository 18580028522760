<template>
  <div class="finder">
    <LineChart
      id="thumb-line-chart"
      class="py-1"
      v-bind:chartData="chartData"
      v-bind:options="options"
      v-bind:key="'multiline-thumb-chart-' + update"
    />
    <div class="mask" left v-bind:style="'right: ' + min + '%'"></div>
    <div class="mask" right v-bind:style="'left: ' + max + '%'"></div>
  </div>
</template>

<style scoped>
.finder {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2rem;
  border: 1px solid #0c1d6969;
  background-repeat: no-repeat;
  background-size: 100% 2rem;
  overflow: hidden;
}

.mask {
  position: absolute;
  background: #0c1d6969;
}

.mask[left] {
  left: 0;
  top: 0;
  bottom: 0;
}

.mask[right] {
  right: 0;
  top: 0;
  bottom: 0;
}

#thumb-line-chart {
  position: absolute;
  width: 100%;
  height: 2rem;
}
</style>

<script>
import { LineChart } from "vue-chart-3";

export default {
  name: "ChartFinder",

  components: { LineChart },

  props: {
    chartData: { type: Object, required: true },
    minTs: { type: Number, required: true },
    maxTs: { type: Number, required: true },
    minTsZoom: { type: Number, required: true },
    maxTsZoom: { type: Number, required: true },
  },

  data: () => ({
    min: 0,
    max: 0,
    update: false,
    options: {
      responsive: true,
      stacked: false,
      interaction: false,
      hover: false,
      animation: false,
      parsing: false,
      elements: {
        point: {
          radius: 0,
          borderWidth: 0,
          hoverRadius: 0,
        },
        line: {
          borderWidth: 1,
          borderJoinStyle: "round",
          cubicInterpolationMode: "monotone",
        },
      },
      plugins: {
        legend: false,
        autocolors: false,
        decimation: {
          enabled: true,
          algorithm: "lttb",
          samples: 500,
          threshold: 1000,
        },
        tooltip: false,
        crosshair: false,
        zoom: false,
      },
      scales: {
        x: {
          type: "time",
          ticks: { display: false },
        },
      },
    },
  }),

  watch: {
    minTs(ts) {
      this.options.scales.x.min = ts;
      this.update = !this.update;
    },
    maxTs(ts) {
      this.options.scales.x.max = ts;
      this.update = !this.update;
    },
    minTsZoom(ts) {
      const normalizedMax = this.maxTs - this.minTs;
      let normalizedTs = ts - this.minTs;

      if (normalizedTs < 0) normalizedTs = 0;

      this.min = 100 - (100 * normalizedTs) / normalizedMax;
    },
    maxTsZoom(ts) {
      const normalizedMax = this.maxTs - this.minTs;
      let normalizedTs = ts - this.minTs;

      if (normalizedTs < 0) normalizedTs = 0;

      this.max = (100 * normalizedTs) / normalizedMax;
    },
    chartData: {
      deep: true,
      handler(newValue) {
        this.options.scales = {
          x: { ...this.options.scales.x },
        };

        newValue.datasets.forEach((ds) => {
          this.options.scales[ds.yAxisID] = {
            display: false,
            beginAtZero: true,
          };
        });

        this.$nextTick(() => {
          this.update = !this.update;
        });
      },
    },
  },
};
</script>
