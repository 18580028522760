<template>
  <div class="pa-4 fill-height grid-container">
    <div class="side-bar">
      <v-card-title
        class="pa-0 grow-false d-flex flex-row align-center justify-space-between"
      >
        <div>
          {{ asset.plate || asset.boxMacAddress || asset.serial }}
          <span class="mx-1"> - </span>
          <span class="primary--text text--lighten-2">
            {{ asset.brand }}
            {{ asset.model }}
          </span>
        </div>
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="primary" icon>
                <v-icon>mdi-tools</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-content style="overflow: visible">
                  <v-switch
                    v-model="startAtZero"
                    v-bind:disabled="loadingChart"
                    v-bind:label="$t('axesFromZeroBtn')"
                    class="py-0 my-0"
                    hide-details
                    inset
                  />
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-on:click="addToVisualAnalysisSession" link>
                <v-list-item-title>
                  {{ $t("addToAnalysis") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>

            <v-list v-if="currentUser.isSuperAdmin || currentUser.isAdmin">
              <v-list-item v-on:click="cacheDialog = true" link>
                <v-list-item-title>
                  {{ $t("btnClearCache") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>

      <v-dialog v-model="pickerDialog" width="unset" persistent scrollable>
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <v-text-field
              v-bind:title="$t('calendarRange')"
              v-bind:value="
                dateFormat(dateRange.startDate) +
                ' - ' +
                dateFormat(dateRange.endDate)
              "
              v-bind="attrs"
              v-on="on"
              class="grow-true mt-4 mb-2"
              prepend-inner-icon="mdi-calendar"
              dense
              hide-details
              outlined
              readonly
            />
          </div>
        </template>

        <v-card class="pa-2">
          <v-card-text>
            <date-range-picker
              ref="picker"
              v-model="dateRange"
              v-bind:locale-data="{
                firstDay: 1,
                format: `${$t('tzLocale').toLowerCase()} HH:mm`,
                daysOfWeek: momentTranslations.days,
                monthNames: momentTranslations.months,
              }"
              v-bind:date-format="disableDates"
              v-bind:max-date="new Date()"
              v-bind:ranges="fixedRanges(isRealTime)"
              v-bind:timePicker="!isRealTime"
              class="mt-2 range-picker"
              opens="inline"
              linkedCalendars
              showDropdowns
              timePicker24Hour
            >
              <template v-slot:input="picker">
                <v-card-subtitle>
                  {{ $t("calendarRange") }}:
                  <span class="font-weight-bold body-1">
                    {{ dateFormat(picker.startDate) }}
                  </span>
                  -
                  <span class="font-weight-bold body-1">
                    {{ dateFormat(picker.endDate) }}
                  </span>
                </v-card-subtitle>
              </template>
              <div slot="footer" slot-scope="data" class="slot">
                <v-card-actions class="py-0">
                  <v-switch
                    v-model="isRealTime"
                    v-bind:label="$t('realtimeChart')"
                  />
                  <v-spacer />
                  <v-btn v-on:click="pickerDialog = false" color="error" text>
                    {{ $t("btnCancel") }}
                  </v-btn>
                  <v-btn
                    v-bind:disabled="loadingChart || data.in_selection"
                    v-on:click="updateDatesRange(data)"
                    color="primary"
                    text
                  >
                    {{ $t("btnSave") }}
                  </v-btn>
                </v-card-actions>
              </div>
            </date-range-picker>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div class="d-flex" style="gap: 2em">
        <v-text-field
          v-on:input="manageSearch"
          v-bind:label="$t('searchPlaceholder')"
          v-bind:dense="outlinedPref"
          v-bind:outlined="outlinedPref"
          class="grow-true mb-4"
          prepend-inner-icon="mdi-magnify"
          clearable
          hide-details
        />

        <v-switch
          v-model="showConstants"
          v-bind:label="$t('showConstants')"
          inset
        />
      </div>

      <div class="d-flex justify-space-between grow-false">
        <v-btn
          v-on:click="
            selected = [];
            selectedTemplate = undefined;
            templateName = undefined;
          "
          color="primary"
          small
          text
        >
          {{ $t("btnDeselectAll") }}
        </v-btn>

        <v-btn v-on:click="templateDialog = true" color="primary" small text>
          {{ $t("btnLoadTemplate") }}
        </v-btn>
      </div>

      <div class="grow-true main-list">
        <v-data-table
          v-bind:expanded.sync="expanded"
          v-bind:headers="headers"
          v-bind:items="filteredMeasures"
          v-bind:items-per-page="-1"
          group-by="msgGroup"
          item-key="msgPath"
          hide-default-footer
        >
          <template v-slot:header.data-table-select>
            <!-- No select all checkbox -->
          </template>

          <template v-slot:group.header="{ group, items, isOpen, toggle }">
            <td class="indigo lighten-5" colspan="4">
              <v-btn v-on:click="toggle" icon>
                <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
              <span>{{ group }}</span>
              <span class="text-lowercase">
                ({{ items.length }} {{ $t("mainParameters") }})
              </span>
            </td>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-simple-checkbox
              v-if="item.isSelectable"
              v-bind:ripple="false"
              v-bind:value="isSelected(item)"
              v-bind:disabled="loadingChart"
              v-on:input="selectRow(item)"
              color="primary"
            />
            <v-btn v-else v-on:click="expandRow(item)" class="my-2" icon>
              <v-icon v-if="isExpanded(item)">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.name="{ item }">
            <div
              v-bind:title="$t(item.msgPath)"
              style="max-width: 24ch; overflow: hidden; text-overflow: ellipsis"
            >
              {{ $t(item.msgPath) }}
            </div>
          </template>

          <template v-slot:item.content="{ item }">
            <div
              v-if="!isExpanded(item)"
              v-bind:title="item.formattedContent(true, 2)"
              v-on:click="displayRawData = item"
              class="font-weight-bold d-flex align-center justify-end"
              style="
                cursor: pointer;
                max-width: 25ch;
                max-height: 1em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              <span v-bind:class="item.getTextColor()">
                {{ item.formattedContent(true, 2) }}
              </span>
              <v-icon
                v-if="item.getIcon() != undefined"
                v-bind:color="item.getIcon()[1]"
                class="ms-2"
                x-small
              >
                {{ item.getIcon()[0] }}
              </v-icon>
            </div>
          </template>

          <template v-slot:item.ts="{ item }">
            <div
              v-if="!isExpanded(item)"
              v-bind:class="'caption' + getColor(item.ts)"
              v-bind:title="item.readableTs(false)"
              v-bind:key="item.msgPath + '-ts-' + mainListKey"
            >
              {{ item.readableTs(true) }}
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              v-if="!item.isSelectable"
              v-bind:colspan="headers.length"
              class="pa-1 white"
            >
              <v-card>
                <v-list>
                  <v-list-item
                    v-for="(value, i) in item.content"
                    v-bind:key="'expanded-' + value.msgPath"
                  >
                    <v-list-item-avatar>
                      <v-simple-checkbox
                        v-bind:ripple="false"
                        v-bind:value="isSelected(value)"
                        v-bind:disabled="loadingChart"
                        v-on:input="selectRow(value)"
                        color="primary"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content
                      v-bind:title="$t(item.msgPath) + ' ' + i"
                    >
                      {{ $t(item.msgPath) }} {{ i }}
                    </v-list-item-content>
                    <v-list-item-content
                      v-bind:style="'width:' + headers[2].width"
                      class="px-0 mx-0"
                    >
                      <div
                        v-bind:title="value.formattedContent()"
                        class="body1 font-weight-bold d-flex align-center justify-end"
                        style="width: 100%"
                      >
                        <span v-bind:class="value.getTextColor()">
                          {{ value.formattedContent() }}
                        </span>
                        <v-icon
                          v-if="value.getIcon() != undefined"
                          v-bind:color="value.getIcon()[1]"
                          class="ms-2"
                          x-small
                        >
                          {{ value.getIcon()[0] }}
                        </v-icon>
                      </div>
                    </v-list-item-content>
                    <v-list-item-action
                      v-bind:style="'width:' + headers[3].width"
                      class="px-0 mx-0"
                    >
                      <div
                        v-bind:class="'text-right caption' + getColor(value.ts)"
                        v-bind:title="value.readableTs(false)"
                        v-bind:key="
                          'expanded-' + value.msgPath + '-ts-' + mainListKey
                        "
                        style="width: 100%"
                      >
                        {{ value.readableTs(true) }}
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="plot-area">
      <div class="plot rounded">
        <Chart
          v-bind:chart-data="chartData"
          v-bind:colors="plotColors"
          v-bind:asset="asset.serial"
          v-bind:filter="plotSelected"
          v-bind:min-timestamp="chartStarting"
          v-bind:max-timestamp="chartEnding"
          v-bind:realtime="inRealtime"
          v-bind:start-at-zero="startAtZero"
          v-bind:data-gaps="!selectedDetailsInterpolate"
          v-on:x-axis-selected="updateSelected"
          v-on:data-loaded="loadingChart = false"
          class="pa-2"
          topic-prefix="measures/"
          ref="lineChart"
        />
      </div>
    </div>

    <div class="plot-data">
      <div class="plot-data-title">
        <v-card-title class="py-0 d-flex justify-space-between">
          <div>
            {{ $t("comparedMeasures") }}
            ({{ selected.length }}/{{ maxSelection }})
          </div>
          <JsonCSV
            v-if="exportData.length > 0"
            v-bind:name="csvName()"
            v-bind:data="exportData"
            delimiter=";"
          >
            <v-btn color="primary" small text>
              {{ $t("downloadCSV") }}
            </v-btn>
          </JsonCSV>
        </v-card-title>
        <v-divider />
      </div>

      <div class="d-flex flex-row justify-space-between plot-data-lists">
        <div class="plot-data-list">
          <v-simple-table height="100%">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(measure, index) in selected"
                  v-bind:key="'selected-' + measure.msgPath"
                  class="my-0"
                >
                  <td class="my-0">
                    <v-checkbox
                      v-model="plotSelected"
                      v-show="measure.isPlottable()"
                      v-bind:color="colors[index]"
                      v-bind:disabled="!measure.isPlottable()"
                      v-bind:value="measure.msgPath"
                      on-icon="mdi-chart-bar"
                      off-icon="mdi-chart-bar"
                    />
                  </td>
                  <td class="my-0">
                    {{ $t(measure.msgPath.split("_")[0]) }}
                    {{ measure.msgPath.split("_")[1] }}
                  </td>
                  <td class="font-weight-bold my-0" align="end">
                    <span v-bind:class="measure.getTextColor()">
                      {{ measure.formattedContent(undefined, undefined, true) }}
                    </span>
                    <v-icon
                      v-if="measure.getIcon() != undefined"
                      v-bind:color="measure.getIcon()[1]"
                      class="ms-2"
                      x-small
                    >
                      {{ measure.getIcon()[0] }}
                    </v-icon>
                  </td>
                  <td align="end" class="caption">
                    {{ getFullTimestamp(measure.chartTs) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <!-- TODO: use this space for something useful
          <div class="mt-2 ms-2 alarms-data-list rounded">
            <v-card-title class="body-1 font-weight-bold">
              {{ $t("otherAlarms") }}
            </v-card-title>
            <v-card-text>
              <span>{{ $t("noAlarm") }}</span>
            </v-card-text>
          </div>
        -->
      </div>
    </div>

    <v-dialog v-model="cacheDialog" width="unset" persistent>
      <v-card>
        <v-card-title>{{ $t("btnClearCache") }}</v-card-title>
        <v-card-subtitle>{{ $t("clearCacheInfo") }}</v-card-subtitle>
        <v-card-text>
          <v-radio-group v-model="clearCacheType">
            <v-radio v-bind:label="$t('allCache')" value="all" />
            <v-radio v-bind:label="$t('cacheOlderThan')" value="from" />
          </v-radio-group>

          <div
            v-on:click="clearCacheType = 'from'"
            class="d-flex flex-row justify-center align-baseline"
          >
            <v-text-field
              v-model="cache.year"
              v-bind:label="$t('year')"
              v-bind:disabled="clearCacheType !== 'from'"
              v-bind:outlined="outlinedPref"
              v-bind:dense="outlinedPref"
              class="date-input"
              hide-details
            />
            <span class="mx-2">/</span>
            <v-text-field
              v-model="cache.month"
              v-bind:label="$t('month')"
              v-bind:disabled="clearCacheType !== 'from'"
              v-bind:outlined="outlinedPref"
              v-bind:dense="outlinedPref"
              class="date-input"
              hide-details
            />
            <span class="mx-2">/</span>
            <v-text-field
              v-model="cache.day"
              v-bind:label="$t('day')"
              v-bind:disabled="clearCacheType !== 'from'"
              v-bind:outlined="outlinedPref"
              v-bind:dense="outlinedPref"
              class="date-input"
              hide-details
            />
            <span v-if="clearCacheType === 'from'" class="ms-4">
              ({{ $t("inclusive") }})
            </span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="cacheDialog = false" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn v-on:click="clearCache" color="primary" text>
            {{ $t("btnClearCache") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="templateDialog" width="min(30%, 92vw)">
      <v-card v-if="templateDialog">
        <v-card-title>{{ $t("btnLoadTemplate") }}</v-card-title>

        <v-card-text>
          <v-select
            v-model="selectedTemplate"
            v-bind:items="templatesList"
            v-bind:outlined="outlinedPref"
            v-bind:label="$t('selectionTemplate')"
            hide-details
          >
            <template v-if="selectedTemplate" v-slot:append-outer>
              <v-btn
                v-bind:title="$t('btnSave')"
                v-on:click="saveTemplate(true)"
                color="primary"
                icon
              >
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="existingTemplate"
                v-bind:title="$t('btnDelete')"
                v-on:click="deleteTemplate"
                color="error"
                icon
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
          </v-select>

          <TextSeparator v-bind:text="$t('separatorOr')" class="my-8" />

          <v-text-field
            v-model="templateName"
            v-bind:outlined="outlinedPref"
            v-bind:label="$t('selectionTemplate')"
            clearable
          />

          <v-btn
            v-bind:disabled="
              !templateName || !selected || selected.length === 0
            "
            v-on:click="saveTemplate(false)"
            color="primary"
            block
          >
            <v-icon class="me-4">mdi-content-save-outline</v-icon>
            <span>{{ $t("btnSaveCurrent") }}</span>
          </v-btn>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="templateDialog = false" color="primary" text>
            {{ $t("btnOk") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="displayRawData != undefined"
      v-bind:value="true"
      width="min(100ch, 90vw)"
      persistent
      scrollable
    >
      <v-card v-if="displayRawData != undefined">
        <v-card-title>{{ displayRawData.msgPath }}</v-card-title>
        <v-card-text>
          <div>{{ $t("detailsTitleValue") }}:</div>
          <div class="font-weight-bold text-body-1 grey--text text--darken-4">
            {{ displayRawData.formattedContent(false) }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="displayRawData = undefined" color="primary" text>
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      v-if="confirmDelete"
      v-bind:callback="confirmCallback"
      v-bind:content="$t('confirmDelete')"
      v-bind:title="$t('dialogDeleteConfirm')"
      v-on:cancel="resetConfirmDelete"
    />
  </div>
</template>

<style scoped>
.grid-container {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: minmax(min-content, 32%) auto;
  grid-template-rows: minmax(0, 50vh) minmax(0, 40vh);
  grid-template-areas:
    "side plot"
    "side data";
}

.data-grid-container {
  display: grid;
  gap: 0.1rem;
}

.side-bar {
  grid-area: side;
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
  overflow: hidden;
}

.grow-false {
  flex: 0 1 0;
}

.grow-true {
  flex: 1 1 0;
}

.main-list {
  overflow: auto;
}

.plot-area {
  grid-area: plot;
  overflow: hidden;
}

.plot {
  height: 100%;
  border: 1px solid #dfe7f0;
  overflow: hidden;
}

.plot-data {
  grid-area: data;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: min-content 1fr;
  height: 100%;
  overflow: hidden;
}

.plot-data-lists {
  height: 100%;
  overflow: hidden;
}

.plot-data-list {
  height: 100%;
  overflow: auto;
  flex-grow: 1;
}

.alarms-data-list {
  border: 1px solid #dfe7f0;
  width: 45%;
}

.date-input {
  max-width: 8ch;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import Messages from "../utils/messages";
import AssetInfo from "../models/AssetInfo";
import AssetDataRequestBody from "../models/AssetDataRequestBody";
import Chart from "../components/Chart";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import JsonCSV from "vue-json-csv";
import Measure from "../models/Measure";
import TextSeparator from "../components/TextSeparator";
import ConfirmationDialog from "../components/ConfirmationDialog";
import distinctColors from "distinct-colors";

export default {
  name: "AssetMoreDetails",

  components: {
    Chart,
    DateRangePicker,
    JsonCSV,
    TextSeparator,
    ConfirmationDialog,
  },

  data: () => ({
    measureTypes: Measure.types,
    assetId: undefined,
    asset: new AssetInfo(),
    searchTerm: undefined,
    showConstants: false,
    rangeIsInPast: false,
    loadingChart: false,
    startAtZero: true,
    selected: [],
    expanded: [],
    plotSelected: [],
    plotColors: {},
    chartData: {},
    exportData: [],
    dataKeys: [],
    clearCacheType: "all",
    cacheDialog: false,
    pickerDialog: false,
    templateDialog: false,
    selectedTemplate: undefined,
    templateName: undefined,
    availableTemplates: [],
    confirmCallback: () => {},
    confirmDelete: false,
    inRealtime: false,
    isRealTime: false,
    mainListKey: true,
    mainListRefresher: undefined,
    maxSelection: 10,
    displayRawData: undefined,
    momentTranslations: {
      days: moment.weekdaysShort(false),
      months: moment.months(true),
    },
    dateRange: {
      startDate: moment().subtract(15, "minutes"),
      endDate: moment(),
    },
    cache: {
      year: moment().format("YYYY"),
      month: moment().format("MM"),
      day: moment().format("DD"),
    },
    chartStarting: moment().subtract(15, "minutes").toDate().getTime(),
    chartEnding: moment().toDate().getTime(),
    minutesWindow: 15,
    colors: [],
  }),

  computed: {
    fixedRanges() {
      return (realtime) => {
        return realtime
          ? {
              [this.$t("lastQuarter")]: [
                this.todayMinutes().subtract(15, "minute").toDate(),
                this.todayMinutes().toDate(),
              ],
              [this.$t("lastHalf")]: [
                this.todayMinutes().subtract(30, "minute").toDate(),
                this.todayMinutes().toDate(),
              ],
              [this.$t("lastHour")]: [
                this.todayMinutes().subtract(1, "hour").toDate(),
                this.todayMinutes().toDate(),
              ],
            }
          : {
              [this.$t("lastHour")]: [
                this.todayMinutes().subtract(1, "hour").toDate(),
                this.todayMinutes().toDate(),
              ],
              [this.$t("last6Hours")]: [
                this.todayMinutes().subtract(6, "hour").toDate(),
                this.todayMinutes().toDate(),
              ],
              [this.$t("last12Hours")]: [
                this.todayMinutes().subtract(12, "hour").toDate(),
                this.todayMinutes().toDate(),
              ],
              [this.$t("lastDay")]: [
                this.todayMinutes().subtract(24, "hour").toDate(),
                this.todayMinutes().toDate(),
              ],
              [this.$t("lastWeek")]: [
                this.todayMinutes().subtract(1, "week").toDate(),
                this.todayMinutes().toDate(),
              ],
              [this.$t("lastMonth")]: [
                this.todayMinutes().subtract(1, "month").toDate(),
                this.todayMinutes().toDate(),
              ],
            };
      };
    },
    crumbs() {
      return this.$store.getters.pages;
    },
    measures() {
      return this.$store.getters.measuresById(this.asset.serial) ?? {};
    },
    lastMeasure() {
      return this.$store.getters.lastMeasure;
    },
    assetDbId() {
      return this.$route.params.assetId;
    },
    templatesList() {
      return this.availableTemplates.map((t) => t.name);
    },
    existingTemplate() {
      return this.findTemplate(this.selectedTemplate) != undefined;
    },
    assetTopics() {
      const allTopics = this.$store.getters.availableTopics || {};

      return Object.keys(allTopics).filter((key) => {
        return allTopics[key].includes(this.asset.entityCode);
      });
    },
    filteredMeasures() {
      const measuresArray = [];
      const measuresObj = this.measures;

      Object.keys(measuresObj).forEach((k) => {
        const realMesaure = this.getMeasureValue(measuresObj[k]);

        // Filter constants measures
        if (
          this.showConstants ||
          realMesaure.metadata["$constant"]?.content != "1"
        ) {
          realMesaure.isSelectable =
            realMesaure.contentType !== this.measureTypes.JSON;

          measuresArray.push(realMesaure);
        }
      });

      return measuresArray
        .filter((m) => m != undefined && m.msgPath != undefined)
        .filter((m) => m.matchSearchTerm(this.searchTerm))
        .sort((m1, m2) => (m1.msgPath > m2.msgPath ? 1 : -1));
    },
    headers() {
      return [
        // eslint-disable-next-line prettier/prettier
        { text: "", value: "actions", align: "start", sortable: false, width: "1px" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("detailsTitleName"), value: "name", align: "start", sortable: true, width: "28%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("detailsTitleValue"), value: "content", align: "end", sortable: false, width: "38%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("detailsTitleTs"), value: "ts", align: "end", sortable: false, width: "18%" },
      ];
    },
  },

  async beforeMount() {
    try {
      await this.fetchAssetData();
    } catch (err) {
      if (process.env.NODE_ENV === "development") console.error(err);
      this.$router.replace({ path: "/dashboard" });
      this.$bus.$emit(Messages.ERROR_MSG, {
        error: err?.response?.data?.error || err,
        description: this.$t("assetFetchFailure").replace(
          "%serial%",
          this.assetId
        ),
      });
      return;
    }

    // eslint-disable-next-line prettier/prettier
    this.colors = distinctColors({ count: this.maxSelection }).map((cc) => cc.hex("rgb"));
    this.plotColors = {};
    this.updateFiltersRules(this.clientId);
    this.$store.commit("setCurrentPage", Pages.MORE_DETAILS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
    this.mainListRefresher = setInterval(this.reRenderer, 15000);
    await this.loadTemplates(true);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuMoreDetails"),
      to: `/asset-more-details/${this.assetDbId}`,
      root: false,
    });
  },

  beforeDestroy() {
    if (this.mainListRefresher !== undefined) {
      clearInterval(this.reRenderer);
      this.mainListRefresher = undefined;
    }
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue, false);
    },
    selectedTemplate(newValue) {
      const template = this.findTemplate(newValue);

      if (template) {
        this.selected = [];

        this.$nextTick(() => {
          this.selected = template.selection
            .map((topic) => this.measures[topic]?.value)
            .filter((m) => m != undefined);
        });
      }
    },
    assetTopics: {
      deep: true,
      handler(newValue) {
        if (!newValue || newValue.length === 0) return;

        this.$store.commit("addTopicsToAsset", {
          box: this.asset.boxMacAddress,
          topics: newValue,
        });
      },
    },
    lastMeasure: {
      deep: true,
      handler(newValue) {
        const topic = `${newValue?.basePath}/${newValue?.msgPath}`;

        if (
          this.inRealtime &&
          newValue?.originId === this.asset?.boxMacAddress &&
          this.dataKeys.includes(topic)
        ) {
          const measureTs = moment.utc(newValue.ts);
          const normalizedTs = moment
            .utc(measureTs.format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss")
            .local()
            .toDate()
            .getTime();

          // Recalculate time window
          this.chartEnding = measureTs.local().toDate().getTime();
          this.chartStarting = measureTs
            .local()
            .subtract(this.minutesWindow, "minute")
            .toDate()
            .getTime();

          // Add to chart data
          // eslint-disable-next-line prettier/prettier
          this.$refs.lineChart.addToDataset(normalizedTs, newValue?.content, newValue?.msgPath);

          // Add to CSV file
          const timestamp = measureTs.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
          const rowIndex = this.exportData.findIndex(
            (r) => r.timestamp === timestamp
          );

          if (rowIndex === -1) {
            this.exportData.push({
              timestamp: timestamp,
              [`${newValue?.basePath}/${newValue?.msgPath}`]:
                Math.trunc(newValue?.content * 100) / 100,
            });
          } else {
            this.exportData[rowIndex][
              `${newValue?.basePath}/${newValue?.msgPath}`
            ] = Math.trunc(newValue?.content * 100) / 100;
          }
        }
      },
    },
    selected: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue == undefined) {
          this.$nextTick(() => (this.selected = []));
        }

        if (newValue.length <= this.maxSelection) {
          // Download only if there are changes
          if (newValue.length !== oldValue.length) {
            const topics = newValue
              .filter((v) => v.isPlottable())
              .map((v) => v.msgPath);

            if (!this.rangeIsInPast) {
              // Reset starting and ending date
              const difference =
                moment(this.dateRange.endDate).diff(
                  moment(this.dateRange.startDate)
                ) / 1000;
              this.dateRange.endDate = moment();
              // eslint-disable-next-line prettier/prettier
              this.dateRange.startDate = moment(this.dateRange.endDate).subtract(difference, "second");
            }

            this.getChartData(topics);
            this.plotSelected = topics;
          }
        } else {
          this.$nextTick(() => (this.selected = oldValue));
        }
      },
    },
    plotSelected: {
      deep: true,
      handler(newValue) {
        if (newValue == undefined) {
          this.$nextTick(() => (this.plotSelected = []));
        }
      },
    },
  },

  methods: {
    manageSearch(value) {
      this.searchTerm = value;
    },
    todayMinutes() {
      const today = moment();
      today.set({ second: 0, millisecond: 0 });
      return today;
    },
    disableDates(classes) {
      if (this.isRealTime && !classes.disabled) {
        classes.disabled = this.isRealTime;
      }

      return classes;
    },
    clearCache() {
      let time = undefined;

      this.selected = [];

      if (this.clearCacheType === "from") {
        time = moment
          .utc(
            // eslint-disable-next-line prettier/prettier
            `${this.cache.year}-${this.cache.month.length === 1 ? "0" : ""}${this.cache.month}-${this.cache.day}T23:59:59Z`
          )
          .format();
      }

      ApiRequests.clearAssetCache(
        this.assetDbId,
        time,
        () => {
          this.$store.commit("clearMeasure", this.asset.boxMacAddress);
          this.getSavedMeasures();
          this.cacheDialog = false;
        },
        (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
      );
    },
    async fetchAssetData() {
      this.asset = new AssetInfo(
        (await ApiRequests.getAsset(this.assetDbId, undefined, undefined)).data
      );

      this.assetId = this.asset.serial;
      this.asset.companyName = this.customersList.find(
        (c) => c.id === this.asset.companyId
      )?.name;
      this.asset.groupName = this.groupsList.find(
        (f) => f.id === this.asset.groupId
      )?.name;
    },
    updateFiltersRules(id, getMeasures = true) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          [
            `^(measures/@${this.asset.boxMacAddress}/*)`,
            ...this.signalRBaseRegexes,
          ],
          () => (getMeasures ? this.getSavedMeasures() : undefined),
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    getSavedMeasures() {
      const requestBody = new AssetDataRequestBody();

      requestBody.ids = [this.assetDbId];
      requestBody.paths = [];
      requestBody.includeMetadata = true;

      ApiRequests.getSavedMeasures(
        requestBody,
        undefined,
        (res) => {
          const requestedData = res.data[this.assetDbId];

          Object.keys(requestedData).forEach((k) => {
            this.$store.dispatch("saveMessage", requestedData[k]);
          });

          this.autoSelectHighlights();
        },
        (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
      );
    },
    autoSelectHighlights() {
      if (this.asset.userInterface?.measures?.highlights) {
        this.selected = this.filteredMeasures
          .filter((measure) =>
            this.asset.userInterface.measures.highlights.includes(
              measure.msgPath
            )
          )
          .slice(0, this.maxSelection);
      }
    },
    getChartData(data = undefined) {
      if (data === undefined && this.selected.length === 0) {
        this.chartData.length = 0;
        this.dataKeys.length = 0;
        this.$refs.lineChart.emptyRefs();

        return;
      }

      this.$refs.lineChart.loading = true;
      this.loadingChart = true;

      const requestBody = new AssetDataRequestBody();
      const starting = moment(this.dateRange.startDate);
      const ending = moment(this.dateRange.endDate);

      requestBody.ids = [this.assetDbId];
      requestBody.starting = starting.format();
      requestBody.ending = ending.format();
      requestBody.paths =
        data !== undefined
          ? data.map((m) => `measures/${m}`)
          : this.selected
              .filter((v) => v.isPlottable())
              .map((v) => `measures/${v.msgPath}`);

      this.dataKeys = requestBody.paths;

      ApiRequests.getSavedMeasures(
        requestBody,
        undefined,
        (res) => {
          this.chartData = res.data[this.assetDbId] ?? {};
          this.plotColors = {};
          this.exportData = [];

          this.dataKeys.forEach((key, index) => {
            this.plotColors[key] = this.colors[index];
          });

          if (this.dataKeys.length > 0) {
            const exportWorker = new Worker("./workers/exporter.js");

            exportWorker.onmessage = (e) => {
              this.exportData = e.data;
              exportWorker.terminate();
            };

            exportWorker.postMessage({
              chartData: this.chartData,
              dataKeys: this.dataKeys,
            });
          }
        },
        (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
      );
    },
    updateDatesRange(data) {
      data.clickApply();
      this.pickerDialog = false;
      this.inRealtime = this.isRealTime;

      const now = moment();

      // Time window for chart
      this.chartStarting = moment
        .utc(this.dateRange.startDate)
        .local()
        .toDate()
        .getTime();
      this.chartEnding = moment
        .utc(this.dateRange.endDate)
        .local()
        .toDate()
        .getTime();
      this.minutesWindow = moment(this.dateRange.endDate).diff(
        moment(this.dateRange.startDate),
        "minute"
      );
      this.rangeIsInPast = moment
        .utc(this.dateRange.endDate)
        .local()
        .isBefore(now, "minute");

      this.getChartData();
    },
    dateFormat(date) {
      if (date == undefined) {
        return "";
      }

      return moment(date).format(`${this.$t("tzLocale")} HH:mm`);
    },
    reRenderer() {
      this.mainListKey = !this.mainListKey;
    },
    getColor(timestamp) {
      const now = new Date().getTime();
      const delta = now - timestamp;
      const hour = 1000 * 60 * 60;

      if (delta >= hour * 24) {
        return " error--text font-weight-bold";
      }

      if (delta >= hour) {
        return " warning--text font-weight-bold";
      }

      return "";
    },
    csvName() {
      const format = "YYYY-MM-DD_HH.mm";
      // eslint-disable-next-line prettier/prettier
      const vName = this.asset.plate || this.asset.boxMacAddress || this.asset.serial;
      const rangeStart = moment(this.dateRange.startDate).format(format);
      const rangeEnd = this.inRealtime
        ? moment().format(format)
        : moment(this.dateRange.endDate).format(format);

      return `${vName}_export_${rangeStart}_${rangeEnd}.csv`;
    },
    isSelected(item) {
      return this.selected.findIndex((i) => i.msgPath === item.msgPath) >= 0;
    },
    selectRow(item) {
      let tmpSelected = [...this.selected];

      if (this.isSelected(item)) {
        tmpSelected = this.selected.filter((i) => i.msgPath !== item.msgPath);
      } else {
        tmpSelected.push(item);
      }

      this.selected = [...tmpSelected];
    },
    isExpanded(item) {
      return this.expanded.findIndex((i) => i.msgPath === item.msgPath) >= 0;
    },
    expandRow(item) {
      if (item.contentType === this.measureTypes.JSON) {
        if (this.isExpanded(item)) {
          this.expanded = this.expanded.filter(
            (i) => i.msgPath !== item.msgPath
          );

          return;
        }

        this.expanded.push(item);
      }
    },
    updateSelected(selection) {
      this.selected.forEach((m) => {
        const newValue = selection[m.msgPath];

        if (newValue !== undefined) {
          m.chartContent = newValue.data;
          m.chartTs = newValue.ts;
        }
      });
    },
    async loadTemplates(synchronous = true) {
      const callback = (res) => (this.availableTemplates = res.data);

      if (synchronous) {
        try {
          callback(await ApiRequests.getTemplates(undefined, undefined));
        } catch (err) {
          if (process.env.NODE_ENV === "development") console.error(err);
        }
      } else {
        ApiRequests.getTemplates(callback, (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
        );
      }
    },
    saveTemplate(update = false) {
      const existingTemplate = update
        ? this.findTemplate(this.selectedTemplate)
        : undefined;
      const template = {
        id: existingTemplate ? existingTemplate.id : undefined,
        name: update ? this.selectedTemplate : this.templateName,
        selection: this.selected.map((m) => m.msgPath),
      };

      ApiRequests.saveTemplate(
        template,
        existingTemplate != undefined,
        async () => {
          this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("saveSuccess"));
          await this.loadTemplates(true);
          if (!update) this.selectedTemplate = String(this.templateName);
          this.templateName = undefined;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("saveFailure"),
          });
        }
      );
    },
    deleteTemplate() {
      const template = this.findTemplate(this.selectedTemplate);

      if (template) {
        this.confirmCallback = () => {
          ApiRequests.deleteTemplate(
            template.id,
            () => {
              this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("deleteSuccess"));
              this.templateName = String(this.selectedTemplate);
              this.selectedTemplate = undefined;
              this.resetConfirmDelete();
              this.loadTemplates(false);
            },
            (err) => {
              if (process.env.NODE_ENV === "development") console.error(err);
              this.resetConfirmDelete();
              this.$bus.$emit(Messages.ERROR_MSG, {
                error: err?.response?.data?.error || err,
                description: this.$t("deleteFailure"),
              });
            }
          );
        };

        this.confirmDelete = true;
      }
    },
    findTemplate(name) {
      return this.availableTemplates.find((t) => t.name === name);
    },
    resetConfirmDelete() {
      this.confirmDelete = false;
      this.confirmCallback = () => {};
    },
    addToVisualAnalysisSession() {
      this.$store.commit("buildSession", {
        asset: this.asset,
        measures: this.selected.map((v) => v.msgPath),
      });
    },
  },
};
</script>
