import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import ApiRequests from "../utils/requests";

const i18nDefault = {
  locale: "en",
  fallbackLocale: "en",
  silentTranslationWarn: true,
  messages: {},
};
const request = new XMLHttpRequest();
const configs = [
  "VUE_APP_LOCALES",
  "VUE_APP_I18N_LOCALE",
  "VUE_APP_I18N_FALLBACK_LOCALE",
];
let fetchedData = [];
let fetchedLangs = [];

Vue.use(VueI18n);
request.open(
  "GET",
  `${ApiRequests.BACKEND_URL}settings?keys=${configs.join(",")}`,
  false
);
request.send(null);

fetchedData = JSON.parse(request.responseText);
fetchedLangs = fetchedData[configs[0]].split(",");

if (!fetchedLangs.includes(fetchedData[configs[2]])) {
  fetchedLangs.push(fetchedData[configs[2]]);
}

if (fetchedLangs.length > 0) {
  const messages = {};
  const ts = new Date().getTime();

  fetchedLangs.forEach(async (l) => {
    try {
      const res = await axios.get(`i18n/${l}.json?ts=${ts}`);
      messages[l] = res.data;
    } catch {
      /* Something went wrong */
    }
  });

  i18nDefault.locale = localStorage[configs[1]] || fetchedData[configs[1]];
  i18nDefault.fallbackLocale = fetchedData[configs[2]];
  i18nDefault.messages = messages;
}

export default new VueI18n(i18nDefault);
