<template>
  <div>
    <PieChart id="pie-chart" v-bind="chartSettings" />
  </div>
</template>

<style scoped>
#pie-chart {
  max-height: 100%;
  max-width: 100%;
}
</style>

<script>
import { PieChart } from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartJsPlugins from "../plugins/chart-plugins";
import { ref } from "@vue/composition-api";

export default {
  name: "Pie",

  components: { PieChart },

  props: {
    data: { type: Array, required: true },
    colors: { type: Object, required: true },
    dataType: { type: String, required: true },
    animationStyle: { type: String, required: false, default: "scale" },
    legendClickable: { type: Boolean, required: false, default: true },
  },

  data: () => ({
    chartSettings: {},
    chartData: {
      labels: ref([]),
      dataset: {
        data: ref([]),
        backgroundColor: ref([]),
      },
    },
  }),

  watch: {
    data: {
      deep: true,
      handler(newData) {
        if (newData.length === 0) return;

        this.emptyRefs();

        newData.forEach(({ category, value }) => {
          this.chartData.labels.value.push(category);
          this.chartData.dataset.data.value.push(value);
          this.chartData.dataset.backgroundColor.value.push(
            this.colors[category]
          );
        });
      },
    },
  },

  beforeMount() {
    this.chartSettings = {
      chartData: {
        labels: this.chartData.labels.value,
        datasets: [
          {
            data: this.chartData.dataset.data.value,
            backgroundColor: this.chartData.dataset.backgroundColor.value,
          },
        ],
      },
      plugins: [ChartJsPlugins.EMPTY_PIE, ChartDataLabels],
      options: {
        responsive: true,
        hoverOffset: 15,
        hoverBorderColor: "#FFFFFF00",
        radius: "95%",
        animation: {
          animateRotate: this.animationStyle.includes("rotate"),
          animateScale: this.animationStyle.includes("scale"),
        },
        plugins: {
          zoom: false,
          crosshair: false,
          legend: { position: "right" },
          tooltip: {
            callbacks: {
              label: (tooltipItem) =>
                // eslint-disable-next-line prettier/prettier
                ` ${tooltipItem.label}: ${tooltipItem.formattedValue} ${this.$t(this.dataType)}`,
            },
          },
          emptyPie: { radius: 95 }, // Percentage of max radius
          datalabels: {
            color: "#FFFFFF",
            backgroundColor: "#1B1B1B7F",
            font: { weight: "bold" },
            padding: { top: 4, bottom: 4, left: 8, right: 8 },
            borderRadius: 4,
            anchor: "end",
            align: "start",
            offset: 20,
            opacity: (item) =>
              item.dataset.data[item.dataIndex] === 0 ? 0 : 1,
          },
        },
      },
    };

    if (!this.legendClickable) {
      this.chartSettings.options.plugins.legend.onClick = () => {};
    }
  },

  methods: {
    emptyRefs() {
      this.chartData.labels.value.length = 0;
      this.chartData.dataset.data.value.length = 0;
      this.chartData.dataset.backgroundColor.value.length = 0;
    },
  },
};
</script>
