class EntityCode {
  _id = undefined;
  _type = undefined;
  _subType = undefined;
  _brand = undefined;
  _model = undefined;
  _version = undefined;

  constructor(ecJson = undefined) {
    if (ecJson != undefined) {
      this._id = ecJson.ID;
      this._type = ecJson.type;
      this._subType = ecJson.subType;
      this._brand = ecJson.brand;
      this._model = ecJson.model;
      this._version = ecJson.version;
    }
  }

  // Getters

  get id() {
    return this._id;
  }
  get type() {
    return this._type;
  }
  get subType() {
    return this._subType;
  }
  get brand() {
    return this._brand;
  }
  get model() {
    return this._model;
  }
  get version() {
    return this._version;
  }
}

export default EntityCode;
