<template>
  <div class="pa-4 fill-height grid">
    <h1>
      <v-row align="center">
        <v-col>
          {{ $t("menuGroups") }}
        </v-col>
        <v-col align="end">
          <template v-if="!currentUser.isOperator">
            <v-btn v-on:click="newGroupDialog = true" color="primary">
              {{ $t("btnNewGroup") }}
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </h1>

    <div class="table">
      <EntitiesTable
        v-bind:headers="headers"
        v-bind:sorting-fields="sortingFields"
        v-bind:items="groupsList"
        v-bind:defaultSort="sortingFields[0].value"
        v-bind:loading="loading"
        v-bind:actionsColumn="true"
        v-bind:actions="{
          edit: true,
          enableDisable: false,
          delete: currentUser.role < roleCodes.MANAGER,
        }"
        v-bind:bulkColumn="currentUser.role < roleCodes.MANAGER"
        v-on:table-bulk-delete="manageGroupBulk"
        v-on:table-click="handleClick"
        v-on:table-edit="manageGroup($event, 0)"
        v-on:table-delete="manageGroup($event, 1)"
        v-on:table-disable="/* Do nothing */"
        v-on:table-enable="/* Do nothing */"
        v-on:table-bulk-enable="/* Do nothing */"
        v-on:table-bulk-disable="/* Do nothing */"
      />
    </div>

    <v-dialog
      v-model="newGroupDialog"
      v-if="newGroupDialog"
      width="unset"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>{{ $t("btnNewGroup") }}</v-card-title>
        <v-card-text class="d-flex flex-column">
          <v-text-field
            v-model="group.name"
            v-bind:label="$t('groupName')"
            v-bind:rules="[(v) => !!v || $t('required')]"
            v-bind:outlined="outlinedPref"
            clearable
          />

          <div class="d-flex" style="gap: 1em">
            <v-text-field
              v-model="group.province"
              v-bind:label="$t('groupProvince')"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            />
            <v-text-field
              v-model="group.municipality"
              v-bind:label="$t('groupMunicipality')"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            />
          </div>

          <v-select
            v-model="group.type"
            v-bind:items="groupTypes"
            v-bind:label="$t('groupType')"
            v-bind:outlined="outlinedPref"
            clearable
            hide-details
          >
            <template v-slot:item="{ item }">
              <div>{{ $t(item) }}</div>
            </template>

            <template v-slot:selection="{ item }">
              <div>{{ $t(item) }}</div>
            </template>
          </v-select>

          <v-divider class="mt-4 mb-1" />

          <div
            class="caption font-weight-bold d-flex justify-space-between align-center"
          >
            {{ $t("groupCoords") }}
            <v-btn
              v-on:click="geocodingDialog = true"
              color="primary"
              text
              x-small
            >
              {{ $t("locationSearch") }}
            </v-btn>
          </div>

          <div class="d-flex" style="gap: 1em">
            <v-text-field
              v-model="group.lat"
              v-bind:label="$t('groupLat')"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            />
            <v-text-field
              v-model="group.lon"
              v-bind:label="$t('groupLon')"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            />
          </div>

          <v-divider class="mt-4 mb-1" />

          <div
            class="caption font-weight-bold d-flex justify-space-between align-center"
          >
            {{ $t("groupCustoms") }}
            <v-btn
              v-on:click="newKeyDialog = true"
              color="primary"
              text
              x-small
            >
              {{ $t("createCustomKey") }}
            </v-btn>
          </div>

          <div
            v-bind:key="'customs-new-list-' + refreshList"
            class="d-flex flex-column"
          >
            <v-text-field
              v-for="key in Object.keys(group.customs)"
              v-bind:key="group.name + '-new-' + key"
              v-model="group.customs[key]"
              v-bind:label="key"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            >
              <template v-slot:append-outer>
                <v-btn
                  v-bind:title="$t('btnDelete')"
                  v-on:click="
                    delete group.customs[key];
                    refreshList = !refreshList;
                  "
                  color="error"
                  icon
                  small
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="resetGroup" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validGroup"
            v-on:click="postGroup(false)"
            color="primary"
            text
          >
            {{ $t("btnCreate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editGroupDialog"
      v-if="editGroupDialog"
      width="unset"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>{{ $t("groupUpdate") }}</v-card-title>
        <v-card-text class="d-flex flex-column">
          <v-text-field
            v-model="group.name"
            v-bind:label="$t('groupName')"
            v-bind:rules="[(v) => !!v || $t('required')]"
            v-bind:outlined="outlinedPref"
            clearable
          />

          <div class="d-flex" style="gap: 1em">
            <v-text-field
              v-model="group.province"
              v-bind:label="$t('groupProvince')"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            />
            <v-text-field
              v-model="group.municipality"
              v-bind:label="$t('groupMunicipality')"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            />
          </div>

          <v-select
            v-model="group.type"
            v-bind:items="groupTypes"
            v-bind:label="$t('groupType')"
            v-bind:outlined="outlinedPref"
            clearable
            hide-details
          >
            <template v-slot:item="{ item }">
              <div>{{ $t(item) }}</div>
            </template>

            <template v-slot:selection="{ item }">
              <div>{{ $t(item) }}</div>
            </template>
          </v-select>

          <v-divider class="mt-4 mb-1" />

          <div
            class="caption font-weight-bold d-flex justify-space-between align-center"
          >
            {{ $t("groupCoords") }}
            <v-btn
              v-on:click="geocodingDialog = true"
              color="primary"
              text
              x-small
            >
              {{ $t("locationSearch") }}
            </v-btn>
          </div>

          <div class="d-flex" style="gap: 1em">
            <v-text-field
              v-model="group.lat"
              v-bind:label="$t('groupLat')"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            />
            <v-text-field
              v-model="group.lon"
              v-bind:label="$t('groupLon')"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            />
          </div>

          <v-divider class="mt-4 mb-1" />

          <div
            class="caption font-weight-bold d-flex justify-space-between align-center"
          >
            {{ $t("groupCustoms") }}
            <v-btn
              v-on:click="newKeyDialog = true"
              color="primary"
              text
              x-small
            >
              {{ $t("createCustomKey") }}
            </v-btn>
          </div>

          <div
            v-bind:key="'customs-edit-list-' + refreshList"
            class="d-flex flex-column"
          >
            <v-text-field
              v-for="key in Object.keys(group.customs)"
              v-bind:key="group.name + '-edit-' + key"
              v-model="group.customs[key]"
              v-bind:label="key"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            >
              <template v-slot:append-outer>
                <v-btn
                  v-bind:title="$t('btnDelete')"
                  v-on:click="
                    delete group.customs[key];
                    refreshList = !refreshList;
                  "
                  color="error"
                  icon
                  small
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="currentUser.role < roleCodes.MANAGER"
            v-on:click="manageGroup(group, 1)"
            color="error"
            text
          >
            {{ $t("btnDelete") }}
          </v-btn>
          <v-spacer class="mx-6" />
          <v-btn v-on:click="resetGroup" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validGroup"
            v-on:click="postGroup(true)"
            color="primary"
            text
          >
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="newKeyDialog"
      v-if="newKeyDialog"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("createCustomKey") }}</v-card-title>
        <v-card-text class="d-flex" style="gap: 2em">
          <v-text-field
            v-model="newKey"
            v-bind:label="$t('newCustomKey')"
            v-bind:rules="[(v) => !!v || $t('required')]"
            v-bind:outlined="outlinedPref"
            style="width: 45ch"
            clearable
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-on:click="
              newKeyDialog = false;
              newKey = undefined;
              refreshList = !refreshList;
            "
            color="error"
            text
          >
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="newKey == undefined"
            v-on:click="
              group.customs[newKey] = '';
              newKeyDialog = false;
              newKey = undefined;
              refreshList = !refreshList;
            "
            color="primary"
            text
          >
            {{ $t("btnCreate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      v-if="confirmDelete"
      v-bind:callback="confirmCallback"
      v-bind:content="$t('confirmGroupDelete')"
      v-bind:title="$t('dialogGroupDeleteConfirm')"
      v-on:cancel="confirmDelete = false"
    />

    <v-dialog
      v-model="geocodingDialog"
      v-if="geocodingDialog"
      width="unset"
      persistent
      scrollable
    >
      <Geocoding
        v-bind:init="{ lat: group.lat, lon: group.lon }"
        v-on:close="geocodingDialog = false"
        v-on:geocoded="useGeocodingCoords"
      />
    </v-dialog>

    <v-dialog v-model="updating" width="unset" persistent>
      <v-card>
        <v-progress-circular class="ma-4" color="primary" indeterminate />
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-rows: min-content 1fr;
  max-height: 100%;
  overflow: hidden;
}

.table {
  position: relative;
  overflow: hidden;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import Messages from "../utils/messages";
import GroupInfo from "../models/GroupInfo";
import ConfirmationDialog from "../components/ConfirmationDialog";
import EntitiesTable from "../components/EntitiesTable";
import UserInfo from "../models/UserInfo";
import Geocoding from "../components/Geocoding";

export default {
  name: "Groups",

  components: { ConfirmationDialog, EntitiesTable, Geocoding },

  data: () => ({
    confirmDelete: false,
    confirmCallback: () => {},
    newGroupDialog: false,
    group: new GroupInfo(),
    assetsCount: {},
    editGroupDialog: false,
    loading: false,
    updating: false,
    roleCodes: UserInfo.roles,
    groupTypes: GroupInfo.TYPES,
    refreshList: false,
    newKeyDialog: false,
    newKey: undefined,
    geocodingDialog: false,
  }),

  computed: {
    validGroup() {
      return !!this.group.name;
    },
    sortingFields() {
      return [
        { text: this.$t("groupName"), value: "name" },
        { text: this.$t("groupType"), value: "type" },
        { text: this.$t("groupAssets"), value: "assets" },
        { text: this.$t("groupModified"), value: "modified" },
        { text: this.$t("groupProvince"), value: "province" },
        { text: this.$t("groupMunicipality"), value: "municipality" },
      ];
    },
    headers() {
      return [
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("groupName"), align: "start", sortable: false, value: "name" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("groupType"), align: "start", sortable: false, value: "type", width: "10%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("groupFunctions"), align: "start", sortable: false, value: "functions", width: "18%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetAlarms"), align: "center", sortable: false, value: "alarmsCount", width: "10%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("groupAssets"), align: "center", sortable: false, value: "assets", width: "5%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("groupModified"), align: "end", sortable: false, value: "modified", width: "10%" },
        // eslint-disable-next-line prettier/prettier
        { text: "", align: "end", sortable: false, value: "actions", width: "5%" },
      ];
    },
  },

  beforeMount() {
    this.updateFiltersRules(this.clientId);
    this.$store.commit("setCurrentPage", Pages.GROUPS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuGroups"),
      to: "/groups",
      root: true,
    });
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    postGroup(editing = false) {
      if (!editing) {
        ApiRequests.createGroup(
          this.group.getCreateUpdateGroupBody(),
          () => {
            this.$bus.$emit(
              Messages.SUCCESS_MSG,
              this.$t("groupCreateSuccess")
            );
            this.resetGroup();
            this.fetchAllData();
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("groupCreateFailure"),
            });
          }
        );
      } else {
        ApiRequests.updateGroup(
          this.group.id,
          this.group.getCreateUpdateGroupBody(),
          () => {
            this.$bus.$emit(
              Messages.SUCCESS_MSG,
              this.$t("groupUpdateSuccess")
            );
            this.resetGroup();
            this.fetchAllData();
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("groupUpdateFailure"),
            });
          }
        );
      }
    },
    manageGroupBulk(selected) {
      const callback = (res, text1, text2, text3) => {
        const total = selected.length;
        const sent = res.reduce(
          (acc, r) => acc + (r.status === "fulfilled" ? 1 : 0),
          0
        );

        if (total > sent) {
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: text3.replace(/%s%/g, sent).replace(/%t%/g, total),
            description: text2,
          });
        } else {
          this.$bus.$emit(Messages.SUCCESS_MSG, text1);
        }

        this.$bus.$emit(Messages.RESET_TABLE_SELECTED);
        this.fetchAllData();
        this.updating = false;
      };

      this.confirmDelete = true;
      this.confirmCallback = () => {
        this.updating = true;
        Promise.allSettled(
          selected.map((f) => {
            return ApiRequests.deleteGroup(f.id, undefined, undefined);
          })
        ).then((res) => {
          this.confirmCallback = () => {};
          callback(
            res,
            this.$t("groupDeleteSuccess"),
            this.$t("groupDeleteFailure"),
            "Error: %s% of %t% groups deleted"
          );
        });
      };
    },
    manageGroup(group, mode) {
      switch (mode) {
        case 0:
          Object.assign(this.group, group);
          this.editGroupDialog = true;
          break;
        default:
          this.confirmDelete = true;
          this.confirmCallback = () =>
            ApiRequests.deleteGroup(
              group.id,
              () => {
                this.editGroupDialog = false;
                this.confirmCallback = () => {};
                this.$bus.$emit(
                  Messages.SUCCESS_MSG,
                  this.$t("groupDeleteSuccess")
                );
                this.fetchAllData();
              },
              (err) => {
                if (process.env.NODE_ENV === "development") console.error(err);
                this.confirmCallback = () => {};
                this.$bus.$emit(Messages.ERROR_MSG, {
                  error: err?.response?.data?.error || err,
                  description: this.$t("groupDeleteFailure"),
                });
              }
            );
          break;
      }
    },
    useGeocodingCoords(coords) {
      this.group.lat = coords.lat;
      this.group.lon = coords.lon;
      this.geocodingDialog = false;
    },
    resetGroup() {
      this.newGroupDialog = false;
      this.editGroupDialog = false;
      this.group = new GroupInfo();
    },
    handleClick(value) {
      if (value.id != undefined) {
        this.$router.push({ path: `/groups-details/${value.id}` });
      }
    },
  },
};
</script>
