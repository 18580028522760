<template>
  <div class="pa-4 fill-height" grid>
    <h1>{{ $t("menuAnalysis") }}</h1>

    <div>
      <v-tabs v-model="tabId">
        <v-tab>{{ $t("menuAnomaly") }}</v-tab>
      </v-tabs>

      <v-tabs-items class="tab-full-height" v-model="tabId">
        <v-tab-item class="tab-full-height">
          <v-card class="tab-full-height" flat>
            <v-card-title>
              {{ $t("detectedAnomalies") }}
            </v-card-title>
            <v-card-subtitle> 1: 'GE870YM' - bmsVoltage </v-card-subtitle>
            <v-card-text class="tab-full-height mt-n16">
              <iframe
                id="i-chart"
                src="anomaly/index.html"
                style="width: 100%; height: 100%; border: none"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<style scoped>
.tab-full-height {
  height: 100%;
}

div[grid] {
  display: grid;
  grid-template-rows: min-content 1fr;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";

export default {
  name: "Analysis",

  data: () => ({
    tabId: 0,
  }),

  beforeMount() {
    this.updateFiltersRules(this.clientId);

    this.$store.commit("setCurrentPage", Pages.ANALYSIS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuAnalysis"),
      to: "/analysis",
      root: true,
    });
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
  },
};
</script>
