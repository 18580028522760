var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"items-per-page":-1,"group-by":"asset","height":"100%","hide-default-footer":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [(group)?_c('td',{staticClass:"indigo lighten-5",attrs:{"colspan":"7"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('span',[_vm._v(_vm._s(group))]),_c('span',{staticClass:"text-lowercase"},[_vm._v(" ("+_vm._s(items.length)+" "+_vm._s(_vm.$t("mainParameters"))+") ")])],1):_vm._e()]}},{key:"item.A",fn:function(ref){
var item = ref.item;
return [(item.A != undefined)?_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('span',[_vm._v(" "+_vm._s(item.name === "Timestamp" ? _vm.getParsedTimestamp(item.A) : item.A.val.toFixed(item.A.format || _vm.defaultDecimals) + " " + item.A.unit)+" ")]),(item.name !== 'Timestamp')?_c('span',{staticClass:"caption grey--text ms-2"},[_vm._v(" ("+_vm._s(_vm.getParsedTimestamp(item.A.ts))+") ")]):_vm._e()]):_vm._e()]}},{key:"item.B",fn:function(ref){
var item = ref.item;
return [(item.B != undefined)?_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('span',[_vm._v(" "+_vm._s(item.name === "Timestamp" ? _vm.getParsedTimestamp(item.B) : item.B.val.toFixed(item.B.format || _vm.defaultDecimals) + " " + item.B.unit)+" ")]),(item.name !== 'Timestamp')?_c('span',{staticClass:"caption grey--text ms-2"},[_vm._v(" ("+_vm._s(_vm.getParsedTimestamp(item.B.ts))+") ")]):_vm._e()]):_vm._e()]}},{key:"item.diff",fn:function(ref){
var item = ref.item;
return [(item.diff != undefined)?_c('div',[_vm._v(" "+_vm._s(item.name === "Timestamp" ? item.diff : item.diff.toFixed(item.B.format || _vm.defaultDecimals) + " " + item.B.unit)+" ")]):_vm._e()]}},{key:"item.max",fn:function(ref){
var item = ref.item;
return [(item.max != undefined)?_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('span',[_vm._v(" "+_vm._s(item.name === "Timestamp" ? "" : item.max.val.toFixed(item.B.format || _vm.defaultDecimals) + " " + item.B.unit)+" ")]),(item.name !== 'Timestamp')?_c('span',{staticClass:"caption grey--text ms-2"},[_vm._v(" ("+_vm._s(_vm.getParsedTimestamp(item.max.ts))+") ")]):_vm._e()]):_vm._e()]}},{key:"item.min",fn:function(ref){
var item = ref.item;
return [(item.min != undefined)?_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('span',[_vm._v(" "+_vm._s(item.name === "Timestamp" ? "" : item.min.val.toFixed(item.A.format || _vm.defaultDecimals) + " " + item.A.unit)+" ")]),(item.name !== 'Timestamp')?_c('span',{staticClass:"caption grey--text ms-2"},[_vm._v(" ("+_vm._s(_vm.getParsedTimestamp(item.min.ts))+") ")]):_vm._e()]):_vm._e()]}},{key:"item.maxDiff",fn:function(ref){
var item = ref.item;
return [(item.maxDiff != undefined)?_c('div',[_vm._v(" "+_vm._s(item.name === "Timestamp" ? "" : item.maxDiff.toFixed(item.B.format || _vm.defaultDecimals) + " " + item.B.unit)+" ")]):_vm._e()]}}])})],1),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-alert',{attrs:{"type":"warning","icon":"mdi-alert-outline","dense":"","text":""}},[_vm._v(" "+_vm._s(_vm.$t("cursorsComparisonWarning"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }