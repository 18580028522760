var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 fill-height grid"},[_c('h1',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("menuRules"))+" ")]),_c('v-col',{attrs:{"align":"end"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.newRuleDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("btnNewRule"))+" ")])],1)],1)],1),_c('div',{staticClass:"table"},[_c('EntitiesTable',{attrs:{"headers":_vm.headers,"sorting-fields":_vm.sortingFields,"items":_vm.allRules,"defaultSort":_vm.sortingFields[0].value,"loading":_vm.loading,"actionsColumn":true,"actions":{ edit: true, enableDisable: true, delete: true },"bulkColumn":true},on:{"table-click":_vm.handleClick,"table-edit":_vm.handleClick,"table-delete":function($event){_vm.deleteRule(_vm.find($event))},"table-disable":function($event){_vm.handleEnable(_vm.find($event), false)},"table-enable":function($event){_vm.handleEnable(_vm.find($event), true)},"table-bulk-delete":function($event){return _vm.manageRuleBulk($event, 0)},"table-bulk-enable":function($event){return _vm.manageRuleBulk($event, 1)},"table-bulk-disable":function($event){return _vm.manageRuleBulk($event, 2)}}})],1),(_vm.newRuleDialog)?_c('v-dialog',{attrs:{"width":"min(60%, 92vw)","persistent":"","scrollable":""},model:{value:(_vm.newRuleDialog),callback:function ($$v) {_vm.newRuleDialog=$$v},expression:"newRuleDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.edit ? _vm.$t("btnEditRule") : _vm.$t("btnNewRule"))+" ")]),_c('v-card-text',[_c('v-form',{staticClass:"input-container"},[_c('div',{staticClass:"mt-1",attrs:{"row":""}},[_c('v-text-field',{attrs:{"outlined":_vm.outlinedPref,"label":_vm.$t('ruleName'),"rules":[
                function (v) { return !!v || _vm.$t('required'); },
                function (v) { return _vm.allRules.findIndex(function (r) { return r.name === v; }) === -1 ||
                  _vm.edit ||
                  _vm.$t('duplicateRule'); } ],"clearable":"","required":""},model:{value:(_vm.newRule.name),callback:function ($$v) {_vm.$set(_vm.newRule, "name", $$v)},expression:"newRule.name"}}),_c('v-switch',{staticClass:"ms-6",attrs:{"label":_vm.newRule.enabled ? _vm.$t('userEnabled') : _vm.$t('userDisabled'),"inset":""},model:{value:(_vm.newRule.enabled),callback:function ($$v) {_vm.$set(_vm.newRule, "enabled", $$v)},expression:"newRule.enabled"}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c('v-textarea',{staticClass:"mb-1",staticStyle:{"max-width":"100%"},attrs:{"label":_vm.$t('ruleDescription'),"rules":[function (v) { return !!v || _vm.$t('required'); }],"rows":"2","hide-details":"","no-resize":"","outlined":"","required":""},model:{value:(_vm.newRule.description),callback:function ($$v) {_vm.$set(_vm.newRule, "description", $$v)},expression:"newRule.description"}}),_c('div',{staticStyle:{"gap":"2ch","align-items":"center"},attrs:{"row":""}},[_c('v-combobox',{staticStyle:{"flex-grow":"1"},attrs:{"label":_vm.$t('alarmsEmails'),"items":_vm.emailSuggestions || [],"return-object":false,"disabled":!_vm.newRule.channels.includes('email'),"outlined":_vm.outlinedPref,"append-icon":"","hide-details":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"close":"","small":""},on:{"click:close":function($event){_vm.newRule.addressees = _vm.newRule.addressees.filter(
                        function (i) { return i != (item + ":email"); }
                      )}}},[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"item",fn:function(ref){
                      var item = ref.item;
                      var attrs = ref.attrs;
                      var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){_vm.newRule.addressees.includes((item + ":email"))
                        ? (_vm.newRule.addressees = _vm.newRule.addressees.filter(
                            function (i) { return i != (item + ":email"); }
                          ))
                        : _vm.newRule.addressees.push(item)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"ripple":false}})],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(item)+" ")])]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,3042351607),model:{value:(_vm.addresseesEmails),callback:function ($$v) {_vm.addresseesEmails=$$v},expression:"addresseesEmails"}}),_c('v-combobox',{staticStyle:{"flex-grow":"1"},attrs:{"label":_vm.$t('alarmsPhones'),"items":(_vm.smsSuggestions || []).concat( (_vm.telegramSuggestions || []) ),"return-object":false,"disabled":!_vm.newRule.channels.includes('sms') &&
                  !_vm.newRule.channels.includes('telegram'),"outlined":_vm.outlinedPref,"append-icon":"","hide-details":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"close":"","small":""},on:{"click:close":function($event){_vm.newRule.addressees = _vm.newRule.addressees.filter(
                        function (i) { return i != (item + ":phone"); }
                      )}}},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,2264292686),model:{value:(_vm.addresseesPhones),callback:function ($$v) {_vm.addresseesPhones=$$v},expression:"addresseesPhones"}}),_c('v-select',{staticStyle:{"flex-grow":"0","max-width":"25%"},attrs:{"items":_vm.channels,"label":_vm.$t('notificationChannel'),"outlined":_vm.outlinedPref,"hide-details":"","multiple":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                      var item = ref.item;
                      var attrs = ref.attrs;
                      var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":!_vm.pluginsStatus(item),"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var active = ref.active;
return [_c('v-list-item-action',{staticClass:"d-flex align-center"},[_c('v-checkbox',{attrs:{"input-value":active,"ripple":false,"disabled":!_vm.pluginsStatus(item)}})],1),_c('v-list-item-content',[_vm._v(_vm._s(_vm.$t(item)))])]}}],null,true)},'v-list-item',attrs,false),on))]}},{key:"selection",fn:function(ref){
                      var item = ref.item;
                      var index = ref.index;
return [_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.$t(item)))]),(index < _vm.newRule.channels.length - 1)?_c('span',{staticClass:"me-1"},[_vm._v(" ; ")]):_vm._e()])]}}],null,false,617333603),model:{value:(_vm.newRule.channels),callback:function ($$v) {_vm.$set(_vm.newRule, "channels", $$v)},expression:"newRule.channels"}})],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"mt-0 vertical-center",attrs:{"row":""}},[_c('v-combobox',{attrs:{"items":_vm.eventGroups,"search-input":_vm.newRule.searchGroup,"label":_vm.$t('ruleGroup'),"rules":[function (v) { return !!v || _vm.$t('required'); }],"outlined":_vm.outlinedPref,"required":""},on:{"update:searchInput":function($event){return _vm.$set(_vm.newRule, "searchGroup", $event)},"update:search-input":function($event){return _vm.$set(_vm.newRule, "searchGroup", $event)}},scopedSlots:_vm._u([(!!_vm.newRule.searchGroup)?{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('comboBoxNoData').replace(
                          '%s%',
                          _vm.newRule.searchGroup
                        )
                      )}})],1)],1)]},proxy:true}:null],null,true),model:{value:(_vm.newRule.group),callback:function ($$v) {_vm.$set(_vm.newRule, "group", $$v)},expression:"newRule.group"}}),_c('span',[_vm._v("/")]),_c('v-text-field',{attrs:{"label":_vm.$t('ruleCode'),"outlined":_vm.outlinedPref,"readonly":"","required":""},model:{value:(_vm.newRule.code),callback:function ($$v) {_vm.$set(_vm.newRule, "code", $$v)},expression:"newRule.code"}}),_c('v-select',{staticClass:"small-input",attrs:{"outlined":_vm.outlinedPref,"items":_vm.types,"label":_vm.$t('ruleType'),"rules":[function (v) { return !!v || _vm.$t('required'); }],"required":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$t(item)))])]}},{key:"selection",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$t(item)))])]}}],null,false,3246045404),model:{value:(_vm.newRule.type),callback:function ($$v) {_vm.$set(_vm.newRule, "type", $$v)},expression:"newRule.type"}}),_c('v-select',{staticClass:"small-input",attrs:{"outlined":_vm.outlinedPref,"items":_vm.severities,"label":_vm.$t('ruleSeverity'),"rules":[function (v) { return !!v || _vm.$t('required'); }],"required":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                      var item = ref.item;
return [_c('div',{attrs:{"row":""}},[_c('v-icon',{attrs:{"color":_vm.getSeverityColor(item)}},[_vm._v(" mdi-"+_vm._s(_vm.getSeverityIcon(item))+" ")]),_c('div',{attrs:{"col":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(item)))]),(item === _vm.constSevs.TRACE)?_c('span',{staticClass:"caption primary--text text--lighten-2"},[_vm._v(" "+_vm._s(_vm.$t("noShowSeverity"))+" ")]):_vm._e()])],1)]}},{key:"selection",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$t(item)))])]}}],null,false,1064798730),model:{value:(_vm.newRule.severity),callback:function ($$v) {_vm.$set(_vm.newRule, "severity", $$v)},expression:"newRule.severity"}})],1),_c('div',{staticClass:"vertical-center",attrs:{"row":""}},[_c('v-select',{staticStyle:{"width":"45%"},attrs:{"items":_vm.filteredEntityCodes,"label":_vm.$t('customerEntityCodes'),"outlined":_vm.outlinedPref,"item-value":"id","clearable":"","multiple":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-select',{attrs:{"items":_vm.allTypes,"label":_vm.$t('assetType'),"clearable":""},model:{value:(_vm.ecFilter),callback:function ($$v) {_vm.ecFilter=$$v},expression:"ecFilter"}})],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
                      var item = ref.item;
                      var index = ref.index;
return [_c('ItemSelection',{attrs:{"index":index,"item":item,"array-length":_vm.newRule.assetsEntityCodes.length,"shown-items":1,"mode":"entityCode"}})]}},{key:"item",fn:function(ref){
                      var active = ref.active;
                      var item = ref.item;
                      var attrs = ref.attrs;
                      var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"my-0",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var active = ref.active;
return [_c('AssetItem',{attrs:{"active":active,"item":item,"mode":"entityCode"}})]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,963387617),model:{value:(_vm.newRule.assetsEntityCodes),callback:function ($$v) {_vm.$set(_vm.newRule, "assetsEntityCodes", $$v)},expression:"newRule.assetsEntityCodes"}}),_c('span',{staticClass:"mx-3"},[_vm._v("|")]),_c('v-select',{staticStyle:{"width":"60%"},attrs:{"items":_vm.filteredAssetsIds,"label":_vm.$t('customerAssets'),"outlined":_vm.outlinedPref,"item-value":"id","clearable":"","multiple":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-select',{attrs:{"items":_vm.allTypes,"label":_vm.$t('assetType'),"clearable":""},model:{value:(_vm.aIdFilter),callback:function ($$v) {_vm.aIdFilter=$$v},expression:"aIdFilter"}})],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
                      var item = ref.item;
                      var index = ref.index;
return [_c('ItemSelection',{attrs:{"index":index,"item":item,"array-length":_vm.newRule.assetsIds.length,"shown-items":1,"mode":"asset"}})]}},{key:"item",fn:function(ref){
                      var active = ref.active;
                      var item = ref.item;
                      var attrs = ref.attrs;
                      var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"my-0",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var active = ref.active;
return [_c('AssetItem',{attrs:{"active":active,"item":item,"mode":"asset"}})]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,3611917680),model:{value:(_vm.newRule.assetsIds),callback:function ($$v) {_vm.$set(_vm.newRule, "assetsIds", $$v)},expression:"newRule.assetsIds"}}),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.selectDeselectAll}},[_vm._v(" "+_vm._s(_vm.newRule.assetsIds.length != _vm.pairedAssetsList.length ? _vm.$t("btnSelectAll") : _vm.$t("btnDeselectAll"))+" ")])],1),_c('h4',[_vm._v(_vm._s(_vm.$t("ruleBoolean")))]),_c('Comparison',{attrs:{"topics":_vm.filteredAvailableTopics},on:{"valid":function($event){_vm.ruleIsValid = $event}},model:{value:(_vm.newRule.structure),callback:function ($$v) {_vm.$set(_vm.newRule, "structure", $$v)},expression:"newRule.structure"}})],1)],1),_c('v-card-actions',[(_vm.edit)?_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.deleteRule(_vm.find(_vm.newRule))}}},[_vm._v(" "+_vm._s(_vm.$t("btnDelete"))+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.resetRule(_vm.edit)}}},[_vm._v(" "+_vm._s(_vm.$t("btnCancel"))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.validRule,"color":"primary","text":""},on:{"click":function($event){return _vm.postRule()}}},[_vm._v(" "+_vm._s(_vm.edit ? _vm.$t("btnSave") : _vm.$t("btnCreate"))+" ")])],1)],1)],1):_vm._e(),(_vm.confirmDelete)?_c('ConfirmationDialog',{attrs:{"callback":_vm.confirmCallback || _vm.deleteCallback,"content":_vm.$t('confirmRuleDelete'),"title":_vm.$t('dialogRuleDeleteConfirm')},on:{"cancel":function($event){_vm.confirmDelete = false}}}):_vm._e(),_c('v-dialog',{attrs:{"width":"unset","persistent":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',[_c('v-progress-circular',{staticClass:"ma-4",attrs:{"color":"primary","indeterminate":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }