import Vue from "vue";
import UserInfo from "../models/UserInfo";
import CustomerInfo from "../models/CustomerInfo";
import ApiRequests from "../utils/requests";

const minutes = 30;

const sessionVuexModule = {
  state: {
    clientId: undefined,
    currentUser: undefined,
    currentToken: {},
    currentCompany: undefined,
    loggedIn: false,
    currentPage: 0,
    currentCustomPage: -1,
    dashboardTab: 0,
    pages: [],
    buildedSession: undefined,
    buildedSessionCount: 0,
    sessionRefresher: undefined,
    pluginsStatus: {
      email: false,
      sms: false,
      telegram: false,
    },
    channelsSuggestions: {
      email: [],
      sms: [],
      telegram: [],
    },
  },

  mutations: {
    setEmailStatus: (state, active) =>
      Vue.set(state.pluginsStatus, "email", active),
    setEmailSuggestions: (state, s) =>
      Vue.set(state.channelsSuggestions, "email", s),
    setSmsStatus: (state, active) =>
      Vue.set(state.pluginsStatus, "sms", active),
    setSmsSuggestions: (state, s) =>
      Vue.set(state.channelsSuggestions, "sms", s),
    setTelegramStatus: (state, active) =>
      Vue.set(state.pluginsStatus, "telegram", active),
    setTelegramSuggestions: (state, s) =>
      Vue.set(state.channelsSuggestions, "telegram", s),
    setDashboardTab: (state, tab) => (state.dashboardTab = tab),
    setCurrentUser: (state, user) => (state.currentUser = new UserInfo(user)),
    setCurrentToken: (state, token) => (state.currentToken = token),
    setCurrentPage: (state, page) => (state.currentPage = page),
    setCustomCurrentPage: (state, page) => (state.currentCustomPage = page),
    setClientId: (state, id) => (state.clientId = id),
    setCurrentCompany: (state, customer) =>
      (state.currentCompany = new CustomerInfo(customer)),
    setLogged: (state, logged) => {
      state.loggedIn = logged;

      // Maintain the session awake
      if (state.loggedIn) {
        if (state.sessionRefresher !== undefined) return;

        state.sessionRefresher = setInterval(
          () =>
            ApiRequests.userCurrent(
              () => {},
              () => {}
            ),
          1000 * 60 * minutes
        );
      } else {
        if (state.sessionRefresher === undefined) return;

        clearInterval(state.sessionRefresher);
        state.sessionRefresher = undefined;
      }
    },
    clearSessionRefresh: (state) => {
      if (state.sessionRefresher === undefined) return;

      clearInterval(state.sessionRefresher);
      state.sessionRefresher = undefined;
    },
    clearBuildSession: (state) => {
      state.buildedSession = undefined;
      state.buildedSessionCount = 0;
    },
    addPage: (state, { text, to, root = true }) => {
      if (root) {
        state.pages = [];
      }

      const pageIndex = state.pages.findIndex((p) => p.text === text);

      if (pageIndex > 0) {
        // Page already in the list
        state.pages[pageIndex].disabled = true;
        state.pages.splice(pageIndex + 1);
      } else {
        // New Page
        if (state.pages.length > 0) {
          state.pages[state.pages.length - 1].disabled = false;
        }
        state.pages.push({ text, disabled: true, to, replace: true });
      }
    },
    buildSession: (state, { asset, measures }) => {
      if (state.buildedSession === undefined) {
        state.buildedSession = {};
      }

      state.buildedSession[asset.id] = JSON.stringify({
        ...asset.toJson(),
        children: measures,
      });

      state.buildedSessionCount = Object.keys(state.buildedSession).length;
    },
  },

  getters: {
    clientId: (state) => state.clientId,
    currentPage: (state) => state.currentPage,
    currentCustomPage: (state) => state.currentCustomPage,
    loggedIn: (state) => state.loggedIn,
    currentUser: (state) => state.currentUser,
    currentToken: (state) => state.currentToken,
    currentCompany: (state) => state.currentCompany,
    currentVersion: () => process.env.VUE_APP_VERSION,
    pages: (state) => state.pages,
    dashboardTab: (state) => state.dashboardTab,
    buildedSession: (state) => state.buildedSession,
    buildedSessionCount: (state) => state.buildedSessionCount,
    emailActive: (state) => state.pluginsStatus.email,
    emailSuggestions: (state) => state.channelsSuggestions.email,
    smsActive: (state) => state.pluginsStatus.sms,
    smsSuggestions: (state) => state.channelsSuggestions.sms,
    telegramActive: (state) => state.pluginsStatus.telegram,
    telegramSuggestions: (state) => state.channelsSuggestions.telegram,
    pluginsStatus: (state) => (type) => state.pluginsStatus[type],
  },
};

export default sessionVuexModule;
