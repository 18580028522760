<template>
  <div class="chart-container">
    <div grid>
      <div class="d-flex align-center mb-2" style="gap: 0.6rem" toolbar>
        <v-card class="d-flex align-center py-1 px-3" flat outlined>
          <div class="me-4 caption">{{ $t("verticalCursor") }}</div>
          <v-btn-toggle v-model="placeCursor" dense>
            <v-btn
              v-for="cursor in Object.keys(cursorsValues)"
              v-bind:key="'cursor-' + cursor"
              v-bind:color="placeCursor === cursor ? cursorsColors[cursor] : ''"
              v-bind:disabled="
                filter.length === 0 || cursorDirection === constants.HORIZONTAL
              "
              v-bind:class="{ 'white--text': placeCursor === cursor }"
              v-bind:value="cursor"
              small
            >
              {{ cursor }}
            </v-btn>
          </v-btn-toggle>
          <v-divider class="mx-3" vertical />
          <v-btn
            v-bind:disabled="options.value.plugins.annotation == undefined"
            v-bind:title="$t('clearCursors')"
            v-on:click="removeCursors"
            color="error"
            icon
            small
          >
            <v-icon small>mdi-delete-outline</v-icon>
          </v-btn>
        </v-card>

        <div
          v-on:click="selectionDialog = cursorsValues.A || cursorsValues.B"
          class="d-flex align-center"
          style="gap: 0.6rem"
        >
          <v-card
            v-for="cursor in Object.keys(cursorsValues)"
            v-bind:key="'cursor-value-' + cursor"
            class="d-flex align-center py-1 px-3"
            flat
            outlined
          >
            <span
              class="body-2 font-weight-bold"
              v-bind:style="'color: ' + cursorsColors[cursor]"
            >
              {{ cursor }}
            </span>
            <template v-if="cursorsValues[cursor]">
              <span class="mx-3">
                {{
                  getParsedTimestamp(
                    getFromCursor(cursorsValues[cursor], "Timestamp")
                  )
                }}
              </span>
              <v-btn
                v-bind:title="
                  $t('btnDelete') +
                  ' ' +
                  $t('cursor').toLowerCase() +
                  ' ' +
                  cursor
                "
                v-on:click.stop="clearCursor(cursor)"
                color="error"
                icon
                x-small
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-card>

          <v-card
            v-if="cursorsValues.A && cursorsValues.B"
            class="d-flex align-center py-1 px-3"
            style="cursor: pointer"
            flat
            outlined
          >
            <span class="body-2 font-weight-bold">&Delta;X</span>
            <span class="ms-3">
              {{
                getDeltaTimestamp(
                  getFromCursor(cursorsValues.B, "Timestamp"),
                  getFromCursor(cursorsValues.A, "Timestamp")
                )
              }}
            </span>
            <template
              v-if="
                pinned != undefined &&
                cursorsValues.A &&
                cursorsValues.B &&
                getFromCursor(cursorsValues.B, pinned) != undefined &&
                getFromCursor(cursorsValues.A, pinned) != undefined
              "
            >
              <span v-bind:title="$t('cursorsComparisonWarning')">
                <v-divider class="mx-2" vertical />

                <span class="body-2 font-weight-bold">&Delta;Y</span>
                <span class="body-2 ms-1">({{ pinned }})</span>

                <span class="ms-3">
                  {{
                    getDifference(
                      getFromCursor(cursorsValues.B, pinned),
                      getFromCursor(cursorsValues.A, pinned)
                    )
                  }}
                </span>
              </span>
            </template>
          </v-card>
        </div>
      </div>
      <div class="pa-2" chart>
        <LineChart
          id="a-line-chart"
          ref="advancedLineChart"
          v-bind:chartData="chartDataSettings"
          v-bind:plugins="plugins.value"
          v-bind:options="options.value"
          v-bind:key="'multiline-advanced-chart-' + update"
        />
      </div>
      <div finder>
        <ChartFinder
          v-bind:chart-data="thumbDataSettings"
          v-bind:min-ts="minTs"
          v-bind:max-ts="maxTs"
          v-bind:min-ts-zoom="minTsZm"
          v-bind:max-ts-zoom="maxTsZm"
        />
      </div>
    </div>

    <div v-if="loading" class="loader">
      <v-progress-circular color="primary" indeterminate />
    </div>

    <v-dialog v-model="selectionDialog" width="min(70%, 92vw)" scrollable>
      <CursorsComparison
        v-if="selectionDialog"
        v-bind:cursors-values="cursorsValues"
        v-bind:maxmin-values="maxMinCursors"
        v-bind:default-decimals="defaultDecimals"
      />
    </v-dialog>
  </div>
</template>

<style scoped>
div[grid] {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  grid-template-areas:
    "toolbar"
    "chart"
    "finder";
}

div[toolbar] {
  grid-area: toolbar;
}

div[chart] {
  grid-area: chart;
}

div[finder] {
  grid-area: finder;
}

.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(245, 245, 245, 0.6);
}

#a-line-chart {
  height: 100%;
  width: 100%;
}
</style>

<script>
import Vue from "vue";
import { Interaction } from "chart.js";
import { CrosshairPlugin, Interpolate } from "chartjs-plugin-crosshair";
import { LineChart } from "vue-chart-3";
import { ref } from "@vue/composition-api";
import CursorsComparison from "./CursorsComparison";
import ChartFinder from "./ChartFinder";
import ChartJsPlugins from "../plugins/chart-plugins";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment";

const HORIZONTAL = "horizontal";
const VERTICAL = "vertical";

export default {
  name: "AdvancedChart",

  components: { LineChart, CursorsComparison, ChartFinder },

  props: {
    chartData: { type: Object, required: false, default: () => {} },
    topicPrefix: { type: String, required: false, default: "" },
    colors: { type: Object, required: false },
    filter: { type: Array, required: false, default: undefined },
    minTimestamp: { type: Number, required: false, default: undefined },
    maxTimestamp: { type: Number, required: false, default: undefined },
    startAtZero: { type: Boolean, required: false, default: true },
    pinned: { type: String, required: false },
    timePartitions: { type: Array, required: false },
    tagChanges: { type: Array, required: false },
    dataGaps: { type: Boolean, required: false, default: false },
  },

  data: () => ({
    constants: { HORIZONTAL, VERTICAL },
    update: false,
    loading: false,
    selectionDialog: false,
    datasets: ref([]),
    options: ref({}),
    plugins: ref([]),
    defaultDecimals: 3,
    topics: 0,
    minTs: 0,
    maxTs: 0,
    minTsZm: 0,
    maxTsZm: 0,
    placeCursor: undefined,
    cursorDirection: "",
    cursorsColors: { A: "#c92c2c", B: "#2c69c9" },
    cursorsValues: {
      A: undefined,
      B: undefined,
    },
    maxMinCursorsHelper: {},
    maxMinCursors: {},
    hCursorValues: undefined,
    notPinnedColor: "#d5dadb",
  }),

  computed: {
    chartDataSettings() {
      return { datasets: this.datasets.value };
    },
    thumbDataSettings() {
      return {
        datasets: this.datasets.value.map((ds, i) => {
          return { ...ds, yAxisID: `y${i}` };
        }),
      };
    },
  },

  watch: {
    cursorDirection(newValue) {
      this.options.value.plugins.hCrosshair.enabled = newValue === HORIZONTAL;
      this.refresh();
    },
    placeCursor(newValue) {
      let resetPlace = true;

      if (this.cursorDirection !== HORIZONTAL) {
        this.cursorDirection = newValue != undefined ? VERTICAL : "";
        resetPlace = false;
      }

      this.$nextTick(() => {
        if (resetPlace) this.placeCursor = undefined;
        this.refresh();
      });
    },
    pinned(newValue) {
      this.datasets.value.forEach((dataset) => {
        if (newValue == undefined || dataset.label[1] === newValue) {
          dataset.borderColor = this.colors[dataset.label[4]];
          dataset.backgroundColor = this.colors[dataset.label[4]];
        } else {
          dataset.borderColor = this.notPinnedColor;
          dataset.backgroundColor = this.notPinnedColor;
        }
      });

      // Recompute horizontal cursor
      if (this.hCursorValues !== undefined) {
        const pinnedDs = this.datasets.value.find(
          (ds) => ds.label[1] === this.pinned
        );
        const axisName = pinnedDs?.yAxisID;
        // eslint-disable-next-line prettier/prettier
        const axis = this.$refs.advancedLineChart.chartInstance.scales[axisName];

        if (axis == undefined) {
          this.removeHorizontalCursor(undefined, true);
          this.$emit("h-cursor", undefined);

          return;
        } else {
          this.removeHorizontalCursor(this.hCursorValues.axisName, false);
        }

        const oldPercentage =
          (this.hCursorValues.value - this.hCursorValues.min) /
          (this.hCursorValues.max - this.hCursorValues.min);

        this.hCursorValues.min = axis.min;
        this.hCursorValues.max = axis.max;
        this.hCursorValues.axisName = axisName;
        this.hCursorValues.value =
          (axis.max - axis.min) * oldPercentage + axis.min;

        // eslint-disable-next-line prettier/prettier
        const cursors = this.createHorizontalCursor(axisName, this.hCursorValues.value);

        this.setAnnotations(cursors);

        this.$emit("h-cursor", {
          value: this.hCursorValues.value,
          unit: this.hCursorValues.axisName,
          asset: {
            topic: pinnedDs.label[2],
            completeTopic: `${this.topicPrefix}${pinnedDs.label[2]}`,
            name: pinnedDs.label[1].split(":")[0],
            dbId: pinnedDs.label[0].split("/")[0],
            description: pinnedDs.label[1],
            serial: pinnedDs.label[3],
          },
        });
      }

      this.refresh();
    },
    startAtZero(newValue) {
      Object.keys(this.options.value.scales).forEach((axis) => {
        if (axis !== "x") {
          const minWorker = new Worker("./workers/min-value.js");

          minWorker.onerror = () => {
            minWorker.terminate();
          };

          minWorker.onmessage = (e) => {
            // eslint-disable-next-line prettier/prettier
            this.options.value.scales[axis].beginAtZero = newValue && e.data >= 0;
            this.$nextTick(() => {
              this.refresh();
              minWorker.terminate();
            });
          };

          minWorker.postMessage({
            datasets: this.datasets.value,
            axis,
          });
        }
      });
    },
    minTimestamp(newValue) {
      if (newValue == undefined) return;

      this.options.value.scales.x.min = newValue;
      this.minTs = newValue;
      this.minTsZm = newValue;
      this.refresh();
    },
    maxTimestamp(newValue) {
      if (newValue == undefined) return;

      this.options.value.scales.x.max = newValue;
      this.maxTs = newValue;
      this.maxTsZm = newValue;
      this.refresh();
    },
    timePartitions: {
      deep: true,
      handler(newValue) {
        this.options.value.plugins.timePartitions.partitions =
          newValue?.map((p) => ({ ...p, color: p.color + "32" })) || [];
      },
    },
    tagChanges: {
      deep: true,
      handler(newValue) {
        this.options.value.plugins.tagChangeCursors.changes = newValue || [];
      },
    },
    filter: {
      deep: true,
      handler(newFilter) {
        this.datasets.value.forEach((dataset) => {
          dataset.hidden =
            newFilter !== undefined && !newFilter.includes(dataset.label[0]);
        });

        let firstGrid = undefined;
        const visibleAxes = this.datasets.value
          .filter((dataset) => !dataset.hidden)
          .map((dataset) => dataset.yAxisID);

        Object.keys(this.options.value.scales).forEach((axis) => {
          if (axis !== "x") {
            const isVisible = visibleAxes.includes(axis);

            if (firstGrid === undefined && isVisible) firstGrid = axis;

            this.options.value.scales[axis].display = isVisible;
            // eslint-disable-next-line prettier/prettier
            this.options.value.scales[axis].grid.drawOnChartArea = firstGrid === axis;
          }
        });

        this.refresh();
      },
    },
    maxMinCursorsHelper: {
      deep: true,
      handler(newData) {
        if (
          !newData.A ||
          !newData.B ||
          newData.A.length === 0 ||
          newData.B.length === 0
        ) {
          this.maxMinCursors = {};
          return;
        }

        for (let index = 0; index < newData.A.length; index++) {
          const indexEnd = newData.B.find(
            (el) => el.datasetIndex === newData.A[index].datasetIndex
          )?.index;

          if (indexEnd == undefined) return;

          newData.A[index].indexStart = newData.A[index].index;
          newData.A[index].indexEnd = indexEnd;
        }

        // eslint-disable-next-line prettier/prettier
        newData.A.forEach(({ name, datasetIndex, indexStart, indexEnd }) => {
          const dsData = this.datasets.value[datasetIndex].data;
          const subset = dsData.slice(
            Math.max(0, indexStart),
            Math.min(indexEnd + 1, dsData.length)
          );

          /* Find min and max*/

          const relativeMin = subset.reduce(
            (min, val) => (val.y < min.y ? val : min),
            subset[0]
          );
          const relativeMax = subset.reduce(
            (max, val) => (val.y > max.y ? val : max),
            subset[0]
          );

          Vue.set(this.maxMinCursors, name, {
            min: relativeMin.y,
            minTs: relativeMin.x,
            max: relativeMax.y,
            maxTs: relativeMax.x,
          });
        });
      },
    },
    chartData: {
      deep: true,
      handler(newData) {
        this.loading = true;

        this.emptyRefs();

        // eslint-disable-next-line prettier/prettier
        const offlineMs = (this.$store.getters.number("VUE_APP_OFFLINE_TIMEOUT_MINUTES") || 15) * 60000;
        const assetsKeys = Object.keys(newData);
        this.topics = 0;
        const createAxes = () => {
          // Find not hidden dataset
          let firstNotHiddenDS = 0;

          while (
            firstNotHiddenDS < this.datasets.value.length &&
            this.datasets.value[firstNotHiddenDS].hidden
          ) {
            firstNotHiddenDS++;
          }

          // Create Y axes
          this.datasets.value.forEach((dataset, index) => {
            // eslint-disable-next-line prettier/prettier
            const minInDataset = dataset.data.reduce((m, v) => v.y < m ? v.y : m, 0);

            if (this.options.value.scales[dataset.yAxisID] == undefined) {
              this.options.value.scales[dataset.yAxisID] = {
                type: "linear",
                beginAtZero: this.startAtZero && minInDataset >= 0,
                position:
                  Object.keys(this.options.value.scales).length < 6
                    ? "left"
                    : "right",
                display: this.filter.includes(dataset.label[0]),
                ticks: {
                  color: this.colors[dataset.label[4]],
                  callback: (label) =>
                    `${this.formatValue(label)}${dataset.yAxisID}`,
                },
                grid: {
                  drawOnChartArea: index === firstNotHiddenDS,
                  color: this.colors[dataset.label[4]],
                },
              };
            } else {
              // eslint-disable-next-line prettier/prettier
              this.options.value.scales[dataset.yAxisID].beginAtZero = minInDataset >= 0;
            }
          });
        };

        if (assetsKeys.length === 0) this.loaded();

        let finishedCount = 0;
        this.topics = assetsKeys.reduce(
          (acc, c) => acc + Object.keys(newData[c]).length,
          0
        );

        assetsKeys.forEach((assetDbId) => {
          const serial = this.$store.getters.serialById(assetDbId);
          const asset = this.$store.getters.assetBySerial(serial);
          const name = asset?.plate || serial;
          const dataKeys = Object.keys(newData[assetDbId]);

          if (dataKeys.length === 0) this.loaded(finishedCount);

          dataKeys.forEach((t) => {
            const topic = t.replace(this.topicPrefix, "");
            const values = newData[assetDbId][t];
            const descriptor = `${name}: ${this.$t(topic)}`;
            const getColor = () => {
              return this.pinned == undefined || descriptor == this.pinned
                ? this.colors[assetDbId + "/" + t]
                : this.notPinnedColor;
            };

            const datasetWorker = new Worker("./workers/datasetter.js");

            datasetWorker.onerror = () => {
              datasetWorker.terminate();
            };

            datasetWorker.onmessage = (e) => {
              this.datasets.value.push(e.data);
              createAxes();
              this.loaded(++finishedCount);
              datasetWorker.terminate();
            };

            datasetWorker.postMessage({
              template: {
                label: [
                  assetDbId + "/" + topic,
                  descriptor,
                  topic,
                  serial,
                  assetDbId + "/" + t,
                ],
                hidden: !this.filter.includes(assetDbId + "/" + topic),
                data: [],
                borderColor: getColor(),
                backgroundColor: getColor(),
                spanGaps: false,
                yAxisID: this.getUnit(topic.split("_")[0], serial) || " ",
              },
              placeGaps: this.dataGaps,
              offlineMs,
              values,
            });
          });
        });
      },
    },
  },

  beforeMount() {
    Interaction.modes.interpolate = Interpolate;
    Interaction.modes.nearestIndex = ChartJsPlugins.INTERACTION_MODE_NEAREST;

    this.plugins.value.push(CrosshairPlugin);
    this.plugins.value.push(zoomPlugin);
    this.plugins.value.push(ChartJsPlugins.H_CROSSHAIR);
    this.plugins.value.push(ChartJsPlugins.TIME_PARTITIONS);
    this.plugins.value.push(ChartJsPlugins.TAG_CHANGE_CURSORS);

    this.loadChartSettings();
  },

  mounted() {
    this.minTs = this.minTimestamp;
    this.minTsZm = this.minTimestamp;
    this.maxTs = this.maxTimestamp;
    this.maxTsZm = this.maxTimestamp;
  },

  methods: {
    loaded(number = 0) {
      if (this.topics === number) {
        this.refresh();
        this.loading = false;
        this.$emit("data-loaded");
      }
    },
    loadChartSettings() {
      this.options.value = {
        responsive: true,
        stacked: false,
        interaction: {
          mode: "x",
          axis: "x",
          intersect: false,
        },
        hover: {
          mode: "x",
          axis: "x",
          intersect: false,
        },
        animation: false,
        parsing: false,
        onClick: this.chartClickedCriteria,
        elements: {
          point: {
            radius: 1.5,
            borderWidth: 0,
            hoverRadius: 5,
          },
          line: {
            borderWidth: 3,
            borderJoinStyle: "round",
            cubicInterpolationMode: "monotone",
          },
        },
        plugins: {
          legend: false,
          autocolors: false,
          decimation: {
            enabled: true,
            algorithm: "lttb",
            samples: 500,
            threshold: 1000,
          },
          tooltip: {
            mode: "nearest",
            axis: "x",
            intersect: false,
            callbacks: {
              title: (tooltipItem) =>
                moment
                  .utc(tooltipItem[0].parsed.x)
                  .local()
                  .format("DD MMM YYYY HH:mm:ss"),
              label: (tooltipItem) => {
                const unit = tooltipItem.dataset.yAxisID;
                const value = this.formatValue(
                  tooltipItem.dataset.data[tooltipItem.dataIndex].y
                );

                return ` ${tooltipItem.dataset.label[1]}: ${value}${unit}`;
              },
            },
          },
          crosshair: {
            snap: { enabled: false },
            zoom: { enabled: false },
            line: {
              color: "#1B1B1B",
              width: 1,
              dashPattern: [6, 3],
            },
          },
          hCrosshair: {
            color: () => {
              const pinnedDs = this.datasets.value.find(
                (ds) => ds.label[1] === this.pinned
              );

              return pinnedDs?.backgroundColor || "#1B1B1B";
            },
            fontSize: 11,
            width: 2,
            dashPattern: [],
            datasetLabel: () => this.pinned,
          },
          timePartitions: {
            partitions: [],
          },
          tagChangeCursors: {
            color: "#0A5405AA",
            textColor: "#1B1B1B",
            textBackground: "#FFFFFF00",
            width: 2,
            size: 14,
            changes: [],
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
              onPan: this.setNewZoom,
            },
            zoom: {
              wheel: { enabled: true },
              mode: "x",
              onZoom: this.setNewZoom,
            },
          },
        },
        scales: {
          x: {
            type: "time",
            ticks: {
              font: { size: 10 },
              maxRotation: 45,
              autoSkip: true,
            },
            time: {
              displayFormats: {
                millisecond: "HH:mm:ss.SSS",
                second: "HH:mm:ss",
                minute: "HH:mm:ss",
                hour: "DD MMM HH:mm:ss",
                day: "DD MMM HH:mm:ss",
                week: "DD MMM HH:mm:ss",
                month: "DD MMM YYYY",
                quarter: "DD MMM YYYY",
                year: "DD MMM YYYY",
              },
            },
          },
        },
      };
    },
    emptyRefs() {
      this.removeCursors();

      this.datasets.value = [];
      this.options.value.scales = {
        x: {
          type: "time",
          ticks: {
            font: { size: 10 },
            maxRotation: 45,
            autoSkip: true,
          },
          time: {
            displayFormats: {
              millisecond: "HH:mm:ss.SSS",
              second: "HH:mm:ss",
              minute: "HH:mm:ss",
              hour: "DD MMM HH:mm:ss",
              day: "DD MMM HH:mm:ss",
              week: "DD MMM HH:mm:ss",
              month: "DD MMM YYYY",
              quarter: "DD MMM YYYY",
              year: "DD MMM YYYY",
            },
          },
        },
      };

      this.refresh();
    },
    getValue(topic, asset) {
      return this.getMeasureValue(
        this.$store.getters.measuresById(asset ?? "")?.[topic]
      );
    },
    getUnit(topic, asset) {
      return this.getValue(topic, asset)?.getUnit() ?? "";
    },
    getFormat(topic, asset) {
      return this.getValue(topic, asset)?.metadata?.["$format"]?.content;
    },
    formatValue(value) {
      if (Number.isNaN(value)) return value;

      const number = Number(value);
      const truncated = Math.trunc(number);

      return number.toFixed(number - truncated !== 0 ? 2 : 0);
    },
    refresh() {
      this.options.value.scales.x.min = this.minTsZm;
      this.options.value.scales.x.max = this.maxTsZm;
      this.update = !this.update;
    },
    getFromCursor(cursor, key) {
      return cursor?.[key];
    },
    clearCursor(cursor) {
      if (this.options.value?.plugins?.annotation?.annotations) {
        // eslint-disable-next-line prettier/prettier
        delete this.options.value.plugins.annotation.annotations[`cursor-${cursor}`];
        // eslint-disable-next-line prettier/prettier
        delete this.options.value.plugins.annotation.annotations[`label-${cursor}`];

        this.$emit(`cursor-${cursor}`, undefined);

        this.cursorsValues[cursor] = undefined;

        this.refresh();
      }
    },
    removeCursors() {
      this.placeCursor = undefined;

      if (this.options.value?.plugins?.annotation != undefined) {
        Object.keys(this.cursorsValues).forEach((c) => {
          // eslint-disable-next-line prettier/prettier
          delete this.options.value.plugins.annotation.annotations[`cursor-${c}`];
          // eslint-disable-next-line prettier/prettier
          delete this.options.value.plugins.annotation.annotations[`label-${c}`];

          this.$emit(`cursor-${c}`, undefined);
        });

        if (
          Object.keys(this.options.value.plugins.annotation.annotations)
            .length === 0
        ) {
          this.options.value.plugins.annotation = undefined;
        }

        Object.keys(this.cursorsValues).forEach(
          (k) => (this.cursorsValues[k] = undefined)
        );

        this.refresh();
      }
    },
    removeHorizontalCursor(filter = "", clearValues = true) {
      if (this.cursorDirection === HORIZONTAL) this.cursorDirection = "";

      this.$nextTick(() => {
        if (this.options.value?.plugins?.annotation != undefined) {
          Object.keys(
            this.options.value.plugins.annotation.annotations
          ).forEach((c) => {
            if (c.includes("horizontal") && c.includes(filter)) {
              // eslint-disable-next-line prettier/prettier
              delete this.options.value.plugins.annotation.annotations[c];
            }
          });

          if (clearValues) this.hCursorValues = undefined;

          this.refresh();
        }
      });
    },
    chartClickedCriteria(evt, active, chart) {
      switch (this.cursorDirection) {
        case HORIZONTAL:
          this.chartHorizontalClicked(evt, active, chart);
          break;
        case VERTICAL:
          this.chartVerticalClicked(evt, active, chart);
          break;
        default:
          break;
      }
    },
    chartHorizontalClicked(evt, _active, chart) {
      if (this.pinned != undefined) {
        const pinnedDs = this.datasets.value.find(
          (ds) => ds.label[1] === this.pinned
        );
        const axisName = pinnedDs?.yAxisID;
        const axis = chart.scales[axisName];
        const yValue = axis.getValueForPixel(evt.y);

        this.hCursorValues = {
          min: axis.min,
          max: axis.max,
          value: yValue,
          axisName,
        };

        const cursors = this.createHorizontalCursor(axisName, yValue);

        this.setAnnotations(cursors);

        this.$emit("h-cursor", {
          value: this.hCursorValues.value,
          unit: this.hCursorValues.axisName,
          asset: {
            topic: pinnedDs.label[2],
            completeTopic: `${this.topicPrefix}${pinnedDs.label[2]}`,
            name: pinnedDs.label[1].split(":")[0],
            dbId: pinnedDs.label[0].split("/")[0],
            description: pinnedDs.label[1],
            serial: pinnedDs.label[3],
          },
        });
      }

      this.cursorDirection = "";
    },
    createHorizontalCursor(axisName, yValue) {
      return {
        [`cursor-horizontal-${axisName}`]: {
          type: "line",
          scaleID: axisName,
          value: yValue,
          endValue: yValue,
          borderColor: "#1B1B1B",
          borderWidth: 2,
        },
        [`label-horizontal-${axisName}`]: {
          type: "label",
          yScaleID: axisName,
          yValue: yValue,
          backgroundColor: "#1B1B1B",
          color: "#ffffff",
          font: { size: 11 },
          content: [`${yValue?.toFixed(this.defaultDecimals)}${axisName}`],
        },
      };
    },
    chartVerticalClicked(evt, _active, chart) {
      if (
        this.options.value?.plugins &&
        this.filter.length > 0 &&
        this.placeCursor
      ) {
        // eslint-disable-next-line prettier/prettier
        const aVal = this.options.value?.plugins?.annotation?.annotations?.["cursor-A"]?.xMin;
        // eslint-disable-next-line prettier/prettier
        const bVal = this.options.value?.plugins?.annotation?.annotations?.["cursor-B"]?.xMin;
        const xValue = chart.scales.x.getValueForPixel(evt.x);

        // Prevent cursors inversion
        if (this.placeCursor === "A" && xValue >= (bVal || xValue + 1)) {
          return;
        }

        if (this.placeCursor === "B" && xValue <= (aVal || xValue - 1)) {
          return;
        }

        // Create the cursor
        const cursors = {
          [`cursor-${this.placeCursor}`]: {
            type: "line",
            xMin: xValue,
            xMax: xValue,
            borderColor: this.cursorsColors[this.placeCursor],
            borderWidth: 2,
          },
          [`label-${this.placeCursor}`]: {
            type: "label",
            xValue: xValue,
            backgroundColor: this.cursorsColors[this.placeCursor],
            color: "#ffffff",
            font: { size: 11 },
            rotation: -90,
            content: [this.getParsedTimestamp(xValue)],
          },
        };

        this.setAnnotations(cursors);

        this.$nextTick(() => {
          this.cursorsValues[this.placeCursor] = {
            Timestamp: Math.trunc(xValue),
          };

          this.$emit(
            `cursor-${this.placeCursor}`,
            this.cursorsValues[this.placeCursor].Timestamp
          );

          Vue.set(this.maxMinCursorsHelper, this.placeCursor, []);

          chart
            .getElementsAtEventForMode(evt, "nearestIndex", { axis: "x" }, true)
            .forEach((val) => {
              const ds = val.datasetIndex;
              const i = val.index;
              const dataset = chart.data.datasets[ds];

              // eslint-disable-next-line prettier/prettier
              const saved = Math.abs(xValue - (this.cursorsValues[this.placeCursor][dataset.label[1]]?.ts || 0));
              const contestant = Math.abs(xValue - dataset.data[i].x);

              if (contestant < saved) {
                this.cursorsValues[this.placeCursor][dataset.label[1]] = {
                  val: dataset.data[i].y,
                  unit: dataset.yAxisID,
                  ts: dataset.data[i].x,
                  format: this.getFormat(dataset.label[2], dataset.label[3]),
                };

                // Prepare for finding min and max
                Vue.set(
                  this.maxMinCursorsHelper[this.placeCursor],
                  this.maxMinCursorsHelper[this.placeCursor].length,
                  {
                    name: dataset.label[1],
                    datasetIndex: ds,
                    index: i,
                  }
                );
              }
            });

          this.placeCursor = undefined;

          this.refresh();
        });
      }
    },
    setAnnotations(annotations) {
      if (this.options.value.plugins.annotation == undefined) {
        this.options.value.plugins.annotation = { annotations };
      } else {
        this.options.value.plugins.annotation.annotations = {
          ...this.options.value.plugins.annotation.annotations,
          ...annotations,
        };
      }
    },
    setNewZoom(evt) {
      this.minTsZm = evt.chart.scales.x.min;
      this.maxTsZm = evt.chart.scales.x.max;
    },
    getParsedTimestamp(timestamp) {
      if (!timestamp) return undefined;

      return moment.utc(timestamp).local().format("DD MMM HH:mm:ss");
    },
    getDifference(first, second) {
      const diff = first.val - second.val;

      return diff.toFixed(first.format || this.defaultDecimals) + first.unit;
    },
    getDeltaTimestamp(first, second) {
      const a = moment(first);
      const b = moment(second);
      const deltaMoments = [
        { tsDelta: a.diff(b, "days"), des: "daysAgo" },
        { tsDelta: a.diff(b, "hours"), des: "hoursAgo" },
        { tsDelta: a.diff(b, "minutes"), des: "minutesAgo" },
        { tsDelta: a.diff(b, "seconds"), des: "secondsAgo" },
      ];
      const deltaMoment = deltaMoments.find((d) => d.tsDelta > 0);

      return deltaMoment != undefined
        ? `${deltaMoment.tsDelta} ${this.$t(deltaMoment.des)}`
        : "--";
    },
    getChartImage() {
      return this.$refs.advancedLineChart.chartInstance.toBase64Image();
    },
  },
};
</script>
