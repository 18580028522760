var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 fill-height grid-container"},[_c('h1',{staticClass:"title"},[_c('div',[_vm._v(" "+_vm._s(_vm.asset.plate || _vm.asset.boxMacAddress || _vm.asset.serial)+" "),_c('span',{staticClass:"mx-1"},[_vm._v(" - ")]),_c('span',{staticClass:"primary--text text--lighten-2"},[_vm._v(" "+_vm._s(_vm.asset.brand)+" "+_vm._s(_vm.asset.model)+" ")])]),_c('div',{staticClass:"d-flex flex-row align-center py-0"},[_c('span',{staticClass:"caption me-1"},[_vm._v(_vm._s(_vm.$t("lastDataTs"))+":")]),_c('span',{staticClass:"caption font-weight-bold me-6"},[_vm._v(" "+_vm._s(_vm.lastMessage != undefined ? _vm.getReadableTimestamp(_vm.lastMessage) : "--")+" ")]),_c('Dot',{attrs:{"color":_vm.isOffline[_vm.asset.boxMacAddress] == undefined
            ? 'grey'
            : _vm.isOffline[_vm.asset.boxMacAddress]
            ? 'error'
            : 'success',"text":_vm.$t(
            _vm.isOffline[_vm.asset.boxMacAddress] == undefined
              ? 'unknown'
              : _vm.isOffline[_vm.asset.boxMacAddress]
              ? 'offline'
              : 'online'
          ),"justify-end":""}})],1)]),_c('div',{staticClass:"body"},[_c('DynamicDashboard',{ref:"dashboard",staticClass:"body-content",attrs:{"asset-id":_vm.assetId,"asset":_vm.asset},on:{"refresh-component":_vm.fetchMeasures}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }