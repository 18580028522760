import * as moment from "moment";
import { getRelativePosition } from "chart.js/helpers";

class ChartJsPlugins {
  static INTERACTION_MODE_NEAREST = (chart, event, options) => {
    const position = getRelativePosition(event, chart);
    const items = [];

    chart.data.datasets.forEach((ds, dsi) => {
      const xVal = Math.trunc(
        chart.scales[options.axis].getValueForPixel(position[options.axis])
      );
      const element = ds.data.reduce(
        (nearest, el, index) => {
          const elIsNear =
            Math.abs(xVal - el[options.axis]) <
            Math.abs(xVal - nearest.element[options.axis]);

          return elIsNear ? { element: el, index } : nearest;
        },
        { element: ds.data[0], index: 0 }
      );

      items.push({
        element: element.element,
        datasetIndex: dsi,
        index: element.index,
      });
    });

    return items;
  };

  static H_CROSSHAIR = {
    id: "hCrosshair",
    afterEvent(chart, args) {
      const scales = Object.keys(chart.config.options.scales).filter(
        (s) => s !== "x"
      );

      // If no Y axis is present: do nothing
      if (scales.length === 0) return;
      // If the event is not mouse movement: do nothing
      if (args.event.type !== "mousemove") return;

      chart.hCrosshair = { y: args.event.y, outside: !args.inChartArea };
    },

    afterDraw(chart) {
      this.drawTraceLine(chart);
    },

    drawTraceLine(chart) {
      const y = chart.hCrosshair?.y;
      const {
        color = "#000000",
        width = 1,
        dashPattern = [],
        enabled,
        datasetLabel,
        fontSize = 12,
      } = this.getOptions(chart);

      if (y == undefined) return;
      if (!enabled || chart.hCrosshair.outside) return;

      const {
        chartArea: { right, left },
        ctx,
      } = chart;

      ctx.beginPath();
      ctx.setLineDash(dashPattern);
      ctx.moveTo(left, y);
      ctx.lineTo(right, y);

      ctx.lineWidth = width;
      ctx.strokeStyle = color;

      ctx.stroke();
      ctx.closePath();

      if (datasetLabel != undefined) {
        const pinnedDs = chart.data.datasets.find(
          (ds) => ds.label[1] === datasetLabel
        );
        const axisName = pinnedDs?.yAxisID;
        const yValue = chart.scales[axisName]?.getValueForPixel(y);
        const formatted = `${yValue?.toFixed(2)}${axisName?.trim()}`;
        const p = 4;
        const position = {
          y: y - fontSize - 3 * p,
          x: left,
        };
        const size = {
          height: fontSize + 2 * p,
          width: fontSize * formatted.length + p,
        };

        ctx.fillStyle = color;

        ctx.fillRect(position.x, position.y, size.width, size.height);

        ctx.font = `${fontSize}px sans-serif`;
        ctx.fillStyle = "white";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        ctx.setLineDash([]);
        ctx.fillText(
          formatted,
          position.x + size.width / 2,
          position.y + size.height / 2
        );
      }
    },

    getOptions(chart) {
      return (
        chart.options.plugins.hCrosshair || {
          color: "#F66",
          width: 1,
          dashPattern: [],
        }
      );
    },
  };

  static EMPTY_PIE = {
    id: "emptyPie",
    afterDraw(chart, _, options) {
      const { datasets } = chart.data;
      const { radius } = options;
      const hasNoData = datasets.reduce(
        (a, c) =>
          a && (c.data.length === 0 || c.data.reduce((t, v) => t + v, 0) === 0),
        true
      );

      if (hasNoData) {
        const {
          chartArea: { left, top, right, bottom },
          ctx,
        } = chart;
        const centerX = (left + right) / 2;
        const centerY = (top + bottom) / 2;
        const r =
          (Math.min(right - left, bottom - top) / 2) * ((radius || 100) / 100);

        ctx.beginPath();
        ctx.fillStyle = "#e6eded";
        ctx.arc(centerX, centerY, r, 0, 2 * Math.PI, false);
        ctx.fill();
      }
    },
  };

  static TOTALIZER = {
    id: "totalizer",
    beforeUpdate: (chart) => {
      let totals = {};
      let utmost = 0;

      chart.data.datasets.forEach((dataset, datasetIndex) => {
        if (chart.isDatasetVisible(datasetIndex)) {
          utmost = datasetIndex;
          dataset.data.forEach((value, index) => {
            totals[index] = (totals[index] || 0) + value;
          });
        }
      });

      chart.$totalizer = {
        totals: totals,
        utmost: utmost,
      };
    },
  };

  static TAG_CHANGE_CURSORS = {
    id: "tagChangeCursors",
    afterDraw(chart, _, options) {
      const {
        color,
        textColor,
        textBackground,
        width,
        size,
        changes,
      } = options;
      const char = size * 1.25;
      const {
        chartArea: { top, left, right, height },
        scales: { x },
        ctx,
      } = chart;

      changes.forEach((change) => {
        const changeX = x.getPixelForValue(change.time);

        if (changeX < left || changeX > right) return;

        ctx.fillStyle = color;
        ctx.fillRect(changeX, top, width, height);
        ctx.fillStyle = textBackground;
        ctx.fillRect(
          changeX + size - 4,
          top,
          (change.to.length / 2) * char + 4,
          char + 8
        );
        ctx.fillRect(
          changeX - char * (change.from.length / 2) - char / 2 - 4,
          top,
          (change.from.length / 2) * char + 4,
          char + 8
        );

        ctx.font = `${size}px sans-serif`;
        ctx.fillStyle = textColor;

        ctx.fillText(change.to, changeX + size, top + char + 2);
        ctx.fillText(
          change.from,
          changeX - char * (change.from.length / 2) - char / 2,
          top + char + 2
        );
      });
    },
  };

  static TIME_PARTITIONS = {
    id: "timePartitions",
    beforeDraw(chart, _, options) {
      const { partitions } = JSON.parse(JSON.stringify(options));
      const {
        chartArea: { top, left, right, height },
        scales: { x },
        ctx,
      } = chart;
      const { ticks } = x;

      if (partitions.length > 0) {
        partitions.forEach((p) => {
          if (p.start >= p.end) {
            partitions.push({ ...p, start: 0, startString: "00:00" });

            p.end = 2400;
            p.endString = "24:00";
          }
        });

        const lastTick = moment.utc(ticks[ticks.length - 1].value).local();
        const days = [];
        const computedPartitions = [];
        let firstTick = moment.utc(ticks[0].value).local();

        while (firstTick.isSameOrBefore(lastTick, "day")) {
          days.push(firstTick.format("YYYY-MM-DD"));
          firstTick = firstTick.add(1, "day");
        }

        days.forEach((day) => {
          const weekDay = moment(day, "YYYY-MM-DD").day();

          partitions.forEach((p) => {
            const applicableDays = p.applicableDays ?? [];

            if (
              applicableDays.length > 0 &&
              !applicableDays.includes(weekDay)
            ) {
              return;
            }

            const start = moment(`${day} ${p.startString}`, "YYYY-MM-DD HH:mm")
              .toDate()
              .getTime();
            const end = moment(`${day} ${p.endString}`, "YYYY-MM-DD HH:mm")
              .toDate()
              .getTime();

            computedPartitions.push({
              start: Math.max(x.getPixelForValue(start), left),
              end: Math.min(x.getPixelForValue(end), right),
              color: p.color,
            });
          });
        });

        computedPartitions
          .filter((partition) => {
            return partition.start >= left && partition.end >= left;
          })
          .filter((partition) => {
            return partition.start <= right && partition.end <= right;
          })
          .forEach((partition) => {
            ctx.fillStyle = partition.color;
            ctx.fillRect(
              partition.start,
              top,
              partition.end - partition.start,
              height
            );
          });
      }
    },
  };
}

export default ChartJsPlugins;
