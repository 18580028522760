<template>
  <div id="container">
    <v-divider v-bind:color="lineColor" />
    <span v-bind:class="'caption' + color">{{ text }}</span>
    <v-divider v-bind:color="lineColor" />
  </div>
</template>

<style scoped>
#container {
  width: 100%;
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}
</style>

<script>
export default {
  name: "TextSeparator",

  props: {
    text: { type: String, required: true },
    textColor: { type: String, required: false, default: "" },
    lineColor: { type: String, required: false, default: "" },
  },

  computed: {
    color() {
      const trimmed = this.textColor.trim();
      return trimmed != "" ? ` ${trimmed}--text` : "";
    },
  },
};
</script>
