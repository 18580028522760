import EnrichedBaseEntity from "./EnrichedBaseEntity";

/**
 * @typedef {Object} CreateUpdateGroupBody
 * @property {String} name - Group name.
 * @property {Object} properties - Key/value pairs describing the group.
 */

class GroupInfo extends EnrichedBaseEntity {
  static TYPES = [
    "transports",
    "public",
    "residential",
    "school",
    "sport",
    "industrial",
    "commercial",
    "utility",
    "other",
  ];
  static FIXED_KEYS = [
    "type",
    "lon",
    "lat",
    "municipality",
    "province",
    "functions",
  ];

  _companyId = undefined;
  _name = undefined;
  _type = undefined;
  _functions = [];
  _lon = undefined;
  _lat = undefined;
  _municipality = undefined;
  _province = undefined;
  _customs = {};
  _assets = 0;

  constructor(groupJson = undefined) {
    super(groupJson);

    if (groupJson != undefined) {
      this._companyId = groupJson.companyId;
      this._name = groupJson.name;

      if (groupJson.properties != undefined) {
        this._type = groupJson.properties.type;
        this._lon = groupJson.properties.lon;
        this._lat = groupJson.properties.lat;
        this._municipality = groupJson.properties.municipality;
        this._province = groupJson.properties.province;
        this._functions =
          groupJson.properties.functions?.split(",").filter((g) => !!g) || [];

        Object.keys(groupJson.properties).forEach((key) => {
          if (!GroupInfo.FIXED_KEYS.includes(key)) {
            this._customs[key] = groupJson.properties[key];
          }
        });
      }
    }
  }

  // Getters

  get companyId() {
    return this._companyId;
  }
  get name() {
    return this._name;
  }
  get type() {
    return this._type != undefined
      ? this._type.length === 0
        ? undefined
        : this._type
      : undefined;
  }
  get functions() {
    return this._functions;
  }
  get lon() {
    return this._lon != undefined
      ? this._lon.length === 0
        ? undefined
        : this._lon
      : undefined;
  }
  get lat() {
    return this._lat != undefined
      ? this._lat.length === 0
        ? undefined
        : this._lat
      : undefined;
  }
  get municipality() {
    return this._municipality != undefined
      ? this._municipality.length === 0
        ? undefined
        : this._municipality
      : undefined;
  }
  get province() {
    return this._province != undefined
      ? this._province.length === 0
        ? undefined
        : this._province
      : undefined;
  }
  get customs() {
    this._cleanUpCustoms(false);
    return this._customs;
  }
  get assets() {
    return this._assets;
  }

  // Setters

  set name(name) {
    this._name = name;
  }
  set type(type) {
    this._type = type;
  }
  set functions(functions) {
    this._functions = functions;
  }
  set lon(lon) {
    this._lon = lon;
  }
  set lat(lat) {
    this._lat = lat;
  }
  set municipality(municipality) {
    this._municipality = municipality;
  }
  set province(province) {
    this._province = province;
  }
  set customs(customs) {
    this._customs = customs;
    this._cleanUpCustoms(true);
  }
  set assets(assets) {
    this._assets = assets;
  }

  // Public methods

  /**
   * Returns an object usable to create and update a group via the API.
   * @public
   * @returns {CreateUpdateGroupBody} - Creation and editing object.
   */
  getCreateUpdateGroupBody() {
    this._cleanUpCustoms(true);

    return {
      name: this._name,
      properties: {
        type: this._type || "",
        lon: this._lon || "",
        lat: this._lat || "",
        municipality: this._municipality || "",
        province: this._province || "",
        functions: this._functions.join(","),
        ...this._customs,
      },
    };
  }

  /**
   * Useful for filtering groups.
   * @public
   * @param {String} searchTerm - The word to search in this group.
   * @returns {Boolean} - Returns `true` if the searched word is contained in this group, `false` otherwise.
   */
  matchSearchTerm(searchTerm) {
    const s = (searchTerm || "").toLowerCase();

    return (
      (this._name || "").toLowerCase().includes(s) ||
      (this._municipality || "").toLowerCase().includes(s) ||
      (this._province || "").toLowerCase().includes(s) ||
      this._functions.join(",").toLowerCase().includes(s) ||
      this.readableCreated().toLowerCase().includes(s) ||
      this.readableModified().toLowerCase().includes(s) ||
      EnrichedBaseEntity.VUE.$t(this._type || "")
        .toLowerCase()
        .includes(s) ||
      Object.keys(this._customs).reduce((result, key) => {
        return result || (this._customs[key] || "").toLowerCase().includes(s);
      }, false)
    );
  }

  // Private methods

  _cleanUpCustoms(deep = false) {
    Object.keys(this._customs).forEach((k) => {
      if (this._customs[k] == undefined || (deep && this._customs[k] == "")) {
        delete this._customs[k];
      }
    });
  }
}

export default GroupInfo;
