<template>
  <div class="d-flex">
    <v-list-item-icon v-if="active != undefined" class="my-0">
      <v-checkbox v-bind:input-value="active" />
    </v-list-item-icon>
    <v-list-item-content class="my-0">
      <template v-if="mode === 'asset'">
        <div class="black--text">
          <span v-if="item.plate != undefined">
            <span class="font-weight-bold">
              {{ item.plate }}
            </span>
            -
          </span>
          <span>{{ item.brand }} {{ item.model }}</span>
          <span v-if="item.groupId != undefined">
            ({{ $t("assetGroup") }}: {{ item.groupName }})
          </span>
        </div>
        <div class="caption primary--text text--lighten-2">
          {{ item.serial }}
        </div>
      </template>
      <template v-if="mode === 'topic'">
        <span class="black--text">{{ $t(item.topic) }}</span>
      </template>
      <template v-else-if="mode === 'entityCode'">
        <div class="black--text">
          <span class="font-weight-bold">
            {{ item.brand }} {{ item.model }}
          </span>
          <span>({{ item.version }})</span>
        </div>
        <div class="caption primary--text text--lighten-2">
          {{ item.id }}
        </div>
      </template>
    </v-list-item-content>
  </div>
</template>

<script>
export default {
  name: "AssetItem",

  props: {
    active: { type: Boolean, required: false },
    item: { type: Object, required: true },
    mode: { type: String, required: true },
  },
};
</script>
