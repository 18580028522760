import EnrichedBaseEntity from "./EnrichedBaseEntity";
import Event from "./Event";
import { v4 as uuidv4 } from "uuid";

/**
 * @typedef {Object} CreateUpdateExpressionBody
 * @property {String} name - Expression name.
 * @property {String} description - Expression description.
 * @property {String} group - Expression group.
 * @property {String} code - Expression code.
 * @property {String} type - Expression type.
 * @property {String} severity - Generated alarm severity.
 * @property {Boolean} enabled - Expression enabled state.
 * @property {[String]} addressees - Selected users for notifications.
 * @property {[String]} channels - Notification channels for the selected users.
 * @property {[String]} assetsIds - Assets to apply the expression to.
 * @property {[String]} assetsEntityCodes - Entity codes to apply the expression to.
 * @property {[Object]} structure - Expression structure.
 */

class ExpressionInfo extends EnrichedBaseEntity {
  static COMPARISONS = ["EQ", "NE", "GT", "GE", "LT", "LE"];
  static BOOLEANS = ["AND", "OR"];
  static TYPES = ["Single", "UpDown"];
  static CHANNELS = ["email", "sms", "telegram"];

  _companyId = undefined;
  _name = undefined;
  _description = undefined;
  _group = undefined;
  _code = uuidv4();
  _type = undefined;
  _severity = undefined;
  _enabled = false;
  _addressees = [];
  _channels = [];
  _assetsIds = [];
  _assetsEntityCodes = [];
  _structure = [];

  constructor(expressionJson = undefined) {
    super(expressionJson);

    if (expressionJson != undefined) {
      this._companyId = expressionJson.companyId;
      this._name = expressionJson.name;
      this._description = expressionJson.description;
      this._group = expressionJson.group;
      this._code = expressionJson.code;
      this._type = expressionJson.type;
      this._severity = expressionJson.severity.toLowerCase();
      this._channels = expressionJson.channels;
      this._enabled = expressionJson.enabled;
      this._addressees = expressionJson.addressees;
      this._assetsIds = expressionJson.assetsIds;
      this._assetsEntityCodes = expressionJson.assetsEntityCodes;
      this._structure = expressionJson.structure;
    }
  }

  // Getters

  get companyId() {
    return this._companyId;
  }
  get name() {
    return this._name;
  }
  get description() {
    return this._description;
  }
  get group() {
    return this._group;
  }
  get code() {
    return this._code;
  }
  get type() {
    return this._type;
  }
  get severity() {
    return this._severity;
  }
  get enabled() {
    return this._enabled;
  }
  get addressees() {
    return (this._addressees || []).filter((a) => a != undefined);
  }
  get channels() {
    return this._channels || [];
  }
  get assetsIds() {
    return this._assetsIds || [];
  }
  get assetsEntityCodes() {
    return this._assetsEntityCodes || [];
  }
  get structure() {
    return this._structure;
  }
  get severityNum() {
    switch (this._severity) {
      case Event.severity.HIGH:
        return 0;
      case Event.severity.MEDIUM:
        return 1;
      case Event.severity.LOW:
        return 2;
      default:
        return 3;
    }
  }
  get severityIcon() {
    switch (this.severityNum) {
      case 3:
        return {
          color: "primary",
          icon: "mdi-information-outline",
        };
      case 2:
        return {
          color: "success",
          icon: "mdi-alert-circle-outline",
        };
      case 1:
        return {
          color: "warning",
          icon: "mdi-alert-outline",
        };
      case 0:
        return {
          color: "error",
          icon: "mdi-alert-decagram-outline",
        };
      default:
        return {
          color: "",
          icon: "mdi-help-circle-outline",
        };
    }
  }

  // Setters

  set companyId(companyId) {
    this._companyId = companyId;
  }
  set name(name) {
    this._name = name;
  }
  set description(description) {
    this._description = description;
  }
  set group(group) {
    this._group = group;
  }
  set code(code) {
    this._code = code;
  }
  set type(type) {
    this._type = type;
  }
  set severity(severity) {
    this._severity = severity;
  }
  set enabled(enabled) {
    this._enabled = enabled;
  }
  set addressees(addressees) {
    this._addressees = (addressees || []).filter((a) => a != undefined);
  }
  set channels(channels) {
    this._channels =
      channels != undefined && channels.length > 0 ? channels : [];
  }
  set assetsIds(assetsIds) {
    this._assetsIds = assetsIds;
  }
  set assetsEntityCodes(assetsEntityCodes) {
    this._assetsEntityCodes = assetsEntityCodes;
  }
  set structure(structure) {
    this._structure = structure;
  }

  // Public methods

  /**
   * Returns the clone of this object.
   * @public
   * @returns Clone of this object.
   */
  clone() {
    return new ExpressionInfo({
      id: this._id,
      created: this._created,
      createdBy: this._createdBy,
      modified: this._modified,
      modifiedBy: this._modifiedBy,
      companyId: this.companyId,
      name: this.name,
      description: this.description,
      group: this.group,
      code: this.code,
      type: this.type,
      severity: this.severity,
      enabled: this.enabled,
      addressees: this.addressees,
      channels: this.channels,
      assetsIds: [...this.assetsIds],
      assetsEntityCodes: [...this.assetsEntityCodes],
      structure: JSON.parse(JSON.stringify(this.structure)),
    });
  }

  /**
   * Returns an object usable to create and update an expression via the API.
   * @public
   * @returns {CreateUpdateExpressionBody} - Creation and editing object.
   */
  getCreateUpdateExpressionBody() {
    return {
      name: this._name,
      description: this._description,
      group: this._group,
      code: this._code,
      type: this._type,
      enabled: this._enabled,
      addressees: this.addressees,
      channels: this.channels,
      assetsIds: this._assetsIds,
      assetsEntityCodes: this._assetsEntityCodes,
      structure: this._structure,
      severity:
        this._severity.charAt(0).toUpperCase() + this._severity.slice(1),
    };
  }

  /**
   * Useful for filtering expressions.
   * @public
   * @param {String} searchTerm - The word to search in this expression.
   * @returns {Boolean} - Returns `true` if the searched word is contained in this expression, `false` otherwise.
   */
  matchSearchTerm(searchTerm) {
    const s = (searchTerm || "").toLowerCase();

    return (
      (this._companyId || "").toLowerCase().includes(s) ||
      (this._name || "").toLowerCase().includes(s) ||
      (this._description || "").toLowerCase().includes(s) ||
      (this._group || "").toLowerCase().includes(s) ||
      (this._code || "").toLowerCase().includes(s) ||
      this.readableCreated().toLowerCase().includes(s) ||
      this.readableModified().toLowerCase().includes(s) ||
      ExpressionInfo.VUE.$t(this._type || "")
        .toLowerCase()
        .includes(s) ||
      ExpressionInfo.VUE.$t(this._severity || "")
        .toLowerCase()
        .includes(s)
    );
  }
}

export default ExpressionInfo;
