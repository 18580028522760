<template>
  <div class="pa-4">
    <h1>{{ $t("menuPluginsSettings") }}</h1>
    <template v-if="loading">
      <div class="d-flex align-center justify-center mt-4">
        <v-progress-circular color="primary" indeterminate />
      </div>
    </template>
    <template v-else>
      <v-tabs v-model="pluginTab" center-active>
        <v-tab v-for="plugin in plugins" v-bind:key="plugin.id">
          {{ plugin.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="pluginTab" class="mt-6">
        <v-tab-item
          v-for="plugin in plugins"
          v-bind:key="plugin.id + '-tab-item'"
        >
          <h2 class="primary--text d-flex align-center justify-space-between">
            <div>
              {{ plugin.name }}
              <span class="caption ms-3">v{{ plugin.version }}</span>
            </div>
            <div>
              <v-btn
                color="primary"
                v-bind:disabled="loading"
                v-on:click="saveSettings(plugin)"
              >
                Save settings
              </v-btn>
            </div>
          </h2>
          <div class="my-8"></div>
          <div class="d-flex flex-wrap" style="gap: 1em">
            <div
              v-for="setting in settingsList(plugin.settings)"
              v-bind:key="plugin.id + '-' + setting"
              style="min-width: 32%"
            >
              <v-text-field
                v-if="textFieldType(setting) != 'area'"
                v-model="plugin.settings[setting]"
                v-bind:label="setting"
                v-bind:rules="[(v) => !!v || 'Required']"
                v-bind:disabled="loading"
                v-bind:type="textFieldType(setting)"
                v-bind:readonly="isMailingList(setting)"
                v-bind:clearable="!isMailingList(setting)"
                v-on:click="
                  isMailingList(setting)
                    ? openDialog(plugin, setting)
                    : undefined
                "
                outlined
              />
              <v-textarea
                v-else
                v-model="plugin.settings[setting]"
                v-bind:label="setting"
                v-bind:rules="[(v) => !!v || 'Required']"
                v-bind:disabled="loading"
                rows="4"
                outlined
              />
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <v-dialog v-model="mailingDialog" width="unset" persistent>
      <v-card v-if="mailingDialog" style="max-width: 100ch">
        <v-card-title>{{ $t("setMailingLists") }}</v-card-title>

        <v-card-text>
          <div v-if="Object.keys(mailingLists).length > 0">
            <div
              v-for="list in Object.keys(mailingLists)"
              v-bind:key="list"
              class="mb-4"
            >
              <v-combobox
                v-model="mailingLists[list]"
                v-bind:label="list"
                v-bind:outlined="outlinedPref"
                v-on:input="updateMailingLists(list, mailingLists[list])"
                append-icon=""
                hide-details
                multiple
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    v-on:click:close="
                      mailingLists[list] = mailingLists[list].filter(
                        (i) => i != item
                      );
                      updateMailingLists(list, mailingLists[list]);
                    "
                    close
                    small
                  >
                    {{ item }}
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <div v-else>
            {{ $t("noMailingLists") }}
          </div>

          <v-divider class="my-4" />

          <div class="d-flex align-center">
            <v-text-field
              v-model="newMailingList"
              v-bind:label="$t('newMailingList')"
              class="me-4"
              outlined
              dense
              hide-details
            />
            <v-btn
              v-bind:disabled="!newMailingList"
              v-on:click="
                addMailingList(newMailingList);
                newMailingList = undefined;
              "
              color="primary"
            >
              <v-icon class="me-3">mdi-plus</v-icon>
              {{ $t("btnAdd") }}
            </v-btn>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="closeDialog()" color="primary" text>
            {{ $t("btnDone") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import Messages from "../utils/messages";

export default {
  name: "PluginsSettings",

  data: () => ({
    pluginTab: 0,
    plugins: [],
    loading: false,
    mailingDialog: false,
    targetPlugin: undefined,
    targetSetting: undefined,
    newMailingList: undefined,
  }),

  computed: {
    mailingLists: {
      get() {
        return !!this.targetPlugin && !!this.targetSetting
          ? JSON.parse(this.targetPlugin.settings[this.targetSetting] || "{}")
          : {};
      },
      set(newLists) {
        if (!!this.targetPlugin && !!this.targetSetting) {
          this.targetPlugin.settings[this.targetSetting] = JSON.stringify(
            newLists || {}
          );
        }
      },
    },
  },

  beforeMount() {
    this.updateFiltersRules(this.clientId);

    this.$store.commit("setCurrentPage", Pages.PLUGINS_SETTINGS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);

    this.getAllPlugins();
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuPluginsSettings"),
      to: "/plugins_settings",
      root: false,
    });
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    getAllPlugins() {
      this.loading = true;

      ApiRequests.getPlugins(
        (res) => {
          this.plugins = res.data;
          this.loading = false;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.loading = false;
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("pluginsFetchFailure"),
          });
        }
      );
    },
    settingsList(settings) {
      return Object.keys(settings);
    },
    textFieldType(setting) {
      const norm = setting.toLowerCase();

      if (norm.includes("password")) return "password";
      if (norm.includes("template")) return "area";

      return "text";
    },
    isMailingList(setting) {
      return setting.toLowerCase() === "mailing_lists";
    },
    openDialog(plugin, settingName) {
      this.targetPlugin = plugin;
      this.targetSetting = settingName;
      this.mailingDialog = true;
    },
    closeDialog() {
      this.mailingDialog = false;
      this.newMailingList = undefined;
    },
    addMailingList(newMail) {
      this.mailingLists = {
        ...this.mailingLists,
        [newMail]: [],
      };
    },
    updateMailingLists(list, mails) {
      this.mailingLists = {
        ...this.mailingLists,
        [list]: mails,
      };
    },
    saveSettings(plugin) {
      this.loading = true;

      ApiRequests.updatePlugin(
        plugin.id,
        plugin.settings,
        () => {
          this.getAllPlugins();
          this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("pluginUpdateSuccess"));
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.loading = false;
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("pluginsUpdateFailure"),
          });
        }
      );
    },
  },
};
</script>
