<template>
  <v-dialog v-model="confirm" width="unset" persistent>
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>{{ content }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn v-on:click="close" color="error" text>
          {{ $t("btnCancel") }}
        </v-btn>
        <v-btn v-on:click="confirmed" color="primary" text>
          {{ $t("btnConfirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",

  props: {
    callback: { type: Function, required: true },
    content: { type: String, required: true },
    title: { type: String, required: true },
  },

  data: () => ({
    confirm: true,
  }),

  methods: {
    close() {
      this.$emit("cancel");
    },
    confirmed() {
      this.callback();
      this.close();
    },
  },
};
</script>
