<template>
  <div
    class="d-flex flex-column align-center justify-center"
    style="height: 100%"
  >
    <img src="../assets/asset.png" height="90vh" width="auto" />
    <div class="caption">Work in progress</div>
  </div>
</template>

<script>
export default {
  name: "wip",
};
</script>
