import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ApiRequests from "../utils/requests";
import * as vuetifyLangs from "vuetify/es5/locale/index";

const vuetifyDefault = {
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#0c1d69",
      },
    },
  },
};
const request = new XMLHttpRequest();
const configs = ["VUE_APP_I18N_LOCALE", "VUE_APP_I18N_FALLBACK_LOCALE"];
let fetchedData = [];

Vue.use(Vuetify);
request.open(
  "GET",
  `${ApiRequests.BACKEND_URL}settings?keys=${configs.join(",")}`,
  false
);
request.send(null);

fetchedData = JSON.parse(request.responseText);

vuetifyDefault.lang = {
  current: fetchedData[configs[0]] || fetchedData[configs[1]],
  locales: vuetifyLangs,
};

export default new Vuetify(vuetifyDefault);
