var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-container"},[_c('div',{attrs:{"grid":""}},[_c('div',{staticClass:"d-flex align-center mb-2",staticStyle:{"gap":"0.6rem"},attrs:{"toolbar":""}},[_c('v-card',{staticClass:"d-flex align-center py-1 px-3",attrs:{"flat":"","outlined":""}},[_c('div',{staticClass:"me-4 caption"},[_vm._v(_vm._s(_vm.$t("verticalCursor")))]),_c('v-btn-toggle',{attrs:{"dense":""},model:{value:(_vm.placeCursor),callback:function ($$v) {_vm.placeCursor=$$v},expression:"placeCursor"}},_vm._l((Object.keys(_vm.cursorsValues)),function(cursor){return _c('v-btn',{key:'cursor-' + cursor,class:{ 'white--text': _vm.placeCursor === cursor },attrs:{"color":_vm.placeCursor === cursor ? _vm.cursorsColors[cursor] : '',"disabled":_vm.filter.length === 0 || _vm.cursorDirection === _vm.constants.HORIZONTAL,"value":cursor,"small":""}},[_vm._v(" "+_vm._s(cursor)+" ")])}),1),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-btn',{attrs:{"disabled":_vm.options.value.plugins.annotation == undefined,"title":_vm.$t('clearCursors'),"color":"error","icon":"","small":""},on:{"click":_vm.removeCursors}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete-outline")])],1)],1),_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.6rem"},on:{"click":function($event){_vm.selectionDialog = _vm.cursorsValues.A || _vm.cursorsValues.B}}},[_vm._l((Object.keys(_vm.cursorsValues)),function(cursor){return _c('v-card',{key:'cursor-value-' + cursor,staticClass:"d-flex align-center py-1 px-3",attrs:{"flat":"","outlined":""}},[_c('span',{staticClass:"body-2 font-weight-bold",style:('color: ' + _vm.cursorsColors[cursor])},[_vm._v(" "+_vm._s(cursor)+" ")]),(_vm.cursorsValues[cursor])?[_c('span',{staticClass:"mx-3"},[_vm._v(" "+_vm._s(_vm.getParsedTimestamp( _vm.getFromCursor(_vm.cursorsValues[cursor], "Timestamp") ))+" ")]),_c('v-btn',{attrs:{"title":_vm.$t('btnDelete') +
                ' ' +
                _vm.$t('cursor').toLowerCase() +
                ' ' +
                cursor,"color":"error","icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.clearCursor(cursor)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]:_vm._e()],2)}),(_vm.cursorsValues.A && _vm.cursorsValues.B)?_c('v-card',{staticClass:"d-flex align-center py-1 px-3",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","outlined":""}},[_c('span',{staticClass:"body-2 font-weight-bold"},[_vm._v("ΔX")]),_c('span',{staticClass:"ms-3"},[_vm._v(" "+_vm._s(_vm.getDeltaTimestamp( _vm.getFromCursor(_vm.cursorsValues.B, "Timestamp"), _vm.getFromCursor(_vm.cursorsValues.A, "Timestamp") ))+" ")]),(
              _vm.pinned != undefined &&
              _vm.cursorsValues.A &&
              _vm.cursorsValues.B &&
              _vm.getFromCursor(_vm.cursorsValues.B, _vm.pinned) != undefined &&
              _vm.getFromCursor(_vm.cursorsValues.A, _vm.pinned) != undefined
            )?[_c('span',{attrs:{"title":_vm.$t('cursorsComparisonWarning')}},[_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('span',{staticClass:"body-2 font-weight-bold"},[_vm._v("ΔY")]),_c('span',{staticClass:"body-2 ms-1"},[_vm._v("("+_vm._s(_vm.pinned)+")")]),_c('span',{staticClass:"ms-3"},[_vm._v(" "+_vm._s(_vm.getDifference( _vm.getFromCursor(_vm.cursorsValues.B, _vm.pinned), _vm.getFromCursor(_vm.cursorsValues.A, _vm.pinned) ))+" ")])],1)]:_vm._e()],2):_vm._e()],2)],1),_c('div',{staticClass:"pa-2",attrs:{"chart":""}},[_c('LineChart',{key:'multiline-advanced-chart-' + _vm.update,ref:"advancedLineChart",attrs:{"id":"a-line-chart","chartData":_vm.chartDataSettings,"plugins":_vm.plugins.value,"options":_vm.options.value}})],1),_c('div',{attrs:{"finder":""}},[_c('ChartFinder',{attrs:{"chart-data":_vm.thumbDataSettings,"min-ts":_vm.minTs,"max-ts":_vm.maxTs,"min-ts-zoom":_vm.minTsZm,"max-ts-zoom":_vm.maxTsZm}})],1)]),(_vm.loading)?_c('div',{staticClass:"loader"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1):_vm._e(),_c('v-dialog',{attrs:{"width":"min(70%, 92vw)","scrollable":""},model:{value:(_vm.selectionDialog),callback:function ($$v) {_vm.selectionDialog=$$v},expression:"selectionDialog"}},[(_vm.selectionDialog)?_c('CursorsComparison',{attrs:{"cursors-values":_vm.cursorsValues,"maxmin-values":_vm.maxMinCursors,"default-decimals":_vm.defaultDecimals}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }