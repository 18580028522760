<template>
  <div class="py-4 ps-4 pe-0 fill-height grid-container">
    <div class="entity-info">
      <div class="flex-grow-1 text-h4 font-weight-bold">
        <template v-if="customer.name != undefined || group.name != undefined">
          {{ customer.name != undefined ? customer.name : group.name }}
          <v-chip
            v-if="customer.name != undefined"
            v-bind:color="customer.enabled ? 'success' : 'error'"
            class="ms-6"
          >
            {{
              customer.enabled ? $t("customerEnabled") : $t("customerDisabled")
            }}
          </v-chip>
        </template>
        <template v-else>
          <div class="d-flex justify-center">
            <v-progress-circular class="my-2" color="primary" indeterminate />
          </div>
        </template>
      </div>
      <div class="d-flex justify-end">
        <template v-if="currentUser.isSuperAdmin && customer.name != undefined">
          <v-btn
            v-on:click="
              Object.assign(editCustomer, cloneEntity(customer));
              editCustomerDialog = true;
            "
            color="primary"
            outlined
          >
            {{ $t("btnEdit") }}
          </v-btn>
        </template>

        <template v-if="!currentUser.isOperator && group.name != undefined">
          <v-btn
            v-on:click="
              Object.assign(editGroup, cloneEntity(group));
              editGroupDialog = true;
            "
            color="primary"
            outlined
          >
            {{ $t("btnEdit") }}
          </v-btn>
        </template>

        <template v-if="!currentUser.isOperator">
          <v-btn
            v-on:click="assignAssetsDialog = true"
            class="ms-4"
            color="primary"
          >
            {{ $t("customerLinkAssets") }}
          </v-btn>
        </template>
      </div>
    </div>

    <div v-if="group.name != undefined" class="entity-details">
      <div v-if="group.municipality" class="d-flex align-center">
        <span class="body-2">{{ $t("groupLocation") }}:</span>
        <span
          v-bind:title="
            group.lat && group.lon
              ? 'LAT. ' + group.lat + '; LON. ' + group.lon
              : undefined
          "
          class="font-weight-bold ms-2"
        >
          {{ group.municipality }}
          <span v-if="group.province">({{ group.province }})</span>
        </span>
      </div>
      <div v-if="group.type" class="d-flex align-center">
        <span class="body-2">{{ $t("groupType") }}:</span>
        <span class="font-weight-bold ms-2">{{ $t(group.type) }}</span>
      </div>
      <div
        v-if="group.customs != {}"
        v-bind:key="'customs-list-' + Object.keys(group.customs).length"
        class="d-flex flex-wrap align-center"
        style="gap: 2em"
      >
        <div
          v-for="key in Object.keys(group.customs)"
          v-bind:key="group.name + '-view-' + key"
        >
          <span class="body-2">{{ key }}:</span>
          <span class="font-weight-bold ms-2">{{ group.customs[key] }}</span>
        </div>
      </div>
      <div
        v-if="group.functions && group.functions.length > 0"
        class="d-flex align-center"
      >
        <div class="body-2">{{ $t("groupFunctions") }}:</div>
        <div class="d-flex flex-wrap ms-2">
          <div
            v-for="(tag, index) in group.functions"
            v-bind:key="group.id + '-info-tag-' + tag"
          >
            <v-chip
              v-bind:class="index < group.functions.length - 1 ? 'me-2' : ''"
              class="indigo lighten-5 primary--text"
              small
            >
              {{ tag }}
            </v-chip>
          </div>
        </div>
      </div>
    </div>

    <div class="entity-data mt-6">
      <v-card class="flex-grow-1 pa-6" flat outlined>
        <div class="text-h3 font-weight-bold text-center mb-3">
          {{ customer.id !== undefined ? customer.assets : group.assets }}
        </div>
        <div class="text-center">
          {{ $t("assignedAssets") }}
        </div>
      </v-card>
      <v-card class="flex-grow-1 pa-6" flat outlined>
        <div class="text-h3 font-weight-bold text-center mb-3">
          <template v-if="!alarmsStats.oneDay.loading">
            {{ alarmsStats.oneDay.count }}
          </template>
          <template v-else>
            <v-progress-circular class="my-2" color="primary" indeterminate />
          </template>
        </div>
        <div class="text-center">
          {{ $t("alarms24H") }}
        </div>
      </v-card>
      <v-card class="flex-grow-1 pa-6" flat outlined>
        <div class="text-h3 font-weight-bold text-center mb-3">
          <template v-if="!alarmsStats.threeDays.loading">
            {{ alarmsStats.threeDays.count }}
          </template>
          <template v-else>
            <v-progress-circular class="my-2" color="primary" indeterminate />
          </template>
        </div>
        <div class="text-center">
          {{ $t("alarms3D") }}
        </div>
      </v-card>
      <v-card class="flex-grow-1 pa-6" flat outlined>
        <div class="text-h3 font-weight-bold text-center mb-3">
          <template v-if="!alarmsStats.sevenDays.loading">
            {{ alarmsStats.sevenDays.count }}
          </template>
          <template v-else>
            <v-progress-circular class="my-2" color="primary" indeterminate />
          </template>
        </div>
        <div class="text-center">
          {{ $t("alarms1W") }}
        </div>
      </v-card>
    </div>

    <div class="entity-assets no-overflow mt-6">
      <v-card-title class="pa-0">
        {{ $t("assetsPaired") }}
      </v-card-title>
      <v-divider class="mb-3" />
      <div class="scrollable-table">
        <EntitiesTable
          v-bind:headers="headers"
          v-bind:sorting-fields="sortingFields"
          v-bind:items="pairedFilteredAssets"
          v-bind:defaultSort="sortingFields[0].value"
          v-bind:loading="loading"
          v-bind:actionsColumn="false"
          v-bind:actions="{
            edit: false,
            enableDisable: false,
            delete: false,
          }"
          v-bind:bulkColumn="false"
          v-on:table-click="handleClick"
          v-on:table-search="manageSearch"
          v-on:table-bulk-customer="/* Do nothing */"
          v-on:table-bulk-group="/* Do nothing */"
          v-on:table-bulk-delete="/* Do nothing */"
          v-on:table-edit="/* Do nothing */"
          v-on:table-delete="/* Do nothing */"
          v-on:table-bulk-enable="/* Do nothing */"
          v-on:table-bulk-disable="/* Do nothing */"
          v-on:table-disable="/* Do nothing */"
          v-on:table-enable="/* Do nothing */"
        />
      </div>
    </div>

    <div class="side-panel px-4">
      <div class="fixed-height-map rounded outlined">
        <Map
          v-bind:features="filteredCoords"
          v-on:map-group-clicked="openGroup"
          ref="assetMap"
        />
      </div>

      <h3 class="d-flex justify-space-between align-center mt-3">
        <span class="d-flex align-center" style="gap: 0.4em">
          {{ $t("lastAlarms") }}
          <span class="caption grey--text">({{ alarmsHours }} h)</span>
        </span>
        <v-btn
          v-on:click="$router.push({ path: '/alarms' })"
          class="mb-2 caption font-weight-bold"
          color="primary"
          outlined
        >
          {{ $t("menuAlarms") }}
        </v-btn>
      </h3>
      <v-divider class="mb-3" />
      <div style="height: 100%; overflow: hidden; position: relative">
        <div style="overflow: auto; position: absolute; inset: 0">
          <v-list>
            <v-list-item v-for="event in events" v-bind:key="event.name" link>
              <v-list-item-avatar class="me-3">
                <v-icon
                  v-bind:color="event.graphics.color"
                  v-bind:title="event.content"
                  small
                >
                  {{ event.graphics.icon }}
                </v-icon>
                <v-icon
                  v-if="event.stateType === eventTypes.SINGLE"
                  v-bind:title="event.contentType"
                  color="primary"
                  small
                >
                  mdi-numeric-1-circle-outline
                </v-icon>
                <v-icon
                  v-if="event.stateType === eventTypes.UP"
                  v-bind:title="event.contentType"
                  color="error"
                  small
                >
                  mdi-arrow-up-circle-outline
                </v-icon>
                <v-icon
                  v-if="event.stateType === eventTypes.DOWN"
                  v-bind:title="event.contentType"
                  color="success"
                  small
                >
                  mdi-arrow-down-circle-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="body-2">
                  {{ event.attributes.message }}
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <div class="caption primary--text text--lighten-2 me-2">
                  {{ event.attributes.plate || event.attributes.serial }}
                </div>
              </v-list-item-action>
              <v-list-item-action-text v-bind:title="event.readableTs(false)">
                {{ event.readableTs(true) }}
              </v-list-item-action-text>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="editCustomerDialog"
      v-if="editCustomerDialog"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("customerUpdate") }}</v-card-title>
        <v-card-text>
          <v-form class="mt-2 d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="editCustomer.name"
              v-bind:label="$t('customerName')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
            <v-text-field
              v-model="editCustomer.code"
              v-bind:label="$t('customerCode')"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-switch
            v-model="editCustomer.enabled"
            v-bind:label="
              editCustomer.enabled
                ? $t('customerEnabled')
                : $t('customerDisabled')
            "
            class="ms-4"
            inset
          />
          <v-spacer />
          <v-btn v-on:click="resetCustomer" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validCustomer"
            v-on:click="postCustomer"
            color="primary"
            text
          >
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editGroupDialog"
      v-if="editGroupDialog"
      width="unset"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>{{ $t("groupUpdate") }}</v-card-title>
        <v-card-text class="d-flex flex-column">
          <v-text-field
            v-model="editGroup.name"
            v-bind:label="$t('groupName')"
            v-bind:rules="[(v) => !!v || $t('required')]"
            v-bind:outlined="outlinedPref"
            clearable
          />

          <div class="d-flex" style="gap: 1em">
            <v-text-field
              v-model="editGroup.province"
              v-bind:label="$t('groupProvince')"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            />
            <v-text-field
              v-model="editGroup.municipality"
              v-bind:label="$t('groupMunicipality')"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            />
          </div>

          <v-select
            v-model="editGroup.type"
            v-bind:items="groupTypes"
            v-bind:label="$t('groupType')"
            v-bind:outlined="outlinedPref"
            clearable
            hide-details
          >
            <template v-slot:item="{ item }">
              <div>{{ $t(item) }}</div>
            </template>

            <template v-slot:selection="{ item }">
              <div>{{ $t(item) }}</div>
            </template>
          </v-select>

          <v-divider class="mt-4 mb-1" />

          <div
            class="caption font-weight-bold d-flex justify-space-between align-center"
          >
            {{ $t("groupCoords") }}
            <v-btn
              v-on:click="geocodingDialog = true"
              color="primary"
              text
              x-small
            >
              {{ $t("locationSearch") }}
            </v-btn>
          </div>

          <div class="d-flex" style="gap: 1em">
            <v-text-field
              v-model="editGroup.lat"
              v-bind:label="$t('groupLat')"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            />
            <v-text-field
              v-model="editGroup.lon"
              v-bind:label="$t('groupLon')"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            />
          </div>

          <v-divider class="mt-4 mb-1" />

          <div
            class="caption font-weight-bold d-flex justify-space-between align-center"
          >
            {{ $t("groupCustoms") }}
            <v-btn
              v-on:click="newKeyDialog = true"
              color="primary"
              text
              x-small
            >
              {{ $t("createCustomKey") }}
            </v-btn>
          </div>

          <div
            v-bind:key="'customs-edit-list-' + refreshCustomsList"
            class="d-flex flex-column"
          >
            <v-text-field
              v-for="key in Object.keys(editGroup.customs)"
              v-bind:key="editGroup.name + '-edit-' + key"
              v-model="editGroup.customs[key]"
              v-bind:label="key"
              v-bind:outlined="outlinedPref"
              clearable
              hide-details
            >
              <template v-slot:append-outer>
                <v-btn
                  v-bind:title="$t('btnDelete')"
                  v-on:click="
                    delete editGroup.customs[key];
                    refreshCustomsList = !refreshCustomsList;
                  "
                  color="error"
                  icon
                  small
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>

          <v-divider class="mt-4 mb-1" />

          <div
            class="caption font-weight-bold d-flex justify-space-between align-center"
          >
            <span>{{ $t("groupFunctions") }}</span>
            <v-btn v-on:click="updateTags" color="primary" x-small text>
              {{ $t("btnUpdateTags") }}
            </v-btn>
          </div>

          <div class="d-flex flex-wrap mt-2">
            <div
              v-for="(tag, index) in editGroup.functions"
              v-bind:key="editGroup.id + '-tag-' + tag"
            >
              <v-chip
                v-bind:class="
                  index < editGroup.functions.length - 1 ? 'me-2' : ''
                "
                class="indigo lighten-5 primary--text"
              >
                {{ tag }}
              </v-chip>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer class="mx-6" />
          <v-btn v-on:click="resetGroup" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validGroup"
            v-on:click="postGroup"
            color="primary"
            text
          >
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="assignAssetsDialog"
      v-if="assignAssetsDialog"
      width="min(30%, 92vw)"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("customerLinkAssets") }}</v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedAssets"
            v-bind:items="morfedAssetsList"
            v-bind:label="$t('customerAssets')"
            v-bind:outlined="outlinedPref"
            clearable
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <ItemSelection
                v-bind:index="index"
                v-bind:item="item.value"
                v-bind:array-length="selectedAssets.length"
                v-bind:shown-items="1"
                mode="asset"
              />
            </template>
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item
                v-on="on"
                v-bind="attrs"
                #default="{ active }"
                class="my-0"
              >
                <AssetItem
                  v-bind:active="active"
                  v-bind:item="item.value"
                  mode="asset"
                />
              </v-list-item>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-on:click="
              assignAssetsDialog = false;
              selectedAssets = [];
            "
            color="error"
            text
          >
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="selectedAssets.length === 0"
            v-on:click="updateAssetsBulk"
            color="primary"
            text
          >
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="updating" width="unset" persistent>
      <v-card>
        <v-progress-circular class="ma-4" color="primary" indeterminate />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="newKeyDialog"
      v-if="newKeyDialog"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("createCustomKey") }}</v-card-title>
        <v-card-text class="d-flex" style="gap: 2em">
          <v-text-field
            v-model="newKey"
            v-bind:label="$t('newCustomKey')"
            v-bind:rules="[(v) => !!v || $t('required')]"
            v-bind:outlined="outlinedPref"
            style="width: 45ch"
            clearable
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-on:click="
              newKeyDialog = false;
              newKey = undefined;
              refreshCustomsList = !refreshCustomsList;
            "
            color="error"
            text
          >
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="newKey == undefined"
            v-on:click="
              editGroup.customs[newKey] = '';
              newKeyDialog = false;
              newKey = undefined;
              refreshCustomsList = !refreshCustomsList;
            "
            color="primary"
            text
          >
            {{ $t("btnCreate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="geocodingDialog"
      v-if="geocodingDialog"
      width="unset"
      persistent
      scrollable
    >
      <Geocoding
        v-bind:init="{ lat: editGroup.lat, lon: editGroup.lon }"
        v-on:close="geocodingDialog = false"
        v-on:geocoded="useGeocodingCoords"
      />
    </v-dialog>
  </div>
</template>

<style scoped>
.grid-container {
  display: grid;
  gap: 0.5em;
  grid-template-columns: auto 32%;
  grid-template-rows: min-content min-content min-content 1fr;
  grid-template-areas:
    "entity side"
    "info side"
    "count side"
    "data side";
}

.entity-info {
  grid-area: entity;
  display: flex;
  align-items: center;
}

.entity-details {
  grid-area: info;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
}

.entity-data {
  grid-area: count;
  display: flex;
  align-items: center;
  gap: 1em;
}

.entity-assets {
  grid-area: data;
  display: flex;
  flex-direction: column;
}

.no-overflow {
  overflow: hidden;
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  max-height: 100%;
}

.scrollable-table {
  position: relative;
  overflow: hidden;
}

.side-panel {
  grid-area: side;
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  overflow: hidden;
}

.text-center {
  display: flex;
  justify-content: center;
}

.fixed-height-map {
  max-height: 30vh;
  min-height: 30vh;
}

.outlined {
  border: 1px solid #dfe7f0;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import Messages from "../utils/messages";
import CustomerInfo from "../models/CustomerInfo";
import GroupInfo from "../models/GroupInfo";
import EntitiesTable from "../components/EntitiesTable";
import Map from "../components/Map.vue";
import AssetDataRequestBody from "../models/AssetDataRequestBody";
import AssetEventsStatsRequestBody from "../models/AssetEventsStatsRequestBody";
import AssetEventsRequestBody from "../models/AssetEventsRequestBody";
import ItemSelection from "../components/ItemSelection";
import AssetItem from "../components/AssetItem";
import Geocoding from "../components/Geocoding";
import Event from "../models/Event";
import moment from "moment";

export default {
  name: "CustomerDetails",

  components: { EntitiesTable, Map, ItemSelection, AssetItem, Geocoding },

  data: () => ({
    searchTerm: undefined,
    customer: new CustomerInfo(),
    editCustomer: new CustomerInfo(),
    editCustomerDialog: false,
    group: new GroupInfo(),
    editGroup: new GroupInfo(),
    editGroupDialog: false,
    assignAssetsDialog: false,
    selectedAssets: [],
    updating: false,
    loading: false,
    refreshCustomsList: false,
    newKeyDialog: false,
    newKey: undefined,
    geocodingDialog: false,
    alarmsHours: 12,
    eventTypes: Event.types,
    groupTypes: GroupInfo.TYPES,
    highlightedMeasures: [],
    gpsTopics: {
      lon: "GPS/longitude",
      lat: "GPS/latitude",
      altLat: "Position/latitude",
      altLon: "Position/longitude",
    },
    alarmsStats: {
      oneDay: { count: 0, loading: false },
      threeDays: { count: 0, loading: false },
      sevenDays: { count: 0, loading: false },
    },
  }),

  computed: {
    crumbs() {
      return this.$store.getters.pages;
    },
    validCustomer() {
      return !!this.editCustomer.name;
    },
    validGroup() {
      return !!this.editGroup.name;
    },
    morfedAssetsList() {
      return this.assetsList
        .filter((v) => v.companyId == this.currentUser.companyId || "")
        .map((v) => ({ value: v }));
    },
    pairedFilteredAssets() {
      return this.assetsList
        .filter((v) => !!v.boxMacAddress)
        .filter((v) =>
          this.customerId != undefined
            ? v.companyId == this.customerId
            : v.groupId == this.groupId
        );
    },
    filteredCoords() {
      return this.pairedFilteredAssets
        .filter((asset) => asset.matchSearchTerm(this.searchTerm))
        .map((asset) => {
          const measures = this.$store.getters.measuresById(asset.serial);
          let lat =
            measures[this.gpsTopics.lat]?.value?.content ||
            measures[this.gpsTopics.altLat]?.value?.content;
          let lon =
            measures[this.gpsTopics.lon]?.value?.content ||
            measures[this.gpsTopics.altLon]?.value?.content;
          const ts =
            measures[this.gpsTopics.lat]?.value?.ts ||
            measures[this.gpsTopics.altLat]?.value?.ts ||
            asset.modified?.toDate()?.getTime();

          if (!lat && !lon && !!asset.latitude && !!asset.longitude) {
            lat = Number(asset.latitude);
            lon = Number(asset.longitude);
          }

          return !!lat && !!lon && !isNaN(lat) && !isNaN(lon)
            ? {
                coords: [lon, lat],
                ts,
                name: asset.plate || asset.serial,
                id: asset.id,
                serial: asset.serial,
                isGroup: false,
              }
            : undefined;
        })
        .filter((gps) => gps !== undefined);
    },
    events() {
      const validIDs = this.pairedFilteredAssets
        .filter((asset) => asset.matchSearchTerm(this.searchTerm))
        .map((asset) => asset.boxMacAddress);
      const evt = this.$store.getters.allEvents ?? {};
      const evtOut = [];

      Object.keys(evt).forEach((k1) => {
        // Filter by box mac-address
        if (validIDs.includes(k1)) {
          Object.keys(evt[k1]).forEach((k2) => {
            evt[k1][k2].forEach((e) => evtOut.push(e));
          });
        }
      });

      evtOut.sort((a, b) => b.ts - a.ts);

      return evtOut;
    },
    sortingFields() {
      return [
        { text: this.$t("assetPlate"), value: "plate" },
        { text: this.$t("assetBrand"), value: "brand" },
        { text: this.$t("assetModel"), value: "model" },
        { text: this.$t("assetType"), value: "type" },
      ];
    },
    headers() {
      return [
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetStatus"), align: "start", sortable: false, value: "connection", width: "12%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetPlate"), align: "start", sortable: false, value: "plate", width: "12%" },
        // eslint-disable-next-line prettier/prettier
        { text: `${this.$t("assetBrand")} - ${this.$t("assetModel")}`, align: "start", sortable: false, value: "brand" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetType"), align: "center", sortable: false, value: "type" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("assetAlarms"), align: "center", sortable: false, value: "alarmsCount" },
        // eslint-disable-next-line prettier/prettier
        { text: "", align: "end", sortable: false, value: "measuresHighlights" },
      ];
    },
    customerId() {
      return this.$route.params.customerId;
    },
    groupId() {
      return this.$route.params.groupId;
    },
  },

  beforeMount() {
    if (
      this.crumbs.length > 0 &&
      (this.customerId != undefined || this.groupId != undefined)
    ) {
      this.updateFiltersRules(this.clientId);
      this.fetchHighlights();
      this.getAlarmsCount();
      this.getLastAlarms();

      this.$store.commit("setCurrentPage", Pages.CUSTOMER_DETAILS_PAGE);
      this.$store.commit("setCustomCurrentPage", -1);
      if (this.customerId != undefined) {
        this.getCustomer();
      } else {
        this.getGroup();
      }
    } else {
      this.$router.replace({ path: "/dashboard" });
    }
  },

  mounted() {
    this.$store.commit("addPage", {
      text:
        this.customerId != undefined
          ? this.$t("menuCustomerDetails")
          : this.$t("menuGroupDetails"),
      to:
        this.customerId != undefined
          ? `/customers-details/${this.customerId}`
          : `/groups-details/${this.groupId}`,
      root: false,
    });
  },

  beforeDestroy() {
    this.$store.commit("clearEventsList");
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) {
        this.updateFiltersRules(newValue, false);
        this.getAlarmsCount();
      }
    },
    pairedFilteredAssets: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.length > 0 && newValue.length > oldValue.length) {
          this.fetchHighlights();
        }
      },
    },
    filteredCoords: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.length === 1) {
          this.$refs.assetMap.setBoundingBox(
            newValue,
            oldValue.length === newValue.length
          );
        } else if (newValue.length > 1 && oldValue.length === 1) {
          this.$refs.assetMap.setBoundingBox(newValue, false);
        }
      },
    },
  },

  methods: {
    updateFiltersRules(id, getMeasures = true) {
      if (id !== undefined) {
        const topicsList = Object.keys(this.gpsTopics).map(
          (k) => this.gpsTopics[k]
        );

        ApiRequests.createOrUpdateFilter(
          id,
          [
            ...this.signalRBaseRegexes,
            ...topicsList.map((v) => `^measures/@[^@$/]+/${v}`),
            ...this.highlightedMeasures,
          ],
          () => {
            if (!getMeasures) return;

            const requestBody = new AssetDataRequestBody();

            requestBody.paths = topicsList.map((t) => `measures/${t}`);
            requestBody.includeMetadata = false;

            ApiRequests.getSavedMeasures(
              requestBody,
              undefined,
              (res) => {
                Object.keys(res.data).forEach((assetDbId) => {
                  const requestedData = res.data[assetDbId];

                  Object.keys(requestedData).forEach((k) => {
                    this.$store.dispatch("saveMessage", requestedData[k]);
                  });

                  this.$refs.assetMap.setBoundingBox(
                    this.filteredCoords,
                    false
                  );
                });
              },
              (err) =>
                process.env.NODE_ENV === "development"
                  ? console.error(err)
                  : undefined
            );
          },
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    fetchHighlights() {
      Promise.allSettled(
        this.pairedFilteredAssets.map((asset) => {
          const highlights = asset.userInterface.measures.highlights;
          const requestBody = new AssetDataRequestBody();

          requestBody.ids = [asset.id];
          requestBody.paths =
            highlights?.map((t) => {
              const newHighlighted = `measures/@[^@$/]+/${t}`;

              if (!this.highlightedMeasures.includes(newHighlighted)) {
                this.highlightedMeasures.push(newHighlighted);
              }

              return `measures/${t}`;
            }) ?? [];
          requestBody.includeMetadata = true;

          return requestBody.paths.length > 0
            ? ApiRequests.getSavedMeasures(
                requestBody,
                undefined,
                undefined,
                undefined
              )
            : Promise.resolve();
        })
      )
        .then((results) => {
          results.forEach((result) => {
            if (result.status === "fulfilled" && result.value != undefined) {
              const configData = JSON.parse(result.value.config.data);
              const requestedData = result.value.data[configData.ids[0]];

              Object.keys(requestedData).forEach((k) => {
                this.$store.dispatch("saveMessage", requestedData[k]);
              });
            }
          });

          this.updateFiltersRules(this.clientId);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
        });
    },
    getLastAlarms() {
      const eventsRequest = new AssetEventsRequestBody();

      eventsRequest.severities = [
        Event.severity.LOW,
        Event.severity.MEDIUM,
        Event.severity.HIGH,
      ];
      eventsRequest.starting = moment()
        .subtract(this.alarmsHours, "hour")
        .format();

      this.pairedFilteredAssets.forEach((asset) => {
        ApiRequests.getSavedEvents(
          {
            ...eventsRequest,
            serial: asset.serial,
          },
          (res) => {
            this.$store.commit("offAlarmsCount");

            res.data.forEach((e) => {
              const normalized = {};

              Object.keys(e).forEach((k) => {
                normalized[k.charAt(0).toUpperCase() + k.substring(1)] = e[k];
              });

              this.$store.dispatch("saveMessage", normalized);
            });

            this.$store.commit("onAlarmsCount");
          },
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      });
    },
    getAlarmsCount() {
      if (this.pairedFilteredAssets.length === 0) return;

      const statsRequest24h = new AssetEventsStatsRequestBody();
      const statsRequest3d = new AssetEventsStatsRequestBody();
      const statsRequest1w = new AssetEventsStatsRequestBody();
      const pairedSerials = this.pairedFilteredAssets.map((a) => a.serial);
      const severities = [
        Event.severity.HIGH,
        Event.severity.MEDIUM,
        Event.severity.LOW,
      ];

      // Set severities
      statsRequest24h.severities = severities;
      statsRequest3d.severities = severities;
      statsRequest1w.severities = severities;
      // Set customer's/group's serials
      statsRequest24h.serials = pairedSerials;
      statsRequest3d.serials = pairedSerials;
      statsRequest1w.serials = pairedSerials;
      // Set interval grouping
      statsRequest24h.groupInterval = "day";
      statsRequest3d.groupInterval = "day";
      statsRequest1w.groupInterval = "day";
      // Set starting period
      statsRequest24h.starting = moment().subtract(24, "hours").format();
      statsRequest3d.starting = moment().subtract(3, "days").format();
      statsRequest1w.starting = moment().subtract(1, "week").format();

      // Make all requests
      const request = (body, field) => {
        this.alarmsStats[field].loading = true;

        ApiRequests.getSavedEventsStats(
          body,
          (res) => {
            this.alarmsStats[field].count = 0;

            res.data.forEach((stat) => {
              this.alarmsStats[field].count += stat.count;
            });

            this.alarmsStats[field].loading = false;
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.alarmsStats[field].loading = false;
          }
        );
      };

      request(statsRequest24h, "oneDay");
      request(statsRequest3d, "threeDays");
      request(statsRequest1w, "sevenDays");
    },
    getCustomer() {
      const enrichCustomer = (customer) => {
        customer.assets = this.customersList.find(
          (c) => c.id === customer.id
        ).assets;

        return customer;
      };

      if (this.currentUser.isSuperAdmin) {
        ApiRequests.getCustomer(
          this.customerId,
          (res) => (this.customer = enrichCustomer(new CustomerInfo(res.data))),
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      } else {
        ApiRequests.getCurrentCustomer(
          (res) => (this.customer = enrichCustomer(new CustomerInfo(res.data))),
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    getGroup() {
      const enrichGroup = (group) => {
        group.assets = this.groupsList.find((f) => f.id === group.id).assets;

        return group;
      };

      ApiRequests.getGroup(
        this.groupId,
        (res) => (this.group = enrichGroup(new GroupInfo(res.data))),
        (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
      );
    },
    resetCustomer() {
      this.editCustomerDialog = false;
      this.editCustomer = new CustomerInfo();
    },
    resetGroup() {
      this.editGroupDialog = false;
      this.editGroup = new GroupInfo();
    },
    postCustomer() {
      ApiRequests.updateCustomer(
        this.editCustomer.id,
        this.editCustomer.getCompanyUpdateBody(),
        () => {
          this.$bus.$emit(
            Messages.SUCCESS_MSG,
            this.$t("customerDetailUpdateSuccess")
          );
          this.resetCustomer();
          this.getCustomer();
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("customerDetailUpdateFailure"),
          });
        }
      );
    },
    postGroup() {
      ApiRequests.updateGroup(
        this.editGroup.id,
        this.editGroup.getCreateUpdateGroupBody(),
        () => {
          // Update the downloaded group
          const downloaded = this.groupsList.find(
            (g) => g.id === this.group.id
          );

          if (downloaded != undefined) {
            downloaded.name = this.editGroup.name;
            downloaded.type = this.editGroup.type;
            downloaded.functions = [...this.editGroup.functions];
            downloaded.customs = { ...this.editGroup.customs };
            downloaded.lon = this.editGroup.lon;
            downloaded.lat = this.editGroup.lat;
            downloaded.municipality = this.editGroup.municipality;
            downloaded.province = this.editGroup.province;
            downloaded.assets = this.editGroup.assets;
          }

          // Inform the app that the update was successful
          this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("groupUpdateSuccess"));
          this.resetGroup();
          this.getGroup();
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("groupUpdateFailure"),
          });
        }
      );
    },
    updateTags() {
      if (this.group.name != undefined) {
        // Create group tags
        this.pairedFilteredAssets.forEach((v) => {
          if (!this.editGroup.functions.includes(v.type))
            this.editGroup.functions.push(v.type);
          if (!this.editGroup.functions.includes(v.subType))
            this.editGroup.functions.push(v.subType);
        });

        // Cleaning up
        this.editGroup.functions =
          this.pairedFilteredAssets.length > 0
            ? this.editGroup.functions.filter((tag) => !!tag)
            : [];
      }
    },
    updateAssetsBulk() {
      this.updating = true;

      const callback = (res, text1, text2, text3) => {
        const total = this.selectedAssets.length;
        const sent = res.reduce(
          (acc, r) => acc + (r.status === "fulfilled" ? 1 : 0),
          0
        );

        if (total > sent) {
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: text3.replace(/%s%/g, sent).replace(/%t%/g, total),
            description: text2,
          });
        } else {
          this.$bus.$emit(Messages.SUCCESS_MSG, text1);
        }

        this.selectedAssets = [];
        this.assignAssetsDialog = false;
        this.fetchAllData();
        this.updating = false;
      };

      if (this.customerId != undefined) {
        this.selectedAssets.forEach((v) => {
          v.groupId = undefined;
          v.companyId = this.customerId;
        });
      } else if (this.groupId != undefined) {
        this.selectedAssets.forEach((v) => (v.groupId = this.groupId));
      }

      Promise.allSettled(
        this.selectedAssets.map((v, i) => {
          if (this.group.name != undefined) {
            Object.assign(this.editGroup, this.group);

            // Create group tags
            if (!this.editGroup.functions.includes(v.type))
              this.editGroup.functions.push(v.type);
            if (!this.editGroup.functions.includes(v.subType))
              this.editGroup.functions.push(v.subType);

            // Cleaning up
            // eslint-disable-next-line prettier/prettier
            this.editGroup.functions = this.editGroup.functions.filter((tag) => !!tag);

            // Update assets count
            this.editGroup.assets = i + 1;
          }

          return ApiRequests.updateAsset(
            v.id,
            v.getAssetUpdateBody(),
            undefined,
            undefined
          );
        })
      ).then((res) => {
        if (this.group.name != undefined) {
          this.postGroup();
        }

        callback(
          res,
          this.$t("assetUpdateSuccess"),
          this.$t("assetUpdateFailure"),
          "Error: %s% of %t% assets updated"
        );
      });
    },
    handleClick(value) {
      this.$router.push({ path: `/asset-details/${value.id}` });
    },
    useGeocodingCoords(coords) {
      this.editGroup.lat = coords.lat;
      this.editGroup.lon = coords.lon;
      this.geocodingDialog = false;
    },
    manageSearch(value) {
      this.searchTerm = value;
    },
    cloneEntity(entity) {
      return JSON.parse(JSON.stringify(entity));
    },
    openGroup(groupId) {
      if (this.customer.name != undefined) {
        this.$router.push({ path: `/groups-details/${groupId}` });
      }
    },
  },
};
</script>
