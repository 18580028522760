var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c('h1',[_vm._v(_vm._s(_vm.$t("menuPluginsSettings")))]),(_vm.loading)?[_c('div',{staticClass:"d-flex align-center justify-center mt-4"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1)]:[_c('v-tabs',{attrs:{"center-active":""},model:{value:(_vm.pluginTab),callback:function ($$v) {_vm.pluginTab=$$v},expression:"pluginTab"}},_vm._l((_vm.plugins),function(plugin){return _c('v-tab',{key:plugin.id},[_vm._v(" "+_vm._s(plugin.name)+" ")])}),1),_c('v-tabs-items',{staticClass:"mt-6",model:{value:(_vm.pluginTab),callback:function ($$v) {_vm.pluginTab=$$v},expression:"pluginTab"}},_vm._l((_vm.plugins),function(plugin){return _c('v-tab-item',{key:plugin.id + '-tab-item'},[_c('h2',{staticClass:"primary--text d-flex align-center justify-space-between"},[_c('div',[_vm._v(" "+_vm._s(plugin.name)+" "),_c('span',{staticClass:"caption ms-3"},[_vm._v("v"+_vm._s(plugin.version))])]),_c('div',[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.saveSettings(plugin)}}},[_vm._v(" Save settings ")])],1)]),_c('div',{staticClass:"my-8"}),_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"1em"}},_vm._l((_vm.settingsList(plugin.settings)),function(setting){return _c('div',{key:plugin.id + '-' + setting,staticStyle:{"min-width":"32%"}},[(_vm.textFieldType(setting) != 'area')?_c('v-text-field',{attrs:{"label":setting,"rules":[function (v) { return !!v || 'Required'; }],"disabled":_vm.loading,"type":_vm.textFieldType(setting),"readonly":_vm.isMailingList(setting),"clearable":!_vm.isMailingList(setting),"outlined":""},on:{"click":function($event){_vm.isMailingList(setting)
                  ? _vm.openDialog(plugin, setting)
                  : undefined}},model:{value:(plugin.settings[setting]),callback:function ($$v) {_vm.$set(plugin.settings, setting, $$v)},expression:"plugin.settings[setting]"}}):_c('v-textarea',{attrs:{"label":setting,"rules":[function (v) { return !!v || 'Required'; }],"disabled":_vm.loading,"rows":"4","outlined":""},model:{value:(plugin.settings[setting]),callback:function ($$v) {_vm.$set(plugin.settings, setting, $$v)},expression:"plugin.settings[setting]"}})],1)}),0)])}),1)],_c('v-dialog',{attrs:{"width":"unset","persistent":""},model:{value:(_vm.mailingDialog),callback:function ($$v) {_vm.mailingDialog=$$v},expression:"mailingDialog"}},[(_vm.mailingDialog)?_c('v-card',{staticStyle:{"max-width":"100ch"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("setMailingLists")))]),_c('v-card-text',[(Object.keys(_vm.mailingLists).length > 0)?_c('div',_vm._l((Object.keys(_vm.mailingLists)),function(list){return _c('div',{key:list,staticClass:"mb-4"},[_c('v-combobox',{attrs:{"label":list,"outlined":_vm.outlinedPref,"append-icon":"","hide-details":"","multiple":""},on:{"input":function($event){return _vm.updateMailingLists(list, _vm.mailingLists[list])}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"close":"","small":""},on:{"click:close":function($event){_vm.mailingLists[list] = _vm.mailingLists[list].filter(
                      function (i) { return i != item; }
                    );
                    _vm.updateMailingLists(list, _vm.mailingLists[list]);}}},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,true),model:{value:(_vm.mailingLists[list]),callback:function ($$v) {_vm.$set(_vm.mailingLists, list, $$v)},expression:"mailingLists[list]"}})],1)}),0):_c('div',[_vm._v(" "+_vm._s(_vm.$t("noMailingLists"))+" ")]),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"me-4",attrs:{"label":_vm.$t('newMailingList'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.newMailingList),callback:function ($$v) {_vm.newMailingList=$$v},expression:"newMailingList"}}),_c('v-btn',{attrs:{"disabled":!_vm.newMailingList,"color":"primary"},on:{"click":function($event){_vm.addMailingList(_vm.newMailingList);
              _vm.newMailingList = undefined;}}},[_c('v-icon',{staticClass:"me-3"},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("btnAdd"))+" ")],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("btnDone"))+" ")])],1)],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }