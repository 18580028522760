import Vue from "vue";
import i18n from "../plugins/i18n";

class BaseMsg {
  static VUE = new Vue({ i18n });

  _origin = {
    base: undefined,
    id: undefined,
    path: undefined,
    group: undefined,
  };
  _name = undefined;

  constructor(msg = undefined) {
    if (msg != undefined) {
      this._name = msg.Name;

      this._parseOrigin();
    }
  }

  // Getters

  get basePath() {
    return this._origin.base;
  }
  get originId() {
    return this._origin.id;
  }
  get msgPath() {
    return this._origin.path;
  }
  get msgGroup() {
    return this._origin.group;
  }
  get name() {
    return this._name;
  }

  // Private methods

  /**
   * Splits the MQTT path into useful bits.
   * @private
   */
  _parseOrigin() {
    const splittedName = this._name.split("/");

    this._origin.base = splittedName[0];
    this._origin.id = splittedName[1].replace("@", "");
    this._origin.path = splittedName.slice(2, splittedName.length).join("/");
    this._origin.group = splittedName.slice(2, splittedName.length)[0];
  }
}

export default BaseMsg;
