<template>
  <div class="pa-4 pe-8 fill-height settings-container">
    <h1 class="settings-title">{{ $t("menuSettings") }}</h1>

    <v-alert
      v-if="reloadWarning"
      border="left"
      class="reload-warning"
      color="indigo"
      text
      outlined
    >
      <div class="d-flex flex-row justify-space-between align-center">
        {{ $t("reloadBanner") }}
        <v-btn v-on:click="$router.go()" color="indigo" icon>
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </div>
    </v-alert>

    <v-card class="app-settings">
      <v-card-title>
        <v-avatar class="primary">
          <v-icon dark>mdi-table-cog</v-icon>
        </v-avatar>
        <span class="ms-8">{{ $t("appSettings") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form class="d-flex flex-row flex-wrap">
          <v-select
            v-model="selectedLanguage"
            v-bind:items="languages"
            v-bind:label="$t('language')"
            v-bind:outlined="outlinedPref"
            class="mx-2"
          />
        </v-form>
        <template v-if="currentUser.isSuperAdmin">
          <v-divider class="mb-2" />
          <v-form class="d-flex flex-column flex-wrap">
            <v-form class="d-flex flex-grow-0 flex-row flex-wrap">
              <v-select
                v-model="editConfigs.VUE_APP_LOCALES"
                v-bind:items="displayableLanguages"
                v-bind:label="$t('displayableLanguages')"
                v-bind:outlined="outlinedPref"
                v-bind:rules="[(v) => (!!v && v.length > 0) || $t('required')]"
                v-on:change="
                  editConfigs.VUE_APP_I18N_FALLBACK_LOCALE = undefined
                "
                class="mx-2"
                multiple
              />
              <v-select
                v-model="editConfigs.VUE_APP_I18N_FALLBACK_LOCALE"
                v-bind:items="editConfigs.VUE_APP_LOCALES"
                v-bind:label="$t('defaultLanguage')"
                v-bind:outlined="outlinedPref"
                v-bind:rules="[(v) => !!v || $t('required')]"
                v-bind:title="$t('defaultLanguage')"
                class="mx-2 small-locale-selector"
              />
            </v-form>
            <v-form
              class="d-flex flex-row flex-wrap justify-space-between mx-2"
            >
              <v-text-field
                v-model.number="editConfigs.VUE_APP_OFFLINE_TIMEOUT_MINUTES"
                v-bind:label="$t('assetOfflineTimeout')"
                v-bind:title="$t('assetOfflineTimeout')"
                v-bind:rules="rules"
                v-on:click:append-outer="
                  editConfigs.VUE_APP_OFFLINE_TIMEOUT_MINUTES++
                "
                v-on:click:prepend="
                  editConfigs.VUE_APP_OFFLINE_TIMEOUT_MINUTES--
                "
                append-outer-icon="mdi-plus"
                prepend-icon="mdi-minus"
              />
              <v-text-field
                v-model.number="editConfigs.VUE_APP_RETRY_DELAY_MILLIS"
                v-bind:label="$t('signalRConnectionRetry')"
                v-bind:title="$t('signalRConnectionRetry')"
                v-bind:rules="rules"
                v-on:click:append-outer="
                  editConfigs.VUE_APP_RETRY_DELAY_MILLIS++
                "
                v-on:click:prepend="editConfigs.VUE_APP_RETRY_DELAY_MILLIS--"
                append-outer-icon="mdi-plus"
                prepend-icon="mdi-minus"
              />
              <v-spacer />
              <v-switch
                v-model="editConfigs.VUE_APP_OUTLINED_COMPONENTS"
                v-bind:label="$t('outlinedFields')"
                inset
              />
            </v-form>
          </v-form>
        </template>
      </v-card-text>
      <v-card-actions v-if="currentUser.isSuperAdmin">
        <v-spacer />
        <v-btn
          v-bind:disabled="!modifiedSettings"
          v-on:click="resetSettings"
          color="error"
          text
        >
          {{ $t("btnCancel") }}
        </v-btn>
        <v-btn
          v-bind:disabled="!validSettings || !modifiedSettings"
          v-on:click="settingsEdit"
          color="primary"
          text
        >
          {{ $t("btnSave") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="user-settings">
      <div class="d-flex flex-row">
        <v-card-title>
          <v-avatar class="primary">
            <v-icon dark large>
              {{ userIcon }}
            </v-icon>
          </v-avatar>
        </v-card-title>
        <div>
          <v-card-title>{{ $t("userInfo") }}</v-card-title>
          <v-card-subtitle>
            {{ $t("userModified") }}:
            {{ currentUser.readableModified() }}
          </v-card-subtitle>
        </div>
      </div>
      <v-card-text class="d-flex flex-column">
        <v-form class="d-flex flex-row flex-wrap">
          <v-text-field
            v-model="editUser.name"
            v-bind:label="$t('userName')"
            v-bind:rules="[(v) => !!v || $t('required')]"
            v-bind:outlined="outlinedPref"
            class="ma-2"
            clearable
            hide-details
          />
          <v-text-field
            v-model="editUser.surname"
            v-bind:label="$t('userSurname')"
            v-bind:rules="[(v) => !!v || $t('required')]"
            v-bind:outlined="outlinedPref"
            class="ma-2"
            clearable
            hide-details
          />
        </v-form>
        <v-form class="d-flex flex-row flex-wrap">
          <v-text-field
            v-model="editUser.email"
            v-bind:label="$t('userEmail')"
            v-bind:rules="[(v) => !!v || $t('required')]"
            v-bind:outlined="outlinedPref"
            class="ma-2"
            clearable
            hide-details
          />
          <v-select
            v-if="
              currentUser.isAdminNotManager && currentUser.assetIds.length > 0
            "
            v-model="editUser.assetIds"
            v-bind:items="assetsListTextFiltered"
            v-bind:label="$t('customerAssets')"
            v-bind:outlined="outlinedPref"
            item-value="id"
            class="ma-2"
            hide-details
            clearable
            multiple
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="aIdFilter"
                    v-bind:label="$t('searchPlaceholder')"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                  />
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
              <ItemSelection
                v-bind:index="index"
                v-bind:item="item"
                v-bind:array-length="editUser.assetIds.length"
                v-bind:shown-items="2"
                mode="asset"
              />
            </template>
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item
                v-on="on"
                v-bind="attrs"
                #default="{ active }"
                class="my-0"
              >
                <AssetItem
                  v-bind:active="active"
                  v-bind:item="item"
                  mode="asset"
                />
              </v-list-item>
            </template>
          </v-select>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <div>
          <v-btn v-on:click="logout()" color="error" text>
            {{ $t("btnLogout") }}
          </v-btn>
          <v-btn v-on:click="password.dialog = true" color="primary" text>
            {{ $t("userChangePassword") }}
          </v-btn>
        </div>
        <div>
          <v-btn
            v-bind:disabled="!modified"
            v-on:click="resetUser"
            color="error"
            text
          >
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validUser || !modified"
            v-on:click="userEdit"
            color="primary"
            text
          >
            {{ $t("btnSave") }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>

    <v-card class="chart-settings">
      <v-card-title>
        <v-avatar class="primary">
          <v-icon dark>mdi-chart-timeline-variant-shimmer</v-icon>
        </v-avatar>
        <span class="ms-8">{{ $t("chartsSettings") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form class="d-flex flex-row flex-wrap mx-2">
          <v-switch
            v-model="selectedDetailsInterpolate"
            v-bind:label="$t('detailsChartGaps')"
            inset
          />
          <!-- Spacer -->
          <div class="mx-5" />

          <v-switch
            v-model="selectedAnalysisInterpolate"
            v-bind:label="$t('analysisChartGaps')"
            inset
          />
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="commands-settings">
      <v-card-title>
        <v-avatar class="primary">
          <v-icon dark>mdi-cog-transfer</v-icon>
        </v-avatar>
        <span class="ms-8">{{ $t("menuCommandsSettings") }}</span>
      </v-card-title>
      <v-card-text
        v-if="currentUser.isSuperAdmin || currentUser.isAdmin"
        class="d-flex flex-row align-center"
        style="gap: 1em"
      >
        <div class="flex-grow-1 text-body-1">
          {{ $t("numberOfCommands").replace("%n%", commandsCount) }}
        </div>
        <v-btn
          v-on:click="$router.push({ path: '/commands_settings' })"
          class="flex-grow-0"
          color="primary"
        >
          {{ $t("commandsManagementBtn") }}
          <v-icon class="ms-1">mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text v-else>
        <v-alert type="error" outlined text>
          {{ $t("commandsSettingsAdmin") }}
        </v-alert>
      </v-card-text>
    </v-card>

    <v-card v-if="currentUser.isSuperAdmin" class="plugins-settings">
      <v-card-title class="d-flex align-center justify-space-between">
        <div>
          <v-avatar class="primary">
            <v-icon dark>mdi-toy-brick-search-outline</v-icon>
          </v-avatar>
          <span class="ms-8">{{ $t("menuPluginsSettings") }}</span>
        </div>
        <div>
          <v-btn
            v-on:click="$router.push({ path: '/plugins_settings' })"
            color="primary"
          >
            {{ $t("goToPluginsSettings") }}
            <v-icon class="ms-1">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-card-title>
    </v-card>

    <v-dialog
      v-model="password.dialog"
      v-if="password.dialog"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("userChangePassword") }}</v-card-title>
        <v-card-text>
          <div class="mt-2 d-flex flex-grow-0 flex-row flex-wrap">
            <v-text-field
              v-model="password.old"
              v-bind:label="$t('oldPassword')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              class="mx-2"
              type="password"
              v-bind:outlined="outlinedPref"
            />
          </div>
          <div class="mt-2 d-flex flex-grow-0 flex-row flex-wrap">
            <v-text-field
              v-model="password.new"
              v-bind:label="$t('newPassword')"
              v-bind:rules="[
                (v) => !!v || $t('required'),
                (v) => pwdRegExp.test(v) || $t('regExpPassword'),
                (v) => v !== password.old || $t('samePasswordError'),
              ]"
              class="mx-2"
              type="password"
              v-bind:outlined="outlinedPref"
            />
            <v-text-field
              v-model="password.confirm"
              v-bind:label="$t('confirmPassword')"
              v-bind:rules="[
                (v) => !!v || $t('required'),
                (v) => v === password.new || $t('samePasswordWarning'),
              ]"
              class="mx-2"
              type="password"
              v-bind:outlined="outlinedPref"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="resetPassword" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validPassword"
            v-on:click="changePassword"
            color="primary"
            text
          >
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.settings-container {
  display: grid;
  gap: 0.8rem;
  overflow: hidden;
  grid-template-columns: 50% 50%;
  grid-template-rows: min-content min-content min-content min-content min-content;
  grid-auto-rows: 1fr;
  grid-template-areas:
    "title title"
    "reload reload"
    "app user"
    "chart commands"
    "plugins plugins";
}

.settings-title {
  grid-area: title;
}

.app-settings {
  grid-area: app;
}

.user-settings {
  grid-area: user;
}

.chart-settings {
  grid-area: chart;
}

.commands-settings {
  grid-area: commands;
}

.plugins-settings {
  grid-area: plugins;
}

.reload-warning {
  grid-area: reload;
}

.no-overflow {
  max-height: 100%;
  overflow: hidden;
  position: relative;
}

.scrollable {
  position: absolute;
  inset: 0;
  overflow: auto;
}

.small-number-selector {
  max-width: 30%;
}

.small-locale-selector {
  max-width: 12ch;
  min-width: 12ch;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Messages from "../utils/messages";
import Pages from "../utils/pages";
import UserInfo from "../models/UserInfo";
import ItemSelection from "../components/ItemSelection";
import AssetItem from "../components/AssetItem";

export default {
  name: "Settings",

  components: { ItemSelection, AssetItem },

  data: () => ({
    reloadWarning: false,
    maxVal: 10,
    reloadBanner: false,
    editUser: new UserInfo(),
    editConfigs: {},
    password: {
      dialog: false,
      old: undefined,
      new: undefined,
      confirm: undefined,
    },
    aIdFilter: undefined,
  }),

  computed: {
    configsCopy() {
      return {
        VUE_APP_LOCALES: this.$store.getters.array("VUE_APP_LOCALES"),
        // eslint-disable-next-line prettier/prettier
        VUE_APP_I18N_FALLBACK_LOCALE: this.$store.getters.string("VUE_APP_I18N_FALLBACK_LOCALE"),
        // eslint-disable-next-line prettier/prettier
        VUE_APP_OFFLINE_TIMEOUT_MINUTES: this.$store.getters.number("VUE_APP_OFFLINE_TIMEOUT_MINUTES"),
        // eslint-disable-next-line prettier/prettier
        VUE_APP_OUTLINED_COMPONENTS: this.$store.getters.bool("VUE_APP_OUTLINED_COMPONENTS"),
        // eslint-disable-next-line prettier/prettier
        VUE_APP_RETRY_DELAY_MILLIS: this.$store.getters.number("VUE_APP_RETRY_DELAY_MILLIS"),
      };
    },
    languages() {
      return this.$store.getters.array("VUE_APP_LOCALES").map((l) => ({
        text: this.readableLanguage(l),
        value: l,
      }));
    },
    displayableLanguages() {
      return this.$store.getters.array("VUE_APP_ALL_LOCALES").map((l) => ({
        text: this.readableLanguage(l),
        value: l,
      }));
    },
    validUser() {
      return (
        !!this.editUser.email && !!this.editUser.name && !!this.editUser.surname
      );
    },
    validSettings() {
      return (
        !!this.editConfigs.VUE_APP_LOCALES &&
        this.editConfigs.VUE_APP_LOCALES.length > 0 &&
        !!this.editConfigs.VUE_APP_OFFLINE_TIMEOUT_MINUTES &&
        this.editConfigs.VUE_APP_OFFLINE_TIMEOUT_MINUTES > 0 &&
        this.editConfigs.VUE_APP_OUTLINED_COMPONENTS !== undefined &&
        !!this.editConfigs.VUE_APP_RETRY_DELAY_MILLIS &&
        this.editConfigs.VUE_APP_RETRY_DELAY_MILLIS > 0 &&
        !!this.editConfigs.VUE_APP_I18N_FALLBACK_LOCALE
      );
    },
    assetsListTextFiltered() {
      return this.assetsList.filter((asset) =>
        this.aIdFilter ? asset.matchSearchTerm(this.aIdFilter) : true
      );
    },
    modified() {
      return (
        this.editUser.email !== this.currentUser.email ||
        this.editUser.name !== this.currentUser.name ||
        this.editUser.surname !== this.currentUser.surname ||
        this.editUser.assetIds.join(",") !== this.currentUser.assetIds.join(",")
      );
    },
    modifiedSettings() {
      return (
        this.editConfigs.VUE_APP_LOCALES !== this.configsCopy.VUE_APP_LOCALES ||
        this.editConfigs.VUE_APP_OFFLINE_TIMEOUT_MINUTES !==
          this.configsCopy.VUE_APP_OFFLINE_TIMEOUT_MINUTES ||
        this.editConfigs.VUE_APP_OUTLINED_COMPONENTS !==
          this.configsCopy.VUE_APP_OUTLINED_COMPONENTS ||
        this.editConfigs.VUE_APP_RETRY_DELAY_MILLIS !==
          this.configsCopy.VUE_APP_RETRY_DELAY_MILLIS ||
        this.editConfigs.VUE_APP_I18N_FALLBACK_LOCALE !==
          this.configsCopy.VUE_APP_I18N_FALLBACK_LOCALE
      );
    },
    validPassword() {
      return (
        !!this.password.old &&
        !!this.password.new &&
        !!this.password.confirm &&
        this.pwdRegExp.test(this.password.new) &&
        this.password.new !== this.password.old &&
        this.password.new === this.password.confirm
      );
    },
    rules() {
      return [
        (value) => !!value || this.$t("required"),
        (value) => value > 0 || this.$t("noNegative").replace("%i%", 1),
      ];
    },
    commandsCount() {
      return this.$store.getters.editableCommands.length;
    },
  },

  beforeMount() {
    this.updateFiltersRules(this.clientId);

    this.$store.commit("setCurrentPage", Pages.SETTINGS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
    this.resetUser();
    this.resetSettings();
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuSettings"),
      to: "/settings",
      root: true,
    });
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
    currentUser(newValue) {
      Object.assign(this.editUser, newValue);
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    userEdit() {
      const assetsUpdated =
        this.editUser.assetIds.join(",") !==
        this.currentUser.assetIds.join(",");

      ApiRequests.updateUser(
        this.editUser.getUserUpdateBody(),
        () => {
          ApiRequests.userCurrent(
            (res) => {
              this.$store.commit("setCurrentUser", res.data);
              if (assetsUpdated) this.$router.go();
            },
            (err) => {
              if (process.env.NODE_ENV === "development") console.error(err);
              this.$router.go();
            }
          );

          this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("userUpdateSuccess"));
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("userUpdateFailure"),
          });
        }
      );
    },
    settingsEdit() {
      const sendConfig = {
        // eslint-disable-next-line prettier/prettier
        VUE_APP_LOCALES: this.editConfigs.VUE_APP_LOCALES.join(","),
        // eslint-disable-next-line prettier/prettier
        VUE_APP_I18N_LOCALE: this.editConfigs.VUE_APP_I18N_FALLBACK_LOCALE,
        // eslint-disable-next-line prettier/prettier
        VUE_APP_I18N_FALLBACK_LOCALE: this.editConfigs.VUE_APP_I18N_FALLBACK_LOCALE,
        // eslint-disable-next-line prettier/prettier
        VUE_APP_OFFLINE_TIMEOUT_MINUTES: this.editConfigs.VUE_APP_OFFLINE_TIMEOUT_MINUTES,
        // eslint-disable-next-line prettier/prettier
        VUE_APP_OUTLINED_COMPONENTS: this.editConfigs.VUE_APP_OUTLINED_COMPONENTS,
        // eslint-disable-next-line prettier/prettier
        VUE_APP_RETRY_DELAY_MILLIS: this.editConfigs.VUE_APP_RETRY_DELAY_MILLIS
      };

      ApiRequests.updateConfig(
        sendConfig,
        (res) => {
          const updateResults = res.data;
          const result = Object.keys(updateResults)
            .map((k) => updateResults[k])
            .reduce((acc, v) => acc || v, false);

          if (!result) {
            throw "No value has been saved into the database";
          }

          this.reloadWarning = true;
          this.$bus.$emit(
            Messages.SUCCESS_MSG,
            this.$t("settingsUpdateSuccess")
          );
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("settingsUpdateFailure"),
          });
        }
      );
    },
    changePassword() {
      ApiRequests.updatePassword(
        { oldPassword: this.password.old, newPassword: this.password.new },
        () => {
          this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("userPasswordSuccess"));
          this.resetPassword();
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("userPasswordFailure"),
          });
        }
      );
    },
    resetPassword() {
      this.password.dialog = false;
      this.password.old = undefined;
      this.password.new = undefined;
      this.password.confirm = undefined;
    },
    resetUser() {
      Object.assign(this.editUser, this.currentUser);
      this.aIdFilter = undefined;
    },
    resetSettings() {
      this.editConfigs = Object.assign({}, this.configsCopy);
    },
  },
};
</script>
