import moment from "moment";
import BaseMsg from "./BaseMsg";

class Measure extends BaseMsg {
  static types = {
    TEXT: "text",
    JSON: "json",
    NUMERIC: "numeric",
    BOOL: "bool",
    UNKNOWN: "unknown",
  };

  _content = undefined;
  _contentType = undefined;
  _ts = undefined;
  _properties = {};

  _chartTs = undefined;
  _chartContent = undefined;

  metadata = {};

  constructor(measure = undefined) {
    super(measure);

    if (measure != undefined) {
      this._content = measure.Content;
      this._contentType = measure.ContentType.toLowerCase();

      Object.keys(measure.Properties).forEach((k) => {
        if (k === "ts") {
          this._ts = Number(measure.Properties[k]);
        } else {
          this._properties[k] = measure.Properties[k];
        }
      });

      this._parseContent();
    }
  }

  // Getters

  get content() {
    return this._content;
  }
  get contentType() {
    return this._contentType;
  }
  get ts() {
    return this._ts;
  }
  get properties() {
    return this._properties;
  }
  get unit() {
    return this._properties.unit;
  }

  // Getters and setters for temporary data

  get chartTs() {
    return this._chartTs;
  }
  get chartContent() {
    return this._chartContent;
  }
  set chartTs(chartTs) {
    this._chartTs = chartTs;
  }
  set chartContent(chartContent) {
    this._chartContent = chartContent;
  }

  // Public methods

  /**
   * Formats the content in a displayable string.
   * @public
   * @returns {String} - Displayable string.
   */
  formattedContent(truncate = false, amount = 2, temporary = false) {
    let contentToFormat = temporary ? this._chartContent : this._content;
    let value = contentToFormat != undefined ? contentToFormat : "--";

    // Manage JSON
    if (this._contentType == Measure.types.JSON && value !== "--") {
      return (
        value
          .map((v) => v.formattedContent())
          .slice(0, truncate ? amount : undefined)
          .join(", ") + (truncate && amount < value.length ? "..." : "")
      );
    }

    let stringified = `${value}`;

    // Format numbers
    if (this._contentType == Measure.types.NUMERIC && value !== "--") {
      stringified = value.toFixed(this.metadata["$format"]?.content ?? 1);
    }

    const unit =
      this._content != undefined && this._properties.unit != undefined
        ? this._properties.unit
        : "";

    return `${stringified} ${unit}`;
  }

  /**
   * Returns the color of the measure content based on max and min thresholds.
   * @returns {String} - Measure color.
   */
  getTextColor() {
    const max = this.metadata["$ThresholdMax"]?.content;
    const min = this.metadata["$ThresholdMin"]?.content;
    let finalColor = undefined;

    if (
      this._contentType != Measure.types.NUMERIC ||
      this._content == undefined
    ) {
      return finalColor;
    }

    if (min !== undefined && this._content < min) {
      finalColor = "blue--text text--darken-3";
    }

    if (max !== undefined && this._content > max) {
      finalColor = "red--text text--darken-3";
    }

    return finalColor;
  }

  /**
   * Returns the icon and color of the measure based on max and min thresholds.
   * @returns {[String]} - Measure [icon, color].
   */
  getIcon() {
    const max = this.metadata["$ThresholdMax"]?.content;
    const min = this.metadata["$ThresholdMin"]?.content;
    let finalIcon = undefined;

    if (
      this._contentType != Measure.types.NUMERIC ||
      this._content == undefined
    ) {
      return finalIcon;
    }

    if (min !== undefined && this._content < min) {
      finalIcon = ["mdi-arrow-expand-down", "blue darken-3"];
    }

    if (max !== undefined && this._content > max) {
      finalIcon = ["mdi-arrow-expand-up", "red darken-3"];
    }

    return finalIcon;
  }

  /**
   * Returns the unit of measure based on the object type.
   * @public
   * @returns {String} - Unit of measure.
   */
  getUnit() {
    if (
      this._contentType == Measure.types.JSON &&
      this._content !== undefined &&
      this._content.length > 0
    ) {
      return this._content[0].getUnit();
    }

    return this.unit;
  }

  /**
   * Converts the timestamp into a human readable date.
   * @public
   * @param {Boolean} delta - Calculate the time past until now.
   * @returns {String} - Formatted string date.
   */
  readableTs(delta = false) {
    // Measure not available
    if (this._content === undefined) return "--/--/--";

    const ts = moment.utc(this._ts).local();
    const today = moment();

    if (!delta) {
      return ts.format(
        ts.isBefore(today, "day") ? BaseMsg.VUE.$t("tzLocale") : "HH:mm:ss"
      );
    }

    // Calculate the time delta

    const deltaMoments = [
      { tsDelta: today.diff(ts, "days"), des: "daysAgo" },
      { tsDelta: today.diff(ts, "hours"), des: "hoursAgo" },
      { tsDelta: today.diff(ts, "minutes"), des: "minutesAgo" },
      { tsDelta: today.diff(ts, "seconds"), des: "secondsAgo" },
    ];
    const deltaMoment = deltaMoments.find((d) => d.tsDelta > 0);

    return deltaMoment != undefined
      ? `${deltaMoment.tsDelta} ${BaseMsg.VUE.$t(deltaMoment.des)}`
      : BaseMsg.VUE.$t("now");
  }

  /**
   * Determines if this measure is newer than another.
   * @public
   * @param {Measure} comparableMeasure - Measure to compare this instance against.
   * @returns {Boolean} - Returns `true` if this instance is more recent, `false` otherwise.
   */
  isNewerThan(comparableMeasure) {
    return comparableMeasure != undefined
      ? this._ts >= comparableMeasure.ts
      : true;
  }

  /**
   * Determines if this measure can be displayed on a plot.
   * @public
   * @returns {Boolean} - Returns `true` if this measure has a plottable type, `false` otherwise.
   */
  isPlottable() {
    return this._contentType === Measure.types.NUMERIC;
  }

  /**
   * Useful for filtering measure.
   * @public
   * @param {String} searchTerm - The word to search in this measure.
   * @returns {Boolean} - Returns `true` if the searched word is contained in this measure, `false` otherwise.
   */
  matchSearchTerm(searchTerm) {
    const s = (searchTerm || "").toLowerCase();

    return (
      (this._name || "").toLowerCase().includes(s) ||
      (this._content?.toString() || "").toLowerCase().includes(s) ||
      (this._contentType || "").toLowerCase().includes(s) ||
      this.readableTs().toLowerCase().includes(s) ||
      BaseMsg.VUE.$t(this._origin.path || "")
        .toLowerCase()
        .includes(s) ||
      Object.keys(this._properties)
        .map((k) => (this._properties[k] || "").toLowerCase().includes(s))
        .reduce((acc, m) => acc || m, false)
    );
  }

  // Private methods

  /**
   * Converts the measure payload onto it's type.
   * @private
   */
  _parseContent() {
    let parsed = undefined;

    if (this._content == undefined) return;

    switch (this._contentType) {
      case Measure.types.NUMERIC:
        parsed = Number.isNaN(this._content)
          ? undefined
          : Number(this._content);
        break;
      case Measure.types.JSON:
        try {
          parsed = JSON.parse(this._content);
          parsed = Object.keys(parsed).map((key) => {
            return new Measure({
              Name: this.name + "_" + key,
              Content: parsed[key],
              ContentType: this._getContentType(parsed[key]),
              Properties: { ts: this._ts, ...this.properties },
            });
          });
        } catch {
          /* Received some weird data */
          parsed = undefined;
        }
        break;
      case Measure.types.BOOL:
        parsed = new RegExp("^1|y|yes|true|on$").test(this._content);
        break;
      default:
        parsed = this._content;
        break;
    }

    this._content = parsed;
  }

  /**
   * Map a JSON value to its type.
   * @private
   */
  _getContentType(value) {
    switch (typeof value) {
      case "string":
        return Measure.types.TEXT;
      case "boolean":
        return Measure.types.BOOL;
      case "number":
        return Measure.types.NUMERIC;
      case "object":
        return Measure.types.JSON;
      default:
        return Measure.types.UNKNOWN;
    }
  }
}

export default Measure;
