class AssetEventsStats {
  _count = 0;
  _timeSlot = undefined;
  _severity = undefined;
  _period = undefined;

  constructor(eventsStats = undefined) {
    if (eventsStats != undefined) {
      this._count = eventsStats.count;
      this._timeSlot = eventsStats.timeSlot;
      this._severity = eventsStats.severity;
      this._period = eventsStats.period;
    }
  }

  // Getters

  get count() {
    return this._count;
  }
  get timeSlot() {
    return this._timeSlot;
  }
  get severity() {
    return this._severity;
  }
  get period() {
    return this._period;
  }
}

export default AssetEventsStats;
