<template>
  <v-dialog v-bind:value="open" width="unset" persistent scrollable>
    <v-card v-if="open" style="min-width: 50ch">
      <v-card-title>
        {{ $t("timePartitioning") }}
      </v-card-title>

      <v-card-text>
        <div class="mb-2">
          <v-text-field
            v-model="partitionModel.text"
            v-bind:outlined="outlinedPref"
            v-bind:label="$t('partitionName')"
            v-bind:rules="[required]"
            clearable
          />
        </div>

        <div>
          <v-btn v-on:click="addNewRange" color="primary" small block>
            <v-icon class="me-2">mdi-plus</v-icon>
            {{ $t("addTimeRange") }}
          </v-btn>
        </div>

        <div>
          <div
            v-for="(partition, id) in partitionModel.value"
            v-bind:key="'timePartitionRange-' + id"
            class="with-divider py-2 d-flex align-center"
          >
            <div class="me-4">
              <v-btn
                v-bind:color="partition.color"
                v-bind:title="$t('btnColor')"
                v-on:click="
                  rangeSelectedColor = partition.color;
                  rangeSelected = id;
                "
                elevation="1"
                small
                fab
              >
                <v-icon color="white" small>mdi-clock</v-icon>
              </v-btn>
            </div>
            <div>
              <v-text-field
                v-model="partition.name"
                v-bind:outlined="outlinedPref"
                v-bind:label="$t('timeRangeName')"
                hide-details
              />
              <div class="d-flex align-baseline">
                <v-text-field
                  v-model="partition.start"
                  v-bind:outlined="outlinedPref"
                  v-bind:label="$t('timeRangeStart')"
                  v-bind:rules="[validateTime]"
                  style="max-width: 17ch"
                />
                <div class="mx-3">
                  <v-icon>mdi-arrow-right</v-icon>
                </div>
                <v-text-field
                  v-model="partition.end"
                  v-bind:outlined="outlinedPref"
                  v-bind:label="$t('timeRangeEnd')"
                  v-bind:rules="[validateTime]"
                  style="max-width: 17ch"
                />
                <div class="mx-3">
                  <v-icon>mdi-drag-vertical</v-icon>
                </div>
                <v-select
                  v-model="partition.applicableDays"
                  v-bind:items="days"
                  v-bind:outlined="outlinedPref"
                  v-bind:label="$t('timeRangeDays')"
                  style="max-width: 25ch"
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <template v-if="index < 2">
                      {{ item.text }}
                      <span
                        v-if="
                          partition.applicableDays != undefined &&
                          partition.applicableDays.length > 1 &&
                          index !== partition.applicableDays.length - 1
                        "
                        class="me-1"
                      >
                        ,
                      </span>
                    </template>
                    <template v-else-if="index === 2">...</template>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="ms-4">
              <v-btn
                v-bind:title="$t('btnDelete')"
                v-on:click="deleteFromPartition(id)"
                color="error"
                text
                fab
                small
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn v-on:click="partitionCancel" color="error" text>
          {{ $t("btnCancel") }}
        </v-btn>
        <v-btn
          v-bind:disabled="!validEntry"
          v-on:click="partitionAdd"
          color="primary"
          text
        >
          {{ edit === undefined ? $t("btnAdd") : $t("btnSave") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-if="rangeSelected >= 0"
      v-bind:value="true"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-text class="pt-4">
          <v-color-picker
            v-model="rangeSelectedColor"
            dot-size="25"
            mode="hex"
            hide-inputs
          />
          <v-text-field
            v-model="rangeSelectedColor"
            v-bind:outlined="outlinedPref"
            label="Hex"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="rangeSelected = -1" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-on:click="
              partitionModel.value[rangeSelected].color = rangeSelectedColor;
              rangeSelected = -1;
            "
            color="primary"
            text
          >
            {{ $t("btnColor") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<style scoped>
.with-divider {
  border-bottom: 1px solid #42424242;
}
</style>

<script>
import chroma from "chroma-js";
import moment from "moment";

export default {
  name: "AddTimePartitionDialog",

  props: {
    open: { type: Boolean, required: true },
    edit: { type: Object, required: false },
  },

  data: () => ({
    rangeSelected: -1,
    rangeSelectedColor: "#000000",
    days: moment.weekdays(false).map((wd, id) => ({ text: wd, value: id })),
    partitionModel: {
      text: undefined,
      value: [],
    },
  }),

  computed: {
    validEntry() {
      return (
        !!this.partitionModel.text &&
        this.partitionModel.value.length > 0 &&
        this.partitionModel.value.reduce((a, c) => {
          return a && this.validTime(c.start) && this.validTime(c.end);
        }, true)
      );
    },
  },

  watch: {
    open(newValue, oldValue) {
      if (newValue !== oldValue && newValue && this.edit !== undefined) {
        this.partitionModel = {
          text: this.edit.text,
          value: this.edit.value.map((range) => {
            return {
              ...range,
              start: range.startString,
              end: range.endString,
            };
          }),
        };
      }
    },
  },

  methods: {
    resetModel() {
      this.partitionModel = {
        text: undefined,
        value: [],
      };
    },
    partitionCancel() {
      this.$emit("close");
      this.resetModel();
    },
    partitionAdd() {
      this.$emit("add", this.partitionModel);
      this.resetModel();
    },
    addNewRange() {
      this.partitionModel.value.push({
        applicableDays: undefined,
        start: "00:00",
        end: "24:00",
        color: chroma.random().hex("rgb"),
        name: "",
      });
    },
    deleteFromPartition(id) {
      this.partitionModel.value.splice(id, 1);
    },
    validTime(value) {
      const pattern = /^\d{2}:\d{2}$/;
      return !!value && pattern.test(value);
    },
    validateTime(value) {
      return this.validTime(value) || this.$t("timeRangeFormatError");
    },
    required(value) {
      return !!value || this.$t("required");
    },
  },
};
</script>
