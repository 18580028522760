class Messages {
  /* Constants */
  static SEARCH_MSG = "new-search-term";
  static SIGNALR_RECONNECTING_MSG = "signalr-reconnecting";
  static SIGNALR_CONNECTED_MSG = "signalr-connected";
  static SIGNALR_DISCONNECTED_MSG = "signalr-disconnected";
  static ERROR_MSG = "present-error";
  static SUCCESS_MSG = "present-success";
  static RESET_TABLE_SELECTED = "table-reset-selected";
  static FEATURE_CLICKED = "map-feature-clicked";
  static FEATURE_GROUP_CLICKED = "map-group-clicked";

  static SIGNALR_BASE_REGEXES = ["^events/@[^@$/]+"];
}

export default Messages;
