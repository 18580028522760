<template>
  <div>
    <BarChart id="category-bar-chart" v-bind="chartSettings" />
  </div>
</template>

<style scoped>
#category-bar-chart {
  max-height: 100%;
  max-width: 100%;
}
</style>

<script>
import { BarChart } from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ref } from "@vue/composition-api";

export default {
  name: "CategoryBars",

  components: { BarChart },

  props: {
    data: { type: Array, required: true },
    dataType: { type: String, required: true },
    xTop: { type: Boolean, required: false, default: false },
    labelsRotation: { type: Number, required: false, default: 0 },
  },

  data: () => ({
    chartSettings: {},
    chartData: {
      labels: ref([]),
      datasets: ref([]),
    },
  }),

  watch: {
    data: {
      deep: true,
      handler(newData) {
        if (newData.length === 0) return;

        this.emptyRefs();

        newData
          .map((d) => d.name)
          .forEach((name) => this.chartData.labels.value.push(name));

        this.chartData.datasets.value.push({
          label: "CategoryBars",
          data: newData.map((d) => d.value),
          backgroundColor: "#0c1d69", // Primary
          borderRadius: 4,
        });
      },
    },
  },

  beforeMount() {
    this.chartSettings = {
      chartData: {
        labels: this.chartData.labels.value,
        datasets: this.chartData.datasets.value,
      },
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        scales: {
          x: {
            type: "category",
            position: this.xTop ? "top" : "bottom",
            ticks: {
              autoSkip: false,
              maxRotation: this.labelsRotation,
              minRotation: this.labelsRotation,
            },
          },
          y: {
            beginAtZero: true,
            reverse: this.xTop,
            ticks: { stepSize: 1 },
          },
        },
        plugins: {
          zoom: false,
          crosshair: false,
          legend: false,
          tooltip: {
            position: "middle",
            callbacks: {
              label: (tooltipItem) =>
                // eslint-disable-next-line prettier/prettier
                ` ${tooltipItem.formattedValue} ${this.$t(this.dataType)}`,
            },
          },
          datalabels: {
            color: "#000000",
            font: { weight: "bold" },
            clip: true,
            clamp: true,
            anchor: this.xTop ? "start" : "end",
            align: this.xTop ? "start" : "end",
          },
        },
      },
    };
  },

  methods: {
    emptyRefs() {
      this.chartData.labels.value.length = 0;
      this.chartData.datasets.value.length = 0;
    },
  },
};
</script>
