<template>
  <div
    v-if="index <= shownItems"
    class="me-1 pa-1 rounded indigo lighten-5 primary--text d-flex"
  >
    <template v-if="index < shownItems">
      <div class="caption primary--text text--lighten-2">
        <template v-if="mode === 'asset'">
          <span v-if="item.plate != undefined">
            <span class="font-weight-bold">
              {{ item.plate }}
            </span>
            -
          </span>
          <span>{{ item.brand }} {{ item.model }}</span>
        </template>
        <template v-else-if="mode === 'topic'">
          {{ $t(item.topic) }}
        </template>
        <template v-else-if="mode === 'entityCode'">
          <span class="font-weight-bold">
            {{ item.brand }} {{ item.model }}
          </span>
          {{ item.version }}
        </template>
      </div>
      <div
        v-if="mode === 'asset'"
        class="ms-1 caption primary--text text--lighten-2"
      >
        ({{ item.serial }})
      </div>
    </template>
    <template v-else>
      <div class="caption primary--text text--lighten-2">
        {{ $t("nMore").replace("%n%", arrayLength - shownItems) }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ItemSelection",

  props: {
    index: { type: Number, required: true },
    item: { type: Object, required: true },
    arrayLength: { type: Number, required: true },
    shownItems: { type: Number, required: true },
    mode: { type: String, required: true },
  },
};
</script>
