<template>
  <div class="map-view" ref="map-container">
    <vl-map
      v-bind:controls="false"
      v-bind:load-tiles-while-animating="true"
      v-bind:load-tiles-while-interacting="true"
      data-projection="EPSG:4326"
      class="map-view"
      name="eye-map"
      ref="map"
    >
      <vl-view
        v-bind:zoom.sync="zoom"
        v-bind:center.sync="center"
        v-bind:rotation.sync="rotation"
        name="eye-map-view"
      />

      <vl-layer-tile name="eye-map-tiles">
        <vl-source-osm v-if="mapTilesService == undefined" name="eye-map-osm" />
        <vl-source-xyz v-else v-bind:url="mapTilesService" name="eye-map-xyz" />
      </vl-layer-tile>

      <div v-for="feature in features" v-bind:key="feature.id">
        <vl-overlay
          v-bind:position="feature.coords"
          v-bind:name="'eye-map-overlay-' + feature.id"
        >
          <div
            v-bind:class="{
              centering: true,
              selected:
                (!feature.isGroup && feature.serial === selected) ||
                feature.id === selectedGroup,
            }"
            v-bind:asset-name="feature.name"
            v-bind:timestamp="
              !feature.isGroup
                ? $t('sampled') + ': ' + getReadableTimestamp(feature.ts, false)
                : undefined
            "
          >
            <v-icon
              v-bind:color="getPinColor(alarmsCountById[feature.id])"
              v-on:click="featureClicked(feature)"
              x-large
            >
              {{ feature.isGroup ? "mdi-folder-marker" : "mdi-map-marker" }}
            </v-icon>
          </div>
        </vl-overlay>
      </div>

      <v-btn
        v-on:click="
          autoZoom = true;
          setBoundingBox();
        "
        class="blue darken-4 re-center"
        dark
        icon
        small
      >
        <v-icon small>mdi-image-filter-center-focus</v-icon>
      </v-btn>
    </vl-map>
  </div>
</template>

<style scoped>
.map-view {
  position: relative;
  height: 100%;
  width: 100%;
}

.centering {
  cursor: pointer;
  transform: translate(-50%, -100%);
}

.selected {
  border-radius: 50%;
  animation: pulse 1s infinite;
}

.re-center {
  position: absolute;
  z-index: 1;
  top: 1em;
  right: 1em;
  box-shadow: 0 0 10px 0 #ffffff;
  opacity: 0.6;
}

div[asset-name] {
  position: relative;
}

div[asset-name]:hover:before {
  content: attr(asset-name);
  position: absolute;
  background: #e7e7e786;
  padding: 0.6ch;
  border-radius: 4px;
  backdrop-filter: blur(5px);
  white-space: nowrap;
  font-weight: bold;
  font-size: 1em;
  left: 100%;
  animation: pop 0.3s ease-in-out;
}

div[timestamp]:hover:after {
  content: attr(timestamp);
  position: absolute;
  background: #e7e7e786;
  padding: 0.6ch;
  border-radius: 4px;
  backdrop-filter: blur(5px);
  white-space: nowrap;
  font-size: 0.7em;
  top: 90%;
  animation: pop 0.3s ease-in-out;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 4px 0 transparent;
    background: transparent;
  }
  35% {
    box-shadow: 0 0 4px 1em #79c4e784;
    background: #79c4e784;
  }
  100% {
    box-shadow: 0 0 4px 0 transparent;
    background: transparent;
  }
}

@keyframes pop {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>

<script>
import "vuelayers/lib/style.css";
import Messages from "../utils/messages";
import Event from "../models/Event";

export default {
  name: "Map",

  props: {
    features: { type: Array, required: true },
    selected: { type: String, required: false },
    selectedGroup: { type: String, required: false },
  },

  data: () => ({
    rotation: 0,
    center: [0, 0],
    zoom: 2,
  }),

  computed: {
    mapTilesService() {
      return (
        this.$store.getters.string("VUE_APP_MAP_TILES_SERVICE")?.trim() ||
        undefined
      );
    },
  },

  mounted() {
    // Fit the features only when the map is loaded
    this.$refs.map.$once("postrender", () => this.setBoundingBox());
  },

  methods: {
    setBoundingBox(coordsGroup = undefined, centerOnly = false) {
      const coords = coordsGroup
        ? coordsGroup.map((f) => f.coords)
        : this.features.map((f) => f.coords);

      if (coords.length === 0) {
        return;
      }

      if (coords.length === 1) {
        this.center = coords[0];
        if (!centerOnly) this.zoom = 17;

        return;
      }

      // Calculate the coordinates center point
      this.center = this.boxCenter(coords);

      // Calculate the coordinates zoom level
      if (!centerOnly) this.zoom = this.boxZoom(coords);
    },
    boxCenter(coordinates) {
      let x = 0;
      let y = 0;
      let z = 0;

      coordinates.forEach((c) => {
        const latitude = this.radDegCoverter(c[1], true);
        const longitude = this.radDegCoverter(c[0], true);

        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
      });

      x = x / coordinates.length;
      y = y / coordinates.length;
      z = z / coordinates.length;

      return [
        this.radDegCoverter(Math.atan2(y, x), false),
        this.radDegCoverter(Math.atan2(z, Math.sqrt(x * x + y * y)), false),
      ];
    },
    boxZoom(coordinates) {
      // eslint-disable-next-line prettier/prettier
      const mapHeight = this.$refs["map-container"].clientHeight;
      const mapWidth = this.$refs["map-container"].clientWidth;
      const worldDim = { height: 256, with: 256 };
      const padding = 50;
      const maxZoom = 21;

      let lefter = undefined;
      let righter = undefined;
      let topper = undefined;
      let bottomer = undefined;

      coordinates.forEach((c) => {
        if (lefter === undefined || c[0] <= lefter[0]) {
          lefter = c;
        }

        if (righter === undefined || c[0] >= righter[0]) {
          righter = c;
        }

        if (topper === undefined || c[1] >= topper[1]) {
          topper = c;
        }

        if (bottomer === undefined || c[1] <= bottomer[1]) {
          bottomer = c;
        }
      });

      // eslint-disable-next-line prettier/prettier
      const latFraction = (this.latRad(topper[1]) - this.latRad(bottomer[1])) / Math.PI;
      const lonDiff = righter[0] - lefter[0];
      const lonFraction = (lonDiff < 0 ? lonDiff + 360 : lonDiff) / 360;

      // eslint-disable-next-line prettier/prettier
      const latZoom = this.zoomLvl(mapHeight - padding, worldDim.height, latFraction);
      // eslint-disable-next-line prettier/prettier
      const lonZoom = this.zoomLvl(mapWidth - padding, worldDim.with, lonFraction);

      return Math.min(latZoom, lonZoom, maxZoom);
    },
    latRad(lat) {
      const sin = Math.sin(this.radDegCoverter(lat));
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;

      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    },
    zoomLvl(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    },
    radDegCoverter(number, toRad = true) {
      return toRad ? (number * Math.PI) / 180 : (number * 180) / Math.PI;
    },
    featureClicked(feature) {
      this.$emit(
        feature.isGroup
          ? Messages.FEATURE_GROUP_CLICKED
          : Messages.FEATURE_CLICKED,
        feature.id
      );
    },
    getPinColor(alarmsCount) {
      if (alarmsCount == undefined) return "primary";

      const low = alarmsCount[Event.severity.LOW];
      const medium = alarmsCount[Event.severity.MEDIUM];
      const high = alarmsCount[Event.severity.HIGH];

      if (high > 0) return "#ED553B";
      if (medium > 0) return "#F6D55C";
      if (low > 0) return "#3CAEA3";

      return "primary";
    },
  },
};
</script>
