class CommandArgument {
  static TYPES = ["STRING", "NUMBER", "BOOL", "STRING ARRAY", "NUMBER ARRAY"];

  name = undefined;
  description = undefined;
  type = CommandArgument.TYPES[0];
  constant = false;
  value = undefined;
}

export default CommandArgument;
