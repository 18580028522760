var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.open,"width":"unset","persistent":"","scrollable":""}},[(_vm.open)?_c('v-card',{staticStyle:{"min-width":"50ch"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("timePartitioning"))+" ")]),_c('v-card-text',[_c('div',{staticClass:"mb-2"},[_c('v-text-field',{attrs:{"outlined":_vm.outlinedPref,"label":_vm.$t('partitionName'),"rules":[_vm.required],"clearable":""},model:{value:(_vm.partitionModel.text),callback:function ($$v) {_vm.$set(_vm.partitionModel, "text", $$v)},expression:"partitionModel.text"}})],1),_c('div',[_c('v-btn',{attrs:{"color":"primary","small":"","block":""},on:{"click":_vm.addNewRange}},[_c('v-icon',{staticClass:"me-2"},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("addTimeRange"))+" ")],1)],1),_c('div',_vm._l((_vm.partitionModel.value),function(partition,id){return _c('div',{key:'timePartitionRange-' + id,staticClass:"with-divider py-2 d-flex align-center"},[_c('div',{staticClass:"me-4"},[_c('v-btn',{attrs:{"color":partition.color,"title":_vm.$t('btnColor'),"elevation":"1","small":"","fab":""},on:{"click":function($event){_vm.rangeSelectedColor = partition.color;
                _vm.rangeSelected = id;}}},[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-clock")])],1)],1),_c('div',[_c('v-text-field',{attrs:{"outlined":_vm.outlinedPref,"label":_vm.$t('timeRangeName'),"hide-details":""},model:{value:(partition.name),callback:function ($$v) {_vm.$set(partition, "name", $$v)},expression:"partition.name"}}),_c('div',{staticClass:"d-flex align-baseline"},[_c('v-text-field',{staticStyle:{"max-width":"17ch"},attrs:{"outlined":_vm.outlinedPref,"label":_vm.$t('timeRangeStart'),"rules":[_vm.validateTime]},model:{value:(partition.start),callback:function ($$v) {_vm.$set(partition, "start", $$v)},expression:"partition.start"}}),_c('div',{staticClass:"mx-3"},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1),_c('v-text-field',{staticStyle:{"max-width":"17ch"},attrs:{"outlined":_vm.outlinedPref,"label":_vm.$t('timeRangeEnd'),"rules":[_vm.validateTime]},model:{value:(partition.end),callback:function ($$v) {_vm.$set(partition, "end", $$v)},expression:"partition.end"}}),_c('div',{staticClass:"mx-3"},[_c('v-icon',[_vm._v("mdi-drag-vertical")])],1),_c('v-select',{staticStyle:{"max-width":"25ch"},attrs:{"items":_vm.days,"outlined":_vm.outlinedPref,"label":_vm.$t('timeRangeDays'),"multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [(index < 2)?[_vm._v(" "+_vm._s(item.text)+" "),(
                        partition.applicableDays != undefined &&
                        partition.applicableDays.length > 1 &&
                        index !== partition.applicableDays.length - 1
                      )?_c('span',{staticClass:"me-1"},[_vm._v(" , ")]):_vm._e()]:(index === 2)?[_vm._v("...")]:_vm._e()]}}],null,true),model:{value:(partition.applicableDays),callback:function ($$v) {_vm.$set(partition, "applicableDays", $$v)},expression:"partition.applicableDays"}})],1)],1),_c('div',{staticClass:"ms-4"},[_c('v-btn',{attrs:{"title":_vm.$t('btnDelete'),"color":"error","text":"","fab":"","small":""},on:{"click":function($event){return _vm.deleteFromPartition(id)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)])}),0)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.partitionCancel}},[_vm._v(" "+_vm._s(_vm.$t("btnCancel"))+" ")]),_c('v-btn',{attrs:{"disabled":!_vm.validEntry,"color":"primary","text":""},on:{"click":_vm.partitionAdd}},[_vm._v(" "+_vm._s(_vm.edit === undefined ? _vm.$t("btnAdd") : _vm.$t("btnSave"))+" ")])],1)],1):_vm._e(),(_vm.rangeSelected >= 0)?_c('v-dialog',{attrs:{"value":true,"width":"unset","persistent":""}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-4"},[_c('v-color-picker',{attrs:{"dot-size":"25","mode":"hex","hide-inputs":""},model:{value:(_vm.rangeSelectedColor),callback:function ($$v) {_vm.rangeSelectedColor=$$v},expression:"rangeSelectedColor"}}),_c('v-text-field',{attrs:{"outlined":_vm.outlinedPref,"label":"Hex"},model:{value:(_vm.rangeSelectedColor),callback:function ($$v) {_vm.rangeSelectedColor=$$v},expression:"rangeSelectedColor"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.rangeSelected = -1}}},[_vm._v(" "+_vm._s(_vm.$t("btnCancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.partitionModel.value[_vm.rangeSelected].color = _vm.rangeSelectedColor;
            _vm.rangeSelected = -1;}}},[_vm._v(" "+_vm._s(_vm.$t("btnColor"))+" ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }