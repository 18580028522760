var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"map-container",staticClass:"map-view"},[_c('vl-map',{ref:"map",staticClass:"map-view",attrs:{"controls":false,"load-tiles-while-animating":true,"load-tiles-while-interacting":true,"data-projection":"EPSG:4326","name":"eye-map"}},[_c('vl-view',{attrs:{"zoom":_vm.zoom,"center":_vm.center,"rotation":_vm.rotation,"name":"eye-map-view"},on:{"update:zoom":function($event){_vm.zoom=$event},"update:center":function($event){_vm.center=$event},"update:rotation":function($event){_vm.rotation=$event}}}),_c('vl-layer-tile',{attrs:{"name":"eye-map-tiles"}},[(_vm.mapTilesService == undefined)?_c('vl-source-osm',{attrs:{"name":"eye-map-osm"}}):_c('vl-source-xyz',{attrs:{"url":_vm.mapTilesService,"name":"eye-map-xyz"}})],1),_vm._l((_vm.features),function(feature){return _c('div',{key:feature.id},[_c('vl-overlay',{attrs:{"position":feature.coords,"name":'eye-map-overlay-' + feature.id}},[_c('div',{class:{
            centering: true,
            selected:
              (!feature.isGroup && feature.serial === _vm.selected) ||
              feature.id === _vm.selectedGroup,
          },attrs:{"asset-name":feature.name,"timestamp":!feature.isGroup
              ? _vm.$t('sampled') + ': ' + _vm.getReadableTimestamp(feature.ts, false)
              : undefined}},[_c('v-icon',{attrs:{"color":_vm.getPinColor(_vm.alarmsCountById[feature.id]),"x-large":""},on:{"click":function($event){return _vm.featureClicked(feature)}}},[_vm._v(" "+_vm._s(feature.isGroup ? "mdi-folder-marker" : "mdi-map-marker")+" ")])],1)])],1)}),_c('v-btn',{staticClass:"blue darken-4 re-center",attrs:{"dark":"","icon":"","small":""},on:{"click":function($event){_vm.autoZoom = true;
        _vm.setBoundingBox();}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-image-filter-center-focus")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }