class BoxCommand {
  static types = {
    TEXT: "text",
    NUMERIC: "numeric",
    BOOL: "bool",
    JSON: "json",
  };

  _description = undefined;
  _group = undefined;
  _field = undefined;
  _type = "unknown";
  _assetIds = [];
  _sendArgs = {};
  _companyId = undefined;

  /* Getters */

  get name() {
    return `${this._group}/${this._field}`;
  }
  get description() {
    return this._description;
  }
  get group() {
    return this._group;
  }
  get field() {
    return this._field;
  }
  get type() {
    return this._type;
  }
  get assetIds() {
    return this._assetIds;
  }
  get arguments() {
    return this._sendArgs;
  }
  get companyId() {
    return this._companyId;
  }

  /* Setters */

  set name(name) {
    this._group = name.split("/")[0];
    this._field = name.split("/")[1];
  }
  set description(description) {
    this._description = description;
  }
  set type(type) {
    this._type = type;
  }
  set assetIds(assetIds) {
    this._assetIds = assetIds;
  }
  set arguments(args) {
    this._sendArgs = args;
  }
  set companyId(id) {
    this._companyId = id;
  }

  /* Methods */

  getBoxCommandBody() {
    let valueToSend = undefined;

    if (this._sendArgs.wrap) {
      valueToSend = {};

      this._sendArgs.args.forEach((arg) => {
        if (
          (arg.value === undefined || arg.value == "" || arg.value === null) &&
          typeof arg.value !== "boolean"
        ) {
          return;
        }

        switch (arg.type) {
          case "NUMBER": {
            const num = Number(arg.value);
            if (Number.isNaN(num)) break;
            valueToSend[arg.name] = num;
            break;
          }
          case "BOOL":
            valueToSend[arg.name] = `${arg.value}`.toLowerCase() == "true";
            break;
          case "NUMBER ARRAY":
            valueToSend[arg.name] = arg.value.map((num) => Number(num));
            break;
          default:
            valueToSend[arg.name] = arg.value;
            break;
        }
      });

      valueToSend = JSON.stringify(valueToSend);
    } else {
      if (this._sendArgs.args[0].type == "NUMBER ARRAY") {
        valueToSend = JSON.stringify(
          this._sendArgs.args[0].value.map((num) => Number(num))
        );
      } else if (this._sendArgs.args[0].type.includes("ARRAY")) {
        valueToSend = JSON.stringify(this._sendArgs.args[0].value);
      } else {
        valueToSend = this._sendArgs.args[0].value;
      }
    }

    return {
      group: this._group,
      field: this._field,
      value: `${valueToSend}`,
      assetIds: this._assetIds,
      companyId: this._companyId ?? "",
      arguments: {},
      type:
        BoxCommand.types[this._type] != undefined
          ? BoxCommand.types[this._type]
          : this._type,
    };
  }
}

export default BoxCommand;
