<template>
  <v-layout
    align-center
    v-bind:justify-start="justifyStart"
    v-bind:justify-center="justifyCenter"
    v-bind:justify-end="justifyEnd"
  >
    <div v-bind:class="'circle me-2 ' + color" />
    <span v-if="text !== undefined" class="body-2">{{ text }}</span>
  </v-layout>
</template>

<style scoped>
.circle {
  display: inline-block;
  height: 1.2ch;
  width: 1.2ch;
  border-radius: 100%;
}
</style>

<script>
export default {
  name: "Dot",

  props: {
    color: { type: String, required: true },
    text: { type: String, required: false },
    justifyStart: { type: Boolean, required: false, default: false },
    justifyCenter: { type: Boolean, required: false, default: false },
    justifyEnd: { type: Boolean, required: false, default: false },
  },
};
</script>
