<template>
  <div class="pa-4 fill-height split-view">
    <div left>
      <v-card class="tab-item assets-list pa-2">
        <div class="d-flex flex-column justify-center" style="overflow: hidden">
          <v-select
            v-if="!addSession"
            v-model="sessionName"
            v-bind:title="sessionName"
            v-bind:items="availableSessions"
            v-bind:outlined="outlinedPref"
            v-bind:label="$t('analysisSession')"
            item-text="name"
            item-value="name"
            class="mt-0 slide-in"
            hide-details
          >
            <template v-slot:item="{ item }">
              <div class="d-flex flex-column">
                <div>{{ item.name }}</div>
                <div
                  class="caption primary--text text--lighten-2 d-flex"
                  style="line-height: 0.8em; gap: 1em"
                >
                  {{ $t("userModified") }}:
                  {{ getReadableTimestamp(item.modified, true) }}
                </div>
              </div>
            </template>

            <template v-slot:append-outer>
              <template v-if="validSessionName">
                <v-btn
                  v-bind:title="$t('btnSave')"
                  v-on:click="confirmUpdate = true"
                  color="primary"
                  icon
                  small
                >
                  <v-icon>mdi-content-save-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="existingSession"
                  v-bind:title="$t('btnDelete')"
                  v-on:click="deleteSession"
                  color="error"
                  icon
                  small
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>

              <v-btn
                v-bind:title="$t('newSession')"
                v-on:click="
                  backupName = sessionName;
                  sessionName = undefined;
                  addSession = true;
                "
                color="primary"
                icon
                small
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-select>
          <v-text-field
            v-else
            v-model="sessionName"
            v-bind:label="
              $t('btnSave') + ' ' + $t('analysisSession').toLowerCase()
            "
            v-bind:outlined="outlinedPref"
            class="mt-0 slide-in"
            clearable
            hide-details
          >
            <template v-slot:append-outer>
              <v-btn
                v-bind:title="$t('btnSave')"
                v-bind:disabled="!validSessionName"
                v-on:click="
                  sessionName === backupName
                    ? (confirmUpdate = true)
                    : saveSession();
                  backupName = undefined;
                  addSession = false;
                "
                color="primary"
                icon
                small
              >
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>

              <v-btn
                v-bind:title="$t('btnCancel')"
                v-on:click="
                  sessionName = backupName;
                  backupName = undefined;
                  addSession = false;
                "
                color="error"
                icon
                small
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-text-field>

          <v-dialog v-model="pickerDialog" width="unset" persistent scrollable>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                v-bind:title="$t('calendarRange')"
                v-bind:class="{
                  'd-flex': true,
                  'mb-1': true,
                  'mt-2': !validSessionName,
                }"
              >
                <v-icon class="me-3" color="primary" small>mdi-calendar</v-icon>
                <div class="body-2">
                  {{
                    dateFormat(dateRange.startDate) +
                    " - " +
                    dateFormat(dateRange.endDate)
                  }}
                </div>
              </div>
            </template>

            <v-card class="pa-2">
              <v-card-text>
                <date-range-picker
                  ref="picker"
                  v-model="dateRange"
                  v-bind:locale-data="{
                    firstDay: 1,
                    format: `${$t('tzLocale').toLowerCase()} HH:mm`,
                    daysOfWeek: momentTranslations.days,
                    monthNames: momentTranslations.months,
                  }"
                  v-bind:max-date="new Date()"
                  v-bind:ranges="fixedRanges"
                  class="mt-2 range-picker"
                  opens="inline"
                  timePicker
                  linkedCalendars
                  showDropdowns
                  timePicker24Hour
                >
                  <template v-slot:input="picker">
                    <v-card-subtitle>
                      {{ $t("calendarRange") }}:
                      <span class="font-weight-bold body-1">
                        {{ dateFormat(picker.startDate) }}
                      </span>
                      -
                      <span class="font-weight-bold body-1">
                        {{ dateFormat(picker.endDate) }}
                      </span>
                    </v-card-subtitle>
                  </template>
                  <div slot="footer" slot-scope="data" class="slot">
                    <v-card-actions class="py-0">
                      <v-spacer />
                      <v-btn
                        v-on:click="pickerDialog = false"
                        color="error"
                        text
                      >
                        {{ $t("btnCancel") }}
                      </v-btn>
                      <v-btn
                        v-bind:disabled="loadingChart || data.in_selection"
                        v-on:click="updateDatesRange(data)"
                        color="primary"
                        text
                      >
                        {{ $t("btnSave") }}
                      </v-btn>
                    </v-card-actions>
                  </div>
                </date-range-picker>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-divider />
        </div>

        <div style="max-height: 100%; overflow: auto">
          <!-- Selection tree -->
          <div
            v-for="asset in chosenAssets"
            v-bind:key="'parent-' + asset.id"
            v-on:mouseleave="hovered = -1"
            class="mb-3"
          >
            <div
              class="pa-2 d-flex justify-space-between align-center"
              v-bind:parent="!loadingChart"
              v-on:mouseover="hovered = loadingChart ? -1 : 'tree-' + asset.id"
              v-on:click="addRemoveMeasures(asset.children, asset.id, true)"
            >
              <!-- Parent node -->
              <div>
                <div class="font-weight-bold" style="line-height: 1.1em">
                  {{ asset.plate || asset.serial }}
                </div>
                <div
                  v-if="!!asset.brand || !!asset.model"
                  class="caption primary--text text--lighten-2"
                  style="line-height: 1em"
                >
                  {{ asset.brand }} {{ asset.model }}
                </div>
              </div>
              <div v-if="'tree-' + asset.id === hovered">
                <v-btn
                  v-bind:title="$t('btnAddToThis')"
                  v-on:click.stop="
                    targetAsset = asset;
                    assetsFilter = asset.entityCode;
                    measuresChooser = true;
                  "
                  color="primary"
                  icon
                  small
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  v-bind:title="$t('btnDelete')"
                  v-on:click.stop="removeFromTree(asset, undefined)"
                  color="error"
                  icon
                  small
                >
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>

            <template v-if="asset.children.length === 0">
              <div class="d-flex align-center pa-2 ms-1 caption">
                <v-icon class="me-2" small>mdi-close</v-icon>
                {{ $t("noMeasureSelected") }}
              </div>
            </template>
            <template v-else>
              <div
                v-for="child in asset.children"
                v-bind:key="'parent-' + asset.id + '-child-' + child"
                v-bind:child="!loadingChart"
                v-on:mouseover="hovered = loadingChart ? -1 : asset.id + child"
                v-on:click="addRemoveMeasures(child, asset.id, false)"
                class="pa-2 ms-1 d-flex justify-space-between align-center"
              >
                <!-- Child node -->
                <div class="d-flex align-center">
                  <v-icon
                    v-bind:color="getChartColor(asset.id, child)"
                    v-bind:class="{ 'me-2': pinned !== child }"
                    small
                  >
                    {{ getListIcon(asset.id, child) }}
                  </v-icon>
                  <v-btn
                    v-if="pinned === getPinnedName(asset, child)"
                    v-bind:title="$t('unpinMeasure')"
                    v-on:click.stop="pinned = undefined"
                    class="me-2"
                    color="primary"
                    icon
                    small
                  >
                    <v-icon small>mdi-pin-outline</v-icon>
                  </v-btn>
                  <span v-bind:class="loadingChart ? 'grey--text' : ''">
                    {{ $t(child) }}
                  </span>
                </div>
                <div>
                  <v-btn
                    v-if="
                      pinned !== getPinnedName(asset, child) &&
                      asset.id + child === hovered &&
                      childIsVisible(child)
                    "
                    v-bind:title="$t('pinMeasure')"
                    v-on:click.stop="pinned = getPinnedName(asset, child)"
                    color="primary"
                    icon
                    x-small
                  >
                    <v-icon x-small>mdi-pin-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="asset.id + child === hovered"
                    v-bind:title="$t('btnDelete')"
                    v-on:click.stop="removeFromTree(child, asset)"
                    color="error"
                    icon
                    x-small
                  >
                    <v-icon x-small>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div
          class="d-flex align-center justify-center flex-wrap"
          style="gap: 0.4rem"
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="flex-grow-1"
                v-bind="attrs"
                v-on="on"
                color="primary"
                small
              >
                <v-icon class="me-4">mdi-plus</v-icon>
                {{ $t("btnAdd") }}
                <v-spacer />
                <v-icon right dark>
                  mdi-chevron-{{
                    attrs["aria-expanded"] == "false" ? "down" : "up"
                  }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-on:click="assetsChooser = true" dense link>
                <v-list-item-avatar>
                  <v-icon class="me-4">mdi-cogs</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  {{ $t("addAsset") }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-bind:disabled="chosenAssets.length === 0"
                v-on:click="measuresChooser = true"
                dense
                link
              >
                <v-list-item-avatar>
                  <v-icon class="me-4">mdi-file-tree-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  {{ $t("addMeasure") }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            v-if="chosenAssets.length > 0"
            v-on:click="clearAll"
            color="error"
            small
          >
            {{ $t("btnClearAll") }}
          </v-btn>
        </div>
      </v-card>
    </div>

    <div right>
      <v-card class="tab-item pa-0">
        <AdvancedChart
          v-bind:chart-data="chartData"
          v-bind:colors="plotColors"
          v-bind:filter="chartFilters"
          v-bind:min-timestamp="toMillis(dateRange.startDate)"
          v-bind:max-timestamp="toMillis(dateRange.endDate)"
          v-bind:topic-prefix="measuresPrefix"
          v-bind:start-at-zero="startAtZero"
          v-bind:pinned="pinned"
          v-bind:time-partitions="partitions"
          v-bind:tag-changes="tagChanges"
          v-bind:data-gaps="!selectedAnalysisInterpolate"
          v-on:data-loaded="loadingChart = false"
          v-on:h-cursor="valueForAnalysis = $event"
          v-on:cursor-A="cursorPlaced('A', $event)"
          v-on:cursor-B="cursorPlaced('B', $event)"
          ref="aLineChart"
        />
      </v-card>
    </div>

    <div class="bottom-nav" bottom>
      <v-tabs v-model="tab" height="1.6rem">
        <v-tab>
          <span style="font-size: 0.8rem">{{ $t("tabTools") }}</span>
        </v-tab>
        <v-tab>
          <span style="font-size: 0.8rem">{{ $t("timePartitioning") }}</span>
        </v-tab>
        <v-tab>
          <span style="font-size: 0.8rem">{{ $t("tabNotes") }}</span>
        </v-tab>
        <v-tab>
          <span style="font-size: 0.8rem">{{ $t("tabExport") }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="tab-item pt-1">
        <v-tab-item class="tab-item">
          <div class="d-flex" style="height: 100%; width: 100%">
            <div
              class="d-flex flex-column justify-center align-center scrollable"
            >
              <v-switch
                v-model="startAtZero"
                v-bind:disabled="loadingChart"
                v-bind:label="$t('axesFromZeroBtn')"
                inset
              />

              <v-btn
                v-bind:disabled="
                  loadingChart ||
                  (pinned == undefined && valueForAnalysis == undefined)
                "
                v-on:click="placeHorizontalCursor"
                color="primary"
              >
                {{
                  valueForAnalysis != undefined
                    ? $t("removeHorizontalCursor")
                    : $t("placeHorizontalCursor")
                }}
              </v-btn>
            </div>

            <v-divider class="mx-4" vertical />

            <div
              class="d-flex flex-column justify-center align-center"
              style="width: 50ch"
            >
              <div
                class="text-caption d-flex justify-space-between"
                style="width: 100%"
              >
                {{ $t("assetTagHistory") }}

                <v-btn
                  v-bind:disabled="tagRanges.length === 0"
                  v-on:click="displayTagRanges = true"
                  color="primary"
                  text
                  x-small
                >
                  {{ $t("selectTagChangeRange") }}
                </v-btn>
              </div>
              <div
                class="flex-grow-1 d-flex flex-column scrollable"
                style="overflow-x: hidden; width: 100%"
              >
                <v-select
                  v-model="assetForTags"
                  v-bind:items="chosenAssets"
                  v-bind:outlined="outlinedPref"
                  v-bind:label="$t('availableSessionAssets')"
                  item-value="serial"
                  hide-details
                  clearable
                >
                  <template v-slot:selection="{ item }">
                    {{ item.plate || item.serial }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.plate || item.serial }}
                  </template>
                </v-select>
                <v-select
                  v-model="selectedAssetTag"
                  v-bind:items="selectedAssetTagsList"
                  v-bind:outlined="outlinedPref"
                  v-bind:label="$t('availableSessionAssetTags')"
                  v-bind:disabled="!assetForTags"
                  hide-details
                  clearable
                />
              </div>
            </div>

            <v-divider class="mx-4" vertical />

            <div class="flex-grow-1 d-flex flex-column">
              <div
                v-if="valueForAnalysis != undefined"
                class="d-flex justify-space-between align-center"
              >
                <div class="d-flex">
                  <div>
                    {{ (valueForAnalysis.asset || {}).description }},
                    {{ $t("horizontalCursor").toLowerCase() }}:
                  </div>
                  <h4 class="ms-3">
                    {{ truncate(valueForAnalysis.value) }}
                    {{ valueForAnalysis.unit }}
                  </h4>
                </div>

                <div>
                  <v-btn
                    v-if="
                      useLimits ||
                      (cursorA != undefined && cursorB != undefined)
                    "
                    v-on:click="useLimits = !useLimits"
                    class="me-2"
                    color="primary"
                    x-small
                    text
                  >
                    {{
                      useLimits
                        ? $t("ignoreCursorsRange")
                        : $t("applyToCursorsRange")
                    }}
                  </v-btn>

                  <v-btn
                    v-bind:disabled="resultLoading"
                    v-on:click="downloadPdf"
                    color="primary"
                    x-small
                    text
                  >
                    {{ $t("downloadPdf") }}
                  </v-btn>
                </div>
              </div>

              <v-divider v-if="valueForAnalysis != undefined" class="my-1" />

              <div class="flex-grow-1 scrollable" functions-grid>
                <template>
                  <!-- Auto computed functions -->
                  <div
                    v-if="resultLoading"
                    class="d-flex justify-center align-center"
                  >
                    <v-progress-circular
                      class="mx-8"
                      color="primary"
                      indeterminate
                    />
                  </div>
                  <div v-else>
                    <div
                      v-for="data in autoComputed"
                      v-bind:key="data.name"
                      class="d-flex justify-space-between"
                      style="gap: 0.5em"
                    >
                      <div>{{ $t(data.name) }}:</div>
                      <h4>{{ data.value }}</h4>
                    </div>
                  </div>
                </template>
                <template>
                  <!-- Manual computed functions -->
                  <div>
                    <!-- TODO: insert analysis tools -->
                  </div>
                </template>
              </div>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item class="tab-item scrollable">
          <div class="d-flex" style="height: 100%; width: 100%">
            <div class="d-flex flex-column align-end justify-start">
              <div class="mb-2" style="min-width: 40ch">
                <v-select
                  v-model="partitions"
                  v-bind:items="availablePartitions"
                  v-bind:outlined="outlinedPref"
                  v-bind:label="$t('availablePartitions')"
                  v-bind:key="'partitionsSelect' + partitionEditId"
                  clearable
                  hide-details
                />
              </div>
              <div>
                <v-btn
                  v-on:click="addTimePartition = true"
                  color="primary"
                  small
                  text
                >
                  {{ $t("btnAdd") }}
                </v-btn>
                <v-btn
                  v-bind:disabled="availablePartitions.length === 0"
                  v-on:click="
                    manageTimePartition = true;
                    partitions = [];
                  "
                  color="primary"
                  small
                  text
                >
                  {{ $t("btnManage") }}
                </v-btn>
              </div>
            </div>

            <v-divider class="mx-4" vertical />

            <div class="d-flex flex-row flex-wrap">
              <div
                v-for="partition in partitions"
                v-bind:key="
                  partition.name + '-' + partition.start + partition.end
                "
                class="body-1 me-4 mb-1"
              >
                <div
                  class="d-flex align-center rounded elevation-4"
                  v-bind:style="
                    'overflow: hidden; height: 7.5ch; border: 1px solid ' +
                    partition.color
                  "
                >
                  <div
                    v-bind:style="
                      'background: ' +
                      partition.color +
                      '; width: 0.7em; height: 100%'
                    "
                    class="me-2"
                  ></div>
                  <div class="d-flex flex-column ma-3">
                    <span class="font-weight-bold">{{ partition.name }}</span>
                    <span class="caption">
                      {{ partition.startString }}
                      <v-icon small>mdi-arrow-right</v-icon>
                      {{ partition.endString }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item class="tab-item scrollable">
          <v-textarea
            v-model="notesText"
            v-bind:label="$t('notesArea')"
            class="mt-2"
            rows="4"
            auto-grow
            no-resize
            outlined
            hide-details
          />
        </v-tab-item>

        <v-tab-item class="tab-item">
          <div class="pa-4 d-flex" style="gap: 1em">
            <JsonCSV
              v-bind:name="fileName()"
              v-bind:data="exportData"
              delimiter=";"
            >
              <v-btn
                v-bind:disabled="loadingChart || exportData.length === 0"
                color="primary"
              >
                {{ $t("downloadCSV") }}
              </v-btn>
            </JsonCSV>

            <v-btn
              v-bind:disabled="loadingChart || selectedMeasures.length === 0"
              v-on:click="downloadChartImage"
              color="primary"
            >
              {{ $t("downloadChartImage") }}
            </v-btn>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <!-- Select assets -->
    <v-dialog v-model="assetsChooser" width="min(40%, 92vw)">
      <v-card v-if="assetsChooser">
        <v-card-title class="d-flex align-center justify-space-between">
          {{ $t("addAsset") }}
          <v-btn v-on:click="closeAssetsChooser" color="error" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div class="d-flex" style="gap: 0.8rem">
            <v-text-field
              v-model="assetSearch"
              v-bind:label="$t('searchPlaceholder')"
              v-bind:hint="filteredAssets.length + ' ' + $t('elements')"
              prepend-inner-icon="mdi-magnify"
              clearable
              persistent-hint
            />

            <v-select
              v-model="filterByEC"
              v-bind:items="filteredEntityCodes"
              v-bind:label="$t('customerEntityCodes')"
              v-bind:outlined="outlinedPref"
              item-value="id"
              style="width: 35%"
              clearable
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-select
                      v-model="ecFilter"
                      v-bind:items="allTypes"
                      v-bind:label="$t('assetType')"
                      clearable
                    />
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item, index }">
                <ItemSelection
                  v-bind:index="index"
                  v-bind:item="item"
                  v-bind:array-length="filterByEC.length"
                  v-bind:shown-items="1"
                  mode="entityCode"
                />
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  #default="{ active }"
                  class="my-0"
                >
                  <AssetItem
                    v-bind:active="active"
                    v-bind:item="item"
                    mode="entityCode"
                  />
                </v-list-item>
              </template>
            </v-select>
          </div>

          <div
            v-on:mouseleave="hovered = -1"
            class="mt-2"
            style="height: 60ch; overflow-y: auto"
          >
            <div
              v-for="asset in filteredAssets"
              v-bind:key="asset.id"
              v-on:click="addAssetToList(asset)"
              v-on:mouseover="hovered = asset.id"
              class="px-2"
              parent
            >
              <div class="d-flex align-center justify-space-between">
                <AssetItem
                  v-bind:active="undefined"
                  v-bind:item="asset"
                  mode="asset"
                />

                <v-icon v-if="hovered == asset.id" color="primary">
                  mdi-plus
                </v-icon>
              </div>

              <v-divider />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Select measures or template -->
    <v-dialog
      v-model="measuresChooser"
      v-on:input="resetMeasuresFilters"
      width="min(40%, 92vw)"
    >
      <v-card v-if="measuresChooser">
        <v-card-title class="d-flex align-center justify-space-between">
          {{ $t("addMeasure") }}
          <v-btn v-on:click="closeMeasureChooser" color="error" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-combobox
            v-model="templateName"
            v-bind:items="templatesList"
            v-bind:search-input.sync="templateSearch"
            v-bind:outlined="outlinedPref"
            v-bind:label="$t('selectionTemplate')"
            ref="templateDropdown"
          >
            <template v-if="!!templateSearch" v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="$t('comboBoxNoData').replace('%s%', templateSearch)"
                  />
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:item="{ item }">
              <div
                class="d-flex align-center justify-space-between"
                style="width: 100%"
              >
                <span>{{ item }}</span>
                <v-btn
                  v-on:click.stop="modifyTemplateData(item)"
                  color="primary"
                  icon
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>

            <template
              v-if="validTemplate && templateCanBeSaved"
              v-slot:append-outer
            >
              <v-btn
                v-bind:title="$t('btnSave')"
                v-on:click="saveTemplate(undefined)"
                color="primary"
                icon
              >
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </template>
          </v-combobox>

          <div>
            <div class="d-flex" style="gap: 0.8rem">
              <v-text-field
                v-model="measureSearch"
                v-bind:label="$t('searchPlaceholder')"
                v-bind:hint="filteredMeasures.length + ' ' + $t('elements')"
                prepend-inner-icon="mdi-magnify"
                style="width: 50%"
                clearable
                persistent-hint
              />

              <v-select
                v-model="assetsFilter"
                v-bind:items="chosenAssets"
                v-bind:label="$t('customerAssets')"
                v-bind:outlined="outlinedPref"
                v-bind:disabled="intersectMeasures"
                item-value="entityCode"
                style="width: 50%"
                clearable
              >
                <template v-slot:selection="{ item }">
                  <ItemSelection
                    v-bind:index="0"
                    v-bind:item="item"
                    v-bind:array-length="1"
                    v-bind:shown-items="1"
                    mode="asset"
                  />
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item
                    v-on="on"
                    v-bind="attrs"
                    #default="{ active }"
                    class="my-0"
                  >
                    <AssetItem
                      v-bind:active="active"
                      v-bind:item="item"
                      mode="asset"
                    />
                  </v-list-item>
                </template>
              </v-select>
            </div>

            <div class="d-flex flex-column align-baseline">
              <v-switch
                v-model="intersectMeasures"
                v-bind:label="$t('measuresIntersect')"
                hide-details
                inset
              />

              <v-switch
                v-model="onlySelectedMeasures"
                v-bind:label="$t('measuresSelected')"
                hide-details
                inset
              />
            </div>

            <v-divider class="mt-3" />

            <div
              v-on:mouseleave="hovered = -1"
              class="mt-2"
              style="height: 50ch; overflow-y: auto"
            >
              <div
                v-for="measure in filteredMeasures"
                v-bind:key="measure.topic"
                v-on:click="addMeasureToList(measure)"
                v-on:mouseover="hovered = measure.topic"
                class="px-2"
                parent
              >
                <div class="d-flex align-center justify-space-between">
                  <AssetItem
                    v-bind:active="undefined"
                    v-bind:item="measure"
                    mode="topic"
                  />

                  <v-icon v-if="hovered == measure.topic" color="primary">
                    mdi-plus
                  </v-icon>
                  <v-icon
                    v-else-if="someoneHasMeasure(measure.topic)"
                    color="primary"
                  >
                    mdi-check
                  </v-icon>
                </div>

                <v-divider />
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modifyTemplateDialog" width="min(30%, 92vw)" persistent>
      <v-card v-if="modifyTemplateDialog" ref="modifyTemplateDialog">
        <v-card-title>{{ modifyTemplate.name }}</v-card-title>
        <v-card-text>
          <v-select
            v-model="modifyTemplate.selection"
            v-bind:items="filteredMeasures"
            v-bind:label="$t('mainParameters')"
            v-bind:outlined="outlinedPref"
            item-value="topic"
            item-text="topic"
            clearable
            hide-details
            multiple
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <div class="d-flex" style="gap: 0.8rem">
                    <v-text-field
                      v-model="measureSearch"
                      v-bind:label="$t('searchPlaceholder')"
                      v-bind:hint="
                        filteredMeasures.length + ' ' + $t('elements')
                      "
                      prepend-inner-icon="mdi-magnify"
                      style="width: 30ch"
                      clearable
                      persistent-hint
                    />

                    <v-select
                      v-model="assetsFilter"
                      v-bind:items="chosenAssets"
                      v-bind:label="$t('customerAssets')"
                      v-bind:outlined="outlinedPref"
                      v-bind:disabled="intersectMeasures"
                      item-value="entityCode"
                      style="width: 40ch"
                      clearable
                      hide-details
                    >
                      <template v-slot:selection="{ item }">
                        <ItemSelection
                          v-bind:index="0"
                          v-bind:item="item"
                          v-bind:array-length="1"
                          v-bind:shown-items="1"
                          mode="asset"
                        />
                      </template>
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item
                          v-on="on"
                          v-bind="attrs"
                          #default="{ active }"
                          class="my-0"
                        >
                          <AssetItem
                            v-bind:active="active"
                            v-bind:item="item"
                            mode="asset"
                          />
                        </v-list-item>
                      </template>
                    </v-select>
                  </div>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="d-flex flex-column align-baseline">
                <v-switch
                  v-model="intersectMeasures"
                  v-bind:label="$t('measuresIntersect')"
                  hide-details
                  inset
                />

                <v-switch
                  v-model="onlySelectedMeasures"
                  v-bind:label="$t('measuresSelected')"
                  hide-details
                  inset
                />
              </v-list-item>

              <v-divider />
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn v-on:click="deleteModified" color="error" text>
            {{ $t("btnDelete") }}
          </v-btn>
          <v-spacer />
          <v-btn v-on:click="closeModify" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn v-on:click="updateModified" color="primary" text>
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="manageTimePartition" width="unset" persistent scrollable>
      <v-card v-if="manageTimePartition">
        <v-card-title>
          {{ $t("timePartitioning") }}
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(partItem, id) in availablePartitions"
              v-bind:key="partItem.text + id"
              link
            >
              <v-list-item-avatar>
                <v-avatar>
                  <v-icon color="primary">mdi-clock-outline</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ partItem.text }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ partItem.value.length }} {{ $t("timeRanges") }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div>
                  <v-btn
                    v-bind:title="$t('btnEdit')"
                    v-on:click="
                      partitionEditId = id;
                      partitionEdit = partItem;
                      addTimePartition = true;
                    "
                    color="primary"
                    icon
                  >
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-bind:title="$t('btnDelete')"
                    v-on:click="deleteTimePartition(id)"
                    color="error"
                    icon
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="manageTimePartition = false" color="primary" text>
            {{ $t("btnOk") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="displayTagRanges" width="unset" scrollable persistent>
      <v-card v-if="displayTagRanges">
        <v-card-title>
          {{ $t("selectTagChangeRange") }} "{{ selectedAssetTag }}"
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
              v-for="tagRange in tagRanges"
              v-bind:key="tagRange.name + '-' + tagRange.value"
              v-on:click="
                dateRange.startDate = tagRange.start;
                dateRange.endDate = tagRange.end;
                displayTagRanges = false;
                fetchChartData(selectedMeasures);
              "
              link
            >
              <v-list-item-avatar>
                <v-icon color="primary">mdi-clock-outline</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ tagRange.value }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  {{ dateFormat(tagRange.start) }}
                  <v-icon class="mx-2" x-small>mdi-arrow-right</v-icon>
                  {{ dateFormat(tagRange.end) }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon color="primary">mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn v-on:click="displayTagRanges = false" color="primary" text>
            {{ $t("btnClose") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AddTimePartitionDialog
      v-bind:open="addTimePartition"
      v-bind:edit="partitionEdit"
      v-on:close="
        addTimePartition = false;
        partitionEdit = undefined;
        partitionEditId = -1;
      "
      v-on:add="useTimePartition"
    />

    <ConfirmationDialog
      v-if="confirmDelete"
      v-bind:callback="confirmCallback"
      v-bind:content="$t('confirmDelete')"
      v-bind:title="$t('dialogDeleteConfirm')"
      v-on:cancel="resetConfirmDelete"
    />

    <ConfirmationDialog
      v-if="confirmUpdate"
      v-bind:callback="saveSession"
      v-bind:content="$t('confirmSessionUpdate')"
      v-bind:title="$t('dialogUpdateSessionConfirm')"
      v-on:cancel="confirmUpdate = false"
    />
  </div>
</template>

<style scoped>
.split-view {
  position: absolute;
  inset: 0;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 20% auto;
  grid-template-rows: 82% 18%;
  grid-template-areas:
    "left right"
    "bottom bottom";
}

.assets-list {
  display: grid;
  gap: 0.4rem;
  grid-template-rows: auto 1fr auto;
}

.bottom-nav {
  display: grid;
  grid-template-rows: auto 1fr;
}

.dense-tabs {
  font-size: 0.5rem;
}

.tab-item {
  height: 100%;
}

.scrollable {
  overflow-y: auto;
}

.slide-in {
  animation: slide 0.4s forwards;
}

div[functions-grid] {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.2em;
}

div[left] {
  position: relative;
  grid-area: left;
}

div[right] {
  position: relative;
  grid-area: right;
}

div[bottom] {
  position: relative;
  grid-area: bottom;
}

div[parent]:hover,
div[child]:hover {
  cursor: pointer;
  background-color: #e8eaf6;
  border-radius: 4px;
}

@keyframes slide {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: 0;
  }
}
</style>

<script>
import Vue from "vue";
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import Messages from "../utils/messages";
import ConfirmationDialog from "../components/ConfirmationDialog";
import ItemSelection from "../components/ItemSelection";
import AssetItem from "../components/AssetItem";
import AdvancedChart from "../components/AdvancedChart";
import AddTimePartitionDialog from "../components/AddTimePartitionDialog";
import AssetDataRequestBody from "../models/AssetDataRequestBody";
import EntityCode from "../models/EntityCode";
import DateRangePicker from "vue2-daterange-picker";
import JsonCSV from "vue-json-csv";
import moment from "moment";
import distinctColors from "distinct-colors";

const DEFAULT_SESSION_NAME = "empty-session";
const DEFAULT_TEMPLATE_NAME = "empty-template";

export default {
  name: "VisualAnalysis",

  components: {
    DateRangePicker,
    ConfirmationDialog,
    ItemSelection,
    AssetItem,
    AdvancedChart,
    JsonCSV,
    AddTimePartitionDialog,
  },

  data: () => ({
    tab: 0,
    hovered: -1,
    addSession: false,
    pickerDialog: false,
    assetsChooser: false,
    measuresChooser: false,
    intersectMeasures: false,
    onlySelectedMeasures: false,
    confirmUpdate: false,
    confirmDelete: false,
    confirmCallback: () => {},
    sessionName: DEFAULT_SESSION_NAME,
    templateName: DEFAULT_TEMPLATE_NAME,
    templateSearch: undefined,
    assetSearch: undefined,
    measureSearch: undefined,
    assetsFilter: undefined,
    notesText: undefined,
    ecFilter: undefined,
    targetAsset: undefined,
    pinned: undefined,
    valueForAnalysis: undefined,
    filterByEC: [],
    availableSessions: [],
    availableTemplates: [],
    chosenAssets: [],
    selectedMeasures: [],
    chartData: {},
    chartFilters: [],
    entityCodes: [],
    measuresPrefix: "measures/",
    modifyTemplate: undefined,
    modifyTemplateDialog: false,
    rangeIsInPast: false,
    momentTranslations: {
      days: moment.weekdaysShort(false),
      months: moment.months(true),
    },
    dateRange: {
      startDate: moment().subtract(1, "days"),
      endDate: moment(),
    },
    loadingChart: false,
    startAtZero: true,
    maxColors: 40,
    plotColors: {},
    colors: [],
    exportData: [],
    cursorA: undefined,
    cursorB: undefined,
    useLimits: false,
    resultLoading: false,
    autoComputed: [],
    algorithms: [],
    partitions: [],
    tagChanges: [],
    tagRanges: [],
    availablePartitions: [],
    displayTagRanges: false,
    partitionEdit: undefined,
    partitionEditId: -1,
    addTimePartition: false,
    manageTimePartition: false,
    assetForTags: undefined,
    selectedAssetTag: undefined,
    selectedAssetTagsList: [],
  }),

  async beforeMount() {
    this.updateFiltersRules(this.clientId);
    this.getEntityCodes();

    this.$store.commit("setCurrentPage", Pages.COMPARISONS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);

    // eslint-disable-next-line prettier/prettier
    this.colors = distinctColors({ count: this.maxColors }).map((cc) => cc.hex("rgb"));
    this.plotColors = {};

    // Define auto algorithms to apply for analysis
    try {
      this.algorithms = (await ApiRequests.getAlgorithms()).data.map(
        (aInfo) => ({
          order: aInfo.order,
          name: aInfo.name,
          // (value, functionForTranslation) => /* do something */
          formatter: eval(aInfo.formatter),
        })
      );
    } catch {
      // algorithms.json is missing
    }

    const buildedSession = this.$store.getters.buildedSession;

    if (buildedSession !== undefined) {
      this.chosenAssets = Object.keys(buildedSession).map((k) =>
        JSON.parse(buildedSession[k])
      );

      this.$store.commit("clearBuildSession");
      this.getSavedMeasures();
    }

    await this.loadSessions(true);
    await this.loadTemplates(true);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuVisualAnalysis"),
      to: "/visual-analysis",
      root: true,
    });
  },

  computed: {
    fixedRanges() {
      return {
        [this.$t("lastHour")]: [
          this.todayMinutes().subtract(1, "hour").toDate(),
          this.todayMinutes().toDate(),
        ],
        [this.$t("last6Hours")]: [
          this.todayMinutes().subtract(6, "hour").toDate(),
          this.todayMinutes().toDate(),
        ],
        [this.$t("last12Hours")]: [
          this.todayMinutes().subtract(12, "hour").toDate(),
          this.todayMinutes().toDate(),
        ],
        [this.$t("lastDay")]: [
          this.todayMinutes().subtract(24, "hour").toDate(),
          this.todayMinutes().toDate(),
        ],
        [this.$t("lastWeek")]: [
          this.todayMinutes().subtract(1, "week").toDate(),
          this.todayMinutes().toDate(),
        ],
        [this.$t("lastMonth")]: [
          this.todayMinutes().subtract(1, "month").toDate(),
          this.todayMinutes().toDate(),
        ],
      };
    },
    validSessionName() {
      return !!this.sessionName && this.sessionName !== DEFAULT_SESSION_NAME;
    },
    existingSession() {
      return this.findSession(this.sessionName) != undefined;
    },
    templatesList() {
      return this.availableTemplates.map((t) => t.name);
    },
    validTemplate() {
      return !!this.templateName && this.templateName !== DEFAULT_TEMPLATE_NAME;
    },
    templateCanBeSaved() {
      return (
        this.allSelectedMeasures.length > 0 &&
        !this.templatesList.includes(this.templateName)
      );
    },
    existingTemplate() {
      return this.findTemplate(this.templateName) != undefined;
    },
    allTypes() {
      return this.entityCodes.map((ec) => ec.type).sort();
    },
    filteredEntityCodes() {
      return this.entityCodes.filter((ec) =>
        this.ecFilter ? ec.type === this.ecFilter : true
      );
    },
    filteredAssets() {
      return this.assetsList
        .filter((a) => !!a.boxMacAddress)
        .filter((a) => this.chosenAssets.findIndex((ca) => ca.id === a.id) < 0)
        .filter((a) => a.matchSearchTerm(this.assetSearch))
        .filter(
          (a) =>
            this.filterByEC.length === 0 ||
            this.filterByEC.includes(a.entityCode)
        );
    },
    allSelectedMeasures() {
      const measuresSet = new Set();

      this.chosenAssets
        .map((ca) => ca.children)
        .forEach((ch) => ch.forEach((c) => measuresSet.add(c)));

      return [...measuresSet];
    },
    filteredMeasures() {
      const availableTopics = this.$store.getters.availableTopics;
      // eslint-disable-next-line prettier/prettier
      const selectedEcs = this.chosenAssets.map((node) => node?.entityCode);

      const measures = Object.keys(availableTopics)
        .map((key) => ({
          topic: key,
          entities: availableTopics[key],
        }))
        .filter(
          (m) =>
            !this.measureSearch ||
            m.topic.toLowerCase().includes(this.measureSearch.toLowerCase()) ||
            this.$t(m.topic)
              .toLowerCase()
              .includes(this.measureSearch.toLowerCase())
        )
        .filter((m) =>
          this.intersectMeasures
            ? selectedEcs.reduce((b, c) => b && m.entities.includes(c), true)
            : this.assetsFilter
            ? m.entities.includes(this.assetsFilter)
            : true
        )
        .filter((m) =>
          this.onlySelectedMeasures
            ? this.allSelectedMeasures.includes(m.topic)
            : true
        );

      measures.sort((m1, m2) => (m1.topic > m2.topic ? 1 : -1));

      return measures;
    },
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
    sessionName(newValue) {
      const session = this.findSession(newValue);

      if (session) {
        this.selectedMeasures = [];
        this.pinned = undefined;
        this.notesText = session.notes ? String(session.notes) : undefined;
        this.chosenAssets = session.assets.map((a) => {
          const wasSaved = JSON.parse(a);
          // eslint-disable-next-line prettier/prettier
          const existingAsset = this.$store.getters.assetBySerial(wasSaved.serial);

          existingAsset.children = wasSaved.children;

          return existingAsset;
        });
        this.templateName = DEFAULT_TEMPLATE_NAME;
        this.partitions = [];
        this.availablePartitions = JSON.parse(
          session.properties?.timePartitions || "[]"
        );

        this.removeHorizontalCursor();
        this.getSavedMeasures();
      }
    },
    templateName(newValue) {
      const template = this.findTemplate(newValue);

      if (template) {
        template.selection.forEach((topic) =>
          this.addMeasureToList({
            topic,
            entities: this.$store.getters.availableTopics[topic],
          })
        );
      }
    },
    measuresChooser(newValue) {
      if (!newValue) {
        if (this.targetAsset != undefined) this.assetsFilter = undefined;
        this.targetAsset = undefined;
      }
    },
    intersectMeasures(newValue) {
      if (newValue) this.assetsFilter = undefined;
    },
    cursorA(newValue) {
      if (newValue == undefined) {
        this.useLimits = false;
      }

      if (
        this.useLimits &&
        newValue != undefined &&
        this.cursorB != undefined
      ) {
        this.startAnalysis(this.valueForAnalysis);
      }
    },
    cursorB(newValue) {
      if (newValue == undefined) {
        this.useLimits = false;
      }

      if (
        this.useLimits &&
        newValue != undefined &&
        this.cursorA != undefined
      ) {
        this.startAnalysis(this.valueForAnalysis);
      }
    },
    useLimits(newValue, oldValue) {
      if (newValue != oldValue) {
        this.startAnalysis(this.valueForAnalysis);
      }
    },
    assetForTags(newValue) {
      this.selectedAssetTag = undefined;

      if (!newValue) {
        this.selectedAssetTagsList = [];
        return;
      }

      const trueAsset = this.$store.getters.assetBySerial(newValue);

      this.selectedAssetTagsList = trueAsset.influxTags(true);
    },
    selectedAssetTag(newValue) {
      if (!newValue) return (this.tagChanges = []);

      this.getTagChanges(this.assetForTags, newValue);
    },
    chosenAssets: {
      deep: true,
      handler() {
        this.assetForTags = undefined;
      },
    },
    valueForAnalysis: {
      deep: true,
      handler(newValue) {
        this.startAnalysis(newValue);
      },
    },
    selectedMeasures: {
      deep: true,
      handler(newValue) {
        if (!this.rangeIsInPast) {
          // Reset starting and ending date
          const difference =
            moment(this.dateRange.endDate).diff(
              moment(this.dateRange.startDate)
            ) / 1000;
          this.dateRange.endDate = moment();
          // eslint-disable-next-line prettier/prettier
          this.dateRange.startDate = moment(this.dateRange.endDate).subtract(difference, "second");
        }

        this.fetchChartData(newValue);
      },
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    getEntityCodes() {
      ApiRequests.getAssetsEntityCodes(
        (res) => {
          this.entityCodes = res.data.map((ec) => new EntityCode(ec));
        },
        (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
      );
    },
    async loadSessions(synchronous = true) {
      const callback = (res) => (this.availableSessions = res.data);

      if (synchronous) {
        try {
          callback(await ApiRequests.getSessions(undefined, undefined));
        } catch (err) {
          if (process.env.NODE_ENV === "development") console.error(err);
        }
      } else {
        ApiRequests.getSessions(callback, (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
        );
      }
    },
    async loadTemplates(synchronous = true) {
      const callback = (res) => (this.availableTemplates = res.data);

      if (synchronous) {
        try {
          callback(await ApiRequests.getTemplates(undefined, undefined));
        } catch (err) {
          if (process.env.NODE_ENV === "development") console.error(err);
        }
      } else {
        ApiRequests.getTemplates(callback, (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
        );
      }
    },
    useTimePartition(partitionObject) {
      const normalized = {
        text: partitionObject.text,
        value: partitionObject.value.map((range) => {
          return {
            ...range,
            start: Number(range.start.replace(":", "")),
            end: Number(range.end.replace(":", "")),
            startString: range.start,
            endString: range.end,
          };
        }),
      };

      if (this.partitionEdit === undefined) {
        this.availablePartitions.push(normalized);
      } else {
        this.availablePartitions[this.partitionEditId] = normalized;
      }

      // Close
      this.addTimePartition = false;
      this.partitionEdit = undefined;
      this.partitionEditId = -1;
    },
    deleteTimePartition(id) {
      this.confirmCallback = () => {
        this.availablePartitions.splice(id, 1);
        this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("deleteSuccess"));
        this.resetConfirmDelete();
      };

      this.confirmDelete = true;
    },
    saveSession() {
      const existingSession = this.findSession(this.sessionName);
      const session = {
        id: existingSession ? existingSession.id : undefined,
        name: this.sessionName,
        notes: this.notesText,
        assets: this.chosenAssets.map((ca) => {
          const toSave = {
            id: ca.id,
            serial: ca.serial,
            children: ca.children,
          };

          return JSON.stringify(toSave);
        }),
        properties: {
          timePartitions: JSON.stringify(this.availablePartitions),
        },
      };

      ApiRequests.saveSession(
        session,
        existingSession != undefined,
        () => {
          this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("saveSuccess"));
          this.loadSessions(false);
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("saveFailure"),
          });
        }
      );
    },
    deleteSession() {
      const session = this.findSession(this.sessionName);

      if (session) {
        this.confirmCallback = () => {
          ApiRequests.deleteSession(
            session.id,
            () => {
              this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("deleteSuccess"));
              this.sessionName = DEFAULT_SESSION_NAME;
              this.resetConfirmDelete();
              this.loadSessions(false);
            },
            (err) => {
              if (process.env.NODE_ENV === "development") console.error(err);
              this.resetConfirmDelete();
              this.$bus.$emit(Messages.ERROR_MSG, {
                error: err?.response?.data?.error || err,
                description: this.$t("deleteFailure"),
              });
            }
          );
        };

        this.confirmDelete = true;
      }
    },
    modifyTemplateData(name) {
      this.modifyTemplate = Object.assign({}, this.findTemplate(name));
      this.modifyTemplateDialog = true;
      this.$nextTick(() => {
        this.$refs.templateDropdown.blur();
        this.$refs.modifyTemplateDialog.focus();
      });
    },
    closeModify() {
      this.modifyTemplateDialog = false;
      this.modifyTemplate = undefined;
      this.measureSearch = undefined;
      this.intersectMeasures = false;
      this.onlySelectedMeasures = false;
      this.assetsFilter = undefined;
    },
    updateModified() {
      this.saveTemplate(this.modifyTemplate);
      this.closeModify();
    },
    deleteModified() {
      this.deleteTemplate(this.modifyTemplate);
      this.closeModify();
    },
    saveTemplate(toSave = undefined) {
      const existingTemplate = toSave || this.findTemplate(this.templateName);
      const template = {
        id: existingTemplate ? existingTemplate.id : undefined,
        name: existingTemplate?.name || this.templateName,
        selection: existingTemplate?.selection || this.allSelectedMeasures,
      };

      ApiRequests.saveTemplate(
        template,
        existingTemplate != undefined,
        () => {
          this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("saveSuccess"));
          this.loadTemplates(false);
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("saveFailure"),
          });
        }
      );
    },
    deleteTemplate(toDelete = undefined) {
      const template = toDelete || this.findTemplate(this.templateName);

      if (template) {
        this.confirmCallback = () => {
          ApiRequests.deleteTemplate(
            template.id,
            () => {
              this.$bus.$emit(Messages.SUCCESS_MSG, this.$t("deleteSuccess"));
              this.templateName = DEFAULT_TEMPLATE_NAME;
              this.resetConfirmDelete();
              this.loadTemplates(false);
            },
            (err) => {
              if (process.env.NODE_ENV === "development") console.error(err);
              this.resetConfirmDelete();
              this.$bus.$emit(Messages.ERROR_MSG, {
                error: err?.response?.data?.error || err,
                description: this.$t("deleteFailure"),
              });
            }
          );
        };

        this.confirmDelete = true;
      }
    },
    resetConfirmDelete() {
      this.confirmDelete = false;
      this.confirmCallback = () => {};
    },
    closeAssetsChooser() {
      this.assetsChooser = false;
      this.assetSearch = undefined;
    },
    closeMeasureChooser() {
      this.measuresChooser = false;
      this.resetMeasuresFilters();
    },
    clearAll() {
      this.assetsChooser = false;
      this.measuresChooser = false;
      this.chosenAssets = [];
      this.sessionName = DEFAULT_SESSION_NAME;
      this.notesText = undefined;
      this.selectedMeasures = [];
      this.pinned = undefined;
      this.resetMeasuresFilters();
    },
    resetMeasuresFilters() {
      this.measureSearch = undefined;
      this.intersectMeasures = false;
      this.onlySelectedMeasures = false;
      this.assetsFilter = undefined;
      this.templateName = DEFAULT_TEMPLATE_NAME;
    },
    addAssetToList(item) {
      const value = item.toJson();
      value.children = [];

      Vue.set(this.chosenAssets, this.chosenAssets.length, value);
      this.getSavedMeasures();

      this.allSelectedMeasures.forEach((topic) =>
        this.addMeasureToList({
          topic,
          entities: this.$store.getters.availableTopics[topic],
        })
      );
    },
    addMeasureToList(item) {
      if (this.targetAsset == undefined) {
        this.chosenAssets.forEach((ca) => {
          if (
            !ca.children.includes(item.topic) &&
            (item.entities.length === 0 ||
              ca?.entityCode == undefined ||
              item.entities.includes(ca?.entityCode))
          ) {
            Vue.set(ca.children, ca.children.length, item.topic);
          }
        });
      } else if (
        !this.targetAsset.children.includes(item.topic) &&
        (item.entities.length === 0 ||
          this.targetAsset?.entityCode == undefined ||
          item.entities.includes(this.targetAsset?.entityCode))
      ) {
        Vue.set(
          this.targetAsset.children,
          this.targetAsset.children.length,
          item.topic
        );
      }
    },
    findSession(name) {
      return this.availableSessions.find((s) => s.name === name);
    },
    findTemplate(name) {
      return this.availableTemplates.find((t) => t.name === name);
    },
    todayMinutes() {
      const today = moment();
      today.set({ second: 0, millisecond: 0 });
      return today;
    },
    updateDatesRange(data) {
      data.clickApply();
      this.pickerDialog = false;

      const now = moment();

      this.rangeIsInPast = moment
        .utc(this.dateRange.endDate)
        .local()
        .isBefore(now, "minute");

      this.fetchChartData(this.selectedMeasures);
    },
    dateFormat(date) {
      if (date == undefined) {
        return "";
      }

      return moment(date).format(`${this.$t("tzLocale")} HH:mm`);
    },
    removeFromTree(item, asset = undefined) {
      if (this.loadingChart) return;

      if (asset == undefined) {
        item.children.forEach((child) => {
          // Clear pinned if not selected any more
          if (this.pinned === this.getPinnedName(item, child)) {
            this.pinned = undefined;
          }

          // Remove from selected measures
          this.selectedMeasures.splice(
            this.findSelectedMeasure(child, item.id),
            1
          );
        });

        this.chosenAssets.splice(
          this.chosenAssets.findIndex((a) => a.id === item.id),
          1
        );
      } else {
        asset.children.splice(asset.children.indexOf(item), 1);

        // Clear pinned if not selected any more
        if (this.pinned === this.getPinnedName(asset, item)) {
          this.pinned = undefined;
        }

        this.selectedMeasures.splice(
          this.findSelectedMeasure(item, asset.id),
          1
        );
      }
    },
    addRemoveMeasures(element, asset, multiple = false) {
      if (this.loadingChart) return;

      const selectionFun = (id, auto, add) => {
        const exists = this.findSelectedMeasure(id, asset);

        if (exists < 0) {
          this.selectedMeasures.push({ topic: id, asset });
        } else if (auto || !add) {
          this.selectedMeasures.splice(exists, 1);
        }
      };

      if (multiple) {
        const remove = element.reduce(
          (s, c) => s && this.isMeasureSelected(c, asset),
          true
        );

        element.forEach((item) => selectionFun(item, false, !remove));
      } else {
        selectionFun(element, true, undefined);
      }
    },
    isMeasureSelected(measure, asset) {
      return this.findSelectedMeasure(measure, asset) >= 0;
    },
    findSelectedMeasure(measure, asset) {
      return this.selectedMeasures.findIndex(
        (sm) => sm.topic === measure && sm.asset === asset
      );
    },
    someoneHasMeasure(topic) {
      return (
        this.allSelectedMeasures.findIndex((measure) => measure === topic) >= 0
      );
    },
    getSavedMeasures() {
      const requestBody = new AssetDataRequestBody();
      const assetDbIds = this.chosenAssets.map((asset) => asset.id);

      requestBody.ids = assetDbIds;
      requestBody.paths = [];
      requestBody.includeMetadata = true;

      ApiRequests.getSavedMeasures(
        requestBody,
        undefined,
        (res) => {
          const ids = Object.keys(res.data);

          ids.forEach((id) => {
            const requestedData = res.data[id];

            Object.keys(requestedData).forEach((k) => {
              this.$store.dispatch("saveMessage", requestedData[k]);
            });
          });
        },
        (err) =>
          process.env.NODE_ENV === "development"
            ? console.error(err)
            : undefined
      );
    },
    placeHorizontalCursor() {
      if (this.valueForAnalysis == undefined) {
        this.$refs.aLineChart.cursorDirection = this.$refs.aLineChart.constants.HORIZONTAL;
      } else {
        this.removeHorizontalCursor();
      }
    },
    removeHorizontalCursor() {
      this.$refs.aLineChart.removeHorizontalCursor();
      this.valueForAnalysis = undefined;
    },
    fetchChartData(assetsTopics) {
      const groupByAsset = Object.values(
        assetsTopics.reduce((map, c) => {
          if (map[c.asset] === undefined) {
            map[c.asset] = {
              item: c.asset,
              topics: [],
            };
          }

          map[c.asset].topics.push(c.topic);

          return map;
        }, {})
      );

      if (groupByAsset.length === 0) {
        this.chartData.length = 0;
        this.chartFilters.length = 0;
        this.$refs.aLineChart.emptyRefs();

        return;
      }

      this.$refs.aLineChart.loading = true;
      this.loadingChart = true;
      this.plotColors = {};

      const pendingRequests = groupByAsset.map((group) => {
        const requestBody = new AssetDataRequestBody();
        const starting = moment(this.dateRange.startDate);
        const ending = moment(this.dateRange.endDate);

        requestBody.ids = [group.item];
        requestBody.starting = starting.format();
        requestBody.ending = ending.format();
        requestBody.paths = group.topics.map((m) => `measures/${m}`);

        return ApiRequests.getSavedMeasures(
          requestBody,
          undefined,
          undefined,
          undefined
        );
      });

      Promise.allSettled(pendingRequests).then((res) => {
        const flattened = [];
        let colorIndex = 0;
        let aggregate = {};
        let tmpFilters = [];

        res.forEach((r) => {
          if (r.status === "fulfilled") {
            const result = r.value.data;
            aggregate = { ...aggregate, ...result };
          }
        });

        Object.keys(aggregate).forEach((dbId) => {
          const result = aggregate[dbId];
          const dataKeys = Object.keys(result);
          const dataKeysAlt = dataKeys.map(
            (k) => dbId + "/" + k.replace(this.measuresPrefix, "")
          );

          tmpFilters = [...tmpFilters, ...dataKeysAlt];

          dataKeys.forEach((k) => {
            // eslint-disable-next-line prettier/prettier
            this.plotColors[dbId + "/" + k] = this.colors[colorIndex++ % this.maxColors];

            flattened[
              this.$store.getters.serialById(dbId) +
                ": " +
                k.replace(this.measuresPrefix, "")
            ] = aggregate[dbId][k];
          });
        });

        if (Object.keys(flattened).length > 0) {
          const exportWorker = new Worker("./workers/exporter.js");

          exportWorker.onerror = () => {
            exportWorker.terminate();
          };

          exportWorker.onmessage = (e) => {
            this.exportData = e.data;
            exportWorker.terminate();
          };

          exportWorker.postMessage({
            chartData: flattened,
            dataKeys: Object.keys(flattened),
          });
        }

        this.$nextTick(() => (this.chartFilters = tmpFilters));
        this.$nextTick(() => (this.chartData = aggregate));
      });
    },
    fileName(ext = "csv") {
      const format = "YYYY-MM-DD_HH.mm";
      // eslint-disable-next-line prettier/prettier
      const vName = this.chosenAssets.map((a) => a.serial).join("-");
      const rangeStart = moment(this.dateRange.startDate).format(format);
      const rangeEnd = moment(this.dateRange.endDate).format(format);

      return `${vName}_export_${rangeStart}_${rangeEnd}.${ext}`;
    },
    downloadChartImage() {
      const base64 = this.$refs.aLineChart.getChartImage();
      const downloader = document.createElement("a");

      downloader.href = base64;
      downloader.download = this.fileName("png");

      downloader.click();
    },
    downloadPdf() {
      this.loadingChart = true;

      const reportData = {
        selectedTopic: this.valueForAnalysis.asset?.topic,
        chart: this.$refs.aLineChart.getChartImage(),
        targetValue:
          this.truncate(this.valueForAnalysis.value) +
          " " +
          this.valueForAnalysis.unit,
        results: this.autoComputed.map((ac) => ({
          name: this.$t(ac.name),
          value: `${ac.value}`,
        })),
        timeStart: this.useLimits
          ? this.cursorA
          : this.toMillis(this.dateRange.startDate),
        timeEnd: this.useLimits
          ? this.cursorB
          : this.toMillis(this.dateRange.endDate),
      };

      // Download PDF report from backend
      ApiRequests.getDataReport(
        this.valueForAnalysis.asset?.dbId,
        reportData,
        (res) => {
          const downloader = document.createElement("a");
          const url = window.URL || window.webkitURL;

          downloader.href = url.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          downloader.download =
            this.$t("analysisReport") +
            "-" +
            moment(reportData.timeStart).format(
              `${this.$t("tzLocale")}_HH:mm:SS`
            ) +
            "-" +
            moment(reportData.timeEnd).format(
              `${this.$t("tzLocale")}_HH:mm:SS`
            ) +
            ".pdf";

          downloader.click();

          this.loadingChart = false;
        },
        (err) => {
          if (process.env.NODE_ENV === "development") console.error(err);

          this.$bus.$emit(Messages.ERROR_MSG, {
            error: err?.response?.data?.error || err,
            description: this.$t("pdfCreateFailure"),
          });

          this.loadingChart = false;
        }
      );
    },
    cursorPlaced(cursor, timestamp) {
      this[`cursor${cursor}`] = timestamp;
    },
    toMillis(date) {
      return moment.utc(date).local().toDate().getTime();
    },
    getListIcon(id, topic) {
      return this.isMeasureSelected(topic, id)
        ? this.getChartColor(id, topic) != undefined
          ? "mdi-checkbox-blank-circle"
          : "mdi-checkbox-blank-circle-outline"
        : "";
    },
    getChartColor(id, topic) {
      return this.plotColors[id + "/" + this.measuresPrefix + topic];
    },
    getPinnedName(asset, child) {
      return (asset.plate || asset.serial) + ": " + this.$t(child);
    },
    childIsVisible(child) {
      return this.chartFilters.findIndex((f) => f.includes(child)) >= 0;
    },
    truncate(value) {
      if (value == undefined) return "";

      return value.toFixed(this.$refs.aLineChart.defaultDecimals);
    },
    getTagChanges(assetSerial, tagValue) {
      ApiRequests.getHistory(
        moment(this.dateRange.startDate).format(),
        moment(this.dateRange.endDate).format(),
        "System/AC",
        assetSerial,
        (res) => {
          const mappedData = res.data.map((hv) => ({
            time: moment.utc(hv.time).local().toDate().getTime(),
            value: hv.tags[tagValue],
          }));

          mappedData.sort((va, vb) => va.time - vb.time);

          const finalChangeData = [];
          const finalRangesData = [];
          let lastSeenTag = undefined;

          mappedData.forEach((tagChange) => {
            if (
              lastSeenTag !== undefined &&
              tagChange.value !== lastSeenTag.value
            ) {
              finalChangeData.push({
                time: tagChange.time,
                from: lastSeenTag.value ?? "",
                to: tagChange.value ?? "",
              });
            }

            lastSeenTag = tagChange;
          });

          this.tagChanges = finalChangeData;

          finalChangeData.forEach((change) => {
            if (finalRangesData.length !== 0) {
              finalRangesData[finalRangesData.length - 1].end = moment(
                change.time
              );
            }

            finalRangesData.push({
              name: tagValue,
              value: change.to,
              start: moment(change.time),
              end: undefined,
            });
          });

          finalRangesData[finalRangesData.length - 1].end = moment(
            this.dateRange.endDate
          );

          this.tagRanges = finalRangesData;
        },
        (error) => {
          if (process.env.NODE_ENV === "development") console.error(error);

          this.tagChanges = [];
          this.tagRanges = [];
        }
      );
    },
    startAnalysis(yValue) {
      this.autoComputed = [];

      if (yValue == undefined) {
        return;
      }

      this.resultLoading = true;

      const total = this.algorithms.length;
      let finished = 0;

      if (total === 0) return (this.resultLoading = false);

      this.algorithms.forEach((algorithm) => {
        const autoWorker = new Worker(
          `./workers/algorithms.js?ts=${new Date().getTime()}`
        );

        autoWorker.onerror = () => {
          autoWorker.terminate();

          // Stop loading
          this.resultLoading = false;
        };

        autoWorker.onmessage = (evt) => {
          this.autoComputed.push({
            order: algorithm.order,
            name: algorithm.name,
            value: algorithm.formatter(evt.data, (str) => this.$t(str)),
          });

          autoWorker.terminate();

          if (++finished === total) {
            this.autoComputed.sort((a, b) => a.order - b.order);
          }

          // Stop loading
          this.resultLoading = false;
        };

        autoWorker.postMessage({
          op: algorithm.name,
          y: yValue.value,
          min: this.cursorA,
          max: this.cursorB,
          useLimits: this.useLimits,
          data: this.chartData[yValue.asset.dbId][yValue.asset.completeTopic],
        });
      });
    },
  },
};
</script>
