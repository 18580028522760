<template>
  <div class="table-grid">
    <!-- Table header -->
    <v-toolbar flat>
      <template v-if="selected.length > 0">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary">
              {{ $t("btnBulkActions") }}
              <v-icon right dark>
                mdi-chevron-{{
                  attrs["aria-expanded"] == "false" ? "down" : "up"
                }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <template
              v-if="
                currentPage === pages.ASSETS_PAGE ||
                currentPage === pages.CUSTOMER_DETAILS_PAGE
              "
            >
              <v-list-item
                v-on:click="$emit('table-bulk-customer', selected)"
                link
              >
                <v-list-item-title>
                  {{ $t("btnAssignCustomer") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-on:click="$emit('table-bulk-group', selected)"
                link
              >
                <v-list-item-title>
                  {{ $t("btnAssignGroup") }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template
              v-if="
                currentPage === pages.ASSETS_PAGE &&
                (currentUser.isSuperAdmin || currentUser.isAdmin)
              "
            >
              <v-list-item v-on:click="$emit('table-bulk-user', selected)" link>
                <v-list-item-title>
                  {{ $t("btnAssignUsers") }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-if="currentPage === pages.ASSETS_PAGE && emailActive">
              <v-list-item
                v-on:click="$emit('table-bulk-mailing', selected)"
                link
              >
                <v-list-item-title>
                  {{ $t("btnAssignMailingLists") }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <v-list-item
              v-if="actions.enableDisable"
              v-on:click="$emit('table-bulk-enable', selected)"
              link
            >
              <v-list-item-title>
                {{ $t("btnEnable") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="actions.enableDisable"
              v-on:click="$emit('table-bulk-disable', selected)"
              link
            >
              <v-list-item-title>
                {{ $t("btnDisable") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="actions.delete"
              v-on:click="$emit('table-bulk-delete', selected)"
              link
            >
              <v-list-item-title class="error--text">
                {{ $t("btnDelete") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-toolbar-title class="body-1 ms-6">
          {{ selected.length }} {{ tableStrings[currentPage].entity }}
          {{ tableStrings[currentPage].selected }}
        </v-toolbar-title>
      </template>
      <slot name="misc-actions" />
      <v-spacer />
      <v-toolbar-title class="body-1 font-weight-bold me-8">
        {{ filteredAndSortedItems.length }}
        {{ tableStrings[currentPage].entity }}
      </v-toolbar-title>
      <v-select
        v-model="sortingField"
        v-bind:items="sortingFields"
        v-bind:label="$t('sortBy')"
        v-bind:outlined="outlinedPref"
        class="filters-select"
        dense
        hide-details
      >
        <template v-slot:append-item>
          <v-switch
            v-model="reversed"
            v-bind:label="$t('reverseOrder')"
            class="ms-6"
            inset
          />
        </template>
      </v-select>
      <v-text-field
        v-model="searchTerm"
        v-bind:outlined="outlinedPref"
        v-bind:label="$t('searchPlaceholder')"
        class="search-field ms-6"
        prepend-inner-icon="mdi-magnify"
        clearable
        dense
        hide-details
      />
    </v-toolbar>

    <div class="scroll-container">
      <div class="t-scroll">
        <v-data-table
          v-model="selected"
          v-bind:disable-pagination="true"
          v-bind:fixed-header="true"
          v-bind:headers="headers"
          v-bind:items="filteredAndSortedItems"
          v-bind:group-by="groupBy"
          v-bind:items-per-page="-1"
          v-bind:loading="loading"
          v-bind:show-select="bulkColumn"
          v-on:click:row="handleClick"
          hide-default-footer
        >
          <!-- Group by -->

          <template v-slot:group.header="{ group, items, isOpen, toggle }">
            <td
              v-bind:colspan="headers.length + (bulkColumn ? 1 : 0)"
              class="indigo lighten-5"
            >
              <v-btn v-on:click="toggle" icon>
                <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
              <v-chip
                v-if="!!group"
                v-on:click="$emit('table-grouping-header', group)"
                class="indigo lighten-5 primary--text"
              >
                {{ group }}
              </v-chip>
              <span class="text-lowercase">
                ({{ items.length }} {{ $t("assets") }})
              </span>
            </td>
          </template>

          <!-- Table body -->

          <!-- COMMONS -->
          <template
            v-if="
              currentPage === pages.USERS_PAGE ||
              currentPage === pages.CUSTOMERS_PAGE ||
              currentPage === pages.RULES_PAGE
            "
            v-slot:item.enabled="{ item }"
          >
            <v-chip v-bind:color="item.enabled ? 'success' : 'error'" small>
              {{
                item.enabled
                  ? tableStrings[currentPage].enabled
                  : tableStrings[currentPage].disabled
              }}
            </v-chip>
          </template>
          <template
            v-if="
              currentPage === pages.USERS_PAGE ||
              currentPage === pages.GROUPS_PAGE ||
              currentPage === pages.RULES_PAGE
            "
            v-slot:item.modified="{ item }"
          >
            <div class="grey--text text--darken-2">
              {{ item.readableModified() }}
            </div>
          </template>

          <!-- NAME -->
          <template v-slot:item.name="{ item }">
            <div v-if="currentPage === pages.USERS_PAGE" class="my-2">
              <div class="font-weight-bold">{{ item.fullName() }}</div>
              <div class="caption primary--text text--lighten-2">
                {{ item.email }}
              </div>
            </div>
            <div v-else-if="currentPage === pages.RULES_PAGE">
              <div class="font-weight-bold">{{ item.name }}</div>
              <div class="caption primary--text text--lighten-2">
                {{ item.description }}
              </div>
            </div>
            <div
              v-else-if="currentPage === pages.GROUPS_PAGE && item.municipality"
            >
              <span class="font-weight-bold">{{ item.name }}</span>
              <span class="caption grey--text ms-3">
                {{ item.municipality }}
                <span v-if="item.province">({{ item.province }})</span>
              </span>
            </div>
            <div v-else class="font-weight-bold">
              {{ item.name }}
            </div>
          </template>

          <!-- ROLE -->
          <template v-slot:item.role="{ item }">
            <div>{{ item.role >= 0 ? $t(item.roleName) : "--" }}</div>
          </template>

          <!-- USER'S ASSETS COUNT -->
          <template v-slot:item.assetIds="{ item }">
            <div class="grey--text text--darken-2">
              {{ item.assetIds.length }}
            </div>
          </template>

          <!-- COMPANY / GROUP -->
          <template v-slot:item.companyName="{ item }">
            <div
              v-if="
                currentPage === pages.USERS_PAGE && currentUser.isSuperAdmin
              "
            >
              <div
                v-if="item.companyName != undefined || item.groupIds.length > 0"
                class="caption primary--text text--lighten-2 grey--text text--darken-2 font-weight-bold"
              >
                {{
                  item.companyName != undefined
                    ? $t("companyName")
                    : item.groupIds.length > 0
                    ? $t("groups")
                    : "--"
                }}
              </div>
              <div class="grey--text text--darken-2">
                {{
                  item.companyName != undefined
                    ? item.companyName
                    : item.groupIds.length > 0
                    ? item.groupNames.join(", ")
                    : "--"
                }}
              </div>
            </div>
            <div v-else class="my-2">
              <div
                v-if="
                  item.companyName != undefined || item.groupName != undefined
                "
                class="caption primary--text text--lighten-2 grey--text text--darken-2 font-weight-bold"
              >
                {{
                  item.companyName != undefined
                    ? $t("assetOwner")
                    : item.groupName != undefined
                    ? $t("assetGroup")
                    : "--"
                }}
              </div>
              <div class="grey--text text--darken-2">
                {{
                  item.companyName != undefined
                    ? item.companyName
                    : item.groupName != undefined
                    ? item.groupName
                    : "--"
                }}
              </div>
            </div>
          </template>
          <template v-slot:item.groupNames="{ item }">
            <div class="grey--text text--darken-2">
              {{
                item.groupIds.length > 0
                  ? item.groupNames.filter((gn) => !!gn).join(", ")
                  : "--"
              }}
            </div>
          </template>

          <!-- CODE -->
          <template v-slot:item.code="{ item }">
            <div class="grey--text text--darken-2">
              {{ item.code }}
            </div>
          </template>

          <!-- ASSETS COUNT -->
          <template v-slot:item.assets="{ item }">
            <div
              v-if="currentPage === pages.CUSTOMERS_PAGE"
              class="grey--text text--darken-2"
            >
              {{ item.assets }}
            </div>
            <div v-else class="grey--text text--darken-2 font-weight-bold">
              {{ item.assets }}
            </div>
          </template>

          <!-- TYPE -->
          <template
            v-if="currentPage === pages.GROUPS_PAGE"
            v-slot:item.type="{ item }"
          >
            <div class="caption">
              {{ $t(item.type) }}
            </div>
          </template>
          <template
            v-else-if="currentPage === pages.RULES_PAGE"
            v-slot:item.type="{ item }"
          >
            <div class="caption grey--text">{{ $t(item.type) }}</div>
          </template>
          <template v-else v-slot:item.type="{ item }">
            <div class="grey--text text--darken-2">
              {{ item.type }}
            </div>
          </template>

          <!-- ALARMS -->
          <template v-slot:item.alarmsCount="{ item }">
            <div class="d-flex justify-center align-center">
              <div
                v-bind:title="$t('low')"
                v-bind:class="{
                  'alarm-chip': true,
                  'low-color': (alarmsCountById[item.id] || {}).low > 0,
                  grey: ((alarmsCountById[item.id] || {}).low || 0) == 0,
                  'lighten-2': ((alarmsCountById[item.id] || {}).low || 0) == 0,
                }"
                style="border-radius: 4px 0 0 4px"
              >
                {{
                  alarmsCountById[item.id] != undefined
                    ? alarmsCountById[item.id].low
                    : 0
                }}
              </div>
              <div
                v-bind:title="$t('medium')"
                v-bind:class="{
                  'alarm-chip': true,
                  'medium-color': (alarmsCountById[item.id] || {}).medium > 0,
                  grey: ((alarmsCountById[item.id] || {}).medium || 0) == 0,
                  'lighten-2':
                    ((alarmsCountById[item.id] || {}).medium || 0) == 0,
                }"
              >
                {{
                  alarmsCountById[item.id] != undefined
                    ? alarmsCountById[item.id].medium
                    : 0
                }}
              </div>
              <div
                v-bind:title="$t('high')"
                v-bind:class="{
                  'alarm-chip': true,
                  'high-color': (alarmsCountById[item.id] || {}).high > 0,
                  grey: ((alarmsCountById[item.id] || {}).high || 0) == 0,
                  'lighten-2':
                    ((alarmsCountById[item.id] || {}).high || 0) == 0,
                }"
                style="border-radius: 0 4px 4px 0"
              >
                {{
                  alarmsCountById[item.id] != undefined
                    ? alarmsCountById[item.id].high
                    : 0
                }}
              </div>
            </div>
          </template>

          <!-- ASSETS PAGE -->
          <template v-slot:item.connection="{ item }">
            <div class="my-2">
              <v-chip
                v-if="
                  isOffline[item.boxMacAddress] == undefined && item.isForecast
                "
                color="indigo"
                class="white--text"
                small
              >
                {{ $t("forecastOnlyAsset") }}
              </v-chip>
              <v-chip
                v-else
                v-bind:color="
                  isOffline[item.boxMacAddress] == undefined
                    ? 'grey'
                    : isOffline[item.boxMacAddress]
                    ? 'error'
                    : 'success'
                "
                v-bind:class="
                  isOffline[item.boxMacAddress] == undefined
                    ? 'white--text'
                    : ''
                "
                small
              >
                {{
                  isOffline[item.boxMacAddress] == undefined
                    ? tableStrings[currentPage].unknown
                    : isOffline[item.boxMacAddress]
                    ? tableStrings[currentPage].disabled
                    : tableStrings[currentPage].enabled
                }}
              </v-chip>
              <template
                v-if="
                  !item.isForecast &&
                  (isOffline[item.boxMacAddress] ||
                    isOffline[item.boxMacAddress] == undefined)
                "
              >
                <div class="caption mt-1">{{ $t("lastDataTs") }}</div>
                <div
                  class="caption font-weight-bold"
                  style="line-height: 0.7rem"
                >
                  {{
                    getReadableTimestamp(lastSeenBySerial(item.serial), true)
                  }}
                </div>
              </template>
            </div>
          </template>
          <template v-slot:item.serial="{ item }">
            <div class="primary--text text--lighten-2">{{ item.serial }}</div>
          </template>
          <template v-slot:item.plate="{ item }">
            <div class="font-weight-bold">{{ item.plate }}</div>
          </template>
          <template v-slot:item.brand="{ item }">
            <div class="font-weight-bold" style="display: inline">
              <v-avatar v-if="item.icon" class="me-4">
                <img v-bind:src="item.icon" />
              </v-avatar>
              {{ item.brand }} {{ item.model }}
              <div
                v-if="item.version"
                class="ms-2 caption font-weight-bold grey--text"
                style="display: inline"
              >
                ({{ item.version }})
              </div>
            </div>
          </template>
          <template v-slot:item.groupName="{ item }">
            <div class="grey--text text--darken-2">
              {{ item.groupName != undefined ? item.groupName : "--" }}
            </div>
          </template>
          <template v-slot:item.measuresHighlights="{ item }">
            <div
              v-if="item.boxMacAddress"
              class="d-flex flex-column justify-center align-end my-1"
            >
              <div
                v-for="measure in item.measuresSummary"
                v-bind:key="item.id + '-h-' + measure"
                class="caption"
              >
                <span class="me-1">{{ item.highlightName(measure) }}:</span>
                <span
                  v-bind:class="
                    'font-weight-bold ' +
                    getMeasureValue(
                      availableMeasures[item.boxMacAddress][measure] || {}
                    ).getTextColor()
                  "
                >
                  {{
                    getMeasureValue(
                      availableMeasures[item.boxMacAddress][measure] || {}
                    ).formattedContent()
                  }}
                </span>
              </div>
            </div>
          </template>

          <!-- GROUPS PAGE -->
          <template v-slot:item.functions="{ item }">
            <div class="d-flex flex-wrap justify-start">
              <div
                v-for="(tag, index) in item.functions"
                v-bind:key="item.id + '-table-tag-' + tag"
              >
                <v-chip
                  v-bind:class="index < item.functions.length - 1 ? 'me-2' : ''"
                  class="indigo lighten-5 primary--text"
                  x-small
                >
                  {{ tag }}
                </v-chip>
              </div>
            </div>
          </template>

          <!-- RULES -->
          <template v-slot:item.severityNum="{ item }">
            <div style="display: inline">
              <v-icon v-bind:color="item.severityIcon.color">
                {{ item.severityIcon.icon }}
              </v-icon>
              <span class="ms-1 caption grey--text">
                {{ $t(item.severity) }}
              </span>
            </div>
          </template>
          <template v-slot:item.structure="{ item }">
            {{ parse(item.structure) }}
          </template>

          <!-- ALARMS -->
          <template v-slot:item.graphics="{ item }">
            <v-icon
              v-bind:color="item.graphics.color"
              v-bind:title="$t(item.severity)"
            >
              {{ item.graphics.icon }}
            </v-icon>
          </template>
          <template v-slot:item.attributes="{ item }">
            <div
              v-bind:title="
                item
                  .getTags()
                  .map((t) => `${t}: ${item.getTagValue(t)}`)
                  .join('\n')
              "
              class="d-flex justify-center align-center flex-wrap"
              style="gap: 2px; padding: 4px"
            >
              <div
                v-for="tag in item.getTags().slice(0, 3)"
                v-bind:key="'table-e-tag-' + item.id + '-' + tag"
                class="event-tag rounded"
              >
                <div>
                  {{ tag }}
                </div>
                <div class="font-weight-bold">
                  {{ item.getTagValue(tag) }}
                </div>
              </div>
              <div v-if="item.getTags().length > 3">...</div>
            </div>
          </template>
          <template v-slot:item.message="{ item }">
            <div class="font-weight-bold">
              {{ item.attributes.message || "--" }}
            </div>
          </template>
          <template v-slot:item.originId="{ item }">
            <div class="grey--text text--darken-2 font-weight-bold">
              {{ item.attributes.plate || item.attributes.serial }}
            </div>
          </template>
          <template v-slot:item.readableTs="{ item }">
            <div class="grey--text text--darken-2">
              {{ item.readableTs(false) }}
            </div>
          </template>
          <template v-slot:item.eventDuration="{ item }">
            <div class="grey--text text--darken-2">
              <code style="background: transparent">{{ item.duration() }}</code>
            </div>
          </template>
          <template v-slot:item.ts="{ item }">
            <div
              v-bind:key="
                item.originId + '-' + item.ts + '-ts-' + refreshTrigger
              "
              class="grey--text text--darken-2"
            >
              {{ item.readableTs(true) }}
            </div>
          </template>

          <!-- Table actions (optional) -->
          <template v-if="actionsColumn" v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-if="actions.edit"
                  v-on:click="$emit('table-edit', item)"
                  link
                >
                  <v-list-item-title>{{ $t("btnEdit") }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="actions.enableDisable"
                  v-on:click="
                    $emit(`table-${item.enabled ? 'disable' : 'enable'}`, item)
                  "
                  link
                >
                  <v-list-item-title>
                    {{ item.enabled ? $t("btnDisable") : $t("btnEnable") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="actions.delete"
                  v-on:click="$emit('table-delete', item)"
                  link
                >
                  <v-list-item-title class="error--text">
                    {{ $t("btnDelete") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-data-table >>> .v-data-table__wrapper {
  overflow: unset;
}

.search-field {
  max-width: 20em;
}

.filters-select {
  max-width: 15em;
}

.table-grid {
  position: absolute;
  inset: 0;
  grid-template-rows: min-content 1fr;
  overflow: hidden;
}

.scroll-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.t-scroll {
  position: absolute;
  inset: 0 0 4.2em 0;
  overflow: auto;
}

.alarm-chip {
  padding: 0.2em 0.6em;
  font-weight: bold;
}

.low-color {
  background: #3caea3b0;
}

.medium-color {
  background: #f6d55cb0;
}

.high-color {
  background: #ed553bb0;
}

.event-tag {
  color: #0c1d69;
  border: 1px solid #0c1d69;
  font-size: smaller;
  line-height: 1em;
  padding: 0.25em;
}

div {
  cursor: pointer !important;
}
</style>

<script>
import Pages from "../utils/pages";
import Messages from "../utils/messages";

export default {
  name: "EntitiesTable",

  props: {
    headers: { type: Array, reqired: true },
    sortingFields: { type: Array, reqired: true },
    items: { type: Array, reqired: true },
    syncedItems: { type: Array, reqired: false, default: () => [] },
    groupBy: { type: String, reqired: false },
    defaultSort: { type: String, require: true },
    loading: { type: Boolean, reqired: false, default: false },
    actionsColumn: { type: Boolean, reqired: false, default: true },
    actions: {
      type: Object,
      required: false,
      default: () => ({ edit: true, enableDisable: true, delete: true }),
    },
    bulkColumn: { type: Boolean, reqired: false, default: true },
    refreshTrigger: { type: Boolean, reqired: false, default: false },
    tableItems: { type: Number, required: false, default: 0 },
  },

  data: () => ({
    pages: Pages,
    sortingField: undefined,
    searchTerm: undefined,
    selected: [],
    reversed: false,
  }),

  beforeMount() {
    this.sortingField = this.defaultSort;
    this.$bus.$on(Messages.RESET_TABLE_SELECTED, this.resetSelected);
  },

  beforeDestroy() {
    this.$bus.$off(Messages.RESET_TABLE_SELECTED, this.resetSelected);
  },

  computed: {
    tableStrings() {
      const strings = {};

      strings[Pages.USERS_PAGE] = {
        d: this.$t("users"),
        selected: this.$t("selected"),
        enabled: this.$t("userEnabled"),
        disabled: this.$t("userDisabled"),
      };
      strings[Pages.CUSTOMERS_PAGE] = {
        entity: this.$t("customers"),
        selected: this.$t("selected"),
        enabled: this.$t("customerEnabled"),
        disabled: this.$t("customerDisabled"),
      };
      strings[Pages.GROUPS_PAGE] = {
        entity: this.$t("groups"),
        selected: this.$t("selectedFemale"),
      };
      strings[Pages.ASSETS_PAGE] = {
        entity: this.$t("assets"),
        selected: this.$t("selected"),
        enabled: this.$t("online"),
        disabled: this.$t("offline"),
        unknown: this.$t("unknown"),
      };
      strings[Pages.CUSTOMER_DETAILS_PAGE] = strings[Pages.ASSETS_PAGE];
      strings[Pages.ALARMS_PAGE] = {
        entity: this.$t("events"),
      };
      strings[Pages.DETAILS_PAGE] = strings[Pages.ALARMS_PAGE];
      strings[Pages.BOXES_PAGE] = {
        ...strings[Pages.ASSETS_PAGE],
        entity: this.$t("boxes"),
      };
      strings[Pages.RULES_PAGE] = {
        entity: this.$t("rules"),
        enabled: this.$t("userEnabled"),
        disabled: this.$t("userDisabled"),
      };

      return strings;
    },
    filteredAndSortedItems() {
      return this.items
        .filter((i) => i.matchSearchTerm(this.searchTerm))
        .sort((i1, i2) => {
          const field1 = i1[this.sortingField];
          const field2 = i2[this.sortingField];

          switch (this.sortingField) {
            case "modified":
              return (
                (this.reversed ? -1 : 1) * (field1.isAfter(field2) ? -1 : 1)
              );
            case "ts":
              return (this.reversed ? -1 : 1) * (field1 > field2 ? -1 : 1);
            default:
              return (this.reversed ? -1 : 1) * (field1 > field2 ? 1 : -1);
          }
        });
    },
  },

  watch: {
    searchTerm(newValue) {
      this.$emit("table-search", newValue);
    },
    filteredAndSortedItems: {
      deep: true,
      handler(newValue) {
        this.$emit("update:tableItems", newValue.length);
        this.$emit("update:syncedItems", newValue);
      },
    },
  },

  methods: {
    handleClick(value) {
      this.$emit("table-click", value);
    },
    resetSelected() {
      this.selected = [];
    },
    parse(structure) {
      let structString = "";

      structure.forEach((operation) => {
        if (typeof operation === "string") {
          structString += ` ${operation} `;
        } else {
          if (Array.isArray(operation)) {
            structString += `[ ${this.parse(operation)} ]`;
          } else {
            // eslint-disable-next-line prettier/prettier
            structString += `( ${operation.topic} ${this.$t(operation.comparison)} ${operation.value} )`;
          }
        }
      });

      return structString;
    },
  },
};
</script>
