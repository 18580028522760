<template>
  <div class="pa-4 fill-height grid">
    <h1>
      <v-row align="center">
        <v-col>
          {{ $t("menuCustomers") }}
        </v-col>
        <v-col align="end">
          <template v-if="currentUser.isSuperAdmin">
            <v-btn v-on:click="newCustomerDialog = true" color="primary">
              {{ $t("btnNewCustomer") }}
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </h1>

    <div class="table">
      <EntitiesTable
        v-bind:headers="headers"
        v-bind:sorting-fields="sortingFields"
        v-bind:items="customersList"
        v-bind:defaultSort="sortingFields[0].value"
        v-bind:loading="loading"
        v-bind:actionsColumn="true"
        v-bind:actions="{ edit: true, enableDisable: true, delete: true }"
        v-bind:bulkColumn="true"
        v-on:table-bulk-enable="manageCustomerBulk($event, 0)"
        v-on:table-bulk-disable="manageCustomerBulk($event, 1)"
        v-on:table-bulk-delete="manageCustomerBulk($event, 2)"
        v-on:table-click="handleClick"
        v-on:table-edit="manageCustomer($event, 0)"
        v-on:table-disable="manageCustomer($event, 1)"
        v-on:table-enable="manageCustomer($event, 1)"
        v-on:table-delete="manageCustomer($event, 2)"
      />
    </div>

    <v-dialog
      v-model="newCustomerDialog"
      v-if="newCustomerDialog"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("btnNewCustomer") }}</v-card-title>
        <v-card-subtitle v-if="!customer.adminPassword" class="pb-0">
          <v-alert class="mt-2 mb-0 short-alert" color="indigo" text outlined>
            {{ $t("autoPassword") }}
          </v-alert>
        </v-card-subtitle>
        <v-card-text>
          <v-form class="mt-2 d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="customer.name"
              v-bind:label="$t('customerName')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
            <v-text-field
              v-model="customer.code"
              v-bind:label="$t('customerCode')"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
          </v-form>
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="customer.adminName"
              v-bind:label="$t('customerUserName')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
            <v-text-field
              v-model="customer.adminSurname"
              v-bind:label="$t('customerUserSurname')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
          </v-form>
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="customer.adminEmail"
              v-bind:label="$t('customerUserEmail')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
          </v-form>
          <v-form class="d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="customer.adminPassword"
              v-bind:append-icon="
                showPwd ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              v-bind:label="$t('customerUserPassword')"
              v-bind:rules="[
                (v) => !v || pwdRegExp.test(v) || $t('regExpPassword'),
              ]"
              v-bind:type="showPwd ? 'text' : 'password'"
              v-on:click:append="showPwd = !showPwd"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-switch
            v-model="customer.enabled"
            v-bind:label="
              customer.enabled ? $t('customerEnabled') : $t('customerDisabled')
            "
            class="ms-4"
            inset
          />
          <v-spacer />
          <v-btn v-on:click="resetCustomer" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validCustomer(false)"
            v-on:click="postCustomer(false)"
            color="primary"
            text
          >
            {{ $t("btnCreate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editCustomerDialog"
      v-if="editCustomerDialog"
      width="unset"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t("customerUpdate") }}</v-card-title>
        <v-card-text>
          <v-form class="mt-2 d-flex flex-grow-1 flex-row flex-wrap">
            <v-text-field
              v-model="customer.name"
              v-bind:label="$t('customerName')"
              v-bind:rules="[(v) => !!v || $t('required')]"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
            <v-text-field
              v-model="customer.code"
              v-bind:label="$t('customerCode')"
              v-bind:outlined="outlinedPref"
              class="mx-2"
              clearable
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-switch
            v-model="customer.enabled"
            v-bind:label="
              customer.enabled ? $t('customerEnabled') : $t('customerDisabled')
            "
            class="ms-4"
            inset
          />
          <v-spacer />
          <v-btn v-on:click="resetCustomer" color="error" text>
            {{ $t("btnCancel") }}
          </v-btn>
          <v-btn
            v-bind:disabled="!validCustomer(true)"
            v-on:click="postCustomer(true)"
            color="primary"
            text
          >
            {{ $t("btnSave") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      v-if="confirmDelete"
      v-bind:callback="confirmCallback"
      v-bind:content="$t('confirmCustomerDelete')"
      v-bind:title="$t('dialogCustomerDeleteConfirm')"
      v-on:cancel="confirmDelete = false"
    />

    <v-dialog v-model="updating" width="unset" persistent>
      <v-card>
        <v-progress-circular class="ma-4" color="primary" indeterminate />
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.short-alert {
  max-width: 60ch;
}

.grid {
  display: grid;
  grid-template-rows: min-content 1fr;
  max-height: 100%;
  overflow: hidden;
}

.table {
  position: relative;
  overflow: hidden;
}
</style>

<script>
import ApiRequests from "../utils/requests";
import Pages from "../utils/pages";
import Messages from "../utils/messages";
import ConfirmationDialog from "../components/ConfirmationDialog";
import CustomerInfo from "../models/CustomerInfo";
import EntitiesTable from "../components/EntitiesTable";

export default {
  name: "Clients",

  components: { ConfirmationDialog, EntitiesTable },

  data: () => ({
    showPwd: false,
    confirmDelete: false,
    confirmCallback: () => {},
    newCustomerDialog: false,
    customer: new CustomerInfo(),
    assetsCount: {},
    editCustomerDialog: false,
    loading: false,
    updating: false,
  }),

  computed: {
    validCustomer() {
      return (editing) => {
        if (editing) {
          return !!this.customer.name;
        }

        return (
          !!this.customer.name &&
          !!this.customer.adminName &&
          !!this.customer.adminSurname &&
          !!this.customer.adminEmail &&
          (!this.customer.adminPassword ||
            this.pwdRegExp.test(this.customer.adminPassword))
        );
      };
    },
    sortingFields() {
      return [
        { text: this.$t("customerName"), value: "name" },
        { text: this.$t("customerCode"), value: "code" },
        { text: this.$t("customerAssets"), value: "assets" },
        { text: this.$t("customerStatus"), value: "enabled" },
      ];
    },
    headers() {
      return [
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("customerStatus"), align: "start", sortable: false, value: "enabled", width: "10%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("customerName"), align: "start", sortable: false, value: "name", width: "35%" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("customerCode"), align: "end", sortable: false, value: "code" },
        // eslint-disable-next-line prettier/prettier
        { text: this.$t("customerAssets"), align: "end", sortable: false, value: "assets", width: "10%" },
        // eslint-disable-next-line prettier/prettier
        { text: "", align: "end", sortable: false, value: "actions", width: "5%" },
      ];
    },
  },

  beforeMount() {
    this.updateFiltersRules(this.clientId);
    this.$store.commit("setCurrentPage", Pages.CUSTOMERS_PAGE);
    this.$store.commit("setCustomCurrentPage", -1);
  },

  mounted() {
    this.$store.commit("addPage", {
      text: this.$t("menuCustomers"),
      to: "/customers",
      root: true,
    });
  },

  watch: {
    clientId(newValue, oldValue) {
      if (newValue != oldValue) this.updateFiltersRules(newValue);
    },
  },

  methods: {
    updateFiltersRules(id) {
      if (id !== undefined) {
        ApiRequests.createOrUpdateFilter(
          id,
          this.signalRBaseRegexes,
          () => {},
          (err) =>
            process.env.NODE_ENV === "development"
              ? console.error(err)
              : undefined
        );
      }
    },
    postCustomer(editing = false) {
      if (!editing) {
        ApiRequests.createCustomer(
          this.customer.getCustomerCreateBody(),
          () => {
            this.$bus.$emit(
              Messages.SUCCESS_MSG,
              this.$t("customerCreateSuccess")
            );
            this.resetCustomer();
            this.fetchAllData();
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("customerCreateFailure"),
            });
          }
        );
      } else {
        ApiRequests.updateCustomer(
          this.customer.id,
          this.customer.getCompanyUpdateBody(),
          () => {
            this.$bus.$emit(
              Messages.SUCCESS_MSG,
              this.$t("customerUpdateSuccess")
            );
            this.resetCustomer();
            this.fetchAllData();
          },
          (err) => {
            if (process.env.NODE_ENV === "development") console.error(err);
            this.$bus.$emit(Messages.ERROR_MSG, {
              error: err?.response?.data?.error || err,
              description: this.$t("customerUpdateFailure"),
            });
          }
        );
      }
    },
    manageCustomerBulk(selected, mode) {
      const enableDisable = (enabled, customer) => {
        return ApiRequests.updateCustomer(
          customer.id,
          { enabled },
          undefined,
          undefined
        );
      };
      const callback = (res, text1, text2, text3) => {
        const total = selected.length;
        const sent = res.reduce(
          (acc, r) => acc + (r.status === "fulfilled" ? 1 : 0),
          0
        );

        if (total > sent) {
          this.$bus.$emit(Messages.ERROR_MSG, {
            error: text3.replace(/%s%/g, sent).replace(/%t%/g, total),
            description: text2,
          });
        } else {
          this.$bus.$emit(Messages.SUCCESS_MSG, text1);
        }

        this.$bus.$emit(Messages.RESET_TABLE_SELECTED);
        this.fetchAllData();
        this.updating = false;
      };

      switch (mode) {
        case 0:
          this.updating = true;
          Promise.allSettled(
            selected.map((c) => enableDisable(true, c))
          ).then((res) =>
            callback(
              res,
              this.$t("customerUpdateSuccess"),
              this.$t("customerUpdateFailure"),
              "Error: %s% of %t% customers updated"
            )
          );
          break;
        case 1:
          this.updating = true;
          Promise.allSettled(
            selected.map((c) => enableDisable(false, c))
          ).then((res) =>
            callback(
              res,
              this.$t("customerUpdateSuccess"),
              this.$t("customerUpdateFailure"),
              "Error: %s% of %t% customers updated"
            )
          );
          break;
        default:
          this.confirmDelete = true;
          this.confirmCallback = () => {
            this.updating = true;
            Promise.allSettled(
              selected.map((c) => {
                return ApiRequests.deleteCustomer(c.id, undefined, undefined);
              })
            ).then((res) => {
              this.confirmCallback = () => {};
              callback(
                res,
                this.$t("customerDeleteSuccess"),
                this.$t("customerDeleteFailure"),
                "Error: %s% of %t% customers deleted"
              );
            });
          };
          break;
      }
    },
    manageCustomer(customer, mode) {
      switch (mode) {
        case 0:
          Object.assign(this.customer, customer);
          this.editCustomerDialog = true;
          break;
        case 1:
          ApiRequests.updateCustomer(
            customer.id,
            { enabled: !customer.enabled },
            () => {
              this.$bus.$emit(
                Messages.SUCCESS_MSG,
                this.$t("customerUpdateSuccess")
              );
              this.fetchAllData();
            },
            (err) => {
              if (process.env.NODE_ENV === "development") console.error(err);
              this.$bus.$emit(Messages.ERROR_MSG, {
                error: err?.response?.data?.error || err,
                description: this.$t("customerUpdateFailure"),
              });
            }
          );
          break;
        default:
          this.confirmDelete = true;
          this.confirmCallback = () =>
            ApiRequests.deleteCustomer(
              customer.id,
              () => {
                this.confirmCallback = () => {};
                this.$bus.$emit(
                  Messages.SUCCESS_MSG,
                  this.$t("customerDeleteSuccess")
                );
                this.fetchAllData();
              },
              (err) => {
                if (process.env.NODE_ENV === "development") console.error(err);
                this.confirmCallback = () => {};
                this.$bus.$emit(Messages.ERROR_MSG, {
                  error: err?.response?.data?.error || err,
                  description: this.$t("customerDeleteFailure"),
                });
              }
            );
          break;
      }
    },
    resetCustomer() {
      this.newCustomerDialog = false;
      this.editCustomerDialog = false;
      this.customer = new CustomerInfo();
    },
    handleClick(value) {
      if (value.id != undefined) {
        this.$router.push({ path: `/customers-details/${value.id}` });
      }
    },
  },
};
</script>
