import axios from "axios";
import moment from "moment";

/**
 * @typedef {Object} Credentials
 * @property {String} email - The user email.
 * @property {String} password - The user password.
 */

/**
 * @typedef {Object} UserUpdateBody
 * @property {String} groupId - Group ID.
 * @property {String} name - User's first name.
 * @property {String} surname - User's last name.
 * @property {String} email - User's email.
 * @property {Boolean} enabled - True if the user is enabled.
 */

/**
 * @typedef {Object} UserCreateBody
 * @property {String} name - User's first name.
 * @property {String} surname - User's last name.
 * @property {String} role - User's role.
 * @property {String} email - User's email.
 * @property {String[]} groupIds - Group IDs.
 * @property {Boolean} enabled - True if the user is enabled.
 * @property {String} password - User's new password.
 */

/**
 * @typedef {Object} UserPasswordBody
 * @property {String} oldPassword - Current password.
 * @property {String} newPassword - New password.
 */

/**
 * @typedef {Object} CustomerCreateBody
 * @property {String} name - Customer's name.
 * @property {String} adminName - Customer's administrator's first name.
 * @property {String} adminSurname - Customer's administrator's last name.
 * @property {String} adminEmail - Customer's administrator's email.
 * @property {String} adminPassword - Customer's administrator's password.
 * @property {Boolean} enabled - True if customer is enabled.
 * @property {String} code - Customer's code.
 */

/**
 * @typedef {Object} CompanyUpdateBody
 * @property {String} name - Customer's name.
 * @property {String} code - Customer's code.
 * @property {Boolean} enabled - True if customer is enabled.
 */

/**
 * @typedef {Object} CreateUpdateGroupBody
 * @property {String} name - Group name.
 */

/**
 * @typedef {Object} AssetUpdateBody
 * @property {String} plate - Asset's plate.
 * @property {String} companyId - Company of the asset.
 * @property {String} groupId - Group of the asset.
 * @property {String} boxMacAddress - The address of the box installed in the asset.
 */

/**
 * @typedef {Object} AssetCreateBody
 * @property {String} serial - Asset's Serial number code.
 * @property {String} plate - Asset's plate.
 * @property {String} groupId - Group of the asset.
 * @property {String} boxMacAddress - The address of the box installed in the asset.
 */

/**
 * @typedef {Object} CreateUpdateExpressionBody
 * @property {String} name - Expression name.
 * @property {String} description - Expression description.
 * @property {String} group - Expression group.
 * @property {String} code - Expression code.
 * @property {String} type - Expression type.
 * @property {String} severity - Generated alarm severity.
 * @property {Boolean} enabled - Expression enabled state.
 * @property {[String]} assetsIds - Assets to apply the expression to.
 * @property {[String]} assetsEntityCodes - Entity codes to apply the expression to.
 * @property {[Object]} structure - Expression structure.
 */

/**
 * @typedef {Object} BoxCommand
 * @property {String} group - Group of the command.
 * @property {String} field - Field of the command.
 * @property {String} value - Value to send.
 * @property {String} type - Value type (text, numeric, bool, json).
 * @property {[String]} assetIds - List of IDs to send the command to.
 * @property {Object} arguments - Additional command properties.
 */

export default class ApiRequests {
  static BACKEND_URL = process.env.VUE_APP_BACKEND_URL || "api/";

  /* Configurations */

  /**
   * Request configuration.
   * @public
   * @static
   * @param {String[]} configs - Needed configuration values.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getConfig(configs, onSuccess, onError) {
    axios
      .get(`${ApiRequests.BACKEND_URL}settings?keys=${configs.join(",")}`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Edit global configuration.
   * @public
   * @static
   * @param {Object} configs - Values to be modified.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static updateConfig(configs, onSuccess, onError) {
    axios
      .patch(`${ApiRequests.BACKEND_URL}settings`, configs)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Request a particular translation.
   * @public
   * @static
   * @param {String} locale - Needed locale.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getTranslations(locale, onSuccess, onError) {
    const ts = new Date().getTime();

    if (onSuccess !== undefined) {
      axios
        .get(`i18n/${locale}.json?ts=${ts}`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(`i18n/${locale}.json?ts=${ts}`);
  }

  /**
   * Request the list of available analysis algorithms.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getAlgorithms(onSuccess, onError) {
    const ts = new Date().getTime();

    if (onSuccess !== undefined) {
      axios
        .get(`workers/auto-algorithms/algorithms.json?ts=${ts}`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(`workers/auto-algorithms/algorithms.json?ts=${ts}`);
  }

  /**
   * Check the current version to find if new update is available.
   * @public
   * @static
   * @param {String} version - Version to be checked.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static checkVersion(version, onSuccess, onError) {
    axios
      .get(`version/${version}.json?ts=${new Date().getTime()}`)
      .then((res) => onSuccess(res))
      .catch((err) => {
        if (err.response?.status === 404) onError(err);
      });
  }

  /* Logged user management */

  /**
   * Log into an user account.
   * @public
   * @static
   * @param {Credentials} credentials - User credentials.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static userLogin(credentials, onSuccess, onError) {
    axios
      .post(`${ApiRequests.BACKEND_URL}users/login`, credentials)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Create a new user.
   * @public
   * @static
   * @param {UserCreateBody} user - The new user.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static createUser(user, onSuccess, onError) {
    axios
      .post(`${ApiRequests.BACKEND_URL}users`, user)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Get the logged user information.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static userCurrent(onSuccess, onError) {
    axios
      .get(`${ApiRequests.BACKEND_URL}users/current`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Get the logged user session token.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static tokenCurrent(onSuccess, onError) {
    axios
      .get(`${ApiRequests.BACKEND_URL}users/current/session`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Get a list of all users.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getUsers(onSuccess, onError) {
    if (onSuccess != undefined) {
      axios
        .get(`${ApiRequests.BACKEND_URL}users`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(`${ApiRequests.BACKEND_URL}users`);
  }

  /**
   * Get a list of all users emails.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getUsersEmails(onSuccess, onError) {
    if (onSuccess != undefined) {
      axios
        .get(`${ApiRequests.BACKEND_URL}users/email`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(`${ApiRequests.BACKEND_URL}users/email`);
  }

  /**
   * Update logged user information.
   * @public
   * @static
   * @param {UserUpdateBody} userBody - Updated user data.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static updateUser(userBody, onSuccess, onError) {
    axios
      .patch(`${ApiRequests.BACKEND_URL}users/current`, userBody)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Update an user.
   * @public
   * @static
   * @param {String} userId - ID of the user to be updated.
   * @param {UserUpdateBody} userBody - Updated user data.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static updateUserAdmin(userId, userBody, onSuccess, onError) {
    if (onSuccess !== undefined) {
      axios
        .patch(`${ApiRequests.BACKEND_URL}users/${userId}`, userBody)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.patch(`${ApiRequests.BACKEND_URL}users/${userId}`, userBody);
  }

  /**
   * Update logged user password.
   * @public
   * @static
   * @param {UserPasswordBody} passwordBody - Updated password data.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static updatePassword(passwordBody, onSuccess, onError) {
    axios
      .patch(
        `${ApiRequests.BACKEND_URL}users/current/change-password`,
        passwordBody
      )
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Delete an existing user.
   * @public
   * @static
   * @param {String} userId - ID of the user to be deleted.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static deleteUser(userId, onSuccess, onError) {
    if (onSuccess !== undefined) {
      axios
        .delete(`${ApiRequests.BACKEND_URL}users/${userId}`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.delete(`${ApiRequests.BACKEND_URL}users/${userId}`);
  }

  /**
   * Log out from the logged user.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static userLogout(onSuccess, onError) {
    axios
      .get(`${ApiRequests.BACKEND_URL}users/current/logout`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /* SignalR filters management */

  /**
   * Create or update a SignalR filter.
   * @public
   * @static
   * @param {String} clientId - SignalR client ID.
   * @param {String[]} dataRegexpsArray - Array of regexp SignalR filters.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static createOrUpdateFilter(clientId, dataRegexpsArray, onSuccess, onError) {
    axios
      .post(`${ApiRequests.BACKEND_URL}client-connection/filters`, {
        clientId,
        regularExpressions: dataRegexpsArray,
      })
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Delete a SignalR filter.
   * @public
   * @static
   * @param {String} clientId - SignalR client ID.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static deleteFilters(clientId, onSuccess, onError) {
    axios
      .delete(`${ApiRequests.BACKEND_URL}client-connection/filters/${clientId}`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /* Customers management */

  /**
   * Get a list of all customers.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getCustomers(onSuccess, onError) {
    if (onSuccess != undefined) {
      axios
        .get(`${ApiRequests.BACKEND_URL}companies`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(`${ApiRequests.BACKEND_URL}companies`);
  }

  /**
   * Create a new customer.
   * @public
   * @static
   * @param {CustomerCreateBody} newCustomer - The new customer to be created.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static createCustomer(newCustomer, onSuccess, onError) {
    axios
      .post(`${ApiRequests.BACKEND_URL}companies`, newCustomer)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Update an existing customer.
   * @public
   * @static
   * @param {String} customerId - ID of the customer to be updated.
   * @param {CompanyUpdateBody} customer - New customer info.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static updateCustomer(customerId, customer, onSuccess, onError) {
    if (onSuccess !== undefined) {
      axios
        .patch(`${ApiRequests.BACKEND_URL}companies/${customerId}`, customer)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.patch(
      `${ApiRequests.BACKEND_URL}companies/${customerId}`,
      customer
    );
  }

  /**
   * Delete an existing customer.
   * @public
   * @static
   * @param {String} customerId - ID of the customer to be deleted.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static deleteCustomer(customerId, onSuccess, onError) {
    if (onSuccess !== undefined) {
      axios
        .delete(`${ApiRequests.BACKEND_URL}companies/${customerId}`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.delete(`${ApiRequests.BACKEND_URL}companies/${customerId}`);
  }

  /**
   * Get data from a single company.
   * @public
   * @static
   * @param {String} customerId - ID to be fetched.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getCustomer(customerId, onSuccess, onError) {
    axios
      .get(`${ApiRequests.BACKEND_URL}companies/${customerId}`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Get data from the current company.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getCurrentCustomer(onSuccess, onError) {
    axios
      .get(`${ApiRequests.BACKEND_URL}companies/current`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /* Groups management */

  /**
   * Get a list of all groups.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getGroups(onSuccess, onError) {
    if (onSuccess != undefined) {
      axios
        .get(`${ApiRequests.BACKEND_URL}groups`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(`${ApiRequests.BACKEND_URL}groups`);
  }

  /**
   * Get data from a single group.
   * @public
   * @static
   * @param {String} groupId - ID to be fetched.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getGroup(groupId, onSuccess, onError) {
    axios
      .get(`${ApiRequests.BACKEND_URL}groups/${groupId}`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Create a new group.
   * @public
   * @static
   * @param {CreateUpdateGroupBody} newGroup - The new group to be created.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static createGroup(newGroup, onSuccess, onError) {
    axios
      .post(`${ApiRequests.BACKEND_URL}groups`, newGroup)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Update an existing group.
   * @public
   * @static
   * @param {String} groupId - ID of the group to be updated.
   * @param {CreateUpdateGroupBody} group - New group info.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static updateGroup(groupId, group, onSuccess, onError) {
    if (onSuccess !== undefined) {
      axios
        .patch(`${ApiRequests.BACKEND_URL}groups/${groupId}`, group)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.patch(`${ApiRequests.BACKEND_URL}groups/${groupId}`, group);
  }

  /**
   * Delete an existing group.
   * @public
   * @static
   * @param {String} groupId - ID of the group to be deleted.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static deleteGroup(groupId, onSuccess, onError) {
    if (onSuccess !== undefined) {
      axios
        .delete(`${ApiRequests.BACKEND_URL}groups/${groupId}`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.delete(`${ApiRequests.BACKEND_URL}groups/${groupId}`);
  }

  /* Assets management */

  /**
   * Get all assets information.
   * @public
   * @static
   * @param {String} companyId - Optional ID of the asset company.
   * @param {String} groupId - Optional ID of the asset group.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getAssets(companyId, groupId, onSuccess, onError) {
    const queryCompany = companyId != undefined ? `companyId=${companyId}` : "";
    const queryGroup = groupId != undefined ? `groupId=${groupId}` : "";
    let query = queryCompany.length > 0 && queryGroup.length > 0 ? "?" : "";

    query += queryCompany.length > 0 ? queryCompany : "";
    query +=
      queryGroup.length > 0
        ? query.length > 1
          ? `&${queryGroup}`
          : queryGroup
        : "";

    if (onSuccess != undefined) {
      axios
        .get(`${ApiRequests.BACKEND_URL}assets${query}`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(`${ApiRequests.BACKEND_URL}assets${query}`);
  }

  /**
   * Get the count of assets per company per group.
   * @public
   * @static
   * @param {String} customerId - Optional customer ID.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getAssetsCount(customerId = undefined, onSuccess, onError) {
    const query = customerId !== undefined ? `?companyId=${customerId}` : "";

    if (onSuccess !== undefined) {
      axios
        .get(`${ApiRequests.BACKEND_URL}assets/count${query}`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(`${ApiRequests.BACKEND_URL}assets/count${query}`);
  }

  /**
   * Get data from a single asset.
   * @public
   * @static
   * @param {String} assetId - ID to be fetched.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getAsset(assetId, onSuccess, onError) {
    if (onSuccess != undefined) {
      axios
        .get(`${ApiRequests.BACKEND_URL}assets/${assetId}`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(`${ApiRequests.BACKEND_URL}assets/${assetId}`);
  }

  /**
   * Create a new asset.
   * @public
   * @static
   * @param {AssetCreateBody} newAsset - The new asset to be created.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static createAsset(newAsset, onSuccess, onError) {
    axios
      .post(`${ApiRequests.BACKEND_URL}assets`, newAsset)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Edit an existing asset.
   * @public
   * @static
   * @param {String} assetId - The asset to be edited.
   * @param {AssetUpdateBody} asset - Modified asset data.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static updateAsset(assetId, asset, onSuccess, onError) {
    if (onSuccess !== undefined) {
      axios
        .patch(`${ApiRequests.BACKEND_URL}assets/${assetId}`, asset)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.patch(`${ApiRequests.BACKEND_URL}assets/${assetId}`, asset);
  }

  /**
   * Delete an existing asset.
   * @public
   * @static
   * @param {String} assetId - ID of the asset to be deleted.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static deleteAsset(assetId, onSuccess, onError) {
    if (onSuccess !== undefined) {
      axios
        .delete(`${ApiRequests.BACKEND_URL}assets/${assetId}`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.delete(`${ApiRequests.BACKEND_URL}assets/${assetId}`);
  }

  /**
   * Get the last contacts from assets.
   * @public
   * @static
   * @param {String} path - Path of the last contact measure.
   * @param {String} assetId - ID of the required asset.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static assetLastContact(path, assetId, onSuccess, onError) {
    const assetQuery = assetId != undefined ? `&assetId=${assetId}` : "";
    const now = moment().subtract(2, "month").format().replace("+", "%2B");
    const encodedPath = path.replace(/\//g, "%2F");

    axios
      .get(
        `${ApiRequests.BACKEND_URL}assets/last-seen?starting=${now}&path=${encodedPath}${assetQuery}`
      )
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Get all entity codes.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getAssetsEntityCodes(onSuccess, onError) {
    if (onSuccess !== undefined) {
      axios
        .get(`${ApiRequests.BACKEND_URL}assets/codes`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(`${ApiRequests.BACKEND_URL}assets/codes`);
  }

  /**
   * Count the assets that are linked to entity codes.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getAssetsCountPerEc(onSuccess, onError) {
    axios
      .get(`${ApiRequests.BACKEND_URL}assets/count-by-ec`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Delete the content of an asset cache.
   * @public
   * @static
   * @param {String} assetId - ID of the required asset.
   * @param {String} date - Delete cache older than this value.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static clearAssetCache(assetId, date, onSuccess, onError) {
    const query = date != undefined ? `?from=${date}` : "";

    axios
      .delete(`${ApiRequests.BACKEND_URL}assets/${assetId}/cache${query}`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Fetch all (or filtered by asset) available topics.
   * @public
   * @static
   * @param {*} assetId - ID of the required asset.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getAssetsTopics(assetId, onSuccess, onError) {
    const query = assetId != undefined ? `?assetId=${assetId}` : "";

    axios
      .get(`${ApiRequests.BACKEND_URL}assets/topics${query}`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /* Expressions management */

  /**
   * Get all expressions (or a specific one) information.
   * @public
   * @static
   * @param {String} expId - Optional ID of the expression.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getExpressions(expId = undefined, onSuccess, onError) {
    const exp = expId != undefined ? `/${expId}` : "";

    axios
      .get(`${ApiRequests.BACKEND_URL}expressions${exp}`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Create a new expression.
   * @public
   * @static
   * @param {CreateUpdateExpressionBody} newExp - The new expression to be created.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static createExpression(newExp, onSuccess, onError) {
    axios
      .post(`${ApiRequests.BACKEND_URL}expressions`, newExp)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Edit an existing expression.
   * @public
   * @static
   * @param {String} expId - The expression to be edited.
   * @param {CreateUpdateExpressionBody} expression - Modified expression data.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static updateExpression(expId, expression, onSuccess, onError) {
    if (onSuccess !== undefined) {
      axios
        .patch(`${ApiRequests.BACKEND_URL}expressions/${expId}`, expression)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.patch(
      `${ApiRequests.BACKEND_URL}expressions/${expId}`,
      expression
    );
  }

  /**
   * Delete an existing expression.
   * @public
   * @static
   * @param {String} expId - ID of the expression to be deleted.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static deleteExpression(expId, onSuccess, onError) {
    if (onSuccess !== undefined) {
      axios
        .delete(`${ApiRequests.BACKEND_URL}expressions/${expId}`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.delete(`${ApiRequests.BACKEND_URL}expressions/${expId}`);
  }

  // Saved measures

  /**
   * Get saved data from backend.
   * @public
   * @static
   * @param {AssetDataRequestBody} dataRequestBody - Info to be fetched.
   * @param {number} aggregate - How many values to be fetched.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getSavedMeasures(dataRequestBody, aggregate, onSuccess, onError) {
    const body = dataRequestBody;

    body.aggregate = aggregate;

    if (onSuccess !== undefined) {
      axios
        .post(`${ApiRequests.BACKEND_URL}assets/data`, body)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.post(`${ApiRequests.BACKEND_URL}assets/data`, body);
  }

  /**
   * Get asset data snapshot from a particular date.
   * @public
   * @static
   * @param {String} assetId - Asset to get the snapshot of.
   * @param {String} date - Date of the snapshot.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getMeasuresSnapshot(assetId, date, onSuccess, onError) {
    axios
      .get(
        `${ApiRequests.BACKEND_URL}assets/${assetId}/snapshot?timestamp=${date}`
      )
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Get saved events from backend.
   * @public
   * @static
   * @param {AssetEventsRequestBody} eventsRequestBody - Info to be fetched.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getSavedEvents(eventsRequestBody, onSuccess, onError) {
    if (onSuccess == undefined) {
      return axios.post(
        `${ApiRequests.BACKEND_URL}assets/events`,
        eventsRequestBody
      );
    }

    axios
      .post(`${ApiRequests.BACKEND_URL}assets/events`, eventsRequestBody)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Get statistics about saved events.
   * @public
   * @static
   * @param {AssetEventsStatsRequestBody} eventsStatsRequestBody - Statistics to be fetched.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getSavedEventsStats(eventsStatsRequestBody, onSuccess, onError) {
    axios
      .post(
        `${ApiRequests.BACKEND_URL}assets/events-stats`,
        eventsStatsRequestBody
      )
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Get messages associated with events codes.
   * @public
   * @static
   * @param {String} serial - Asset Serial number number.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getEventsMessages(serial, onSuccess, onError) {
    const query = serial != undefined ? `?serial=${serial}` : "";

    if (onSuccess != undefined) {
      axios
        .get(`${ApiRequests.BACKEND_URL}assets/events-messages${query}`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(
      `${ApiRequests.BACKEND_URL}assets/events-messages${query}`
    );
  }

  // Sessions management

  /**
   * Fetch saved sessions.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getSessions(onSuccess, onError) {
    if (onSuccess != undefined) {
      axios
        .get(`${ApiRequests.BACKEND_URL}interface/sessions`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(`${ApiRequests.BACKEND_URL}interface/sessions`);
  }

  /**
   * Save a session.
   * @public
   * @static
   * @param {Object} session - Session to be saved.
   * @param {Boolean} isUpdate - This update an existing session.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static saveSession(session, isUpdate, onSuccess, onError) {
    if (!isUpdate) {
      axios
        .post(`${ApiRequests.BACKEND_URL}interface/sessions`, session)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));
    } else {
      const id = session.id;
      const body = { ...session, id: undefined };

      axios
        .patch(`${ApiRequests.BACKEND_URL}interface/sessions/${id}`, body)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));
    }
  }

  /**
   * Delete a session.
   * @public
   * @static
   * @param {String} sessionId - ID of the session to be deleted.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static deleteSession(sessionId, onSuccess, onError) {
    axios
      .delete(`${ApiRequests.BACKEND_URL}interface/sessions/${sessionId}`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  // Templates management

  /**
   * Fetch saved templates.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getTemplates(onSuccess, onError) {
    if (onSuccess != undefined) {
      axios
        .get(`${ApiRequests.BACKEND_URL}interface/templates`)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(`${ApiRequests.BACKEND_URL}interface/templates`);
  }

  /**
   * Save a template.
   * @public
   * @static
   * @param {Object} template - Template to be saved.
   * @param {Boolean} isUpdate - This update an existing template.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static saveTemplate(template, isUpdate, onSuccess, onError) {
    if (!isUpdate) {
      axios
        .post(`${ApiRequests.BACKEND_URL}interface/templates`, template)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));
    } else {
      const id = template.id;
      const body = { ...template, id: undefined };

      axios
        .patch(`${ApiRequests.BACKEND_URL}interface/templates/${id}`, body)
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));
    }
  }

  /**
   * Delete a template.
   * @public
   * @static
   * @param {String} templateId - ID of the template to be deleted.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static deleteTemplate(templateId, onSuccess, onError) {
    axios
      .delete(`${ApiRequests.BACKEND_URL}interface/templates/${templateId}`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /* Report management */

  /**
   * Request the generation of a PDF report for analysis data.
   * @public
   * @static
   * @param {String} assetId - The subject of the report.
   * @param {Object} data - Data to be printed on the report.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getDataReport(assetId, data, onSuccess, onError) {
    axios
      .post(`${ApiRequests.BACKEND_URL}reports/analysis/${assetId}`, data, {
        responseType: "arraybuffer",
      })
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Request the generation of a PDF report for events.
   * @public
   * @static
   * @param {Object} data - Data to be printed on the report.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getEventsReport(data, onSuccess, onError) {
    axios
      .post(`${ApiRequests.BACKEND_URL}reports/events`, data, {
        responseType: "arraybuffer",
      })
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  // History

  /**
   * Get all values in a topic in a range of Dates.
   * @public
   * @static
   * @param {String} starting - Starting time.
   * @param {String} ending - Ending time.
   * @param {String} topic - Desired topic.
   * @param {String} serial - Asset's serial number.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getHistory(starting, ending, topic, serial, onSuccess, onError) {
    const splittedTopic = topic.split("/");

    axios
      .post(`${ApiRequests.BACKEND_URL}history`, {
        starting,
        ending,
        serial,
        group: splittedTopic[splittedTopic.length - 2],
        field: splittedTopic[splittedTopic.length - 1],
      })
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  // Commands

  /**
   * Get all box commands.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getCommands(onSuccess, onError) {
    axios
      .get(`${ApiRequests.BACKEND_URL}commands`)
      .then((res) => {
        res.data = res.data?.filter((cmd) => cmd.name.includes("/")) || [];

        onSuccess(res);
      })
      .catch((err) => onError(err));
  }

  /**
   * Create a new box command.
   * @public
   * @static
   * @param {Object} newCommand - New command.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static createCommand(newCommand, onSuccess, onError) {
    axios
      .post(`${ApiRequests.BACKEND_URL}commands`, newCommand)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Update a box command.
   * @public
   * @static
   * @param {String} cmdId - Database ID of the command to update.
   * @param {Object} update - New values.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static updateCommand(cmdId, update, onSuccess, onError) {
    axios
      .patch(`${ApiRequests.BACKEND_URL}commands/${cmdId}`, update)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Delete a box command.
   * @public
   * @static
   * @param {String} cmdId - Database ID of the command to update.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static deleteCommand(cmdId, onSuccess, onError) {
    axios
      .delete(`${ApiRequests.BACKEND_URL}commands/${cmdId}`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Send a command to a set of boxes.
   * @public
   * @static
   * @param {BoxCommand} command - Command to send.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static sendBoxCommand(command, onSuccess, onError) {
    axios
      .post(`${ApiRequests.BACKEND_URL}commands/box-command`, command)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  // Plugins

  /**
   * Get a list of available plugins.
   * @public
   * @static
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getPlugins(onSuccess, onError) {
    axios
      .get(`${ApiRequests.BACKEND_URL}plugins`)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Update plugin settings.
   * @public
   * @static
   * @param {String} id - Id of the plugin to be updated.
   * @param {Object} settings - Dictionary string-string of settings.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static updatePlugin(id, settings, onSuccess, onError) {
    axios
      .patch(`${ApiRequests.BACKEND_URL}plugins/${id}/settings`, settings)
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  /**
   * Get info about a plugin.
   * @public
   * @static
   * @param {String} name - Name of the plugin.
   * @param {String} setting - Key of the desired setting.
   * @param {function(res: *)} onSuccess - Called when the request succeeds.
   * @param {function(err: *)} onError - Called when the request fails.
   */
  static getPluginsStates(name, setting, onSuccess, onError) {
    if (onSuccess != undefined) {
      axios
        .get(
          `${ApiRequests.BACKEND_URL}plugins/installed?name=${name}&key=${setting}`
        )
        .then((res) => onSuccess(res))
        .catch((err) => onError(err));

      return;
    }

    return axios.get(
      `${ApiRequests.BACKEND_URL}plugins/installed?name=${name}&key=${setting}`
    );
  }
}
