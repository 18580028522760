import Vue from "vue";
import BaseMsg from "../models/BaseMsg";
import Event from "../models/Event";
import Measure from "../models/Measure";
import Metadata from "../models/Metadata";
import moment from "moment";

const localTime = (timestamp = undefined) => {
  return timestamp != undefined
    ? moment.utc(timestamp).local()
    : moment.utc().local();
};

// Used to update the online/offline status of assets
const evaluateOffline = (lastSeen, offline, offlineTimeout) => {
  Object.keys(lastSeen).forEach((mac) => {
    // Create a copy to avoid side effects
    const tsCopy = localTime(lastSeen[mac] || 0);
    tsCopy.add(offlineTimeout, "minutes");

    if (lastSeen[mac] != undefined) {
      Vue.set(offline, mac, tsCopy.isBefore(localTime(), "minutes"));
    }
  });
};

const setCount = (id, severity, count, amount) => {
  if (count[id] == undefined) {
    count[id] = {
      [Event.severity.HIGH]: 0,
      [Event.severity.MEDIUM]: 0,
      [Event.severity.LOW]: 0,
    };
  }

  count[id][severity] = Math.max(0, count[id][severity] + amount);

  return count;
};

const countAmount = (event) => {
  return event._contentType !== Event.types.UP
    ? event._contentType === Event.types.DOWN
      ? -1
      : 0
    : 1;
};

const dataVuexModule = {
  state: {
    allAssets: [],
    allGroups: [],
    allCustomers: [],
    allUsers: [],
    allCommands: [],
    idSerialMap: {},
    serialMacMap: {},
    macSerialPlateMap: {},
    tagsById: {},
    lastSeen: {},
    offline: {},
    events: {},
    eventMessages: {},
    alarmsCount: 0,
    alarmsCountById: {},
    stopAlarmsCount: false,
    measures: {},
    offlineInterval: undefined,
    models: {},
    types: {},
    lastMeasure: undefined,
    availableTopics: {},
  },

  mutations: {
    setGroups: (state, groupsList) => (state.allGroups = groupsList),
    setUsers: (state, usersList) => (state.allUsers = usersList),
    setOffline: (state, { mac, value }) => Vue.set(state.offline, mac, value),
    resetAlarmsCount: (state) => (state.alarmsCount = 0),
    offAlarmsCount: (state) => (state.stopAlarmsCount = true),
    onAlarmsCount: (state) => (state.stopAlarmsCount = false),
    setEventMessages: (state, metadata) => (state.eventMessages = metadata),
    clearEventsList: (state) => (state.events = {}),
    setAvailableTopics: (state, topics) => (state.availableTopics = topics),
    setAlarmsCountById: (state, count) => (state.alarmsCountById = count),
    setCustomers: (state, customersList) =>
      (state.allCustomers = customersList),
    setAssetsCommands: (state, commandsList) =>
      (state.allCommands = commandsList),
    addTopicsToAsset: (state, { box, topics }) => {
      topics.forEach((topic) => {
        if (state.measures[box] == undefined) {
          Vue.set(state.measures, box, {});
        }

        if (state.measures[box][topic] == undefined) {
          Vue.set(state.measures[box], topic, {
            value: new Measure({
              Name: `measures/${box}/${topic}`,
              ContentType: Measure.types.NUMERIC,
              Properties: {},
            }),
          });
        }
      });
    },
    enumerateAssets: (state, assetsList) => {
      state.allAssets = assetsList;

      assetsList.forEach((asset) => {
        Vue.set(state.idSerialMap, asset.id, asset.serial);
        Vue.set(state.macSerialPlateMap, asset.serial, {
          plate: asset.plate,
          id: asset.id,
        });

        // Enumerate Influx tags

        state.tagsById[asset.id] = asset.influxTags(false).map((tag) => ({
          name: tag,
          value: asset.custom[tag],
        }));

        // Enumerate models

        if (asset.brand && state.models[asset.brand] === undefined) {
          state.models[asset.brand] = [];
        }

        if (
          asset.brand &&
          !state.models[asset.brand].includes(asset.modelVersion)
        ) {
          Vue.set(
            state.models[asset.brand],
            state.models[asset.brand].length,
            asset.modelVersion
          );
        }

        // Enumerate types

        if (asset.type && state.types[asset.type] === undefined) {
          state.types[asset.type] = [];
        }

        if (asset.type && !state.types[asset.type].includes(asset.subType)) {
          Vue.set(
            state.types[asset.type],
            state.types[asset.type].length,
            asset.subType
          );
        }

        if (asset.boxMacAddress) {
          Vue.set(state.serialMacMap, asset.serial, asset.boxMacAddress);
          Vue.set(state.lastSeen, asset.boxMacAddress, null);
          Vue.set(state.offline, asset.boxMacAddress, null);
          Vue.set(state.measures, asset.boxMacAddress, {});
          Vue.set(state.macSerialPlateMap, asset.boxMacAddress, {
            plate: asset.plate,
            serial: asset.serial,
            id: asset.id,
            group: asset.groupId,
          });
        }
      });

      if (assetsList.length === 0) {
        state.idSerialMap = {};
        state.serialMacMap = {};
        state.lastSeen = {};
        state.offline = {};
        state.measures = {};
        state.macSerialPlateMap = {};

        if (state.offlineInterval != undefined) {
          clearInterval(evaluateOffline);
          state.offlineInterval = undefined;
        }
      }
    },
    clearOfflineInterval: (state) => {
      if (state.offlineInterval != undefined) {
        clearInterval(evaluateOffline);
        state.offlineInterval = undefined;
      }
    },
    enumerateLastContact: (state, { contacts, offlineTimeout }) => {
      Object.keys(contacts).forEach((k) => {
        const serial = state.idSerialMap[k];

        if (serial != undefined) {
          const mac = state.serialMacMap[serial];
          const contact = contacts[k];

          if (contact != undefined && mac != undefined) {
            const savedValue = state.lastSeen[mac];
            const ts = localTime(contact);

            if (savedValue == undefined || savedValue.isBefore(ts, "seconds")) {
              Vue.set(state.lastSeen, mac, ts);

              // Create a copy to avoid side effects
              const tsCopy = localTime(contact);
              tsCopy.add(offlineTimeout, "minutes");

              Vue.set(
                state.offline,
                mac,
                tsCopy.isBefore(localTime(), "minutes")
              );

              if (state.offlineInterval == undefined) {
                state.offlineInterval = setInterval(
                  evaluateOffline,
                  60000,
                  state.lastSeen,
                  state.offline,
                  offlineTimeout
                );
              }
            }
          }
        }
      });
    },
    setLastSeen: (state, { probe, offlineTimeout }) => {
      const mac = probe.originId;
      const savedValue = state.lastSeen[mac];
      const ts = localTime(probe.ts);

      if (savedValue == undefined || savedValue.isBefore(ts, "seconds")) {
        Vue.set(state.lastSeen, mac, ts);

        // Create a copy to avoid side effects
        const tsCopy = localTime(probe.ts);
        tsCopy.add(offlineTimeout, "minutes");

        Vue.set(state.offline, mac, tsCopy.isBefore(localTime(), "minutes"));

        if (state.offlineInterval == undefined) {
          state.offlineInterval = setInterval(
            evaluateOffline,
            60000,
            state.lastSeen,
            state.offline,
            offlineTimeout
          );
        }
      }
    },
    setEvent: (state, event) => {
      // Prevent the visualization of alarms coming from non-managed assets
      if (
        !(event.originId in state.macSerialPlateMap) &&
        !(event.serial in state.macSerialPlateMap)
      ) {
        return;
      }

      if (state.events[event.originId] == undefined) {
        Vue.set(state.events, event.originId, {});
      }

      if (state.events[event.originId][event.msgPath] == undefined) {
        Vue.set(state.events[event.originId], event.msgPath, []);
      }

      const savedValue = state.events[event.originId][event.msgPath];

      if (state.eventMessages[event.msgPath] != undefined) {
        event._attributes.message = state.eventMessages[event.msgPath];
      } else if (event._attributes.message != undefined) {
        state.eventMessages[event.msgPath] = event._attributes.message;
      }

      event._attributes.assetId =
        (state.macSerialPlateMap[event.serial] || {}).id ||
        (state.macSerialPlateMap[event.originId] || {}).id;
      event._attributes.plate =
        (state.macSerialPlateMap[event.serial] || {}).plate ||
        (state.macSerialPlateMap[event.originId] || {}).plate ||
        (
          state.macSerialPlateMap[
            (state.macSerialPlateMap[event.originId] || {}).serial
          ] || {}
        ).plate ||
        event.serial ||
        event.originId;

      let replaced = false;

      savedValue.sort((a, b) => b.ts - a.ts);

      if (event._contentType === Event.types.DOWN) {
        for (
          let index = 0;
          index < state.events[event.originId][event.msgPath].length;
          index++
        ) {
          if (
            state.events[event.originId][event.msgPath][index]._contentType ===
            Event.types.UP
          ) {
            event._downTs = event._ts;
            event._ts = state.events[event.originId][event.msgPath][index]._ts;
            state.events[event.originId][event.msgPath][index] = event;
            replaced = true;

            break;
          }
        }
      }

      // Inject current Influx tags
      if (event.isFromSignalR) {
        const tags = state.tagsById[event._attributes.assetId] || [];

        tags.forEach((tag) => {
          event._attributes[tag.name] = tag.value;
        });
      }

      if (!replaced) {
        savedValue.unshift(event);
      }
      if (!state.stopAlarmsCount && event._contentType !== Event.types.DOWN) {
        state.alarmsCount += 1;
      }

      Vue.set(
        state.events[event.originId],
        event.msgPath,
        savedValue.slice(0, 3000) // Max 3000 alarms/asset
      );

      if (!event.isFromSignalR) return;

      // Update alarms counter
      state.alarmsCountById = setCount(
        state.macSerialPlateMap[event.originId].id,
        event.severity,
        state.alarmsCountById,
        countAmount(event)
      );

      if (
        state.macSerialPlateMap[event.originId].group != undefined &&
        state.macSerialPlateMap[event.originId].group != ""
      ) {
        state.alarmsCountById = setCount(
          state.macSerialPlateMap[event.originId].group,
          event.severity,
          state.alarmsCountById,
          countAmount(event)
        );
      }
    },
    clearMeasure: (state, originId) => {
      Vue.set(state.measures, originId, {});
    },
    setMeasure: (state, measure) => {
      if (state.measures[measure.originId] == undefined) {
        Vue.set(state.measures, measure.originId, {});
      }

      if (state.measures[measure.originId][measure.msgPath] == undefined) {
        Vue.set(state.measures[measure.originId], measure.msgPath, {});
      }

      const savedValue =
        state.measures[measure.originId][measure.msgPath].value;

      if (savedValue == undefined || (savedValue.ts || 0) < measure.ts) {
        Vue.set(
          state.measures[measure.originId][measure.msgPath],
          "value",
          measure
        );

        state.lastMeasure = measure;
      }
    },
    setMetadata: (state, metadata) => {
      if (state.measures[metadata.originId] == undefined) {
        Vue.set(state.measures, metadata.originId, {});
      }

      const path = metadata.msgPath.replace(`/${metadata.label}`, "");

      if (state.measures[metadata.originId][path] == undefined) {
        Vue.set(state.measures[metadata.originId], path, {});
      }

      Vue.set(
        state.measures[metadata.originId][path],
        metadata.label,
        metadata
      );
    },
  },

  actions: {
    setLastContacts: ({ commit, getters }, contacts) => {
      const offlineTimeout = getters.number("VUE_APP_OFFLINE_TIMEOUT_MINUTES");

      commit("enumerateLastContact", { contacts, offlineTimeout });
    },
    saveMessage: ({ commit, getters }, message) => {
      const rawMsg =
        typeof message === "string" ? JSON.parse(message) : message;
      const baseMessage = new BaseMsg(rawMsg);
      const dollarIndex = baseMessage.msgPath.indexOf("$");

      // Required settings
      const events = getters.string("VUE_APP_EVENTS_TOPIC");
      const measures = getters.string("VUE_APP_MEASURES_TOPIC");
      const offlineTimeout = getters.number("VUE_APP_OFFLINE_TIMEOUT_MINUTES");

      if (
        !baseMessage.basePath.includes(events) &&
        !baseMessage.basePath.includes(measures)
      ) {
        /* Not managed message */
        return;
      }

      if (baseMessage.basePath.includes(events)) {
        if (dollarIndex < 0) {
          const parsed = new Event(rawMsg);

          parsed.isFromSignalR = rawMsg.isFromSignalR;

          if (rawMsg.saveTrace || parsed.severity !== Event.severity.TRACE) {
            commit("setEvent", parsed);
          }
        }

        return;
      }

      if (dollarIndex < 0) {
        // eslint-disable-next-line prettier/prettier
        let lastSeenTopic = getters.string("VUE_APP_LAST_SEEN_TOPIC").split("/");

        lastSeenTopic.shift();
        lastSeenTopic = lastSeenTopic.join("/");

        const parsed = new Measure(rawMsg);

        commit("setMeasure", parsed);

        // Connection probe message
        if (parsed.msgPath == lastSeenTopic) {
          commit("setLastSeen", { probe: parsed, offlineTimeout });
        }
      } else {
        const parsed = new Metadata(rawMsg);

        commit("setMetadata", parsed);
      }
    },
  },

  getters: {
    idSerialMap: (state) => state.idSerialMap,
    serialMacMap: (state) => state.serialMacMap,
    serialById: (state) => (id) => state.idSerialMap[id],
    macBySerial: (state) => (serial) => state.serialMacMap[serial],
    assetIdsByMac: (state) => (mac) => state.macSerialPlateMap[mac],
    lastSeenBySerial: (state) => (serial) =>
      state.lastSeen[state.serialMacMap[serial]],
    allAssets: (state) => state.allAssets,
    assetBySerial: (state) => (serial) =>
      state.allAssets.find((a) => a.serial === serial),
    assetById: (state) => (id) =>
      state.allAssets.find((a) => a.serial === state.idSerialMap[id]),
    allGroups: (state) => state.allGroups,
    allCustomers: (state) => state.allCustomers,
    customerById: (state) => (id) =>
      state.allCustomers.find((c) => c.id === id),
    allUsers: (state) => state.allUsers,
    userById: (state) => (id) => state.allUsers.find((u) => u.id === id),
    allEvents: (state) => state.events,
    alarmsCount: (state) => state.alarmsCount,
    eventsById: (state) => (serial) => state.events[state.serialMacMap[serial]],
    measures: (state) => state.measures,
    measuresById: (state) => (serial) =>
      state.measures[state.serialMacMap[serial]],
    lastMeasure: (state) => state.lastMeasure,
    offline: (state) => state.offline,
    models: (state) => state.models,
    types: (state) => state.types,
    eventMessages: (state) => state.eventMessages,
    availableTopics: (state) => state.availableTopics,
    alarmsCountById: (state) => state.alarmsCountById,
    allCommands: (state) => state.allCommands,
    editableCommands: (state) =>
      state.allCommands.filter((cmd) => cmd.editable),
  },
};

export default dataVuexModule;
